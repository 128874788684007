import type { ConsultationChargeSchema, consultationPaymentsContract } from '@montugroup/payments-contracts';
import type { ClientInferResponses } from '@ts-rest/core';

import useConsultationPaymentContractClient from '../clients/useConsultationPaymentContractClient';

export function usePostEwayInitialConsultCharge() {
  const client = useConsultationPaymentContractClient();
  const { mutateAsync, ...rest } = client.charge.useMutation();
  const doPost = async (
    payload: ConsultationChargeSchema
  ): Promise<ClientInferResponses<typeof consultationPaymentsContract.charge>> => {
    return mutateAsync({ body: { data: payload } });
  };

  return { doPost, ...rest };
}

export default usePostEwayInitialConsultCharge;
