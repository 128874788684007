import axios from 'axios';

import settings from '@/data/constants';

type PutDeactivateDoctorParams = {
  doctorId: number;
  userId: number;
  modifiedBy: number;
};

export const putDeactivateDoctor = async ({ doctorId, userId, modifiedBy }: PutDeactivateDoctorParams) => {
  const putDeactivateDoctorUrl = `${settings.url}/doctor/deactivateDoctor`;
  const requestBody = {
    doctorId,
    userId,
    modifiedBy
  };
  const doctorsResponse = await axios.put(putDeactivateDoctorUrl, requestBody);

  return doctorsResponse.data;
};
