// Foreword:
// Ideally this hook should return the result of a form utility such as `useFormik` from Formik or `useForm` from
// React-Hook-Form and manage consistent schema validation. For now however, I deemed refactoring the entirety of the
// prescription forms out of scope. I still chose to consolidate some initial common logic here due to the duplication
// of the prescription form logic existing in `ConsultationTab`.
// https://formik.org/docs/examples/field-arrays
// https://react-hook-form.com/api/usefieldarray/

export type PrescriptionFormProduct = {
  product_id: string | number; // Snake case for legacy
  name?: string;
  dosage?: string;
  quantity?: string | number; // This shouldn't also need to be a string...
  other_product?: string;
  repeats?: string | number;
  interval?: string | number;
  is_concession?: boolean;
};

/**
 * Vaporizer product basically
 */
export interface DeviceProduct {
  product_id: number;
  name: string;
  quantity: number;
  vaporizer: boolean;
}

export const initialPrescriptionProductValues: PrescriptionFormProduct = {
  product_id: '',
  name: '',
  dosage: '',
  quantity: '',
  other_product: '',
  repeats: '',
  interval: '',
  is_concession: false
};

export function usePrescriptionForm() {
  return {
    tmpHelpers: {
      initialPrescriptionProductValues
    }
  };
}

export default usePrescriptionForm;
