import { Box, styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import type { ArrayPath, Control, FieldValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import DayRanges from '../../shared/components/DayRanges';

/**
 * Schedule Day component
 *
 * @remarks
 * Used within the Availability Scheduler to render the availability for a day of the week
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'ScheduleDay'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param name - String to identify the form
 * @param control - React-hook-form control. This object contains methods for registering components into React Hook Form
 * @param weekday - The day of the week
 * @param CopyDayButton - CopyDayButton component
 *
 * @returns JSXElement
 *
 */

export const defaultDayRange = {
  start: new Date(new Date().setUTCHours(9, 0, 0, 0)).toISOString(),
  end: new Date(new Date().setUTCHours(17, 0, 0, 0)).toISOString()
};

const StyledScheduleDay = styled(Box)({
  display: 'flex',
  marginBottom: 15
});

const StyledLabel = styled(Box)({
  width: '200px'
});

const ScheduleDay = <TFieldValues extends FieldValues>({
  name,
  weekday,
  control,
  CopyDayButton
}: {
  name: ArrayPath<TFieldValues>;
  weekday: string;
  control: Control<TFieldValues>;
  CopyDayButton: JSX.Element;
}) => {
  const { watch, setValue } = useFormContext();
  const watchDayRange = watch(name);
  return (
    <StyledScheduleDay data-testid="day-of-week">
      <StyledLabel>
        <Switch
          disabled={!watchDayRange}
          defaultChecked={watchDayRange?.length > 0}
          checked={!!watchDayRange?.length}
          onChange={(event) => {
            const isChecked = event.target.checked;
            setValue(name, (isChecked ? [defaultDayRange] : []) as TFieldValues[typeof name], { shouldDirty: true });
          }}
        />
        <span>{weekday}</span>
      </StyledLabel>
      {watchDayRange && (
        <>
          <Box>
            <DayRanges control={control} name={name} />
          </Box>
          {!!watchDayRange.length && <div>{CopyDayButton}</div>}
        </>
      )}
    </StyledScheduleDay>
  );
};

export default ScheduleDay;
