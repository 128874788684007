import { Alert } from '@mui/material';
import { useState } from 'react';

import type { ConcessionCardFile, ConcessionChipProps, ConcessionDialogProps } from '..';
import { CONCESSION_CARD_TYPE, ConcessionChip, ConcessionDialog } from '..';
import settings from '../../../data/constants';
import type { ConcessionCardReponse } from '../../../hooks/rest/types';
import usePostPatientConcessionCard from '../../../hooks/rest/usePostPatientConcessionCard';
import usePostPatientDocumentUpload from '../../../hooks/rest/usePostPatientDocumentUpload';
import usePutPatientConcessionCard from '../../../hooks/rest/usePutPatientConcessionCard';
import { isAxiosErrorWithCommonErrorPayload } from '../../../utils/axios';
import { parseMomentYearMonthDay } from '../utils';

type ConcessionCard = ConcessionCardReponse;

export type ManageConcessionProps = {
  patientId: string | number;
  ready?: boolean;
  concessionCard?: ConcessionCard;
  isConcessionCardValid?: boolean;
  onChangeConcessionCard?: (newCard: ConcessionCard) => void;
};

export const ManageConcession = ({
  ready,
  patientId,
  concessionCard,
  isConcessionCardValid,
  onChangeConcessionCard
}: ManageConcessionProps) => {
  const { doPost: postUpload, error: uploadError } = usePostPatientDocumentUpload();
  const { doPost: postCard, error: cardPostError } = usePostPatientConcessionCard({ patientId });
  const { doPut: putCard, error: cardPutError } = usePutPatientConcessionCard({ patientId });

  const deriveDialogErrorMessage = () => {
    if (uploadError) {
      return 'The card image could not be uploaded, please try again or contact support';
    }
    const cardError = cardPostError || cardPutError;
    if (cardError) {
      const cardErrorMessage = isAxiosErrorWithCommonErrorPayload(cardError)
        ? (cardError?.response?.data.message as string)
        : cardError instanceof Error
          ? cardError.message
          : String(cardError);
      if (cardErrorMessage.includes('expiry date')) {
        return 'There was a problem uploading the concession details expiry date';
      }
      if (cardErrorMessage.includes('UniqueConstraintError')) {
        return 'There was a problem uploading the concession details, the same card information already exists';
      }
      return 'There was a problem uploading the concession details, please try again or contact support';
    }
    return undefined;
  };

  const dialogErrorMessage = deriveDialogErrorMessage();

  const [open, setOpen] = useState(false);

  // Note: ATSI is deprecated as a new concession card type, however there may be existing data from the BE...
  const hasConcessionAtsi = concessionCard && concessionCard.cardType === CONCESSION_CARD_TYPE.ATSI;
  const hasConcessionCard = concessionCard && concessionCard.cardType !== CONCESSION_CARD_TYPE.ATSI;

  const cardExpiry =
    concessionCard &&
    concessionCard.expiryYear &&
    concessionCard.expiryMonth &&
    concessionCard.expiryDay &&
    parseMomentYearMonthDay(concessionCard.expiryYear, concessionCard.expiryMonth, concessionCard.expiryDay);

  const chipState: ConcessionChipProps['state'] =
    ready === false
      ? 'loading'
      : concessionCard && isConcessionCardValid
        ? 'validConcession'
        : concessionCard && !isConcessionCardValid
          ? 'expiredConcession'
          : 'noConcession';

  const assignImageIdentifier = (
    file: File | ConcessionCardFile,
    identifier: string | null | undefined
  ): ConcessionCardFile => {
    return Object.assign(file, { identifier: identifier || undefined });
  };

  const cardFormProps: ConcessionDialogProps['cardFormProps'] = {
    ...(hasConcessionCard && !hasConcessionAtsi
      ? {
          defaultValues: {
            ...(cardExpiry && { cardExpiry }),
            // For now to avoid making another network request to get the actual file information, just stub it.
            cardImage: concessionCard.documentIdentifier
              ? assignImageIdentifier(new File([], 'Concession Card'), concessionCard.documentIdentifier)
              : null,
            ...(concessionCard.cardNumber && { cardNumber: concessionCard.cardNumber }),
            ...(concessionCard.cardType && { cardType: concessionCard.cardType })
          }
        }
      : {})
  };

  const handleClickChip = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const ensureImagePersistedAndGetIdentifier = async (file: ConcessionCardFile): Promise<string | undefined> => {
    if (file.identifier) {
      // Already persisted
      return file.identifier;
    }
    const { data: uploadData } = await postUpload({
      file: file,
      fileName: `concession-${file.name}`,
      expiryDate: '9999-12-31',
      patientId,
      documentTypeId: settings.patientDocumentUploadType.other
    });
    if (!uploadData) {
      return;
    }
    return `${uploadData.id}`;
  };

  // The difference between a new card and existing card update is linked to the card number.
  const determineIfWantsCardUpdate = (submittingCardNumber: string) => {
    if (concessionCard && concessionCard.cardNumber === submittingCardNumber) {
      return true;
    }
    return false;
  };

  const handleSubmitConcession: ConcessionDialogProps['onSubmitConcession'] = async ({
    cardImage,
    cardNumber,
    cardType,
    expiryDay,
    expiryMonth,
    expiryYear
  }) => {
    const imageIdentifier = await ensureImagePersistedAndGetIdentifier(cardImage);
    if (!imageIdentifier) {
      return;
    }
    const isAnUpdate = determineIfWantsCardUpdate(cardNumber);
    const mutator = isAnUpdate ? putCard : postCard;
    const { data: newCard } = await mutator({
      ...(concessionCard?.id && { id: concessionCard.id }),
      cardType,
      cardNumber,
      expiryDay,
      expiryMonth,
      expiryYear,
      documentIdentifier: imageIdentifier
    });
    if (newCard) {
      onChangeConcessionCard?.(newCard);
      handleCloseDialog();
    }
    return {
      cardImage: assignImageIdentifier(cardImage, imageIdentifier),
      cardExpiry: parseMomentYearMonthDay(expiryYear, expiryMonth, expiryDay),
      cardNumber,
      cardType
    };
  };

  return (
    <>
      <ConcessionChip state={chipState} onClick={handleClickChip} />
      <ConcessionDialog
        open={open}
        onClose={handleCloseDialog}
        onSubmitConcession={handleSubmitConcession}
        alert={dialogErrorMessage ? <Alert severity="error">{dialogErrorMessage}</Alert> : null}
        cardFormProps={cardFormProps}
      />
    </>
  );
};

export default ManageConcession;
