import iziToast from 'izitoast';

import { getDoctorPatientAndConsultCount } from '@/api/doctor/getDoctorPatientAndConsultCount';
import { putDeactivateDoctor } from '@/api/doctor/putDeactivateDoctor';
import { getAuthData } from '@/data/service/authService';
import type { IziToastInstance } from '@/types';

import type { RefetchDoctors } from './useGetDoctors';

const { user } = getAuthData();

export const useDeactivateDoctor = (refetchDoctors: RefetchDoctors) => {
  const deactivateDoctor = async (doctorId: number, doctorUserId: number) => {
    const response = await putDeactivateDoctor({
      doctorId: doctorId,
      userId: doctorUserId,
      modifiedBy: user?.id
    });

    if (response) {
      iziToast.success({
        title: 'Doctor successfully deactivated',
        position: 'topRight'
      });
      refetchDoctors();
    } else {
      iziToast.error({
        title: 'Failed to deactivate doctor!',
        message: 'Please try again later.',
        position: 'topRight'
      });
    }
  };

  // TODO - Is this still the way we want to display these kinds of messages?
  // With the PMS re-skin, this might be a deprecated pattern. Ideally, this
  // should be in a standard modal with buttons, font etc aligned to the theme
  const handleAlertMsg = (
    message: string,
    is_deactivate: boolean,
    doctorUserId: number,
    doctorId: number,
    type: string
  ) => {
    // TODO - We will likely be updating this logic when we reskin PMS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    iziToast[type]({
      id: 'deactivate-doctor',
      timeout: false,
      displayMode: 1,
      zindex: 999,
      overlay: true,
      message: message,
      messageLineHeight: '25',
      position: 'center',
      buttons: is_deactivate && [
        [
          '<button style="margin-top:5px"><b>Yes</b></button>',
          function (instance: IziToastInstance, toast: Element) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'Yes');
          },
          true
        ],
        [
          '<button style="margin-top:5px">Cancel</button>',
          function (instance: IziToastInstance, toast: Element) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'No');
          }
        ]
      ],
      onClosed: async (_instance: IziToastInstance, _toast: Element, closedBy: string) => {
        if (closedBy === 'Yes') {
          deactivateDoctor(doctorId, doctorUserId);
        }
      }
    });
  };

  const handleDeactivateDoctor = async (doctorId: number, doctorUserId: number, doctorCode: string) => {
    const response = await getDoctorPatientAndConsultCount(doctorId);

    if (response) {
      if (response.consultationCount > 0 || response.patientCount > 0) {
        // TODO - Is 'IziToast' still the way we want to display these kinds of messages?
        // With the PMS re-skin, this might be a deprecated pattern. Ideally, this
        // should be in a standard modal with buttons, font etc aligned to the theme
        handleAlertMsg(
          `<strong>Doctor ${doctorCode} has ${response.patientCount} active patients and ${response.consultationCount} active consultations</strong>.
          <br />They cannot be deactivated until after all of their active patients/consultations have been cancelled or reassigned.`,
          false,
          doctorUserId,
          doctorId,
          'info'
        );
      } else {
        handleAlertMsg(
          `<div style="margin-bottom: 7px">Are you sure you want to deactivate Doctor ${doctorCode}<br/><span style="color:red">This action is irreversible!</span></div>`,
          true,
          doctorUserId,
          doctorId,
          'question'
        );
      }
    }
  };

  return {
    handleDeactivateDoctor
  };
};
