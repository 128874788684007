import { useEffect, useMemo, useRef } from 'react';

import configureGTM from '@/services/googleTagManager.service';
import datadog, { customAction } from '@/utils/datadog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useTrackingProviders(settings: any) {
  const mpRef = useRef<(eventName: string, data?: unknown) => void>();

  useEffect(() => {
    datadog(settings);
    configureGTM(settings);
  }, [settings]);

  return {
    mpTrackEvent: mpRef.current,
    ddTrackAction: useMemo(
      () =>
        (topic: string, payload = {}) =>
          customAction(topic, payload),
      []
    )
  };
}
