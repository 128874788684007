import type { TextFieldProps } from '@mui/material';
import { MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { ConcessionCardType } from '..';
import { CONCESSION_CARD_TYPE } from '..';

export type ConcessionCardTypeTextFieldProps = Pick<
  TextFieldProps,
  'error' | 'value' | 'onChange' | 'helperText' | 'disabled'
> & {
  value?: Omit<ConcessionCardType, 'ATSI'> | '';
};

const StyledTextField = styled(TextField)({
  minWidth: '337px'
});

export const ConcessionCardTypeTextField = ({
  error,
  value,
  onChange,
  helperText,
  disabled
}: ConcessionCardTypeTextFieldProps) => {
  return (
    <StyledTextField
      label="Select concession card type"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      select
      disabled={disabled}
    >
      <MenuItem value={CONCESSION_CARD_TYPE.HEALTH_CARE}>Health Care Card</MenuItem>
      <MenuItem value={CONCESSION_CARD_TYPE.PENSIONER}>Pensioner Concession Card</MenuItem>
      <MenuItem value={CONCESSION_CARD_TYPE.COMMONWEALTH_SENIOR}>Commonwealth Seniors Health Card</MenuItem>
      <MenuItem value={CONCESSION_CARD_TYPE.VETERAN}>Veterans Card Holders (DVA)</MenuItem>
    </StyledTextField>
  );
};

export default ConcessionCardTypeTextField;
