import { WithSnowplow } from '@montugroup/data-collection';
import React from 'react';
import { FF_ENABLE_SNOWPLOW } from '../constants/featureFlags';
import { SNOWPLOW_SETTINGS } from '../constants/snowplow';
import { getUser } from '../data/service/authService';
import { useFeatureFlags } from '../hooks';

// This component acts as a wrapper around WithSnowplow from data-collection
// It ensures the user_id field is properly set at all times
export const AuthenticatedSnowplow = (props: React.PropsWithChildren) => {
  const { children } = props;
  const { flags } = useFeatureFlags();
  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW];
  const user = getUser();
  let userId = null;
  if (
    user &&
    user.constructor === Object &&
    user.id !== undefined &&
    user.id !== null &&
    ['string', 'number'].includes(typeof user.id)
  ) {
    try {
      userId = user.id.toString();
      console.info('The user has been set in snowplow:', userId);
    } catch (error) {
      console.error('Failed to set user in snowplow:', error);
    }
  }

  return WithSnowplow(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>,
    {
      appId: SNOWPLOW_SETTINGS.appId,
      trackerName: SNOWPLOW_SETTINGS.trackerName,
      userId: userId
    },
    enableSnowplow
  );
};

export default AuthenticatedSnowplow;
