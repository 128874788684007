import axios from 'axios';

import settings from '@/data/constants';

export type DoubleBookingsPatientModel = {
  count: number;
  patient_id: number;
  types?: string[];
  nextConsultation?: string;
  Patient: {
    patient_code: string;
    PatientUser: {
      first_name: string;
      last_name: string;
    };
  };
};
export type DoubleBookingsPracitionerModel = {
  start_time: string;
  doctor_id: number | null;
  nurse_id: number | null;
  count: number;
  Doctor: {
    doctor_code: string;
    DoctorUser: {
      first_name: string;
      last_name: string;
    };
  } | null;
  Nurse: {
    nurse_code: string;
    NurseUser: {
      first_name: string;
      last_name: string;
    };
  } | null;
};

export type GetConsultationDoubleBookingsResponse = {
  patients: DoubleBookingsPatientModel[];
  pracitioners: DoubleBookingsPracitionerModel[];
};

export const getConsultationDoubleBookings = async () => {
  const url = `${settings.url}/consultation/double-bookings/find`;
  const response = await axios.get<GetConsultationDoubleBookingsResponse>(url);

  return response?.data;
};
