/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum Country {
  Au = 'AU'
}

export enum Currency {
  Aud = 'AUD'
}

export type Mutation = {
  __typename?: 'Mutation';
  registerPayment: PaymentResponseModel;
};

export type MutationRegisterPaymentArgs = {
  registerPaymentInput: PaymentInput;
};

export type PaymentCardDataModel = {
  __typename?: 'PaymentCardDataModel';
  _TYPE: Scalars['String'];
  cardHolder: Scalars['String'];
  lastFourDigits: Scalars['String'];
};

export type PaymentErrorModel = {
  __typename?: 'PaymentErrorModel';
  adapterCode: Scalars['String'];
  adapterMessage: Scalars['String'];
  errorCode: Scalars['Float'];
  errorMessage: Scalars['String'];
};

export type PaymentInput = {
  /** country of the transaction */
  country: Country;
  /** ISO currency code of the transaction */
  currency: Currency;
  /** email address of the customer transacting */
  email: Scalars['String'];
  /** A merchant transaction description like e.g. "Consult Booking" */
  merchantTransactionDescription: Scalars['String'];
  /** A merchant transaction id e.g booking Id or a cart Id */
  merchantTransactionId: Scalars['String'];
  nameOnCard: Scalars['String'];
  /** payment type for the transaction */
  paymentType: PaymentType;
  /** tokenized transaction (Card) from the payment provider */
  transactionToken: Scalars['String'];
};

export type PaymentResponseModel = {
  __typename?: 'PaymentResponseModel';
  errors?: Maybe<Array<PaymentErrorModel>>;
  paymentMethod?: Maybe<PaymentType>;
  purchaseId: Scalars['String'];
  redirectType?: Maybe<Scalars['String']>;
  /** only populated if returnType is REDIRECT */
  redirectUrl?: Maybe<Scalars['String']>;
  returnData?: Maybe<PaymentCardDataModel>;
  returnType?: Maybe<PaymentReturnType>;
  success: Scalars['Boolean'];
  uuid: Scalars['String'];
};

export enum PaymentReturnType {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Pending = 'PENDING',
  Redirect = 'REDIRECT'
}

export type PaymentStatusInput = {
  /** email address of the customer transacting */
  email: Scalars['String'];
  /** A merchant transaction id e.g booking Id or a cart Id */
  merchantTransactionId: Scalars['String'];
};

export type PaymentStatusResponseModel = {
  __typename?: 'PaymentStatusResponseModel';
  debitTransactionExists: Scalars['Boolean'];
  paymentStatus?: Maybe<PaymentStatus>;
  preAuthTransactionExists: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  getPaymentStatus: PaymentStatusResponseModel;
  health: Scalars['String'];
};

export type QueryGetPaymentStatusArgs = {
  getPaymentStatusInput: PaymentStatusInput;
};

export enum PaymentStatus {
  None = 'NONE',
  Paid = 'PAID',
  PreAuthorised = 'PRE_AUTHORISED'
}

export enum PaymentType {
  Card = 'CARD'
}

export type RegisterPaymentMutationVariables = Exact<{
  registerPaymentInput: PaymentInput;
}>;

export type RegisterPaymentMutation = {
  __typename?: 'Mutation';
  registerPayment: {
    __typename?: 'PaymentResponseModel';
    success: boolean;
    returnType?: PaymentReturnType | null;
    redirectUrl?: string | null;
    redirectType?: string | null;
    errors?: Array<{ __typename?: 'PaymentErrorModel'; errorMessage: string; errorCode: number }> | null;
  };
};

export type GetPaymentStatusQueryVariables = Exact<{
  getPaymentStatusInput: PaymentStatusInput;
}>;

export type GetPaymentStatusQuery = {
  __typename?: 'Query';
  getPaymentStatus: {
    __typename?: 'PaymentStatusResponseModel';
    preAuthTransactionExists: boolean;
    debitTransactionExists: boolean;
    paymentStatus?: PaymentStatus | null;
  };
};

export const RegisterPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'registerPaymentInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registerPaymentInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'registerPaymentInput' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'redirectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'redirectType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorCode' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RegisterPaymentMutation, RegisterPaymentMutationVariables>;
export const GetPaymentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPaymentStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'getPaymentStatusInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentStatusInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPaymentStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getPaymentStatusInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'getPaymentStatusInput' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'preAuthTransactionExists' } },
                { kind: 'Field', name: { kind: 'Name', value: 'debitTransactionExists' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>;
