import type { PrescriptionPadReviewAction } from '@/components/PatientPageV2/components/PrescriptionPad';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

export interface ConfirmationCheckListProps {
  actions: PrescriptionPadReviewAction[];
  onClick: (action: PrescriptionPadReviewAction) => void;
}

export const ConfirmationCheckList = ({ actions = [], onClick }: ConfirmationCheckListProps) => {
  return (
    <Box sx={{ padding: 2, width: '100%' }}>
      <Typography variant="h5">Have you completed all required actions?</Typography>
      <Typography variant="body1" color="textSecondary">
        Please review and complete any outstanding items before confirming.
      </Typography>

      <FormGroup>
        {actions.map((action) => (
          <FormControlLabel
            key={`confirmation-checklist-${action.id}`}
            control={
              <Checkbox
                checked={action.checked}
                onChange={() =>
                  onClick({
                    ...action,
                    checked: !action.checked
                  })
                }
                name={`confirmation-checklist-${action.id}`}
              />
            }
            label={action.label}
          />
        ))}
      </FormGroup>
    </Box>
  );
};
