import { styled } from '@mui/material';

export interface StyleSpanProps {
  color: 'warning' | 'error' | 'success' | 'default' | 'primary' | 'secondary' | 'info';
}

export const StyleSpan = styled('div')<StyleSpanProps>(({ theme, color }) => ({
  height: '0.7rem',
  width: ' 0.7rem',
  borderRadius: '50%',
  backgroundColor: !color || color === 'default' ? theme.palette.common.black : theme.palette[color]?.dark
}));
