import axios from 'axios';

import { requestHeaders } from '@/api';
import settings from '@/data/constants';

export interface GetPrescriptionUrlResponse {
  url: string;
}

export const getPrescriptionUrl = async (prescriptionId: number) => {
  const url = `${settings.url}/prescription/getPathOfPrescription/${prescriptionId}`;

  const response = await axios.get<GetPrescriptionUrlResponse>(url, {
    headers: requestHeaders()
  });

  return {
    url: response.data.url
  };
};
