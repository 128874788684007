import { Box } from '@mui/material';

import type { CalendarData } from '@/hooks/rest/types';

import CreditCardPayment from './components/CreditCardPayment';
import PaymentFailure from './components/PaymentFailure';
import PaymentSuccess from './components/PaymentSuccess';

export enum PaymentCardState {
  Input,
  Success,
  Fail
}

type Props = {
  data: CalendarData;
  state: PaymentCardState | undefined;
};

export default function PaymentCard({ data, state }: Props) {
  return (
    <Box paddingX={4}>
      {state === PaymentCardState.Input && <CreditCardPayment data={data} />}
      {state === PaymentCardState.Success && <PaymentSuccess data={data} />}
      {state === PaymentCardState.Fail && <PaymentFailure />}
    </Box>
  );
}
