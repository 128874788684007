import type { FilteredProduct } from '@/api';

import {
  PRODUCT_INTERVAL_DEFAULT,
  PRODUCT_QUANTITY_DEFAULT,
  PRODUCT_REPEATS_DEFAULT
} from './ProductDetailsCard.constants';

export const transformFilteredProductToPrescriptionMetadata = (filteredProduct: FilteredProduct) => {
  const { attributes, productName, supplierName } = filteredProduct;
  return {
    repeats: attributes?.repeats?.default || PRODUCT_REPEATS_DEFAULT,
    interval: attributes?.interval?.default || PRODUCT_INTERVAL_DEFAULT,
    quantity: attributes?.quantity?.default || PRODUCT_QUANTITY_DEFAULT,
    dosage: attributes?.dosage ?? '',
    supplierName,
    productName
  };
};
