import { requestHeaders } from '@/api';
import settings from '@/data/constants';
import type { DoctorConsultation } from '@/types';
import axios from 'axios';

export type GetDoctorCalendarConsultationsParams = {
  selectedDoctor: string | number;
  dateIsoString: string;
};

export type DoctorConsultationResponse = {
  statusText: string;
  data: {
    consultations: DoctorConsultation[];
  };
};

export const getDoctorCalendarConsultations = async ({
  selectedDoctor,
  dateIsoString
}: GetDoctorCalendarConsultationsParams): Promise<DoctorConsultationResponse> => {
  const selectedDoctorParam = selectedDoctor !== 'all' && !!selectedDoctor ? `&doc=${selectedDoctor}` : '';
  const getDoctorConsultsUrl = `${settings.url}/consultation/?date=${dateIsoString}&active=true${selectedDoctorParam}`;

  const doctorConsultationsResponse = await axios.get(getDoctorConsultsUrl, {
    headers: requestHeaders()
  });

  return doctorConsultationsResponse;
};
