import type { CreatePrescriptionRequestBody } from '@/api/prescriptions/createPrescription';
import { createPrescription } from '@/api/prescriptions/createPrescription';
import { QueryKey } from '@/data/query';

import { useAsyncMutation } from '../reactQuery';

/**
 * useMutation hook to create a new Prescription via POST
 */
export const usePostPrescription = () => {
  const { mutateAsync, ...otherProps } = useAsyncMutation({
    mutationKey: [QueryKey.CreatePrescription],
    mutationFn: (payload: CreatePrescriptionRequestBody) => createPrescription(payload)
  });

  return {
    ...otherProps,
    /**
     * Async mutate function that posts a prescription
     */
    postPrescription: mutateAsync
  };
};
