import { FormControl, InputLabel, MenuItem, Select, Stack, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { getSelectedChildFilters } from './ProductFilter.utils';

type Options = { label: string; value: string; imageUrl?: string; filter?: Filter }[];

export type Filter = {
  id: string;
  label: string;
  options: Options;
};

export type Filters = Map<string, string>;

export type ProductFilterProps = {
  selectedFilters?: Filters;
  productFilter: Filter;
  onChange: (filters: Filters) => void;
};

type ChildPropDataFilter = { props: { 'data-filter': Filter } };

const StyledImage = styled('img')({
  width: '1rem',
  height: '1rem',
  marginRight: '0.5rem'
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: '32.333%'
  }
}));
/**
 * Typically used when creating a prescription to filter the medication
 * based on the predefined filters
 *
 * @param selectedFilters - selected filters in key value pairs
 * @param productFilter - nested object that contains a chain of filter values
 * @param onChange - callback fired when a filter is selected from a list
 *
 * @returns JSXElement
 *
 */

export default function ProductFilter({ selectedFilters, productFilter, onChange }: ProductFilterProps) {
  const selectedFiltersByKey = selectedFilters && Array.from(selectedFilters.keys());
  const initialFilterKey = selectedFiltersByKey?.[0] || productFilter.id;
  const [childFilters, setChildFilters] = useState<Filter[]>([]);
  const [filter, setFilter] = useState<Filters>(selectedFilters ?? new Map<string, string>([[initialFilterKey, '']]));

  useEffect(() => {
    if (selectedFilters) {
      const selectedChildFilters = getSelectedChildFilters(productFilter, Array.from(selectedFilters.values()));
      setChildFilters(selectedChildFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key: string, val: string, childFilter?: Filter) => {
    const isNewFormulation = initialFilterKey === key && filter.get(key) !== val;

    setFilter((prevState) => {
      // if it's new formulation (initialFilterKey doesn't exist), then reset with the initial key
      const newState = new Map(isNewFormulation ? [[initialFilterKey, val]] : [...prevState, [key, val]]);

      if (isNewFormulation) {
        setChildFilters([]);
      }

      if (childFilter) {
        setChildFilters((childState) => {
          // TODO - review the logic, setState should be similar to dispatch and have as little side-effects as possible
          if (childState.length > 1) {
            // reset filter state to initial filter key + the new key/val
            newState.clear();
            // the OR condition of the first set should not occur
            newState.set(initialFilterKey, filter.get(initialFilterKey) || '').set(key, val);
            childState.pop();
          }
          if (!childState.find((child) => child.id === childFilter.id)) {
            childState.push(childFilter);
          }
          return childState;
        });
      }

      onChange(newState);
      return newState;
    });
  };

  return (
    <Stack direction={{ md: 'column', lg: 'row' }} spacing={2}>
      <StyledFormControl size="small">
        <InputLabel id={`${initialFilterKey}-label`} data-dd-privacy="allow">
          {productFilter.label}
        </InputLabel>
        <Select
          label={productFilter.label}
          labelId={`${initialFilterKey}-label`}
          onChange={(e, child) => {
            handleChange(productFilter.id, e.target.value, (child as ChildPropDataFilter)?.props?.['data-filter']);
          }}
          value={filter.get(initialFilterKey) || ''}
          size="small"
          data-dd-privacy="allow"
        >
          {(productFilter.options || []).map(({ label, value, imageUrl, filter: childFilter }) => (
            <MenuItem key={value} value={value} data-filter={childFilter}>
              {imageUrl && <StyledImage src={imageUrl} height={16} />}
              {label}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      {(childFilters || []).map((childFilter) => (
        <StyledFormControl key={childFilter.id} size="small">
          <InputLabel id={`${childFilter.id}-label`} data-dd-privacy="allow">
            {childFilter.label}
          </InputLabel>
          <Select
            label={childFilter.label}
            labelId={`${childFilter.id}-label`}
            onChange={(e, child) =>
              handleChange(childFilter.id, e.target.value, (child as ChildPropDataFilter)?.props?.['data-filter'])
            }
            value={filter?.get(childFilter.id) || ''}
            size="small"
            data-dd-privacy="allow"
          >
            {(childFilter.options || []).map(({ label, value, imageUrl, filter: anotherchildFilter }) => (
              <MenuItem key={value} value={value} data-filter={anotherchildFilter}>
                {imageUrl && <StyledImage src={imageUrl} height={16} />}
                {label}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      ))}
    </Stack>
  );
}
