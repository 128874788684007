import type { Dispatch, ReactNode } from 'react';
import { createContext, useReducer } from 'react';

import { initialState, reducer } from './reducers/reducer';

type Action = { type: string };

const StoreContext = createContext<{
  state: typeof initialState;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

const StoreProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export { StoreContext, StoreProvider };
