import type { PrescribedMedicationSchema } from '@montugroup/prescription-contracts';
import { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import { ROWS_PER_PAGE_MULTIPLE } from './PrescribedMedicationsTabsTable.config';

export const getActiveProducts = (products: PrescribedMedicationSchema[]) => {
  return products.filter((product) => {
    const isMedicationDisabled = !product?.cancel?.isCancellable || product?.reissue.isDisabled;
    return product?.status === MedicationPrescriptionStatus.Active && !isMedicationDisabled;
  });
};

// If the productId is not in itemsToReissue OR in itemsToCancel, then it needs to be actioned
export const getItemIdsToAction = ({
  itemsToReissueProductIds,
  itemsToCancelProductIds,
  activeProductsIds
}: {
  itemsToReissueProductIds: number[];
  itemsToCancelProductIds: number[];
  activeProductsIds: number[];
}) => {
  const combinedItemsToReissueCancel = [...itemsToReissueProductIds, ...itemsToCancelProductIds];

  return activeProductsIds.filter((productId) => !combinedItemsToReissueCancel.includes(productId));
};

/**
 * This method calculates the page size required to house all the active products in the prescription
 * history table.
 * @param numberOfActiveProducts a number representing the total active products
 * @returns A multiple of ROWS_PER_PAGE_MULTIPLE that is big enough to house the active products
 */
export const getPageSizeForActiveProducts = (numberOfActiveProducts: number): number => {
  const closestMultipleRoundUp = Math.ceil(numberOfActiveProducts / ROWS_PER_PAGE_MULTIPLE) * ROWS_PER_PAGE_MULTIPLE;
  return closestMultipleRoundUp;
};

/**
 * This method calculates and builds an array of numbers counting up based on
 * the ROWS_PER_PAGE_MULTIPLE.
 * @param numberOfProducts total number of products to build the pagination from
 * @returns an array of numbers following the ROWS_PER_PAGE_MULTIPLE
 */
export const getPaginationMultipleOptions = (numberOfProducts: number): number[] => {
  const maxMultiple = Math.ceil(numberOfProducts / ROWS_PER_PAGE_MULTIPLE) * ROWS_PER_PAGE_MULTIPLE;
  return Array.from(
    { length: maxMultiple / ROWS_PER_PAGE_MULTIPLE },
    (_, index) => (index + 1) * ROWS_PER_PAGE_MULTIPLE
  );
};
