import { Box, styled } from '@mui/material';
import { useState } from 'react';

import type {
  TimeRange
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // WorkingHours
} from '@/components/calcom/shared/types/calcom-schedule-types';
import BasicModal from '@/components/common/BasicModal';

import DateOverrideForm from './DateOverrideForm';

/**
 * Date Override Input Dialog component
 *
 * @remarks
 * A modal which contains the DateOverrideForm. Used to add dates/times to be overridden
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DateOverrideInputDialog'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/DateOverrideInputDialog.tsx
 *
 * @param trigger - A button or element to be used to trigger modal to be opened
 * @param workingHours - The standard working hours for that user. Used for default values
 * @param excludedDates - Dates which the user is not working on
 * @param onChange - Callback for when the form is updated
 * @param onClose - Callback for when the form is closed
 * @param value - An array of TimeRanges with start and end times for the overrides
 *
 * @returns JSXElement
 *
 */

const StyledBasicModal = styled(BasicModal)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto'
});

const DateOverrideInputDialog = ({
  trigger,
  excludedDates = [],
  ...passThroughProps
}: {
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // workingHours?: WorkingHours[];
  excludedDates?: string[];
  trigger: React.ReactNode;
  onChange: (newValue: TimeRange[]) => void;
  value?: TimeRange[];
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalOpen = () => setModalIsOpen(true);
  const handleModalClose = () => setModalIsOpen(false);

  return (
    <>
      <Box onClick={handleModalOpen}>{trigger}</Box>
      <StyledBasicModal open={modalIsOpen} handleClose={handleModalClose}>
        <DateOverrideForm excludedDates={excludedDates} {...passThroughProps} onClose={handleModalClose} />
      </StyledBasicModal>
    </>
  );
};

export default DateOverrideInputDialog;
