import SupportIcon from '@mui/icons-material/Support';
import { Alert, AlertTitle, Chip, Skeleton, Tooltip, Typography } from '@mui/material';

import type { SupportTasksProps } from '@/types/support-self-service.types';

interface Props {
  tasks: SupportTasksProps[];
  teams: string[];
  loading?: boolean;
  error?: string;
}

const SupportHeaderContent = ({ tasks, teams, loading, error }: Props) => {
  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Support Self Service
        <Tooltip title="You've got this!" placement="right">
          <SupportIcon color="primary" fontSize="large" sx={{ ml: 2 }} />
        </Tooltip>
      </Typography>

      {loading && (
        <>
          <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem' }} />
          <Skeleton animation="wave" variant="text" sx={{ fontSize: '.5rem' }} />
          <Skeleton animation="wave" variant="rounded" height={60} />
        </>
      )}

      {!loading && (
        <>
          {error && (
            <Alert severity="error">
              <AlertTitle>{error}</AlertTitle>
              Please reach out to the Engineering Support Team - <strong>@eng_on_call</strong> on Slack
            </Alert>
          )}

          {!error && (
            <>
              <Typography variant="h6" component="h6" gutterBottom>
                {teams.length > 0 ? (
                  <>
                    Teams you are assigned to:
                    {teams.map((team: string) => (
                      <Chip key={team} label={team} color="primary" sx={{ ml: 4 }} />
                    ))}
                  </>
                ) : (
                  <>You are not assigned to any teams</>
                )}
              </Typography>

              {tasks.length < 1 && (
                <Typography variant="body1">
                  You do not have the the required permissions to access to this page, if you believe this is a bug
                  please reach out to the Engineering Support Team - @eng_on_call on Slack.
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default SupportHeaderContent;
