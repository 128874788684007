import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { PatientsPageHeader } from '@/components/Patients/PatientsPageHeader';
import { PatientsTable } from '@/components/tables/PatientsTable';

import '../assets/scss/styles.scss';

const PREFIX = 'Patients';

const classes = {
  button: `${PREFIX}-button`,
  boxStyle: `${PREFIX}-boxStyle`,
  floatCalendar: `${PREFIX}-floatCalendar`,
  Buttonlink: `${PREFIX}-Buttonlink`,
  linkDanger: `${PREFIX}-linkDanger`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.button}`]: {
    borderRadius: 20
  },

  [`& .${classes.boxStyle}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '32px'
  },

  [`& .${classes.floatCalendar}`]: {
    float: 'right',
    zIndex: 1,
    position: 'absolute',
    right: '30px',
    top: '40px',
    '& .calendarWrap': {
      zIndex: 1,
      position: 'absolute',
      right: '0px'
    }
  },

  [`& .${classes.Buttonlink}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    color: theme.palette.secondary.main,
    background: 'none !important',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  [`& .${classes.linkDanger}`]: {
    color: `${theme.palette.orange} !important`
  }
}));

const Patients = () => {
  return (
    <Root>
      <PatientsPageHeader />
      <Box p={4} mb={-10} display="flex">
        <Box component="div" width="100%">
          <PatientsTable />
        </Box>
      </Box>
    </Root>
  );
};

export default Patients;
