import { requestHeaders } from '@/api';
import type { PatientMedicalConditionData } from '@/components/Patient/Patient.types';
import { API_URL } from '@/data/service/dataService';
import { Box, Grid, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import iziToast from 'izitoast';
import { useState } from 'react';
import type { DassAssessmentDetails, DassAssessmentScoreDetail } from '../MedicalCondition.types';

interface DassAssessmentScoreStatusProps {
  patientMedicalConditionData: PatientMedicalConditionData;
  dassAssessmentDetails?: DassAssessmentDetails;
  dassAssessmentScoreDetail?: DassAssessmentScoreDetail;
  fetchDassAssessment: () => void;
  isPDARequired: boolean;
  patientId: number | string;
  userEmail: string;
  isDoc: boolean;
  disabled: boolean;
}

const StyledGrid = styled(Grid)({
  border: `1px solid ${grey[500]}`,
  borderRadius: '5px',
  padding: 10
});

const StyledResendButton = styled(Button)({
  textTransform: 'capitalize',
  padding: 0,
  textDecoration: 'underline',

  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textDecoration: 'underline'
  }
});

const DassAssessmentScoreStatus = ({
  patientMedicalConditionData,
  dassAssessmentDetails,
  dassAssessmentScoreDetail,
  fetchDassAssessment,
  isPDARequired,
  patientId,
  userEmail,
  isDoc,
  disabled
}: DassAssessmentScoreStatusProps) => {
  const [resendEmailClicked, setResendEmailClicked] = useState(false);

  const handleResendDassEmail = async () => {
    if (resendEmailClicked || !isPDARequired) {
      return;
    }

    setResendEmailClicked(true);

    const sendBody = {
      patientId,
      userEmail,
      daysLinkValid: 5,
      patientEmail: patientMedicalConditionData.email,
      patientName: patientMedicalConditionData.FirstName
    };

    try {
      const response = await fetch(`${API_URL}/dass/updateDass`, {
        method: 'PUT',
        headers: requestHeaders(),
        body: JSON.stringify(sendBody)
      });

      if (response.ok) {
        /*
          TODO - Migrate from iziToast to React-Toastify
          See: https://montugroup.atlassian.net/browse/B2C-4975
         */
        iziToast.success({
          title: 'Sent Dass Assessment Email',
          position: 'topRight'
        });
        fetchDassAssessment();
      } else {
        /*
          TODO - Migrate from iziToast to React-Toastify
          See: https://montugroup.atlassian.net/browse/B2C-4975
         */
        iziToast.error({
          title: 'Failed to Send Dass Assessment Email',
          position: 'topRight'
        });
      }
    } catch (_error) {
      /*
        TODO - Migrate from iziToast to React-Toastify
        See: https://montugroup.atlassian.net/browse/B2C-4975
       */
      iziToast.error({
        title: 'Failed to Send Dass Assessment Email',
        position: 'topRight'
      });
    }
  };

  const renderResendEmailButton = !isDoc && (
    <Box>
      <StyledResendButton data-testid="resend-email" disabled={disabled} onClick={handleResendDassEmail}>
        Resend E-mail
      </StyledResendButton>
    </Box>
  );

  return (
    <Box marginBottom={3}>
      <Typography fontWeight={'bold'}>DASS Assessment Score</Typography>
      <StyledGrid container>
        {!dassAssessmentDetails?.is_submitted && (
          <Grid item xs={7} data-testid="yet_to_be_completed">
            <Typography color={grey[500]}>Yet to be Completed</Typography>
            {renderResendEmailButton}
          </Grid>
        )}
        {(dassAssessmentDetails?.is_submitted || dassAssessmentScoreDetail?.anxiety?.level) && (
          <Grid item xs={7} data-testid="dass-assessment-score">
            <Grid container>
              <Grid item xs={6}>
                Depression
              </Grid>
              <Grid item xs={6}>
                {dassAssessmentScoreDetail?.depression?.score}
              </Grid>
              <Grid item xs={6}>
                Anxiety
              </Grid>
              <Grid item xs={6}>
                {dassAssessmentScoreDetail?.anxiety?.score}
              </Grid>
              <Grid item xs={6}>
                Stress
              </Grid>
              <Grid item xs={6}>
                {dassAssessmentScoreDetail?.stress?.score}
              </Grid>
            </Grid>
          </Grid>
        )}
      </StyledGrid>
    </Box>
  );
};

export default DassAssessmentScoreStatus;
