import Box from '@mui/material/Box';
import React from 'react';

export default (props) => (
  <React.Fragment>
    <Box width="100%">
      <img src="/DesktopBanner.png" width="100%" />
      <Box marginTop={window.innerWidth < 600 ? 0 : props.marginTop || -15}>{props.children}</Box>
    </Box>
  </React.Fragment>
);
