import { Box, Typography } from '@mui/material';

import { TutorialModal } from '@/shared-ui';

import { TutorialVideo } from './TutorialVideo';

type CreatePrescriptionTutorialModalProps = {
  open: boolean;
  handleClose: () => void;
  /**
   * This is currently mapped to when "Close" is clicked
   */
  handleClickPrimaryButton: () => void;
  /**
   * This is currently mapped to when "Watch Later" is clicked
   */
  handleClickSecondaryButton: () => void;
};

export const CreatePrescriptionTutorialModal = ({
  open,
  handleClose,
  handleClickPrimaryButton,
  handleClickSecondaryButton
}: CreatePrescriptionTutorialModalProps) => {
  return (
    <TutorialModal
      open={open}
      primaryButtonText="Close"
      secondaryButtonText="Watch later"
      handleClose={handleClose}
      handleClickPrimaryButton={handleClickPrimaryButton}
      handleClickSecondaryButton={handleClickSecondaryButton}
      alertContent={
        <>
          <b>IMPORTANT:</b>
          <br />
          Change to prescription creation feature
        </>
      }
      // eslint-disable-next-line react/no-children-prop
      children={
        <>
          <Typography color="textPrimary" sx={{ marginTop: '1.5rem', fontWeight: '700' }}>
            A NEW TOOL HAS BEEN ADDED TO PRESCRIPTIONS IN ALTERNALEAF, REPLACING THE MANUAL MEDICATION SELECTION MENU.
          </Typography>
          <Typography color="textPrimary" sx={{ marginTop: '1rem' }}>
            Watch this short 5 minute video to see how to use it. We recommend that you watch this video as soon as
            possible to avoid confusion during your consultations.
          </Typography>

          <Box sx={{ padding: '0 4rem' }}>
            <TutorialVideo />
          </Box>
        </>
      }
    />
  );
};
