import type { Moment } from 'moment';

import settings from '../../data/constants';

import useRequest from './useRequest';

type ObjectValues<T> = T[keyof T];
type PatientDocumentUploadType = ObjectValues<typeof settings.patientDocumentUploadType>;

type UsePostPatientDocumentUploadPayload = {
  file: File;
  fileName?: string; // Optional override
  expiryDate: Moment | string; // ISO8601
  patientId: string | number;
  documentTypeId: PatientDocumentUploadType;
  // Only for SAS document type?
  ingredientId?: string | number;
  formulationId?: string | number;
  mbNumber?: string | number;
  approvalDate?: Moment | string;
};

type UsePostPatientDocumentUploadResponse = {
  id: string | number;
  // ... other Document DB model attributes
};

function transformPayloadToFormData({
  file,
  fileName,
  expiryDate,
  patientId,
  documentTypeId,
  approvalDate,
  ingredientId,
  formulationId,
  mbNumber
}: UsePostPatientDocumentUploadPayload): FormData {
  const postBody = new FormData();
  postBody.append('file', file);
  if (fileName) {
    postBody.append('file_name', fileName);
  } else {
    postBody.append('file_name', file.name);
  }
  postBody.append('expiry_date', String(expiryDate));
  postBody.append('patient_id', String(patientId));
  postBody.append('document_type_id', String(documentTypeId));
  if (approvalDate) {
    postBody.append('approval_date', String(approvalDate));
  }
  if (ingredientId) {
    postBody.append('ingredient_id', String(ingredientId));
  }
  if (formulationId) {
    postBody.append('formulation_id', String(formulationId));
  }
  if (mbNumber) {
    postBody.append('mb_number', String(mbNumber));
  }
  return postBody;
}

export function usePostPatientDocumentUpload() {
  const { loading, data, error, execute } = useRequest<UsePostPatientDocumentUploadResponse>({
    config: {
      url: `${settings.url}/document`,
      method: 'POST'
    }
  });

  const doPost = async (body: UsePostPatientDocumentUploadPayload) => {
    const postBody = transformPayloadToFormData(body);
    return execute({ data: postBody });
  };

  return {
    loading,
    data,
    error,
    doPost
  };
}

export default usePostPatientDocumentUpload;
