import { QueryKey } from '@/data/query';
import { useQueryClient } from '@tanstack/react-query';
import { prescribeClient } from '../../api/prescribedProducts/prescribedProductClient';

export const usePrescribePrescriptions = () => {
  const queryClient = useQueryClient();

  const queryKey = [QueryKey.PrescribePrescription];
  const { mutateAsync } = prescribeClient.prescribe.useMutation({
    mutationKey: queryKey,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PrescribedProducts] });
    }
  });

  return { mutateAsync };
};
