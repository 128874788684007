import { TextField } from '@mui/material';

import type { InputField } from '@/types/support-self-service.types';

interface Props {
  field: InputField;
  value: string;
  handleInputChange: (property: string, value: string) => void;
}

const SupportTaskTextField = ({ field, value, handleInputChange }: Props) => {
  return (
    <TextField
      fullWidth
      label={field.label}
      variant="outlined"
      value={value}
      onChange={(e) => handleInputChange(field.property, e.target.value)}
    />
  );
};

export default SupportTaskTextField;
