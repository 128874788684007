/* eslint-disable indent */

export const authState = {
  isAuthenticated: Boolean(localStorage.getItem('token')),
  user: localStorage.getItem('user'),
  token: localStorage.getItem('token')
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        user: JSON.stringify(action.payload.user),
        token: action.payload.token
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};
