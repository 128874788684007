import { Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useEffect } from 'react';

import { InitialisingContext } from '@/components/paymentCard/components/CreditCardPayment';
import settings from '@/data/constants';
import useEwaySdkScript from '@/hooks/useEwaySdkScript';
import theme from '@/theme';

declare global {
  interface Window {
    eWAYUtils: any;
    eWAY: any;
  }
}

export type EwaySecureFieldEvent = {
  errors: null;
  fieldHeight: null;
  fieldValid: boolean;
  originaldata: string;
  secureFieldCode: string;
  targetField: string;
  valueHasFocus: boolean;
  valueIsSaved: boolean;
  valueIsValid: boolean;
};

type EwayFormProps = {
  fullName: string;
  data: any;
  secureFieldCallbackFn: (event: EwaySecureFieldEvent) => void;
};

const StyledHostedInput = styled(Box)({
  height: '3.6rem'
});

const StyledCustomerDetails = styled(Box)({
  padding: '10px 0',
  display: 'flex',
  justifyContent: 'space-between'
});

const StyledCustomerDetail = styled(Typography)({
  color: theme.palette.grey[600]
});

// securefield styling
const fieldStyles =
  'border: 0px;\n' +
  'border-radius: 0;\n' +
  'border-bottom: 1px solid rgba(0, 0, 0, 0.42);\n' +
  'background: rgba(0, 0, 0, 0.06);\n' +
  'height: 100%;\n' +
  'padding: 25px 12px 8px 12px;\n' +
  `fontFamily: ${theme.typography.fontFamily};\n` +
  'line-height: 1.5rem';

export default function EwayForm({ fullName, data, secureFieldCallbackFn }: EwayFormProps) {
  const ewaySdkStatus = useEwaySdkScript();
  const { setInitialising } = useContext(InitialisingContext);

  useEffect(() => {
    if (ewaySdkStatus === 'ready') {
      // prevent showing duplicate securefields on HMR (development)
      window.eWAYUtils?.getVar('activeFields').forEach((field: any) => field.iframe.remove());

      ['name', 'card', 'expiry', 'cvn'].forEach((fieldType) => {
        // initialise hosted fields
        window.eWAY.setupSecureField(
          {
            publicApiKey: settings.ewayPublicKey,
            fieldDivId: `eway-secure-${fieldType}-field`,
            fieldType: fieldType === 'expiry' ? 'expirytext' : fieldType,
            disablePageClickHandler: true,
            styles: fieldStyles
          },
          // callback to be called when input value has changed
          secureFieldCallbackFn
        );
      });

      // hide skeleton loaders when the hosted fields are rendered
      window.eWAY.getField('card').iframe.onload = function () {
        setInitialising(false);
      };
      // setInitialising(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ewaySdkStatus, setInitialising]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <StyledHostedInput id="eway-secure-card-field" mb={1} />
      <Box display="flex" justifyContent="space-between" mb={1}>
        <StyledHostedInput id="eway-secure-expiry-field" mr={2}></StyledHostedInput>
        <StyledHostedInput id="eway-secure-cvn-field" ml={2}></StyledHostedInput>
      </Box>
      <StyledHostedInput id="eway-secure-name-field" />
      <Box my={5}>
        <StyledCustomerDetails sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}>
          <StyledCustomerDetail>Full name</StyledCustomerDetail>
          <Typography fontWeight={'bold'}>{fullName}</Typography>
        </StyledCustomerDetails>
        <StyledCustomerDetails>
          <StyledCustomerDetail>Email</StyledCustomerDetail>
          <Typography fontWeight={'bold'}>{data?.email}</Typography>
        </StyledCustomerDetails>
      </Box>
    </Box>
  );
}
