/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import iziToast from 'izitoast';

import MergePatients from '../components/MergePatients';
import { getAuthData } from '../data/service/authService';
import { makeGET, makePUT } from '../data/service/dataService';

import '../assets/scss/MergePatient.scss';

export default function MergePatientModal(props) {
  const { open, setOpen } = props;
  // status --->  1 - disabled btn, 2 - enable btn, 3 - hide btn
  const [primary_btn_status, setPrimaryBtnStatus] = React.useState(1);
  const [secondary_btn_status, setSecondaryBtnStatus] = React.useState(1);
  const [primary_alpat, setPrimaryAlpat] = React.useState('');
  const [secondary_alpat, setSecondaryAlpat] = React.useState('');
  const emptyVal = {
    name: '',
    patient_code: '',
    email: ''
  };
  const [primary_patient_detail, setPrimaryPatientDetail] = React.useState(emptyVal);
  const [secondary_patient_detail, setSecondaryPatientDetail] = React.useState(emptyVal);
  const [showAlert, setShowAlert] = React.useState(false);
  const [primary_not_found_err, setPrimaryNotFoundErr] = React.useState(false);
  const [secondary_not_found_err, setSecondaryNotFoundErr] = React.useState(false);
  const { user } = getAuthData();

  const refresh = () => {
    setPrimaryBtnStatus(1);
    setSecondaryBtnStatus(1);
    setPrimaryAlpat('');
    setSecondaryAlpat('');
    setSecondaryPatientDetail(emptyVal);
    setPrimaryPatientDetail(emptyVal);
    setShowAlert(false);
    setPrimaryNotFoundErr(false);
    setSecondaryNotFoundErr(false);
  };

  const handleClose = () => {
    if (showAlert) {
      setShowAlert(false);
    } else {
      setOpen(false);
      refresh();
    }
  };

  const getPatientDetail = async (val) => {
    if (val === 'primary_patient') {
      setPrimaryBtnStatus(3);
      const resp = await makeGET(`patient/detail/${primary_alpat}`, 'getPatientDetail');
      if (resp?.PatientUser) {
        const primaryPatientDetailValue = {
          name: `${resp.PatientUser.first_name ? resp.PatientUser.first_name : ''} ${
            resp.PatientUser.last_name ? resp.PatientUser.last_name : ''
          }`,
          email: resp.PatientUser.email,
          patient_code: resp.patient_code
        };
        setPrimaryPatientDetail(primaryPatientDetailValue);
        setPrimaryNotFoundErr(false);
      } else {
        setPrimaryNotFoundErr(true);
        setPrimaryPatientDetail(emptyVal);
      }
    } else if (val === 'secondary_patient') {
      setSecondaryBtnStatus(3);
      const resp = await makeGET(`patient/detail/${secondary_alpat}`, 'getPatientDetail');
      if (resp?.PatientUser) {
        const secondaryPatientDetailValue = {
          name: `${resp.PatientUser.first_name ? resp.PatientUser.first_name : ''} ${
            resp.PatientUser.last_name ? resp.PatientUser.last_name : ''
          }`,
          email: resp.PatientUser.email,
          patient_code: resp.patient_code
        };
        setSecondaryPatientDetail(secondaryPatientDetailValue);
        setSecondaryNotFoundErr(false);
      } else {
        setSecondaryNotFoundErr(true);
        setSecondaryPatientDetail(emptyVal);
      }
    }
  };

  const handlePrimaryAlpat = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    setPrimaryAlpat(value.toUpperCase());
    setPrimaryNotFoundErr(false);
    setPrimaryPatientDetail(emptyVal);
    if (value.length > 6) {
      setPrimaryBtnStatus(2);
    } else {
      setPrimaryBtnStatus(1);
    }
  };

  const handleSecondaryAlpat = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    setSecondaryAlpat(value.toUpperCase());
    setSecondaryPatientDetail(emptyVal);
    setSecondaryNotFoundErr(false);
    if (value.length > 6) {
      setSecondaryBtnStatus(2);
    } else {
      setSecondaryBtnStatus(1);
    }
  };

  const handleMergePatient = () => {
    setShowAlert(true);
  };

  const handleSubmitMergeProfile = async () => {
    if (
      primary_patient_detail.patient_code &&
      secondary_patient_detail.patient_code &&
      primary_alpat !== secondary_alpat
    ) {
      setOpen(false);
      const body = {
        fromPatientCode: secondary_alpat,
        toPatientCode: primary_alpat,
        user_id: user?.id
      };
      const resp = await makePUT('patient/mergePatientProfiles', body, 'MergeProfile/handleSubmitMergeProfile');
      if (resp) {
        iziToast.success({
          title: 'Success',
          timeout: 3000,
          message: 'Profile has been merged successfully',
          position: 'topRight'
        });

        refresh();
      } else {
        iziToast.error({
          title: 'Error',
          timeout: 3000,
          message: 'Failed to merge patients',
          position: 'topRight'
        });
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="mp_modal"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className="mp_paper">
          <div className="mp_header">
            <Typography variant="h5" className="mp_headerName">
              Merge Duplicate Patient Profiles
            </Typography>
            <CloseIcon className="mp_cancelIcon" onClick={handleClose} />
          </div>
          <hr className="mp_hrClass"></hr>
          {!showAlert ? (
            <MergePatients
              primary_patient_detail={primary_patient_detail}
              secondary_patient_detail={secondary_patient_detail}
              open={open}
              handleSecondaryAlpat={handleSecondaryAlpat}
              handlePrimaryAlpat={handlePrimaryAlpat}
              getPatientDetail={getPatientDetail}
              primary_btn_status={primary_btn_status}
              secondary_btn_status={secondary_btn_status}
              primary_alpat={primary_alpat}
              secondary_alpat={secondary_alpat}
              handleMergePatient={handleMergePatient}
              primary_not_found_err={primary_not_found_err}
              secondary_not_found_err={secondary_not_found_err}
              setOpen={setOpen}
              refresh={refresh}
            />
          ) : (
            <div>
              <p>
                Are you sure you want to merge <strong>{secondary_patient_detail?.patient_code}</strong> with{' '}
                <strong>{primary_patient_detail?.patient_code}</strong>?
              </p>
              <p className="mp_alert_msg mp_irrv_alert">Please note this action is irreversible</p>
              <div className="mp_contentDiv">
                <Button className="mp_yes_btn" onClick={handleSubmitMergeProfile}>
                  Yes
                </Button>
                <Button variant="outlined" className="mp_cancel_btn" onClick={handleClose}>
                  No
                </Button>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
