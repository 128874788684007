import { Table } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';

import { useAccessControl } from '@/hooks';
import { ConsultationTableColumnHeaders, UserRole } from '@/types';

import type { ConsultationWithPaymentStatus } from './hooks/useConsultationTabOverviewTable';

// TODO: Remove this once the column header minWidth is removed from the <Table> in upcoming refactor
const StyledBox = styled(Box)({
  'thead > tr > th': {
    minWidth: 'inherit'
  }
});

type ConsultationTabOverviewTableProps = {
  consultations: ConsultationWithPaymentStatus[];
  isLoading: boolean;
  searchFilterText: string;
};

/**
 * ConsultationTabOverviewTable component
 *
 * @remarks
 * Renders a table of consultations in the Consultation Tab on the Patient page
 *
 * @param consultations - An array of consultations
 * @param isLoading - isLoading state from the Patient profile
 * @param searchFilterText - The search filter text
 *
 * @returns JSXElement
 *
 */

export const ConsultationTabOverviewTable = ({
  consultations,
  isLoading,
  searchFilterText
}: ConsultationTabOverviewTableProps) => {
  const columnHelper = createColumnHelper();
  const { restrictAccess } = useAccessControl();

  const isLoadingConsultations = isLoading || !consultations.length;

  const columns: any = [
    columnHelper.accessor('view_link', {
      header: ConsultationTableColumnHeaders.ViewLink,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('id', {
      header: ConsultationTableColumnHeaders.ConsultID,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('appointment_time', {
      header: ConsultationTableColumnHeaders.AppointmentTime,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('type', {
      header: ConsultationTableColumnHeaders.ConsultationType,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('practitioner', {
      header: ConsultationTableColumnHeaders.Practitioner,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('payment_status', {
      header: ConsultationTableColumnHeaders.PaymentStatus,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('consultation_status', {
      header: ConsultationTableColumnHeaders.ConsultationStatus,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('payment_type', {
      header: ConsultationTableColumnHeaders.PaymentType,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('reschedule_link', {
      header: ConsultationTableColumnHeaders.RescheduleLink,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('cancel_link', {
      header: ConsultationTableColumnHeaders.CancelLink,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    })
  ];

  const rescheduleConsultationAccessId = restrictAccess([UserRole.Doctor], 'Reschedule');
  const cancelConsultationAccessId = restrictAccess([UserRole.Doctor], 'Cancel');
  const editConsultationAccessId = restrictAccess([UserRole.Admin], 'View');

  return (
    <StyledBox>
      <Table
        data={consultations}
        columns={columns}
        isLoading={isLoadingConsultations}
        skeletonCount={2}
        pageSize={5}
        pageSizeOptions={[5, 10, 20, 50]}
        globalFilter={searchFilterText}
        noDataFoundMessage="No consultations found"
        showPagination
        columnVisibility={{
          view_link: !!editConsultationAccessId,
          reschedule_link: !!rescheduleConsultationAccessId,
          cancel_link: !!cancelConsultationAccessId
        }}
      />
    </StyledBox>
  );
};
