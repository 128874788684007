import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Link, TextField, Typography, styled } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { DiscountCodeStatus } from '@/hooks/rest/useDiscountCode';

import { StyledTypography } from './PaymentForm';

export type AddPromoCodeProps = {
  isLoading: boolean;
  status?: DiscountCodeStatus;
  value?: string;
  handleDiscountApply: (discountCode?: string) => void;
  handleTermsConditionsChecked: (event: ChangeEvent<HTMLInputElement>) => void;
  showTCValidationMessage: boolean;
};

const StyledTextField = styled(TextField)({
  flexBasis: '80%',
  marginRight: 40,
  marginBottom: 15,

  '.MuiInputBase-input': {
    paddingTop: '20px'
  }
});

const StyledButton = styled(Button)({
  flexBasis: '20%',
  minHeight: '45px',
  alignSelf: 'flex-start'
});

function discountCodeInputIcon(status?: DiscountCodeStatus) {
  switch (status) {
    case DiscountCodeStatus.valid:
      return {
        InputProps: {
          endAdornment: <CheckCircleIcon color="success" />
        }
      };
    case DiscountCodeStatus.invalid:
    case DiscountCodeStatus.expired:
      return {
        InputProps: {
          endAdornment: <ErrorIcon color="error" />
        }
      };
    default:
      return null;
  }
}

function discountCodeValidationText(status?: DiscountCodeStatus) {
  switch (status) {
    case DiscountCodeStatus.valid:
      return (
        <Typography component="span">
          <strong>Congratulations!</strong> Your promo code has been successfully applied. Your discount will be applied
          once you reach the consultation payment page.
        </Typography>
      );
    case DiscountCodeStatus.invalid:
    case DiscountCodeStatus.expired:
      return (
        <Typography component="span">
          The promo code you have entered is not valid or has expired. Please double-check your code and try again.
        </Typography>
      );
    default:
      return null;
  }
}

export default function AddPromoCode({
  isLoading,
  status,
  value,
  handleDiscountApply,
  showTCValidationMessage,
  handleTermsConditionsChecked
}: AddPromoCodeProps) {
  const [discountCode, setDiscountCode] = useState(value);

  return (
    <>
      <StyledTypography component="h3">Add promo code</StyledTypography>

      <Box display="flex" flexDirection={'column'}>
        <Box display="flex">
          <StyledTextField
            type="text"
            variant="filled"
            placeholder="Enter code"
            {...discountCodeInputIcon(status)}
            disabled={isLoading}
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value.toLocaleUpperCase())}
          />
          <StyledButton
            disabled={isLoading || !discountCode?.length || status === DiscountCodeStatus.valid}
            variant="contained"
            sx={{ minWidth: 'auto' }}
            onClick={() => handleDiscountApply(discountCode)}
          >
            Apply
          </StyledButton>
        </Box>
        <Typography>{discountCodeValidationText(status)}</Typography>
      </Box>

      <Box display="flex" alignItems="center" py={5}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={handleTermsConditionsChecked} color="warning" />}
            label={
              <>
                I&apos;ve read and agreed to the{' '}
                <Link href="https://www.alternaleaf.com.au/promotion-terms" target="_blank">
                  terms and conditions
                </Link>
              </>
            }
          />
          {showTCValidationMessage && (
            <Typography component="span" color="red" mt={5}>
              To proceed with payment, please agree to the terms and conditions.
            </Typography>
          )}
        </FormGroup>
      </Box>
    </>
  );
}
