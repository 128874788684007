import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

interface TabItem {
  label: string;
  content: React.ReactNode;
}

type HorizontaTabsProps = {
  tabs: TabItem[];
};

interface HorizontalTabsItemsProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledHorizontalTabs = styled(Box)({
  width: '100%'
});

const StyledHorizontalTabItems = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}));

const StyledTab = styled(Tab)({
  fontSize: '1rem'
});

const HorizontalTabsItem = (props: HorizontalTabsItemsProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tab-item-${index}`}
      aria-labelledby={`horizontal-tab-item-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

const a11yProps = (index: number) => ({
  id: `horizontal-tab-item-${index}`,
  'aria-controls': `horizontal-tab-item-${index}`
});

const HorizontalTabs = (props: HorizontaTabsProps) => {
  const { tabs } = props;
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledHorizontalTabs>
      <StyledHorizontalTabItems>
        <Tabs value={value} onChange={handleChange} aria-label="horizontal-tabs">
          {tabs.map((tab: TabItem, index: number) => (
            <StyledTab label={tab.label} {...a11yProps(index)} key={`${tab.label}${index}`} />
          ))}
        </Tabs>
      </StyledHorizontalTabItems>
      {tabs.map((tab: TabItem, index: number) => (
        <HorizontalTabsItem value={value} index={index} key={`${tab.label}${index}`}>
          {tab.content}
        </HorizontalTabsItem>
      ))}
    </StyledHorizontalTabs>
  );
};

export default HorizontalTabs;
