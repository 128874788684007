import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
  FF_CONCESSION_DISCOUNT_RATE,
  FF_CONSULTATION_BOOKING_PAYMENT_WINDOW,
  FF_CONSULTATION_COMPLETION_PROMPT,
  FF_CONSULTATION_FOLLOW_UP_PRICE,
  FF_CONSULTATION_PRICE_JSON,
  FF_ENABLE_DISCOUNT_CODES,
  FF_ENABLE_DOCTOR_OOS_RESCRIPT,
  FF_ENABLE_DOCTORS_G_SCRIPT,
  FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE,
  FF_ENABLE_PMS_PATIENT_DETAILS_V3,
  FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES,
  FF_ENABLE_SNOWPLOW,
  FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS,
  FF_IMMUTABLE_CLINIC_NOTES,
  FF_INVENTORY_INSIGHTS_ENFORCE_GREENA_REPEATS,
  FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL,
  FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB,
  FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_PRESCRIPTION_ASSISTANT_PHASE_2,
  FF_PRESCRIPTION_ASSISTANT_TOOL_USE_ZUSTAND,
  FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS,
  FF_PRESCRIPTION_RECOMMENDED_INFO,
  FF_PRESCRIPTION_RECOMMENDED_PRODUCTS_COUNT,
  FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_VIEW_CONSULTATION_NOTES_HISTORY,
  FF_WA_NOTIFICATION_OF_TREATMENT
} from '@/constants/featureFlags';

const ffDefaults: LDFlagSet = {
  [FF_CONSULTATION_PRICE_JSON]: { displayPrice: '59', chargePrice: '59' },
  [FF_CONSULTATION_FOLLOW_UP_PRICE]: '59',
  [FF_CONCESSION_DISCOUNT_RATE]: 0,
  [FF_ENABLE_DOCTORS_G_SCRIPT]: false,
  [FF_ENABLE_DOCTOR_OOS_RESCRIPT]: false,
  [FF_ENABLE_DISCOUNT_CODES]: false,
  [FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES]: false,
  [FF_ENABLE_SNOWPLOW]: false,
  [FF_PRESCRIPTION_RECOMMENDED_PRODUCTS_COUNT]: 3,
  [FF_PRESCRIPTION_RECOMMENDED_INFO]:
    '{"href": "https://app.getguru.com/card/iByRzd4T/Prescription-assistant-guide","text": "Product recommendations are based on your selections and availability criteria"}',
  [FF_PRESCRIPTION_ASSISTANT_PHASE_2]: false,
  [FF_PRESCRIPTION_ASSISTANT_TOOL_USE_ZUSTAND]: false,
  [FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS]: false,
  [FF_CONSULTATION_BOOKING_PAYMENT_WINDOW]: 600,
  [FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION]: false,
  [FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE]: false,
  [FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION]: false,
  [FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS]: false,
  [FF_INVENTORY_INSIGHTS_ENFORCE_GREENA_REPEATS]: false,
  [FF_WA_NOTIFICATION_OF_TREATMENT]: false,
  [FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB]: false,
  [FF_IMMUTABLE_CLINIC_NOTES]: false,
  [FF_ENABLE_PMS_PATIENT_DETAILS_V3]: false,
  [FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL]: false,
  [FF_VIEW_CONSULTATION_NOTES_HISTORY]: false,
  [FF_CONSULTATION_COMPLETION_PROMPT]: false
};

export default ffDefaults;
