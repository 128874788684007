import { Box, styled } from '@mui/material';
import { useContext } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import EditableSections from '../../EditableSections';

import { EditModeContext, withEditModeContextProvider } from '@/components/Patient/EditModeContextProvider';
import { useGetMedicalConditions } from '@/components/Patient/hooks/useGetMedicalConditions';
import { getAuthData } from '@/data/service/authService';
import { FormContainer, MultiSelectElement, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import DassAssessmentScoreStatus from './DassAssessmentScoreStatus/DassAssessmentScoreStatus';
import type { MedicalConditionProps } from './MedicalCondition.types';

const StyledMultiSelectElement = styled(MultiSelectElement)({
  width: '100%',
  '.MuiChip-root': {
    margin: '0 3px'
  }
});

const StyledFieldContainer = styled(Box)({
  marginTop: 10,
  marginBottom: 10
});

const MedicalCondition = (props: MedicalConditionProps) => {
  const {
    editable,
    defaultValues,
    dassAssessmentDetails,
    dassAssessmentScoreDetail,
    patientId,
    userEmail,
    checkIfPDAisRequired,
    fetchDassAssessment,
    onSubmit
  } = props;

  const { isDoc } = getAuthData();
  const { isEditMode } = useContext(EditModeContext);
  const { data: medicalConditionList = [] } = useGetMedicalConditions();
  const formContext = useForm<FieldValues>({
    values: defaultValues
  });

  const isPDARequired = checkIfPDAisRequired(defaultValues);

  const shouldShowDASSAssessment =
    ((dassAssessmentDetails?.link_send_count ?? 0) > 0 && isPDARequired) || dassAssessmentScoreDetail?.anxiety?.level;

  // Watch for changes in the conditions
  const watchedPrimaryCondition = useWatch({ name: 'medical_condition_1', control: formContext.control });
  const watchedSecondaryConditions = useWatch({
    name: ['medical_condition_2', 'medical_condition_3', 'medical_condition_4'],
    control: formContext.control
  }).flat();

  const medicalConditionOtherId = medicalConditionList?.find(
    (item) => item.condition_name.toLowerCase() === 'other'
  )?.id;
  const isPrimaryConditionOtherChosen = watchedPrimaryCondition?.includes(medicalConditionOtherId);
  const isSecondaryConditionOtherChosen = watchedSecondaryConditions.includes(medicalConditionOtherId);

  // Get all selected conditions in form
  const allSelectedConditions = useWatch({
    name: ['medical_condition_1', 'medical_condition_2', 'medical_condition_3', 'medical_condition_4'],
    control: formContext.control
  }).flat();

  const conditionsAvailableForSingleSelect = medicalConditionList?.map((item) => {
    return {
      id: item.id,
      label: item.condition_name,
      disabled: item.id !== medicalConditionOtherId && allSelectedConditions.includes(item.id)
    };
  });

  const conditionsAvailableForMultiSelect = medicalConditionList
    ?.filter((item) => !watchedSecondaryConditions.includes(item.id) || item.id === medicalConditionOtherId)
    .map((item) => ({
      id: item.id,
      label: item.condition_name
    }));

  const submitForm = (formData: FieldValues | undefined) => {
    const payload = {
      medical_condition_1: formData?.medical_condition_1,
      medical_condition_2: formData?.medical_condition_2,
      medical_condition_3: formData?.medical_condition_3,
      medical_condition_4: formData?.medical_condition_4,
      others_1_comment: isPrimaryConditionOtherChosen ? formData?.others_1_comment : '',
      others_2_comment: isSecondaryConditionOtherChosen ? formData?.others_2_comment : ''
    };

    if (onSubmit) {
      onSubmit(payload);
    }
  };

  return (
    <Box marginY={7}>
      <EditableSections
        title="Medical Condition"
        editable={editable}
        formContext={formContext}
        actions={{ onSubmit: submitForm }}
      >
        <FormContainer formContext={formContext}>
          <Box marginY={5}>
            {shouldShowDASSAssessment && (
              <DassAssessmentScoreStatus
                patientMedicalConditionData={defaultValues}
                dassAssessmentDetails={dassAssessmentDetails}
                isPDARequired={isPDARequired}
                dassAssessmentScoreDetail={dassAssessmentScoreDetail}
                fetchDassAssessment={fetchDassAssessment}
                patientId={patientId}
                userEmail={userEmail}
                disabled={!isEditMode}
                isDoc={isDoc}
              />
            )}

            {/* Primary Condition */}
            <StyledFieldContainer>
              <StyledMultiSelectElement
                label={'Primary Condition'}
                fullWidth
                name="medical_condition_1"
                options={conditionsAvailableForMultiSelect ?? []}
                disabled={!isEditMode}
                showChips
                data-testid={'primary-condition'}
              />
            </StyledFieldContainer>

            {isPrimaryConditionOtherChosen && (
              <StyledFieldContainer>
                <TextFieldElement
                  label={'Comment'}
                  name="others_1_comment"
                  variant="outlined"
                  data-testid="primary-condition-comment"
                  fullWidth
                  multiline
                  disabled={!isEditMode}
                  minRows={3}
                  inputProps={{ maxLength: 500 }}
                />
              </StyledFieldContainer>
            )}

            {/* Secondary Conditions */}
            <StyledFieldContainer>
              <SelectElement
                label={'Secondary Condition 1'}
                disabled={!isEditMode}
                name={'medical_condition_2'}
                options={conditionsAvailableForSingleSelect}
                fullWidth
                data-testid={'secondary-condition-1'}
              />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <SelectElement
                label={'Secondary Condition 2'}
                disabled={!isEditMode}
                name={'medical_condition_3'}
                options={conditionsAvailableForSingleSelect}
                fullWidth
                data-testid={'secondary-condition-2'}
              />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <SelectElement
                label={'Secondary Condition 3'}
                disabled={!isEditMode}
                name={'medical_condition_4'}
                options={conditionsAvailableForSingleSelect}
                fullWidth
                data-testid={'secondary-condition-3'}
              />
            </StyledFieldContainer>
            {isSecondaryConditionOtherChosen && (
              <StyledFieldContainer>
                <TextFieldElement
                  label={'Comment'}
                  name="others_2_comment"
                  variant="outlined"
                  data-testid="secondary-condition-comment"
                  fullWidth
                  multiline
                  disabled={!isEditMode}
                  minRows={3}
                  inputProps={{ maxLength: 500 }}
                />
              </StyledFieldContainer>
            )}
          </Box>
        </FormContainer>
      </EditableSections>
    </Box>
  );
};

export default withEditModeContextProvider(MedicalCondition);
