import { useQuery } from '@tanstack/react-query';

import { getProductDetailsById } from '@/api/product/getProductDetails';
import type { GetProductDetailsParams, GetProductDetailsResponse } from '@/api/product/getProductDetails.types';
import { QueryKey } from '@/data/query';

export const useGetProductDetails = (payload: GetProductDetailsParams) => {
  return useQuery({
    queryKey: [QueryKey.FilteredProducts, payload],
    queryFn: () => getProductDetailsById(payload),
    select: (response: GetProductDetailsResponse) => response.product
  });
};
