import { FF_CONSULTATION_FOLLOW_UP_PRICE, FF_CONSULTATION_PRICE_JSON } from '../../constants/featureFlags';
import { useFeatureFlags } from '../flags';

// TODO: Do not harcode AUD
const locale = 'en-AU';
const currency = 'AUD';

// We have a disjoint between the ownership of consult price and the discounts applied. The frontend owns the consult
// price for both initial and follow up consults. The frontend owns the manual discount rate applied by clinicians and
// the discount rate for concession patient consults. The backend owns campaigns which may apply a discountRate or
// discountAmount.
//
// Scenario Example:
// The business wants to introduce a consult price of $39 for initial consults booked within the month of June. In
// order to achieve this consistently, the backend applies a campaign to consult bookings. The frontend needs to
// display $39 in the Credit Card UI for the month of June, however still needs to invoke the `charge-for-consult`
// endpoint with the value of $59 so that the campaign discountAmount will be applied off the standard full amount.

function formatPrice(price: string | number) {
  if (typeof price === 'string') {
    const hasDecimal = price.includes('.') ? true : false;
    const minimumFractionDigits = hasDecimal ? 2 : 0;

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits
    }).format(parseFloat(price));
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(price);
}

export function useConsultationPrice() {
  const { flags } = useFeatureFlags();
  const consultFlagValue = flags[FF_CONSULTATION_PRICE_JSON];
  const consultValues = consultFlagValue;

  const consultationPrice = consultValues.displayPrice;
  const consultationChargePrice = consultValues.chargePrice;
  const formattedConsultationPrice = formatPrice(consultationPrice);

  const followUpConsultationPrice = flags[FF_CONSULTATION_FOLLOW_UP_PRICE];
  const followUpConsultationChargePrice = followUpConsultationPrice; // No difference for now
  const formattedFollowUpConsultationPrice = formatPrice(followUpConsultationPrice);

  return {
    consultationPrice,
    consultationChargePrice,
    formattedConsultationPrice,
    followUpConsultationPrice,
    followUpConsultationChargePrice,
    formattedFollowUpConsultationPrice,
    locale,
    currency
  };
}

export default useConsultationPrice;
