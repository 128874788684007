/* eslint-disable no-console */
import type { StatusType } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import type { Debugger } from 'debug';
import debug from 'debug';

/*
  Usage:-
    logs will show in development only, in production logs will be disabled
    to turn on in production set localStorage.debug = 'MG*';

  import { Logger } from '@/utils/logger';
  const logger = new Logger('MyComponent');
  ...
  ...
  logger.info(`hello world ${someValue}`);

*/

const NAMESPACE = 'MG';
const ENABLE_DDOG = !import.meta.env.DEV;

if (import.meta.env.DEV) {
  debug.enable(`${NAMESPACE}*`);
}

export class Logger {
  _debug: Debugger;

  _info: Debugger;

  _warn: Debugger;

  _error: Debugger;

  prefix?: string;

  enableDDogBrowserLogs: boolean;

  static getUserIdFunc: (() => string | undefined) | undefined;

  constructor(prefix?: string | undefined) {
    if (prefix) {
      this._debug = debug(`${NAMESPACE}:${prefix}`);
      this._info = debug(`${NAMESPACE}:INFO:${prefix}`);
      this._warn = debug(`${NAMESPACE}:WARN:${prefix}`);
      this._error = debug(`${NAMESPACE}:ERROR:${prefix}`);
    } else {
      this._debug = debug(`${NAMESPACE}`);
      this._info = debug(`${NAMESPACE}:INFO`);
      this._warn = debug(`${NAMESPACE}:WARN`);
      this._error = debug(`${NAMESPACE}:ERROR`);
    }

    this.enableDDogBrowserLogs = !import.meta.env.DEV;

    this.prefix = prefix;

    this._debug.log = function () {
      // @ts-expect-error: TS2345: Argument of type 'IArguments' is not assignable to parameter of type 'string'.
      // eslint-disable-next-line prefer-spread, prefer-rest-params
      console.debug.apply(console, arguments);
    };
    this._info.log = function () {
      // @ts-expect-error: TS2345: Argument of type 'IArguments' is not assignable to parameter of type 'string'.
      // eslint-disable-next-line prefer-spread, prefer-rest-params
      console.info.apply(console, arguments);
    };
    this._warn.log = function () {
      // @ts-expect-error: TS2345: Argument of type 'IArguments' is not assignable to parameter of type 'string'.
      // eslint-disable-next-line prefer-spread, prefer-rest-params
      console.warn.apply(console, arguments);
    };
    this._error.log = function () {
      // @ts-expect-error: TS2345: Argument of type 'IArguments' is not assignable to parameter of type 'string'.
      // eslint-disable-next-line prefer-spread, prefer-rest-params
      console.error.apply(console, arguments);
    };
  }

  debug(...args: unknown[]) {
    this.wrapLog(this._debug, 'debug', ...args);
  }

  info(...args: unknown[]) {
    this.wrapLog(this._info, 'info', ...args);
  }

  warn(...args: unknown[]) {
    this.wrapLog(this._warn, 'warn', ...args);
  }

  error(...args: unknown[]) {
    this.wrapLog(this._error, 'error', ...args);
  }

  static getUserId(): string | undefined {
    if (Logger.getUserIdFunc) {
      return Logger.getUserIdFunc();
    }
    return undefined;
  }

  sendToDatadog(logLevel: StatusType, ...args: unknown[]): void {
    try {
      const first = args?.[0];

      const firstMsg = typeof first === 'string' || first instanceof String ? (first as string) : JSON.stringify(first);

      const msg = [NAMESPACE];

      if (this.prefix) {
        msg.push(this.prefix);
      }

      msg.push(firstMsg);

      const messageContext = {
        args,
        user_id: Logger.getUserId() || 'logged_out'
      };

      // find the first error in the args, and send this to ddog as an 'error'
      const err = args.find((x) => x instanceof Error) as Error | undefined;

      datadogLogs.logger.log(msg.join(':'), messageContext, logLevel, err);
    } catch (err) {
      console.error('failed to send to datadog: ', err);
    }
  }

  wrapLog = (logFunc: (...args: unknown[]) => unknown | void, logLevel: StatusType, ...args: unknown[]) => {
    logFunc(...args);
    if (ENABLE_DDOG && logLevel !== 'debug') {
      this.sendToDatadog(logLevel, ...args);
    }
  };
}
