import settings from '@/data/constants';
import type { DoctorRoundRobinItem } from '@/hooks/rest/types';
import { useGetRoundRobinDoctorsInitial } from '@/hooks/rest/useGetRoundRobinDoctorsInitial';
import useTransferList from '@/hooks/useTransferList';
import theme from '@/theme';
import type { TransferListItem } from '@montugroup/design-system';
import { TransferList } from '@montugroup/design-system';
import type { AlertColor } from '@mui/material';
import { Button, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';

interface SnackBarMessage {
  type: string;
  message: string;
}

const DoctorsRoundRobinInitial = () => {
  const [showSnackbar, setShowSnackbar] = useState<{ type: string; message: string } | boolean>(false);

  const { data: roundRobinDoctors, isError, refetch } = useGetRoundRobinDoctorsInitial();

  useEffect(() => {
    if (isError) {
      setShowSnackbar({ type: 'error', message: 'An error has occurred while loading the round robin' });
    }
  }, [isError]);

  const formatDoctorsTransferList = (list: DoctorRoundRobinItem[]): TransferListItem[] => {
    return list?.map((listItem) => ({
      id: listItem.calcom_doctor_id,
      label: `Dr ${listItem.DoctorUser.first_name} ${listItem.DoctorUser.last_name}`
    }));
  };

  const leftSideItems = useMemo(() => {
    return formatDoctorsTransferList(roundRobinDoctors?.removed || []);
  }, [roundRobinDoctors]);

  const rightSideItems = useMemo(() => {
    return formatDoctorsTransferList(roundRobinDoctors?.current || []);
  }, [roundRobinDoctors]);

  const transferList = useTransferList(leftSideItems, rightSideItems);

  const handleSaveRoundRobin = async () => {
    const doctorsForRoundRobin = transferList.rightSideItems.map((checkedDoctor) => checkedDoctor.id);
    const url = `${settings.url}/calcom/doctor-roundrobin`;

    const snackbarError = () => {
      setShowSnackbar({ type: 'error', message: 'An error has occurred while saving the round robin' });
    };

    try {
      const response = await axios.put(url, {
        hosts: doctorsForRoundRobin
      });

      if ([200, 201].includes(response.status)) {
        setShowSnackbar({ type: 'success', message: 'Round Robin Saved' });
        refetch();
      } else {
        snackbarError();
      }
    } catch {
      snackbarError();
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      <Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} flexDirection={'column'} mr={50}>
            <Typography variant="h6">Initial Consultations Round Robin</Typography>
            <Typography color={theme.palette.grey[500]}>
              Add or remove doctors from the &apos;initial consultation&apos; round robin directly in PMS. Ensure users
              have their schedules set up beforehand for it to take effect.
            </Typography>
          </Box>
          <Button variant="contained" color="secondary" onClick={handleSaveRoundRobin}>
            Save Round Robin
          </Button>
        </Box>
        <Box>
          <TransferList {...transferList} headingRightSide={'In Round Robin'} headingLeftSide={'Not in Round Robin'} />
        </Box>
      </Box>
      <Snackbar
        open={!!showSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSnackbarClose}
        autoHideDuration={3000}
      >
        <Alert variant="filled" severity={(showSnackbar as SnackBarMessage)?.type as AlertColor}>
          {(showSnackbar as SnackBarMessage).message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DoctorsRoundRobinInitial;
