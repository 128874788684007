import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';

import type { CatalogProduct } from '../../../hooks/rest/useGetProductList';

type Props = {
  value: string | number;
  options: CatalogProduct[];
  uniqueKey: string;
  onSelect: (uniqueKey: string, arg: CatalogProduct | undefined) => void;
};

export default function ProductSelect({ value, options, uniqueKey, onSelect }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    const productId = parseInt(event.target.value);
    const selectedProduct = options.find((product) => product.id === productId);
    onSelect(uniqueKey, selectedProduct);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select displayEmpty value={value.toString()} onChange={handleChange}>
          {options.map((product) => (
            <MenuItem key={product.id} value={product.id}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
