import { Button, styled } from '@mui/material';

/*
  This component should be nested within the alternaleaf theme.
  The 'theme' used corresponds to the @/theme/alternaleaf theme.
*/
export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  lineHeight: 'initial',
  border: `1px solid ${theme.palette.primary.main}`,
  width: '100%',
  padding: 10,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`
  }
}));
