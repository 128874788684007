import settings from '@/data/constants';

import { requestHeaders } from '../api.utils';

import type {
  FilteredProductsPayloadType,
  ProductByDeviceResponse,
  ProductFilteredResponse
} from './filteredProducts.types';

export const fetchFilteredProductsByDoctor = async ({
  doctorId,
  isConcession
}: FilteredProductsPayloadType): Promise<ProductFilteredResponse> => {
  const response = await (
    await fetch(`${settings.url}/product/filtered/by-doctor/${doctorId}?isConcession=${isConcession || false}`, {
      method: 'GET',
      headers: requestHeaders()
    })
  ).json();
  return response;
};

/**
 * Used for getting vaporizer.
 */
export const fetchFilteredProductsByDoctorByDevice = async ({
  doctorId
}: FilteredProductsPayloadType): Promise<ProductByDeviceResponse> => {
  const response = await (
    await fetch(`${settings.url}/product/filtered/device/by-doctor/${doctorId}`, {
      method: 'GET',
      headers: requestHeaders()
    })
  ).json();
  return response;
};
