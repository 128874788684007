import { Logger } from '@/utils/logger';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import App from './App';
import ErrorFallback from './ErrorFallback';

const logger = new Logger('PMS_APP');

const root = createRoot(document.getElementById('root') as HTMLElement);

// enable logging to data dog
const logError = (error: Error, info: { componentStack: string }) => {
  logger.error(error, info);
};

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
