import type { PrescribedMedicationSchema } from '@montugroup/prescription-contracts';
import { useMemo } from 'react';

type SortablePrescriptionItem = {
  status: string;
  createdDate?: string;
  updatedDate?: string;
  dossier?: { createdDate: string }[];
};
// Define constants for status priorities
const STATUS_PRIORITY = {
  ACTIVE: 0,
  CANCELLED: 1,
  EXPIRED: 2,
  UNKNOWN: 3
} as const;

/**
 * Determines the priority of a product based on its status.
 * Lower numbers indicate higher priority. This prioritization is used to sort products by their status in a predictable manner.
 *
 * @param status - The status of the product.
 * @returns The priority of the given status.
 */
const getStatusPriority = (status: string): number => {
  switch (status.toLowerCase()) {
    case 'active':
      return STATUS_PRIORITY.ACTIVE;
    case 'cancelled':
      return STATUS_PRIORITY.CANCELLED;
    case 'expired':
      return STATUS_PRIORITY.EXPIRED;
    default:
      return STATUS_PRIORITY.UNKNOWN;
  }
};

/**
 * Generic comparator function for sorting items by status and date.
 *
 * @param a - The first item to compare.
 * @param b - The second item to compare.
 * @returns A number indicating the sort order.
 */
export const comparePrescriptionItems = <T extends SortablePrescriptionItem>(a: T, b: T): number => {
  // First, sort by status priority
  const statusDiff = getStatusPriority(a.status) - getStatusPriority(b.status);
  if (statusDiff !== 0) {
    return statusDiff;
  }

  // If status is the same, sort by the date
  const aLatestDate = new Date(a.dossier?.[0]?.createdDate || a.createdDate || a.updatedDate || 0);
  const bLatestDate = new Date(b.dossier?.[0]?.createdDate || b.createdDate || b.updatedDate || 0);
  return bLatestDate.getTime() - aLatestDate.getTime();
};

/**
 * Custom hook for sorting prescribed products.
 *
 * This hook takes an array of prescribed products and returns a new array sorted based on two criteria:
 * 1. Status priority: Products are first sorted by their status. The priority of each status is determined by the `getStatusPriority` function.
 * 2. Most recent prescription date: If two products have the same status, they are then sorted by the date of their most recent prescription.
 *    The date is determined by the `createdDate` of the first item in the `dossier` array, if available, or the `updatedDate` otherwise.
 *
 * The sorting is done in a way that does not mutate the original array of prescribed products.
 *
 * @param prescribedProductsData - The array of prescribed products to be sorted.
 * @returns A new array of prescribed products sorted by status priority and most recent prescription date.
 */
export const useSortedPrescribedProducts = (prescribedProductsData: PrescribedMedicationSchema[]) => {
  return useMemo(() => {
    if (!prescribedProductsData.length) {
      return prescribedProductsData;
    }

    // .slice to avoid mutating the original array
    return prescribedProductsData.slice().sort(comparePrescriptionItems);
  }, [prescribedProductsData]);
};

/**
 * Sorts an array of items by status priority and date.
 *
 * @param items - The array of items to be sorted.
 * @returns A new sorted array of items.
 */
export const sortPrescriptionItems = <T extends SortablePrescriptionItem>(items: T[]): T[] => {
  return items.slice().sort(comparePrescriptionItems);
};
