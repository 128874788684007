import settings from '@/data/constants';
import type { DeviceProduct, PrescriptionFormProduct } from '@/hooks/prescription/usePrescriptionForm';
import type { PrescriptionAttributes, TypedResponse } from '@/types';

import { requestHeaders } from '../api.utils';

export interface CreatePrescriptionData {
  order_date: Date;
  // TODO: review whether the actual value is number or string. It's unclear at the moment
  consultation_id: number;
  patient_id: number;
  doctor_id: number | null;
  is_home_delivery: boolean;
}

export interface CreatePrescriptionRequestBody {
  data: CreatePrescriptionData;
  products: (PrescriptionFormProduct | DeviceProduct)[];
}

export const createPrescription = async (
  payload: CreatePrescriptionRequestBody
): Promise<TypedResponse<PrescriptionAttributes>> => {
  const endpoint = `${settings.url}/prescription`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: requestHeaders(),
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    const errorMessage = await response.text();
    // TODO: consider custom error object
    throw new Error(errorMessage);
  }
  return response;
};
