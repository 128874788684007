export enum ModalIhiFieldNames {
  FirstName = 'First Name',
  LastName = 'Last Name',
  MedicareReferenceNumber = 'Medicare Reference Number',
  MedicareNumber = 'Medicare Number',
  IHINumber = 'IHI Number',
  Gender = 'Gender',
  DateOfBirth = 'Date of Birth'
}

export interface RadioSelectionState {
  firstName?: boolean;
  lastName?: boolean;
  medicareNumber?: boolean;
  medicareRefNumber?: boolean;
  ihiNumber?: boolean;
  dob?: boolean;
  gender?: boolean;
}

export type IhiFieldsWithDifferences = {
  firstName?: boolean;
  lastName?: boolean;
  medicareNumber?: boolean;
  medicareRefNumber?: boolean;
  ihiNumber?: boolean;
  dob?: boolean;
  gender?: boolean;
};
