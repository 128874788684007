import { createColumnHelper } from '@tanstack/react-table';

import { renderHeader } from '@/components/tables/components/Table.utils';

export const useTableUtils = () => {
  const columnHelper = createColumnHelper();
  const createColumnDefinition = (
    accessorValue: string,
    headerValue: string,
    columnWidth?: number,
    extraAttributes?: Record<string, unknown> | null
  ) => {
    return columnHelper.accessor(accessorValue, {
      header: () => renderHeader(headerValue),
      cell: (cell) => {
        return cell.getValue() ? cell.getValue() : '-';
      },
      ...(columnWidth
        ? {
            meta: {
              sx: {
                width: columnWidth
              }
            }
          }
        : {}),
      ...extraAttributes
    });
  };

  return {
    createColumnDefinition
  };
};
