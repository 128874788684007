/* eslint-disable indent */

export const idleState = {
  allowed_idle_time: 10,
  last_activity: new Date()
};

export const IdleReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALLOWED_IDLE_TIME':
      return {
        ...state,
        allowed_idle_time: action.payload.inactive_mins
      };
    case 'SET_LAST_ACTIVITY':
      return {
        ...state,
        last_activity: action.payload.last_activity
      };
    default:
      return state;
  }
};
