import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { getUser } from '@/data/service/authService';
import identifyUserForLaunchDarkly from '@/utils/launchdarkly/identifyUserForLaunchDarkly';
import { Logger } from '@/utils/logger';

const logger = new Logger('useIdentifyUserForLaunchDarkly');

export const useIdentifyUserForLaunchDarkly = () => {
  const ldClient = useLDClient();
  const user = getUser();
  useEffect(() => {
    if (ldClient && user) {
      identifyUserForLaunchDarkly(ldClient, user);
    } else {
      logger.error(
        `Unable to identify user for LaunchDarkly. Details: 
        ldClient: ${ldClient ? 'Available' : 'Not available'}
        user data: ${user ? 'Available' : 'Missing'}`
      );
    }
  }, [ldClient, user]);
};
