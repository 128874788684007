/** @deprecated we're moving towards using a design system */
const FullCalendarStyles = () => ({
  fullCalendar: {
    '& .fc-button:empty': {
      display: 'none'
    },
    '& .fc-button:has(+ .fc-button:empty)': {
      borderRadius: 3
    },
    '& .fc-timegrid-body': {
      zIndex: '0'
    },
    '& .fc-event': {
      cursor: 'pointer'
    }
  }
});

export default FullCalendarStyles;
