import { useQuery } from '@tanstack/react-query';

import type { GetDoctorCalendarConsultationsParams } from '@/api/consultations/getDoctorCalendarConsultations';
import { getDoctorCalendarConsultations } from '@/api/consultations/getDoctorCalendarConsultations';
import { QueryKey } from '@/data/query';
import type { DoctorConsultation } from '@/types';

type DoctorCalendarConsultationsResponse = {
  data: {
    consultations: DoctorConsultation[];
  };
};
export const useGetDoctorCalendarConsultations = ({
  selectedDoctor,
  dateIsoString
}: GetDoctorCalendarConsultationsParams) => {
  const {
    data: doctorCalendarConsultations,
    isError,
    isLoading,
    refetch
  } = useQuery<DoctorCalendarConsultationsResponse>({
    queryKey: [QueryKey.DoctorConsultations, selectedDoctor, dateIsoString],
    queryFn: async () => {
      const getDoctorCalendarConsultationsResponse = await getDoctorCalendarConsultations({
        selectedDoctor,
        dateIsoString
      });

      return getDoctorCalendarConsultationsResponse;
    }
  });

  return {
    doctorCalendarConsultations: doctorCalendarConsultations?.data.consultations,
    isError,
    isLoading,
    refetch
  };
};
