import { AppBar, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import { ConsultationDoubleBookingsTable } from '@/components/ConsultationDoubleBookings/ConsultationDoubleBookingsTable';
import { Tab } from '@/shared-ui/Tabs/Tab';
import { Tabs } from '@/shared-ui/Tabs/Tabs';
import theme from '@/theme';

import '../assets/scss/styles.scss';

const StyledAppBarContainer = styled(Box)({
  width: 500,

  '.MuiAppBar-root': {
    boxShadow: 'none'
  }
});

const ConsultationDoubleBookings = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <Box paddingX={4} mt={3}>
      <Box component="header" display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Double Bookings</Typography>
        <StyledAppBarContainer>
          <AppBar position="static" color="transparent">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              variant={isSmallerScreen ? 'scrollable' : 'fullWidth'}
              scrollButtons="auto"
            >
              <Tab label="Patients" />
              <Tab label="Practitioners" />
            </Tabs>
          </AppBar>
        </StyledAppBarContainer>
      </Box>
      <Box display="flex">
        <Box component="div" width="100%">
          <ConsultationDoubleBookingsTable tabValue={tabValue} />
        </Box>
      </Box>
    </Box>
  );
};

export default ConsultationDoubleBookings;
