import { Button } from '@montugroup/design-system';
import PersonIcon from '@mui/icons-material/Person';
import { buttonClasses } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import settings from '@/data/constants';

import type { DoctorWithSlugs, PrefillCalCom } from './BookSpecificDoctorButton';

export type BookSpecificDoctorListItemProps = {
  doctor: DoctorWithSlugs;
  formData: PrefillCalCom;
  disableBookingTypeButton: boolean;
  handleClose: () => void;
};

const BookSpecificDoctorListItem = (props: BookSpecificDoctorListItemProps) => {
  const { doctor, formData, disableBookingTypeButton, handleClose } = props;

  const disableInitialConsultBookingBtn = disableBookingTypeButton;
  const disableFollowUpConsultBookingBtn = !disableBookingTypeButton;

  const StyledListItem = styled('li')(({ theme }) => ({
    marginBottom: 10,
    borderRadius: 10,
    border: `2px solid ${theme.palette.grey[200]}`,
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold'
  }));

  const StyledButtonContainer = styled(Box)({
    display: 'flex'
  });

  const StyledBookTypeButton = styled(Button)(({ theme }) => ({
    '&:first-of-type': {
      marginRight: 20
    },

    [`&.${buttonClasses.contained}&.${buttonClasses.disabled}`]: {
      border: 'none',
      background: `${theme.palette.grey[200]}`
    }
  }));

  const StyledDoctorName = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '> svg': {
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[400],
      padding: 2,
      zoom: '1.2',
      marginRight: 10
    }
  }));

  const StyledDoctorCode = styled('span')(({ theme }) => ({
    color: theme.palette.grey[400]
  }));

  return (
    <StyledListItem>
      <StyledDoctorName>
        <PersonIcon />
        {doctor.name}&nbsp;<StyledDoctorCode>{doctor.doctor_code}</StyledDoctorCode>
      </StyledDoctorName>
      <StyledButtonContainer>
        {doctor.calcom_initial_consult_event && (
          <StyledBookTypeButton
            color="secondary"
            data-cal-config={JSON.stringify(formData ?? {})}
            data-cal-link={`${doctor.calcom_username}/${doctor.calcom_initial_consult_event}`}
            data-cal-origin={settings.calendar.calOrigin}
            disabled={disableInitialConsultBookingBtn}
            onClick={() => handleClose()}
          >
            Book Initial Consult
          </StyledBookTypeButton>
        )}
        {doctor.calcom_follow_up_consult_event && (
          <StyledBookTypeButton
            color="secondary"
            data-cal-config={JSON.stringify(formData ?? {})}
            data-cal-link={`${doctor.calcom_username}/${doctor.calcom_follow_up_consult_event}`}
            data-cal-origin={settings.calendar.calOrigin}
            disabled={disableFollowUpConsultBookingBtn}
            onClick={() => handleClose()}
          >
            Book Follow-Up Consult
          </StyledBookTypeButton>
        )}
      </StyledButtonContainer>
    </StyledListItem>
  );
};

export default BookSpecificDoctorListItem;
