// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Checkbox, Chip, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import iziToast from 'izitoast';
import { useEffect, useState } from 'react';

import useStyles from '../../assets/js/patientsV2';
import { makePUT } from '../../data/service/dataService';

import DassAssessmentScoreStatus from './DassAssessmentScoreStatus';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 300
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  variant: 'menu'
};

const MedicalCondition = (props) => {
  const classes = useStyles();
  const {
    data,
    medicalConditionDisabled,
    setData,
    setChanged,
    medicalConditionList,
    medicalConditionOtherId,
    dassAssessmentDetails,
    dassAssessmentScoreDetail,
    patientId,
    userEmail,
    isDoc,
    setMedicalConditionChanged,
    checkPDAisAvailable,
    fetchDassAssessment
  } = props;
  const [medicalConditionPrimaryList, setMedicalConditionPrimaryList] = useState(medicalConditionList);
  const [medicalConditionSecondary1List, setMedicalConditionSecondary1List] = useState(medicalConditionList);
  const [medicalConditionSecondary2List, setMedicalConditionSecondary2List] = useState(medicalConditionList);
  const [medicalConditionSecondary3List, setMedicalConditionSecondary3List] = useState(medicalConditionList);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [show_other_1_comment, setOther1Comment] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [show_other_2_comment, setOther2Comment] = useState(false);
  const [resendEmailClicked, setResendEmailClicked] = useState(false);

  const handleDelete = (e, value) => {
    e.preventDefault();
    setData({
      ...data,
      medical_condition_1: data.medical_condition_1.filter((x) => x !== value.id),
      others_1_comment: value.id === medicalConditionOtherId ? '' : data.others_1_comment
    });
    setChanged(true);
    setMedicalConditionChanged(true);
  };

  const handleMedicalCondition = (event, key) => {
    let val;
    if (key === 'medical_condition_1') {
      val = event.target.value.find((e) => e === medicalConditionOtherId);
      if (val === medicalConditionOtherId) {
        setOther1Comment(true);
      } else {
        setOther1Comment(false);
      }
    }
    if (key !== 'others_1_comment') {
      setData({
        ...data,
        [key]: event.target.value,
        others_1_comment: key === 'medical_condition_1' && val !== medicalConditionOtherId ? '' : data.others_1_comment
      });
    } else {
      setData({
        ...data,
        [key]: event.target.value
      });
    }
    setChanged(true);
    setMedicalConditionChanged(true);
  };

  const handleSecondaryMedicalCondition = (event, key) => {
    if (
      event.target.value === medicalConditionOtherId ||
      (key !== 'medical_condition_2' && data.medical_condition_2 === medicalConditionOtherId) ||
      (key !== 'medical_condition_3' && data.medical_condition_3 === medicalConditionOtherId) ||
      (key !== 'medical_condition_4' && data.medical_condition_4 === medicalConditionOtherId)
    ) {
      setOther2Comment(true);
      setData({
        ...data,
        [key]: event.target.value
      });
    } else {
      setOther2Comment(false);
      setData({
        ...data,
        [key]: event.target.value,
        others_2_comment: ''
      });
    }
    setChanged(true);
    setMedicalConditionChanged(true);
  };

  const handleResendEmail = async () => {
    if (!resendEmailClicked) {
      setResendEmailClicked(true);
      const mcondition = checkPDAisAvailable();
      if (mcondition) {
        const sendBody = {
          patientId: patientId,
          userEmail: userEmail,
          daysLinkValid: 5,
          patientEmail: data.email,
          patientName: data.FirstName
        };
        const resp = await makePUT(`dass/updateDass`, sendBody, 'resendEmail');
        if (resp?.status === 200) {
          iziToast.success({
            title: 'DASS assessment email has been sent',
            position: 'topRight'
          });
          fetchDassAssessment();
        } else {
          iziToast.success({
            title: 'Failed to send DASS assessment email',
            position: 'topRight'
          });
        }
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const medical_condition_comment = data.medical_condition_1.find((e) => e === medicalConditionOtherId);
    if (medical_condition_comment === medicalConditionOtherId) {
      setOther1Comment(true);
    } else {
      setOther1Comment(false);
    }
  }, [medicalConditionOtherId, data.medical_condition_1]);

  useEffect(() => {
    setMedicalConditionPrimaryList(
      medicalConditionList.filter(function (value) {
        return (
          [
            data.medical_condition_2 !== medicalConditionOtherId ? data.medical_condition_2 : '',
            data.medical_condition_3 !== medicalConditionOtherId ? data.medical_condition_3 : '',
            data.medical_condition_4 !== medicalConditionOtherId ? data.medical_condition_4 : ''
          ]
            .filter(Number)
            .indexOf(value.id) === -1
        );
      })
    );
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const medicalCondition1_withoutOtherId = data.medical_condition_1.filter((e) => e !== medicalConditionOtherId);
    setMedicalConditionSecondary1List(
      medicalConditionList.filter(function (value) {
        return (
          [
            ...medicalCondition1_withoutOtherId,
            data.medical_condition_3 !== medicalConditionOtherId ? data.medical_condition_3 : '',
            data.medical_condition_4 !== medicalConditionOtherId ? data.medical_condition_4 : ''
          ]
            .filter(Number)
            .indexOf(value.id) === -1
        );
      })
    );
    setMedicalConditionSecondary2List(
      medicalConditionList.filter(function (value) {
        return (
          [
            ...medicalCondition1_withoutOtherId,
            data.medical_condition_2 !== medicalConditionOtherId ? data.medical_condition_2 : '',
            data.medical_condition_4 !== medicalConditionOtherId ? data.medical_condition_4 : ''
          ]
            .filter(Number)
            .indexOf(value.id) === -1
        );
      })
    );
    setMedicalConditionSecondary3List(
      medicalConditionList.filter(function (value) {
        return (
          [
            ...medicalCondition1_withoutOtherId,
            data.medical_condition_3 !== medicalConditionOtherId ? data.medical_condition_3 : '',
            data.medical_condition_2 !== medicalConditionOtherId ? data.medical_condition_2 : ''
          ]
            .filter(Number)
            .indexOf(value.id) === -1
        );
      })
    );
  }, [
    data.medical_condition_1,
    data.medical_condition_2,
    data.medical_condition_3,
    data.medical_condition_4,
    medicalConditionList,
    medicalConditionOtherId
  ]);

  return (
    <div className={classes.accordionDetail}>
      <Grid xs={12} item>
        {((dassAssessmentDetails?.link_send_count > 0 && checkPDAisAvailable()) ||
          dassAssessmentScoreDetail?.anxiety?.level) && (
          <DassAssessmentScoreStatus
            dassAssessmentDetails={dassAssessmentDetails}
            dassAssessmentScoreDetail={dassAssessmentScoreDetail}
            handleResendEmail={handleResendEmail}
            disabled={medicalConditionDisabled}
            isDoc={isDoc}
          />
        )}
      </Grid>
      <InputLabel style={{ margin: '1rem 0 0.3rem' }} className={classes.subHeading} htmlFor="demo-customized-textbox">
        Primary Condition
      </InputLabel>
      <Select
        labelId="primary-condition"
        id="primary-medical-condition"
        multiple
        disabled={medicalConditionDisabled}
        className={classes.selectOption}
        value={data.medical_condition_1}
        dat-testid="primaryCondition"
        onChange={(e) => handleMedicalCondition(e, 'medical_condition_1')}
        variant="outlined"
        inputProps={{
          'data-testid': 'primary-condition-1'
        }}
        renderValue={(selected) => (
          <div className={classes.chips_container}>
            {medicalConditionList
              .filter((fEle) => selected.includes(fEle.id))
              .map((value, index) => (
                <div key={value.id}>
                  {!medicalConditionDisabled ? (
                    <Chip
                      key={value.id}
                      label={value.condition_name}
                      className={classes.chip}
                      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      onDelete={(e) => handleDelete(e, value)}
                    />
                  ) : (
                    <div>{`${value.condition_name}${index === selected.length - 1 ? '' : ','}`}&nbsp;</div>
                  )}
                </div>
              ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {medicalConditionPrimaryList?.map((item) => (
          <MenuItem key={item.id} value={item.id} data-testid="primary-condition-1-option">
            <Checkbox checked={data.medical_condition_1.indexOf(item.id) > -1} />
            <ListItemText primary={item.condition_name} />
          </MenuItem>
        ))}
      </Select>
      {show_other_1_comment && (
        <Box mt={2}>
          <InputLabel className={classes.subHeading} htmlFor="demo-customized-textbox">
            Comment
          </InputLabel>
          <TextField
            variant="outlined"
            value={data.others_1_comment}
            data-testid="primary-condition-1-comment"
            fullWidth
            multiline
            disabled={medicalConditionDisabled}
            minRows={3}
            inputProps={{ maxLength: 500 }}
            onChange={(e) => handleMedicalCondition(e, 'others_1_comment')}
          />
        </Box>
      )}
      <InputLabel style={{ margin: '1rem 0 0.3rem' }} className={classes.subHeading} htmlFor="demo-customized-textbox">
        Secondary Condition 1
      </InputLabel>
      <Select
        value={data.medical_condition_2}
        className={classes.selectOption}
        disabled={medicalConditionDisabled}
        onChange={(e) => handleSecondaryMedicalCondition(e, 'medical_condition_2')}
        variant="outlined"
        inputProps={{
          className: data.medical_condition_2 ? classes.selectInput : '',
          'data-testid': 'secondary-condition-1'
        }}
        MenuProps={MenuProps}
      >
        {medicalConditionSecondary1List?.map((ele) => (
          <MenuItem key={ele.id} value={ele.id} data-testid="secondary-condition-1-option">
            <ListItemText primary={ele.condition_name} />
          </MenuItem>
        ))}
      </Select>
      <InputLabel style={{ margin: '1rem 0 0.3rem' }} className={classes.subHeading} htmlFor="demo-customized-textbox">
        Secondary Condition 2
      </InputLabel>
      <Select
        value={data.medical_condition_3}
        className={classes.selectOption}
        disabled={medicalConditionDisabled}
        onChange={(e) => handleSecondaryMedicalCondition(e, 'medical_condition_3')}
        variant="outlined"
        inputProps={{
          className: data.medical_condition_3 ? classes.selectInput : '',
          'data-testid': 'secondary-condition-2'
        }}
        MenuProps={MenuProps}
      >
        {medicalConditionSecondary2List?.map((ele) => (
          <MenuItem key={ele.id} value={ele.id}>
            <ListItemText primary={ele.condition_name} />
          </MenuItem>
        ))}
      </Select>
      <InputLabel style={{ margin: '1rem 0 0.3rem' }} className={classes.subHeading} htmlFor="demo-customized-textbox">
        Secondary Condition 3
      </InputLabel>
      <Select
        value={data.medical_condition_4}
        className={classes.selectOption}
        disabled={medicalConditionDisabled}
        onChange={(e) => handleSecondaryMedicalCondition(e, 'medical_condition_4')}
        variant="outlined"
        inputProps={{
          className: data.medical_condition_4 ? classes.selectInput : '',
          'data-testid': 'secondary-condition-3'
        }}
        MenuProps={MenuProps}
      >
        {medicalConditionSecondary3List?.map((ele) => (
          <MenuItem key={ele.id} value={ele.id}>
            <ListItemText primary={ele.condition_name} />
          </MenuItem>
        ))}
      </Select>
      {show_other_2_comment && (
        <>
          <InputLabel
            style={{ margin: '1rem 0 0.3rem' }}
            className={classes.subHeading}
            htmlFor="demo-customized-textbox"
          >
            Comment
          </InputLabel>
          <TextField
            variant="outlined"
            value={data?.others_2_comment}
            data-testid="secondary-condition-comment"
            fullWidth
            multiline
            disabled={medicalConditionDisabled}
            minRows={3}
            inputProps={{ maxLength: 500 }}
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
            onChange={(e) => handleMedicalCondition(e, 'others_2_comment')}
          />
        </>
      )}
    </div>
  );
};

export default MedicalCondition;
