import { formatProductList } from '@/components/rescripting/RescriptingPage.utils';
import { FF_OOS_CONCESSION_REPLACEMENTS } from '@/constants/featureFlags';

import { useFeatureFlags } from '../flags';
import type { CatalogProduct } from '../rest/useGetProductList';
import useGetRescriptProducts from '../rest/useGetRescriptProducts';

export function useRescriptAlternatives() {
  const { flags } = useFeatureFlags();
  const ffOOSConcessionReplacements = flags[FF_OOS_CONCESSION_REPLACEMENTS];

  const { data, ...other } = useGetRescriptProducts();

  let parsed: CatalogProduct[] = [];
  if (ffOOSConcessionReplacements) {
    /** The logic from {@link formatProductList} has been moved to the BE in this flow */
    parsed = data ? data.products : [];
  } else {
    parsed = data ? formatProductList(data) : [];
  }

  return { data, ...other, parsed };
}

export default useRescriptAlternatives;
