import { Drawer } from '@mui/material';

import type { DoctorConsultation, NurseConsultation } from '@/types';
import { LUXON_FORMAT_DAY_MONTH_YEAR, LUXON_FORMAT_LONG_DATE_TIME, ZONE_LOCAL, ZONE_UTC } from '@/utils/luxon';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/styles';
import { DateTime } from 'luxon';

export type TimelineSchedulerDrawerProps = {
  selectedEvent: NurseConsultation | DoctorConsultation | null;
  isOpen: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const StyledDrawerContent = styled(Box)({
  width: '400px'
});

const StyledConsultType = styled(Typography)({
  backgroundColor: grey[300],
  display: 'inline-block',
  padding: '2px 13px',
  borderRadius: '15px',
  fontWeight: '600',
  marginBottom: 5
});

export const TimelineSchedulerDrawer = (props: TimelineSchedulerDrawerProps) => {
  const { selectedEvent, toggleDrawer, isOpen } = props;

  if (!selectedEvent) {
    return null;
  }

  const consultLocalStartTime = DateTime.fromISO(selectedEvent.start_time, { zone: ZONE_UTC })
    .setZone(ZONE_LOCAL)
    .toFormat(LUXON_FORMAT_LONG_DATE_TIME);
  const birthdate = selectedEvent.patient_dob
    ? DateTime.fromISO(selectedEvent.patient_dob, { zone: ZONE_UTC }).setZone(ZONE_LOCAL)
    : null;

  const patientsAge = birthdate ? Math.floor(DateTime.now().diff(birthdate, 'years').years) : null;
  const patientTimezone = (selectedEvent as NurseConsultation)?.timezone ?? null;
  const consultType =
    (selectedEvent as NurseConsultation)?.consultation_type ?? (selectedEvent as DoctorConsultation)?.consult_type;

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)} data-testid="calendar-drawer">
      <StyledDrawerContent p={5}>
        <Typography fontSize={'1.2rem'} fontWeight={'bold'} mb={3}>
          {selectedEvent.consultation_code}
        </Typography>
        <Box display={'flex'} mb={10}>
          <CalendarTodayIcon />
          <Typography ml={3} fontSize={'1rem'}>
            {consultLocalStartTime} {patientTimezone ? `(${patientTimezone})` : ''}
          </Typography>
        </Box>
        <Box mb={10}>
          <StyledConsultType>{consultType}</StyledConsultType>
          <Typography fontWeight={500} fontSize={'1.6rem'}>
            {selectedEvent.patient_name}
          </Typography>
          <Typography>{selectedEvent.patient_code}</Typography>
          <Typography>
            DoB: {birthdate?.isValid ? birthdate.toFormat(LUXON_FORMAT_DAY_MONTH_YEAR) : 'unknown'}
          </Typography>
          <Typography>Age: {birthdate?.isValid ? patientsAge : 'unknown'}</Typography>
        </Box>
        <Box mb={5}>
          <Typography mb={2}>Phone</Typography>
          <Typography fontSize={'1.2rem'} pb={2} borderBottom={`1px solid ${grey[500]}`}>
            {selectedEvent.patient_phone}
          </Typography>
        </Box>
        <Box mb={15}>
          <Typography mb={2}>Email</Typography>
          <Typography fontSize={'1.2rem'} pb={2} borderBottom={`1px solid ${grey[500]}`}>
            {selectedEvent.patient_email}
          </Typography>
        </Box>
        <Button
          component={'a'}
          href={`/patients/${selectedEvent.patient_id}?show=1`}
          variant="contained"
          color="secondary"
          target={'_blank'}
          fullWidth
        >
          View
        </Button>
      </StyledDrawerContent>
    </Drawer>
  );
};
