export const appEnvStrings = ['development', 'staging', 'production'] as const;
export type AppEnv = (typeof appEnvStrings)[number];

export const getAppEnv = (): AppEnv => {
  const mode = (import.meta.env.VITE_MODE || import.meta.env.MODE) as AppEnv | undefined;
  if (!mode || !appEnvStrings.includes(mode)) {
    return 'production';
  }
  return mode;
};

export const e2eTestsSkipToken = () => {
  const skipToken = import.meta.env.VITE_E2E_TESTS_SKIP_TOKEN;
  return skipToken;
};

export const viteAppBaseUrl = () => {
  const baseUrl = import.meta.env?.VITE_API_BASE_URL;
  if (baseUrl === undefined) {
    throw new Error('API Base URL not configured');
  }
  return baseUrl;
};

export const viteProductImageBaseUrl = () => {
  const baseUrl = import.meta.env?.VITE_PRODUCT_IMAGE_BASE_URL;
  if (baseUrl === undefined) {
    throw new Error('Product Image Base URL not configured');
  }
  return baseUrl;
};

export const viteFFOverrides = (): object | null => {
  const ffOverrides = import.meta.env?.VITE_FF_OVERRIDES;

  if (ffOverrides) {
    try {
      return JSON.parse(ffOverrides);
    } catch (e) {
      throw new Error(`VITE_FF_OVERRIDES is not a valid JSON string ${e}`);
    }
  }

  return null;
};

export default null;
