import { alpha, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    pillCard: Palette['primary'];
    orange: Palette['primary'];
    green: Palette['primary'];
    blue: Palette['primary'];
    purple: Palette['primary'];
    white: Palette['primary'];
    yellow: Palette['primary'];
  }

  interface PaletteOptions {
    pillCard?: PaletteOptions['primary'];
    orange?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
  }

  // shared-ui/StatusChip
  interface ChipVariantsOptions {
    status: string;
  }
}

// shared-ui/StatusChip
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    status: true;
  }
}

export default createTheme({
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: `Mier B, -apple-system, BlinkMacSystemFont, avenir next, avenir,
      segoe ui, helvetica neue, helvetica, Ubuntu, roboto,
      noto, arial, sans-serif`,
    button: {
      textTransform: 'none'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  palette: {
    background: {
      default: '#FFFCF2'
    },
    primary: {
      main: '#300431',
      light: '#1E88E5',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#FF5D2B',
      light: '#FFCCCB'
    },
    pillCard: {
      main: '#F0EDE4',
      dark: '#E6E3DB'
    },
    orange: {
      main: '#FF5D2B'
    },
    green: {
      main: '#7FFFA9'
    },
    blue: {
      main: '#194EFF',
      light: '#6FB6C8',
      dark: '#32526B'
    },
    purple: {
      main: '#300431'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true
      },
      styleOverrides: {
        root: ({ ownerState, theme: t }) => ({
          borderRadius: 28,
          textTransform: 'uppercase',
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              borderColor: t.palette.grey[300],
              color: t.palette.common.black,
              '&:hover': {
                backgroundColor: t.palette.grey[50],
                borderColor: t.palette.grey[400]
              }
            })
        })
      }
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          textDecorationColor: t.palette.orange.main,
          color: t.palette.orange.main
        })
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 6
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.raised && {
            padding: '2rem',
            borderRadius: 16
          }),
          ...(ownerState.variant === 'outlined' && {
            borderRadius: 6
          })
        })
      }
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'status' },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          style: ({ theme: t, ownerState: { color } }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const lightColor = color === 'default' ? t.palette.grey[200] : alpha(t?.palette[color]?.light, 0.1);
            return {
              color: t.palette.primary.main,
              backgroundColor: lightColor
            };
          }
        }
      ]
    }
  }
});
