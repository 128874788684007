import type { EventContentArg } from '@fullcalendar/core';
import CircleIcon from '@mui/icons-material/Circle';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// TODO - Update to brand colours when design system theming is rolled out
import { LUXON_FORMAT_HOUR_MINUTE_AM_PM, ZONE_AUS_BRISBANE } from '@/utils/luxon';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/styles';
import { DateTime } from 'luxon';

export type CalendarEventCardProps = {
  eventArgs: EventContentArg;
  isSmallVariant?: boolean;
};

const StyledEventCard = styled(Stack)({
  padding: 5,
  marginLeft: 4,
  marginRight: 2,
  borderRadius: 5,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  backgroundColor: blue[50]
});

const StyledAlphaTypography = styled(Typography)({
  color: blue[900],
  fontSize: '0.8rem'
});

const StyledBetaTypography = styled(Typography)({
  color: blue[400],
  fontSize: '0.8rem'
});

const StyledCircleIcon = styled(CircleIcon)({
  color: blue[400],
  zoom: '0.6'
});

export const CalendarEventCard = (props: CalendarEventCardProps) => {
  const { eventArgs, isSmallVariant } = props;
  const event = eventArgs.event;
  const eventDetails = event.extendedProps.eventDetails;
  const formattedStartTime = DateTime.fromISO(event.extendedProps.start_time, { zone: ZONE_AUS_BRISBANE }).toFormat(
    LUXON_FORMAT_HOUR_MINUTE_AM_PM
  );

  const cardSmall = (
    <StyledEventCard width={'100%'}>
      <StyledBetaTypography ml={1} fontSize={'0.8rem'}>
        {formattedStartTime} - {eventDetails.consult_type} - {eventDetails.patient_name}
      </StyledBetaTypography>
    </StyledEventCard>
  );

  const cardMedium = (
    <StyledEventCard>
      <Box display={'flex'} alignItems={'center'}>
        <StyledCircleIcon fontSize={'small'} data-testid="circle-icon" />
        <StyledBetaTypography ml={1} fontSize={'0.8rem'}>
          {eventDetails.consult_type}
        </StyledBetaTypography>
      </Box>

      <StyledAlphaTypography>{eventDetails.patient_name}</StyledAlphaTypography>
    </StyledEventCard>
  );

  return isSmallVariant ? cardSmall : cardMedium;
};
