import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { useGetIhiReviewLog } from '@/hooks/patients/useGetIhiReviewLog';
import { StatusChip } from '@/shared-ui';
import type { PaginationModel } from '@montugroup/design-system';
import { PaginationVariant, SearchTextField, Table } from '@montugroup/design-system';
import type { IHIReviewLogDto } from '@montugroup/pms-api-contracts';
import { Box, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateTime } from 'luxon';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';

export enum IhiReviewLogsTableColumnHeaders {
  Id = 'ID',
  IhiNumber = 'IHI Number',
  IhiError = 'Error',
  IhiAssignedDate = 'Assigned Date',
  ResolvedReason = 'Resolved Reason',
  PatientId = 'Patient ID',
  Resolved = 'Resolution'
}

type IHIReviewLogTableProps = {
  pageSize?: number;
  filters?: {
    patientId?: number;
    isResolved?: boolean;
    resolverId?: number | null;
  };
};

const IHIReviewLogTable = ({ pageSize = 50 }: IHIReviewLogTableProps) => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [cursors, setCursors] = useState<{
    next: string | null;
    prev: string | null;
  } | null>(null);

  const [searchParams, setSearchParams] = useState<{
    patientId: string;
    resolverId: string;
    isResolved: boolean | null;
  }>({
    patientId: '',
    resolverId: '',
    isResolved: null
  });

  const { createColumnDefinition } = useTableUtils();

  const columns = [
    createColumnDefinition('patient', IhiReviewLogsTableColumnHeaders.PatientId),
    createColumnDefinition('ihi_error', IhiReviewLogsTableColumnHeaders.IhiError),
    createColumnDefinition('ihi_number', IhiReviewLogsTableColumnHeaders.IhiNumber),
    createColumnDefinition('ihi_assigned_date', IhiReviewLogsTableColumnHeaders.IhiAssignedDate),
    createColumnDefinition('resolution', IhiReviewLogsTableColumnHeaders.Resolved),
    createColumnDefinition('resolved_reason', IhiReviewLogsTableColumnHeaders.ResolvedReason)
  ];

  const pageParams = {
    size: pageSize,
    after: cursors?.next ?? undefined,
    before: cursors?.prev ?? undefined
  };

  const patientId = searchParams.patientId.trim();
  const resolverId = searchParams.resolverId.trim();

  const filterParams = {
    ...(searchParams.isResolved ? { isResolved: true } : {}),
    ...(patientId.length ? { patientId: Number(patientId) } : {}),
    ...(resolverId.length ? { resolverId: Number(resolverId) } : {})
  };

  /*
    Not needed in this phase but may be used in the future.
    Keeping this here to let others know it's an option
   */
  // const sortParams = {
  //   field: null,
  //   direction: null
  // };

  const { data, isLoading, isError } = useGetIhiReviewLog(pageParams, filterParams);

  const responseData = data?.body.data ?? [];
  const responseCursors = data?.body.meta?.page?.cursor;

  const formatCellAssignedDate = (item: IHIReviewLogDto) => {
    return item.ihi_assigned_date ? DateTime.fromISO(item.ihi_assigned_date).toFormat('MM/dd/yy - h:mma') : '-';
  };

  const formatCellResolution = (item: IHIReviewLogDto) =>
    item.resolution_by ? (
      <Stack flexDirection="row" alignItems="center">
        <Box mr={3}>
          <StatusChip label="Yes" color="success" showIcon={false} />
        </Box>
        <Typography variant="body2">
          {item.resolution_by.first_name} {item.resolution_by.last_name} ({item.resolution_by.id})
        </Typography>
      </Stack>
    ) : (
      <StatusChip label="No" color="error" showIcon={false} />
    );

  const formattedTableData = useMemo(
    () =>
      responseData.map((item) => ({
        patient: item.patient?.id,
        ihi_error: item.ihi_error,
        ihi_number: item.ihi_number,
        ihi_assigned_date: formatCellAssignedDate(item),
        resolution: formatCellResolution(item),
        resolved_reason: item.resolved_reason
      })),
    [responseData]
  );

  const handlePaginationChange = ({ page: newPageIndex }: PaginationModel) => {
    const isNextPage = newPageIndex > currentPageIndex;
    const cursorKey = isNextPage ? 'next' : 'prev';

    if (responseCursors?.[cursorKey]) {
      setCursors({ prev: null, next: responseCursors[cursorKey] });
    }
    setCurrentPageIndex(newPageIndex);
  };

  const handleSearchChange = (field: 'patientId' | 'resolverId', value: string) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => ({ ...prev, isResolved: event.target.checked }));
  };

  return (
    <Box>
      {!isError && (
        <>
          <Stack direction={'row'} alignItems={'center'}>
            <Box marginRight={5}>
              <SearchTextField
                handleChange={(e) => handleSearchChange('patientId', e.target.value)}
                handleClear={() => handleSearchChange('patientId', '')}
                labelText="Search by Patient ID"
                enableClear={true}
              />
            </Box>
            <Box marginRight={5}>
              <SearchTextField
                handleChange={(e) => handleSearchChange('resolverId', e.target.value)}
                handleClear={() => handleSearchChange('resolverId', '')}
                labelText="Search by Resolver ID"
                enableClear={true}
              />
            </Box>
            <FormControlLabel
              control={<Checkbox checked={searchParams.isResolved ?? false} onChange={handleCheckboxChange} />}
              label="Show only resolved"
            />
          </Stack>
          <Table
            data={formattedTableData}
            columns={columns}
            isLoading={isLoading}
            showPagination
            noDataFoundMessage={'No matching ihi review logs found'}
            onPaginationModelChange={handlePaginationChange}
            paginationVariant={PaginationVariant.UNKNOWN_PAGE_SIZE}
            hasPrevPage={Boolean(responseCursors?.prev)}
            hasNextPage={Boolean(responseCursors?.next)}
          />
        </>
      )}
      {isError && <Alert severity="error">There has been an error loading the Ihi Review Logs table</Alert>}
    </Box>
  );
};

export default IHIReviewLogTable;
