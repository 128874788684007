// @deprecated use react-hook-form-mui instead
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);
/**
 * @deprecated due to the non-scalable composition of the component
 */
const DisplayField = (props) => {
  const classes = useStyles();
  return (
    <Box
      component={Grid}
      width="100%"
      display={props.display ? props.display : 'flex'}
      m={isNaN(props.margin) ? 1 : props.margin}
      p={props.padding !== undefined ? props.padding : 0}
    >
      <Grid xs={props.customGrid ? props.customGrid[0] : 4} item={true}>
        <Box
          role="term"
          id={props.id}
          component="div"
          textAlign={props.labeltextAlign ? props.labeltextAlign : 'left'}
          p={props.labelPadding !== undefined ? props.labelPadding : '12px'}
          px={props.labelPadding !== undefined ? '' : '10px'}
          className={`${props.disabled && classes.disabledLabel} ${props.labelClasses ? props.labelClasses : ''}`}
          fontWeight={isNaN(props.labelFontWeight) ? 600 : props.labelFontWeight}
          fontSize={props.fontSize}
          lineHeight={props.lineHeight}
          mb={props.marginBottom}
        >
          {props.label}
        </Box>
      </Grid>
      <Grid xs={props.customGrid ? props.customGrid[1] : 8} item={true}>
        <Box
          aria-labelledby={props.id}
          role="definition"
          component="div"
          textAlign={props.fieldTextAlign ? props.fieldTextAlign : 'left'}
          p={props.fieldPadding !== undefined ? props.fieldPadding : '12px'}
          px={props.fieldPadding !== undefined ? '' : '10px'}
          className={`${props.disabled && classes.disabledLabel} ${props.labelClasses ? props.labelClasses : ''}`}
          fontSize={props.fontSize}
          fontWeight={isNaN(props.fieldFontWeight) ? 500 : props.fieldFontWeight}
          color="#0F455D"
        >
          {props.fieldText}
        </Box>
      </Grid>
    </Box>
  );
};

export default DisplayField;
