import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { prescriptionAssistantSlice } from './slices';
import type { PrescriptionAssistantSliceType } from './types';
import { createSelectors } from './utils';

// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern
const useAppStoreBase = create<PrescriptionAssistantSliceType>()(
  /**
   * Recommend using devtools middleware as last as possible.
   * For example, when you use it with immer as a middleware, it should be immer(devtools(...)) and not devtools(immer(...)).
   */
  immer(
    devtools(
      (...items) => ({
        ...prescriptionAssistantSlice(...items)
      }),
      // need to serialise Map or Set to display in devtools
      {
        serialize: {
          options: {
            map: true,
            set: true
          }
        }
      }
    )
  )
);

export const useAppStore = createSelectors(useAppStoreBase);
