import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';

type BasicModalProps = {
  children: React.ReactNode;
  className?: string;
  open: boolean;
  handleClose: () => void;
};

export default function BasicModal({ children, open, className, handleClose }: BasicModalProps) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box className={className}>{children}</Box>
      </Modal>
    </div>
  );
}
