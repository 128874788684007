import type { ChipProps } from '@mui/material';
import { Chip, styled } from '@mui/material';

export interface StyledChipProps {
  colour: string;
}

export type CustomColourChipProps = ChipProps & {
  colour: string;
};

const StyledChip = styled(Chip)<StyledChipProps>(({ colour }: { colour: string }) => ({
  color: colour,
  border: `1px solid ${colour}`,
  backgroundColor: colour
}));

const CustomColourChip = (props: CustomColourChipProps) => {
  return <StyledChip {...props} />;
};

export default CustomColourChip;
