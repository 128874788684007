import { List, styled } from '@mui/material';
import type { UseFieldArrayRemove } from 'react-hook-form';

import type {
  DateOverrideRange,
  TimeRange
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // WorkingHours
} from '@/components/calcom/shared/types/calcom-schedule-types';

import DateOverrideListItem from './DateOverrideListItem';

/**
 * Date Override List component
 *
 * @remarks
 * A list of all the date overrides with their dates and times
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DateOverrideList'
 * See - https://github.com/calcom/cal.com/blob/main/apps/web/pages/availability/[schedule].tsx
 *
 * @param trigger - A button or element to be used to trigger modal to be opened
 * @param remove - React-Hook-Form method to remove a field from the form array
 * @param replace - React-Hook-Form method to replace a field in the form array
 * @param workingHours - The standard working hours for that user. Used for default values
 * @param items - An array of list items which contain the date and times being overriden
 * @param excludedDates - Dates which the user is not working on
 *
 * @returns JSXElement
 *
 */

type DateOverrideListProps = {
  remove: UseFieldArrayRemove;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replace: any;
  items: DateOverrideRange[];
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // workingHours: WorkingHours[];
  excludedDates?: string[];
};

const StyledList = styled(List)({
  marginBottom: 300
});

const DateOverrideList = ({
  items,
  remove,
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // workingHours,
  replace,
  excludedDates = []
}: DateOverrideListProps) => {
  const unsortedFieldArrayMap = items.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (map: { [id: number]: any }, { id }: any, index) => ({ ...map, [id]: index }),
    {}
  );

  const sortByDate = (a: DateOverrideRange, b: DateOverrideRange) => {
    return a.ranges[0].start > b.ranges[0].start ? 1 : -1;
  };

  const handleReplaceFields = (ranges: TimeRange[], item: DateOverrideRange) => {
    // update has very weird side-effects with sorting.
    replace([...items.filter((currentItem) => currentItem.id !== item.id), { ranges }]);

    if (item.id) {
      delete unsortedFieldArrayMap[item.id as unknown as number];
    }
  };

  const handleRemoveFields = (item: DateOverrideRange) => {
    if (item.id) {
      remove(unsortedFieldArrayMap[item.id as unknown as number]);
    }
  };

  if (!items.length) {
    return null;
  }

  return (
    <StyledList data-testid="date-overrides-list">
      {items.sort(sortByDate).map((item) => (
        <DateOverrideListItem
          key={item.id}
          item={item}
          // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
          // workingHours={workingHours}
          excludedDates={excludedDates}
          handleReplaceFields={handleReplaceFields}
          handleRemoveFields={handleRemoveFields}
        />
      ))}
    </StyledList>
  );
};

export default DateOverrideList;
