import { Table } from '@montugroup/design-system';
import { DateTime } from 'luxon';

import type { MedicationData } from '@/components/PatientPageV2/components/AcknowledgementModal/AcknowledgementModal';
import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import type { ISODateString } from '@/types';

export enum AcknowledgementModalTableColumnHeaders {
  ScriptCreated = 'Script created',
  ProductName = 'Product name',
  Quantity = 'Quantity',
  Interval = 'Interval',
  Repeats = 'Repeats'
}

type AcknowledgementModalTableProps = {
  medicationData: MedicationData[];
};

/**
 * AcknowledgementModalTable component
 *
 * @remarks
 * Renders data table of medications prescribed to a patient
 *
 * @param medicationData - an array of medication
 *
 * @returns {JSX.Element} The rendered JSX element.
 */

const AcknowledgementModalTable = ({ medicationData }: AcknowledgementModalTableProps) => {
  const { createColumnDefinition } = useTableUtils();

  const resultsNotFoundMessage = 'Medication not found';

  const formattedMedicationData = medicationData.map((medication: MedicationData) => {
    return {
      script_created: DateTime.fromISO(medication[0] as ISODateString).toFormat('dd LLL yyyy'),
      product_name: medication[1],
      quantity: medication[2],
      interval: medication[3],
      repeats: medication[4]
    };
  });

  const columns = [
    createColumnDefinition('script_created', AcknowledgementModalTableColumnHeaders.ScriptCreated),
    createColumnDefinition('product_name', AcknowledgementModalTableColumnHeaders.ProductName),
    createColumnDefinition('quantity', AcknowledgementModalTableColumnHeaders.Quantity),
    createColumnDefinition('interval', AcknowledgementModalTableColumnHeaders.Interval),
    createColumnDefinition('repeats', AcknowledgementModalTableColumnHeaders.Repeats)
  ];

  return (
    <Table
      data={formattedMedicationData || []}
      columns={columns}
      pageSize={5}
      noDataFoundMessage={resultsNotFoundMessage}
    />
  );
};

export default AcknowledgementModalTable;
