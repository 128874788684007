import type { ISODateString } from './common';

// TODO - update with library or sync with BE types
export type NurseModel = any;

type UserState = {
  name: string;
};

export type Nurse = {
  id: number;
  nurse_code: string;
  calendly_nurse_uuid: string;
  user_id: number;
  calendly_slug: string;
  coviu_url: string;
  calcom_nurse_id: number | null;
  NurseUser: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    city: string;
    created_date: ISODateString;
    State: UserState;
  };
};

export enum NurseTableColumnHeaders {
  NurseCode = 'Nurse ID',
  Name = 'Name',
  Email = 'Email',
  JoinedDate = 'Joined Date',
  Phone = 'Phone',
  State = 'State',
  CoviuLink = 'Coviu Link',
  CalcomId = 'Calcom ID',
  CalcomSchedule = 'Calcom Schedule'
}
