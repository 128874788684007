import IhiReviewLogsTable from '@/components/tables/IhiReviewLogsTable';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const IhiReviewLogs = () => {
  return (
    <Box paddingX={15} paddingY={10}>
      <Typography variant={'h4'} marginBottom={5}>
        IHI Review Logs
      </Typography>
      <IhiReviewLogsTable />
    </Box>
  );
};
