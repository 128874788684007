import DoctorsRoundRobinInitial from '@/components/Doctors/DoctorRoundRobins/DoctorsRoundRobinInitial';
import DoctorsRoundRobinOverflow from '@/components/Doctors/DoctorRoundRobins/DoctorsRoundRobinOverflow';
import { Tab } from '@/shared-ui/Tabs/Tab';
import { TabPanel } from '@/shared-ui/Tabs/TabPanel';
import { Tabs } from '@/shared-ui/Tabs/Tabs';
import theme from '@/theme';
import TabContext from '@mui/lab/TabContext';
import { styled, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

const StyledAppBarContainer = styled(Box)({
  width: 600,

  '.MuiAppBar-root': {
    boxShadow: 'none'
  }
});

const RoundRobinDoctors = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box padding={5}>
      <TabContext value={'Doctor Round Robins'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h5'}>Doctor Round Robins</Typography>
          <StyledAppBarContainer>
            <AppBar position="static" color="transparent">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                variant={isSmallerScreen ? 'scrollable' : 'fullWidth'}
                scrollButtons="auto"
              >
                <Tab label="Initial Consultation" />
                <Tab label="Follow-up Overflow Consultation" />
              </Tabs>
            </AppBar>
          </StyledAppBarContainer>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <DoctorsRoundRobinInitial />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <DoctorsRoundRobinOverflow />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default RoundRobinDoctors;
