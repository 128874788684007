import mixpanel from 'mixpanel-browser';

import settings from '../data/constants';
import { getParamDataFromLocalStorage } from '../utils/calendar';

function updateUserProfile(userData) {
  const { email, firstName, fullName } = userData;
  mixpanel.identify(email || '');
  mixpanel.people.set({
    $name: firstName || fullName, // firstName will be empty after the switch to cal.com
    $email: email,
    $last_login: new Date()
  });
}

function createMixpanelTracker() {
  let initilsed = false;

  const initMixpanel = (userData) => {
    mixpanel.init(settings.mixpanelKey, {
      debug: true,
      api_host: settings.mixpanelApiUrl,
      trackAutomaticEvents: true
    });
    updateUserProfile(userData);
  };

  return (eventName, data) => {
    let userData = data;
    if (!userData) {
      // On payment page, calendar data will be only data available to user for Mixpanel
      // TODO : Improve consistant user tracking on PMS
      const localVal = getParamDataFromLocalStorage();
      userData = localVal || {};
    }

    if (!initilsed) {
      initMixpanel(userData);
      initilsed = true;
    }

    return mixpanel.track(eventName, {
      userData
    });
  };
}

const Mixpanel = createMixpanelTracker();

export default Mixpanel;
