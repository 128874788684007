import usePutCancelPrescribedProducts from '@/hooks/prescribedProducts/usePutCancelPrescribedProducts';
import { useAppStore } from '@/state-management';
import type { CancelMedicationItemType } from './../PrescriptionPad/';

import { AlertModal } from '@/shared-ui';
import { Logger } from '@/utils/logger';
import { useEffect } from 'react';
import { DEFAULT_CANCEL_ALERT_MODAL_PROPS } from './CancelPrescribedMedicationModal.config';
import { StyledList, StyledListItem } from './CancelPrescribedMedicationModal.styles';

type CancelPrescribedMedicationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  patientId: string;
};

const logger = new Logger('CancelPrescribedMedicationModal');

const CancelPrescribedMedicationModal = ({
  isOpen,
  onClose,
  onConfirm,
  patientId
}: CancelPrescribedMedicationModalProps) => {
  const { mutateAsync: cancelPrescribedProductsMutateAsync, isError, reset } = usePutCancelPrescribedProducts();
  const {
    prescriptionHistory: { itemsToCancel }
  } = useAppStore.use.prescriptionAssistant();
  const updatePrescriptionHistoryItemsToCancel = useAppStore.use.updatePrescriptionHistoryItemsToCancel();

  useEffect(() => {
    reset();
  }, [reset]);

  if (itemsToCancel.length < 1) {
    return null;
  }

  const handleConfirm = async () => {
    // We have check the items length it should not be 0 anymore
    // but we still need to do a casting here typescript seems still not able to infer
    const productIds = itemsToCancel.map((item: CancelMedicationItemType) => item.productId) as [number, ...number[]];

    try {
      await cancelPrescribedProductsMutateAsync({
        params: { patientId },
        body: { productIds }
      });

      updatePrescriptionHistoryItemsToCancel([]);
      onConfirm();
    } catch (error) {
      logger.error(
        `Failed to cancel prescribed products:\npatientId: ${patientId},\nproductIds: ${productIds}\nerror: `,
        error
      );
    }
  };

  /* Silent fail if any errors occur and let the Doctor proceed with Create Prescription */
  if (isError) {
    return null;
  }

  return (
    <AlertModal isOpen={isOpen} onClose={onClose} onConfirm={handleConfirm} {...DEFAULT_CANCEL_ALERT_MODAL_PROPS}>
      <StyledList>
        {itemsToCancel.map(({ productId, displayName }: CancelMedicationItemType) => (
          <StyledListItem key={productId}>{displayName}</StyledListItem>
        ))}
      </StyledList>
    </AlertModal>
  );
};

export default CancelPrescribedMedicationModal;
