import Box from '@mui/material/Box';

import { PrescriptionsPageHeader } from '@/components/Prescriptions/PrescriptionsPageHeader';
import { PrescriptionsTable } from '@/components/tables/PrescriptionsTable';

const Prescriptions = () => {
  return (
    <>
      <PrescriptionsPageHeader />
      <Box p={4} display="flex">
        <Box component="div" className="calendarShow" width="100%">
          <PrescriptionsTable />
        </Box>
      </Box>
    </>
  );
};

export default Prescriptions;
