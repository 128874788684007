import { Button } from '@montugroup/design-system';
import Close from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';

import DisplayField from '@/components/fields/DisplayField';
import { SectionBody } from '@/shared-ui/PageElements/SectionBody';
import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';
import type { ConsultationModel } from '@/types';

export type ConsultationDetailsProps = {
  consultation: ConsultationModel;
  onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ConsultationDetails = ({ consultation, onClose }: ConsultationDetailsProps) => {
  const getPractitionerName = () => {
    if (consultation?.Doctor?.DoctorUser) {
      return `${consultation.Doctor.DoctorUser?.first_name} ${consultation.Doctor.DoctorUser?.last_name}`;
    }
    if (consultation?.Nurse?.NurseUser) {
      return `${consultation.Nurse?.NurseUser?.first_name} ${consultation.Nurse?.NurseUser?.last_name}`;
    }

    // when there's nothing, return empty string
    return '';
  };

  return (
    <Box component="section">
      <SectionHeader
        headerText="Consultation Details"
        actionButton={
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        }
      />
      {/* Section Content */}
      <SectionBody>
        <Typography
          variant="h4"
          sx={{
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '150%',
            letterSpacing: '0.15px',
            paddingY: 3
          }}
        >
          {consultation.doctor_id ? 'Doctor Consultation' : 'Nurse Consultation'} ({consultation.consultation_code})
        </Typography>
        <DisplayField
          id="doctor-appointment-time"
          label="Practitioner"
          fieldText={getPractitionerName()}
          margin={0}
          labelPadding="0px"
          fieldPadding="0px"
          labelFontWeight={400}
          customGrid={[3, 9]}
        />
        <DisplayField
          id="doctor-appointment-time"
          label="Appointment Time"
          fieldText={`${moment(consultation.start_time).format('MMM Do YYYY, h:mm a')} - ${moment(
            consultation.end_time
          ).format('h:mm a')} (${consultation.ConsultationType.duration_mins} mins)`}
          margin={0}
          labelPadding="0px"
          fieldPadding="0px"
          labelFontWeight={400}
          customGrid={[3, 9]}
        />
        <DisplayField
          id="doctor-zoom-link"
          label="Zoom link"
          fieldText={
            <Button
              variant="text"
              onClick={() => {
                if (consultation?.zoom_url) {
                  window.open(consultation.zoom_url);
                }
              }}
            >
              {consultation.zoom_url || ''}
            </Button>
          }
          margin={0}
          labelPadding="0px"
          fieldPadding="0px"
          labelFontWeight={400}
          customGrid={[3, 9]}
        />
        <DisplayField
          id="doctor-appointment-status"
          label="Current Status"
          fieldText={consultation.ConsultationStatus?.name || ''}
          margin={0}
          labelPadding="0px"
          fieldPadding="0px"
          labelFontWeight={400}
          customGrid={[3, 9]}
        />
      </SectionBody>
    </Box>
  );
};
