import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { Alert, Button as MuiButton, Typography } from '@mui/material';

import type { RecommendationInfo } from '../PrescriptionItemEditor.types';

export type RecommendationInfoAlertProps = {
  info?: RecommendationInfo;
};

export const RecommendationInfoAlert = ({ info }: RecommendationInfoAlertProps) => {
  if (!info?.text || !info?.href) {
    return null;
  }
  return (
    <>
      <Alert variant="outlined" severity="info" sx={{ border: 0, p: 0 }}>
        {info.text}
      </Alert>
      <MuiButton
        color="warning"
        size="small"
        href={info.href}
        target="_blank"
        startIcon={<InsertLinkOutlinedIcon />}
        sx={{ minWidth: '10rem', width: 'fit-content' }}
      >
        <Typography textTransform="initial" sx={{ textDecoration: 'underline' }}>
          Cheat sheet
        </Typography>
      </MuiButton>
    </>
  );
};
