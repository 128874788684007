import settings from '@/data/constants';
import type { ProductImage } from '@/types/productImage.types';

const unavailableProductImage = `${settings.productImageUrl}Image_Unavailable.png`;

const getProductImageUrl = (
  useShopifyProductImages: boolean,
  imageUrl?: string | null,
  productImages?: ProductImage[]
): string => {
  const image =
    useShopifyProductImages && productImages?.length
      ? productImages[0].image_url
      : imageUrl && settings.productImageUrl + imageUrl;

  return image || unavailableProductImage;
};
export default getProductImageUrl;
