import settings from '../data/constants';

import useScript from './useScript';

export default function useTillPaymentScript() {
  return useScript(`${settings.tillSdkUrl}`, {
    id: 'tillPayment',
    attributes: [['data-main', 'payment-js']],
    removeOnUnmount: false
  });
}
