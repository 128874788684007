import type {
  MedicationPrescriptionStatus,
  PrescribedProductMetadataSchema,
  ReissueLinkAttributesSchema
} from '@montugroup/prescription-contracts';
import { CircularProgress, Stack } from '@mui/material';
import { kebabCase } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useFeatureFlags, useGetFilteredProducts } from '@/hooks';
import { useGetProductSurvey } from '@/hooks/rest/useGetProductSurvey';
import { useAppStore } from '@/state-management';

import { ConfirmPrescriptionCard } from '../ConfirmPrescriptionCard/ConfirmPrescriptionCard';
import type { PatientDetails, PrescriberDetails } from '../ConfirmPrescriptionCard/ConfirmPrescriptionCard.types';
import { PrescribedMedicationHistory } from '../PrescribedMedicationHistory/PrescribedMedicationHistory';
import type { MedicationType } from '../ProductDetailsCard/ProductDetailsCard.types';

import {
  FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL,
  FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION
} from '@/constants/featureFlags';
import { DeviceSuitabilityModal } from '../DeviceSuitabilityModal/DeviceSuitabilityModal';
import { PrescriptionPad } from './PrescriptionPad';
import type { CancelMedicationItemType, MedicationPadItemType } from './PrescriptionPad.types';
import { PrescriptionPadStep } from './PrescriptionPad.types';

export type PrescriptionPadWrapperProps = {
  doctorId: number;
  patientId: number;
  prescriberNumber: string;
  doctorPatientDetails: {
    doctor: PrescriberDetails;
    patient: PatientDetails;
  };
  showDeviceSuitabilityModal: boolean;
  deviceSuitabilityModalOnConfirm: () => void;
  deviceSuitabilityModalOnCancel: () => void;
};

export const PrescriptionPadWrapper = ({
  doctorId,
  patientId,
  prescriberNumber,
  doctorPatientDetails,
  showDeviceSuitabilityModal,
  deviceSuitabilityModalOnConfirm,
  deviceSuitabilityModalOnCancel
}: PrescriptionPadWrapperProps) => {
  const {
    prescriptionPad: {
      step: prescriptionPadStep,
      medicationPadItems,
      devicePadItems,
      actions: prescriptionPadReviewActions
    },
    prescriptionHistory: { itemsToReissue, itemsToCancel, itemsToAction }
  } = useAppStore.use.prescriptionAssistant();
  const updatePrescriptionPadStep = useAppStore.use.updatePrescriptionPadStep();
  const updateMedicationPadItems = useAppStore.use.updateMedicationPadItems();
  const updatePrescriptionHistoryItemsToReissue = useAppStore.use.updatePrescriptionHistoryItemsToReissue();
  const updatePrescriptionHistoryItemsToCancel = useAppStore.use.updatePrescriptionHistoryItemsToCancel();
  const updatePrescriptionHistoryItemsToAction = useAppStore.use.updatePrescriptionHistoryItemsToAction();
  const addReissuedMedicationToPrescriptionPad = useAppStore.use.addReissuedMedicationToPrescriptionPad();
  const updatePrescriptionPadReviewAction = useAppStore.use.updatePrescriptionPadReviewAction();
  const { data: productSurveyData } = useGetProductSurvey(doctorId);
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const isPatPrescribingSuitableDeviceModalEnabled: boolean = flags[FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL];
  const hasCompletedActions = !ffV4PrescriptionAssistantCancelPrescribedMedication ? true : itemsToAction.length === 0;

  const { isLoading: isFilteredProductsLoading, allProducts } = useGetFilteredProducts({
    doctorId,
    isConcession: doctorPatientDetails?.patient?.hasValidConcessionCard
  });

  // Get only the first question from the array
  const firstQuestion = productSurveyData?.survey?.[0]?.question || {
    value: 0,
    name: '',
    subText: '',
    answers: []
  };

  const handleReturnToScriptDetails = () => {
    updatePrescriptionPadStep(PrescriptionPadStep.Create);
  };

  const medicationPadItemDetails = medicationPadItems
    .filter((item) => !!item.medicationPadItem)
    .map((item) => item.medicationPadItem) as MedicationType[];

  const removeIdFromItemsToAction = (productId: number) => {
    if (!itemsToAction.length) {
      return;
    }

    updatePrescriptionHistoryItemsToAction(itemsToAction.filter((itemToActionId) => itemToActionId !== productId));
  };

  /**
   * Handles the event when the reissue link is clicked. Note that this function is
   * memoised by the DataGrid component, so state changes in the parent will not
   * be accessible.
   */
  const handleReissueProductClick = (
    attributes: ReissueLinkAttributesSchema,
    medication: PrescribedProductMetadataSchema,
    prescriptionStatus: MedicationPrescriptionStatus
  ) => {
    if (attributes.productId) {
      removeIdFromItemsToAction(attributes.productId);
    }

    // skip if the medication is already in the medication pad items
    if (medicationPadItems.find((item) => item.medicationPadItem?.productId === attributes.productId)) {
      return;
    }

    const mappedFilters: [string, string][] = attributes.filters.map((filter) => {
      const formatLabel = kebabCase(filter.label);
      return [`${formatLabel}`, `${filter.value}`];
    });
    const filters = new Map([...mappedFilters]);

    const reissuedMedication: MedicationPadItemType = {
      medicationPadItemId: uuidv4(),
      medicationPadItem: {
        productId: attributes.productId || 0,
        productName: medication.productName,
        description: medication.description,
        supplierName: medication.supplierName
      },
      filters: filters,
      reissueStatus: {
        isReissued: true,
        isOutOfStock: attributes.isOutOfStock
      },
      prescriptionStatus
    };

    // remove the reissued medication from the cancel list
    updatePrescriptionHistoryItemsToCancel(
      itemsToCancel.filter((item) => item.productId !== reissuedMedication?.medicationPadItem?.productId)
    );
    updatePrescriptionHistoryItemsToReissue([...itemsToReissue, reissuedMedication]);
    addReissuedMedicationToPrescriptionPad(allProducts, reissuedMedication);
  };

  const handleReissuedMedicationPadItems = (cancelItem: CancelMedicationItemType) => {
    updateMedicationPadItems(
      medicationPadItems.filter((item) => {
        if (!item.reissueStatus?.isReissued) {
          return true;
        }

        const medicationPadItemProductId = item.medicationPadItem?.productId;
        // check the OOS reissued alternate product id
        const medicationPadItemOOSAlternateProductId = item.reissueStatus?.productId;
        const cancelItemProductId = cancelItem.productId;

        return (
          medicationPadItemProductId !== cancelItemProductId &&
          medicationPadItemOOSAlternateProductId !== cancelItemProductId
        );
      })
    );
  };

  const handleCancelMedicationItemClick = (cancelItem: CancelMedicationItemType) => {
    // remove productId from items to action
    removeIdFromItemsToAction(cancelItem.productId);

    // skip if the medication is already in the cancel list
    if (itemsToCancel.find((item) => item.productId === cancelItem.productId)) {
      return;
    }
    // remove the cancel item from the reissue list
    updatePrescriptionHistoryItemsToReissue(
      itemsToReissue.filter((item) => item.medicationPadItem?.productId !== cancelItem.productId)
    );
    // remove the cancel item from the medication pad items (reissued)
    handleReissuedMedicationPadItems(cancelItem);
    updatePrescriptionHistoryItemsToCancel([...itemsToCancel, cancelItem]);
  };

  const renderLoading = () => (
    <Stack direction="row" alignItems="center" justifyContent="center" height={300}>
      <CircularProgress color="inherit" />
    </Stack>
  );

  const renderPrescriptionPad = () => {
    if (!hasCompletedActions) {
      return null;
    }

    return (
      <PrescriptionPad
        doctorId={doctorId}
        isConcessionCardHolder={doctorPatientDetails?.patient?.hasValidConcessionCard}
        productFeedbackProps={{
          label: 'View other products',
          subText: firstQuestion?.subText,
          questionId: firstQuestion?.value,
          title: firstQuestion?.name,
          answers: firstQuestion?.answers
        }}
      />
    );
  };

  return isFilteredProductsLoading ? (
    renderLoading()
  ) : (
    <>
      {prescriptionPadStep === PrescriptionPadStep.Create && (
        <>
          <PrescribedMedicationHistory
            patientId={patientId}
            onClickReissue={handleReissueProductClick}
            onClickCancel={handleCancelMedicationItemClick}
          />
          {renderPrescriptionPad()}
          {isPatPrescribingSuitableDeviceModalEnabled && (
            <DeviceSuitabilityModal
              isOpen={showDeviceSuitabilityModal}
              onClose={deviceSuitabilityModalOnCancel}
              onConfirm={deviceSuitabilityModalOnConfirm}
            />
          )}
        </>
      )}
      {prescriptionPadStep === PrescriptionPadStep.Confirm && (
        <ConfirmPrescriptionCard
          prescriberNumber={prescriberNumber}
          prescriberDetails={doctorPatientDetails?.doctor}
          patientDetails={doctorPatientDetails?.patient}
          createdPrescriptionDetails={medicationPadItemDetails}
          onReturn={handleReturnToScriptDetails}
          createdDevices={devicePadItems}
          reviewActions={prescriptionPadReviewActions}
          onActionClick={updatePrescriptionPadReviewAction}
        />
      )}
    </>
  );
};
