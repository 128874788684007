import type { RadioGroupProps } from '@mui/material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from '@mui/material';

export interface RadioOption {
  label: string;
  value: string | number;
}

export interface FeedbackRadioGroupProps extends Partial<RadioGroupProps> {
  options?: RadioOption[];
  title?: string;
  subText?: string;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '0.5rem',
  margin: '0 0 0.5rem'
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.25rem'
}));

const StyledFormSubText = styled(FormLabel)(() => ({
  marginBottom: '1rem'
}));

/**
 * This component is suitable for question and answer for feedback or survey
 *
 * @param title - heading text normally in a form of question
 * @param value - the value from the options
 * @param options - defines the number of radio options to select
 * @param onChange - callback fired when an option is selected
 *
 * @returns JSXElement
 *
 */

export default function FeedbackRadioGroup({
  className,
  subText,
  onChange,
  options,
  title,
  value
}: FeedbackRadioGroupProps) {
  return (
    <FormControl fullWidth className={className}>
      {title && <StyledFormLabel>{title}</StyledFormLabel>}
      {subText && <StyledFormSubText>{subText}</StyledFormSubText>}
      <RadioGroup value={value} onChange={onChange} aria-label={title || 'radio group'}>
        {options &&
          options.map((radio) => (
            <StyledFormControlLabel
              key={radio.value}
              value={radio.value}
              label={radio.label}
              control={
                <Radio
                  inputProps={{
                    'aria-label': `${radio.label}`
                  }}
                />
              }
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
}
