import { getNurseCalendarConsultations } from '@/api/consultations/getNurseCalendarConsultations';
import { CalendarEventCard } from '@/components/calendar/CalendarEventCard';
import TimelineCalendar from '@/components/calendar/TimelineCalendar';
import type { SelectOption } from '@/components/calendar/TimelineCalendar.types';
import {
  formatNurseConsultationsAsCalendarEvents,
  formatNurseList
} from '@/components/calendar/TimelineScheduler/TimelineScheduler.utils';
import { TimelineSchedulerDrawer } from '@/components/calendar/TimelineScheduler/TimelineSchedulerDrawer';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import type { Nurse, NurseConsultation } from '@/types';
import type { EventClickArg, EventContentArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import { AutocompleteMultiSelect } from '@montugroup/design-system';
import { Snackbar, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';

export type TimelineSchedulerNursesProps = {
  nurses: Nurse[];
};

const StyledTimelineCalendarContainer = styled(Box)({
  '.fc': {
    height: '70vh'
  }
});

export const TimelineSchedulerNurses = (props: TimelineSchedulerNursesProps) => {
  const { nurses } = props;
  const [selectedNurses, setSelectedNurses] = useState<SelectOption[]>([]);
  const [snackbarProps, setSnackbarProps] = useState<Pick<ExtendedSnackbarProps, 'severity' | 'message' | 'open'>>({
    open: false,
    message: ''
  });
  const [selectedEvent, setSelectedEvent] = useState<NurseConsultation | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const selectListNurses = formatNurseList(nurses ?? []);
  const formatEventContentCard = (eventArgs: EventContentArg) => <CalendarEventCard eventArgs={eventArgs} />;

  // This function will run everytime something changes in Timeline Calendar (date etc.)
  const handleCalendarEvents = useCallback(
    async (fetchInfo: EventSourceFuncArg, successCallback: (eventInputs: EventInput[]) => void) => {
      const selectedNurseIds = selectedNurses.map((nurse) => nurse.id);

      if (!selectedNurses.length) {
        // FullCalendar requires successCallback to return at least an empty array else it will error
        successCallback([]);
      }

      const timelineDateStartToUTC = DateTime.fromISO(fetchInfo.startStr).toUTC().toString();

      const response = await getNurseCalendarConsultations({
        selectedNurseIds,
        dateIsoString: timelineDateStartToUTC
      });

      if (response.statusText !== 'OK') {
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: 'An error has occurred getting the nurse consultations'
        });
        successCallback([]);
      }

      const nurseConsultations: NurseConsultation[] = response.data;
      const calendarEvents = formatNurseConsultationsAsCalendarEvents(nurseConsultations ?? []);

      successCallback(calendarEvents ?? []);
    },
    [selectedNurses]
  );

  const handleEventClicked = (eventClickInfo: EventClickArg) => {
    setSelectedEvent(eventClickInfo.event.toPlainObject().extendedProps.eventDetails);
    setIsDrawerOpen(true);
  };

  const handleNurseSelected = (selectedNurses: SelectOption[]): void => {
    setSelectedNurses(selectedNurses);
  };

  const handleToggleDrawer = (open: boolean) => (_event: React.KeyboardEvent | React.MouseEvent) => {
    setIsDrawerOpen(open);
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({ open: false, message: '' });
  };

  return (
    <>
      <Box paddingY={5}>
        <AutocompleteMultiSelect
          options={selectListNurses}
          itemSelected={handleNurseSelected}
          labelText={'Select nurses'}
          placeholderText={'Nurse name'}
        />
      </Box>
      <StyledTimelineCalendarContainer>
        <TimelineCalendar
          resources={selectedNurses}
          eventCallback={handleCalendarEvents}
          resourceHeader="Nurses"
          handleEventClicked={handleEventClicked}
          eventContent={formatEventContentCard as unknown as ReactElement}
        />
      </StyledTimelineCalendarContainer>
      <TimelineSchedulerDrawer selectedEvent={selectedEvent} isOpen={isDrawerOpen} toggleDrawer={handleToggleDrawer} />
      <Snackbar
        {...snackbarProps}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
