import type { AlertModalProps } from '@/shared-ui';
import { AlertSeverity } from '@/shared-ui';

export const DEFAULT_NOTICE_OF_TREATMENT_MODAL_PROPS: Omit<AlertModalProps, 'onClose' | 'onConfirm' | 'isOpen'> = {
  title: 'WA Notification of Treatment',
  body: 'Please review the form below, and if you agree, sign and confirm so it can be sent to the Department of Health.',
  cancelText: 'Cancel',
  confirmText: 'Sign and Submit',
  severity: AlertSeverity.Warning
};
