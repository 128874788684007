import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { NursesTable } from '@/components/tables/NursesTable';
import { useAccessControl } from '@/hooks';
import { UserRole } from '@/types';

const StyledButton = styled(Button)({
  '&.MuiButton-root': {
    marginRight: 30
  }
});

const Nurses = () => {
  const { allowAccess } = useAccessControl();
  const navigate = useNavigate();

  const headerButtons = (
    <Box display={'flex'} justifyContent={'end'}>
      <StyledButton variant="contained" color="secondary" onClick={() => navigate('/roundrobin-nurses')}>
        Manage Nurse Round Robin
      </StyledButton>

      <Button variant="contained" color="secondary" onClick={() => navigate('/onboard-new-nurse')}>
        Onboard New Nurse
      </Button>
    </Box>
  );

  return (
    <Box component="div" p={4}>
      <Box ml={1} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h5" color="textPrimary">
          Nurses
        </Typography>
        {allowAccess([UserRole.SuperAdmin, UserRole.Manager], headerButtons)}
      </Box>
      <Box component="div">
        <NursesTable />
      </Box>
    </Box>
  );
};

export default Nurses;
