import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { GetPrescriptionsQuery } from '@/api/prescriptions/getPrescriptions';
import { getPrescriptions } from '@/api/prescriptions/getPrescriptions';
import { QueryKey } from '@/data/query';

export const useGetPrescriptions = (params?: GetPrescriptionsQuery) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [QueryKey.GetPrescriptions, { ...params }],
    queryFn: () => getPrescriptions(params),
    placeholderData: keepPreviousData
  });

  return {
    data,
    isError,
    isLoading
  };
};
