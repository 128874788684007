import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useContext } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import type { z } from 'zod';
import { EditModeContext, withEditModeContextProvider } from '../../EditModeContextProvider';
import EditableSections from '../../EditableSections';
import { schema } from './IhiInformation.schema';

type FormFields = z.infer<typeof schema>;

type IhiInformationProps = {
  editable?: boolean;
  defaultValues?: FormFields;
  onSubmit: (data: FormFields) => void;
};

function IhiInformation({ editable = true, defaultValues, onSubmit }: IhiInformationProps) {
  const { isEditMode } = useContext(EditModeContext);
  const formContext = useForm<FormFields>({
    values: defaultValues,
    resolver: zodResolver(schema)
  });

  const onSubmitIhiNumber = (formData: FieldValues) => {
    const payload = {
      ihiNumber: formData.ihiNumber
    };
    if (onSubmit) {
      onSubmit(payload);
    }
  };

  return (
    <EditableSections
      title="IHI Information"
      editable={editable}
      formContext={formContext}
      actions={{ onSubmit: onSubmitIhiNumber }}
    >
      <FormContainer formContext={formContext}>
        <Grid container columnSpacing={10} rowSpacing={5} marginY={2} data-testid="ihi-information-form">
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="ihiNumber" label="IHI Number" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="recordStatus" label="Record Status" placeholder="-" fullWidth disabled={true} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="numberStatus" label="Number Status" placeholder="-" fullWidth disabled={true} />
          </Grid>
        </Grid>
      </FormContainer>
    </EditableSections>
  );
}

export default withEditModeContextProvider(IhiInformation);
