import { patientIhiClient } from '@/api/patients/patientIhiClient';
import type {
  GetPatientIhiBodyDto,
  GetPatientIhiErrorResponseDto,
  GetPatientIhiSuccessResponseDto
} from '@montugroup/pms-api-contracts';

enum PatientSexType {
  MALE = 'Male',
  FEMALE = 'Female',
  TRANSGENDER = 'Transgender',
  INTERSEX = 'Intersex',
  NON_BINARY = 'Non-binary',
  NOT_LISTED = 'I prefer not to say/Not listed'
}

enum IHISexType {
  MALE = 'M',
  FEMALE = 'F',
  INTERSEX = 'I',
  NOT_LISTED = 'N'
}

const sexTypeMapping: { [key in IHISexType]?: PatientSexType } = {
  [IHISexType.MALE]: PatientSexType.MALE,
  [IHISexType.FEMALE]: PatientSexType.FEMALE,
  [IHISexType.INTERSEX]: PatientSexType.INTERSEX,
  [IHISexType.NOT_LISTED]: PatientSexType.NOT_LISTED
};

export const useVerifyPatientIhi = () => {
  const { mutateAsync, ...rest } = patientIhiClient().verifyPatientIhi.useMutation();

  const verifyPatientIhi = async (payload: GetPatientIhiBodyDto): Promise<GetPatientIhiSuccessResponseDto | string> => {
    try {
      const { body } = await mutateAsync({
        body: payload
      });

      const mappedSex = sexTypeMapping[body.response.patient?.sex as IHISexType];
      return {
        ...body,
        response: {
          ...body.response,
          ...(body.response.patient && {
            patient: {
              ...body.response.patient,
              sex: mappedSex
            }
          })
        }
      } as GetPatientIhiSuccessResponseDto;
    } catch (error) {
      const defaultErrorMessage = 'An unknown error occurred while verifying the Patient IHI details';
      if (typeof error === 'object' && error !== null && 'status' in error && 'body' in error) {
        if ((error.status as number) < 500) {
          return (error.body as GetPatientIhiErrorResponseDto)?.error?.message ?? defaultErrorMessage;
        }
      }
      throw new Error(defaultErrorMessage);
    }
  };

  return { verifyPatientIhi, ...rest };
};
