import { AlertModal } from '@/shared-ui';
import { DEFAULT_NOTICE_OF_TREATMENT_MODAL_PROPS } from './NoticeOfTreatmentModal.config';

export type NoticeOfTreatmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
};

/** @deprecated please use ConfirmDialog component under the design-system */
const NoticeOfTreatmentModal = ({ isOpen, onClose, onConfirm, children }: NoticeOfTreatmentModalProps) => (
  <AlertModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} {...DEFAULT_NOTICE_OF_TREATMENT_MODAL_PROPS}>
    {children}
  </AlertModal>
);

export default NoticeOfTreatmentModal;
