import AccountCircle from '@mui/icons-material/AccountCircle';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
// TODO: update to actual icon from design system
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import type { CSSObject, DrawerProps } from '@mui/material';
import { Drawer, IconButton, List, ListItem, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import { getUser, logout } from '../../data/service/authService';
import { useFeatureFlags } from '../../hooks';

import { DropDownMenu } from './DropDownMenu';
import type { MenuItemWithChildren } from './MenuItems';
import { DEFAULT_MENUS } from './MenuItems';
import { MenuNavButton } from './MenuNavButton';
import { NewFeatureDialog } from './NewFeatureDialog/NewFeatureDialog';
import { useShowCollapsibleMenuAlert } from './NewFeatureDialog/useShowCollapsibleMenuAlert';

const PREFIX = 'ButtonAppBar';

const DRAWER_WIDTH_OPEN = '300px';

export const DRAWER_WIDTH_CLOSE = '72px';

export const classes = {
  root: `${PREFIX}-root`,
  menuToggle: `${PREFIX}-menuToggle`,
  menuLink: `${PREFIX}-menuLink`,
  activeMenuLink: `active`, // default class name set by react-router-dom when the link is active
  title: `${PREFIX}-title`,
  listItem: `${PREFIX}-listItem`,
  link: `${PREFIX}-link`,
  logoImg: `${PREFIX}-logoImg`,
  profile: `${PREFIX}-profile`
};

const hideScrollBar: CSSObject = {
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none'
};
/**
 * Shared transitions for the drawer animation
 */
const miniVariantDrawerTransitions = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

/**
 * Used to add the "mini variant" effect for the Drawer and the Paper component contained within
 */
const toggleDrawerMixin = (theme: Theme, open?: boolean): CSSObject => ({
  width: open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE,
  ...miniVariantDrawerTransitions(theme)
});

const StyledDrawer = styled(Drawer)<DrawerProps>(({ theme, open }) => ({
  flexShrink: 0,
  ...toggleDrawerMixin(theme, open),
  '& .MuiDrawer-paper': {
    border: 'none',
    backgroundColor: '#ceedff',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    ...toggleDrawerMixin(theme, open),
    ...hideScrollBar
  },
  '& *.menuButtonText': {
    display: open ? {} : 'none'
  },
  [`& .${classes.menuToggle}`]: {
    padding: theme.spacing(6, 4),
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      transform: open ? {} : 'scaleX(-1)'
    }
  },
  [`& .${classes.listItem}`]: {
    padding: open ? theme.spacing(0, 1) : 0,
    ...(!open && {
      '& > .dropDropMenuDiv': {
        margin: '10px 8px',
        button: {
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      '& > a': {
        margin: '10px 8px',
        '& > button': {
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    })
  },
  [`& .${classes.logoImg}`]: {
    height: '40px',
    overflowX: 'hidden',
    padding: open ? theme.spacing(0, 7) : theme.spacing(0, 0, 0, 4),
    boxSizing: 'content-box',
    margin: theme.spacing(5, 0),
    ...(open ? {} : { width: '40px' }),
    ['& a']: {
      display: 'flex',
      height: '23px',
      overflowX: 'hidden'
    },
    ['& img']: {
      height: '100%'
    }
  },
  [`& .${classes.profile}`]: {
    justifyContent: 'center',
    textWrap: 'nowrap',
    '& > svg': {
      width: '100%'
    },
    ...(!open && {
      '& > button': {
        overflowX: 'hidden',
        padding: theme.spacing('6px', 4),
        justifyContent: 'flex-start',
        '& > svg': {
          marginRight: theme.spacing(2),
          padding: theme.spacing(0, 4.5),
          boxSizing: 'content-box'
        }
      }
    })
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  zIndex: '10',
  alignSelf: 'self-start',
  marginTop: '10px',
  marginLeft: '10px',
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main
  },
  '&:after': {
    content: "''",
    zIndex: '-1',
    position: 'absolute',
    backgroundColor: '#CEEDFF',
    height: '100px',
    width: '100px',
    borderRadius: '999px',
    top: '-20px',
    left: '-20px'
  }
}));

const GenerateRoleIdsFromChildItems = (menuItem: MenuItemWithChildren) => {
  let roleIds: number[] = menuItem.role_id ?? [];

  // Not really worth it to de-dedupe role ids
  if (menuItem.children) {
    menuItem.children.forEach((child) => {
      roleIds = roleIds.concat(GenerateRoleIdsFromChildItems(child));
    });
  }

  return roleIds;
};

const ButtonAppBar = () => {
  const user = getUser();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isNotDesktop);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (isNotDesktop) {
      setDrawerOpen(false);
    }
  }, [isNotDesktop]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { flags } = useFeatureFlags();

  const menus = useMemo(() => {
    return DEFAULT_MENUS;
  }, []);

  const { openFeatureAlert, setOpenFeatureAlert, handleDismissAlert } = useShowCollapsibleMenuAlert();
  const handleCloseFeatureAlert = () => {
    setOpenFeatureAlert(false);
    handleDismissAlert();
  };

  const RenderMenuItemButton = (
    menuItem: MenuItemWithChildren,
    navLinks: React.RefObject<HTMLAnchorElement>[] = [],
    isChild: boolean
  ): React.JSX.Element | null => {
    const roleIds = GenerateRoleIdsFromChildItems(menuItem);

    const featureFlagEnabled = menuItem.featureFlag !== undefined ? flags[menuItem.featureFlag] : true;
    const hasPermission = roleIds && roleIds.length > 0 ? roleIds.indexOf(user.role_id) > -1 : true;
    const accessCheckResult = menuItem.accessCheckMethod !== undefined ? menuItem.accessCheckMethod(user, flags) : true;

    if (!hasPermission || !featureFlagEnabled || !accessCheckResult) {
      return null;
    }

    if (menuItem.children && menuItem.children.length === 0) {
      return null;
    }

    const navLinkRef = React.createRef<HTMLAnchorElement>();
    if (menuItem.children === undefined) {
      navLinks.push(navLinkRef);
    }

    const isInDropDown = navLinks.length > 0 && isChild;

    return (
      <ListItem key={menuItem.id} className={classes.listItem}>
        <>
          {menuItem.children === undefined && (
            <MenuNavButton
              key={menuItem.id}
              url={menuItem.link ?? '#'}
              end={!menuItem.activeLinkStateIncludesNested}
              icon={menuItem.icon}
              name={menuItem.name}
              isSideBarOpen={drawerOpen}
              enableToolTip={!isInDropDown}
              navRef={navLinkRef}
            />
          )}

          {menuItem.children !== undefined && (
            <DropDownMenu name={menuItem.name} icon={menuItem.icon} navLinks={navLinks} sideBarOpen={drawerOpen}>
              {menuItem.children.map((child) => {
                return RenderMenuItemButton(child, navLinks, true);
              })}
            </DropDownMenu>
          )}
        </>
      </ListItem>
    );
  };

  return (
    <StyledDrawer className={classes.root} variant="permanent" open={drawerOpen}>
      <Box justifyContent="space-between" display="flex" flexDirection="column" height="100vh" alignContent="center">
        <div>
          <Box className={classes.menuToggle}>
            <IconButton aria-label="menu toggle" disableRipple onClick={() => setDrawerOpen((prevState) => !prevState)}>
              <MenuOpenIcon />
            </IconButton>
            {openFeatureAlert && (
              <NewFeatureDialog
                heading="New feature"
                description="You can now collapse the navigation menu."
                isOpen={true}
                onClose={() => handleCloseFeatureAlert()}
              >
                <Box className={classes.menuToggle}>
                  <StyledIconButton aria-label="menu toggle" disableRipple>
                    <MenuOpenIcon />
                  </StyledIconButton>
                </Box>
              </NewFeatureDialog>
            )}
          </Box>
          <Box className={classes.logoImg} my={2} textAlign="center">
            <Link to="/" className={classes.link}>
              <img src="/images/Alternaleaf_purple.svg" alt="Alternaleaf | PMS" />
            </Link>
          </Box>
          {user ? (
            <List>
              {menus.map((itm) => {
                return RenderMenuItemButton(itm, [], false);
              })}
            </List>
          ) : (
            ''
          )}
        </div>
        <Box width="100%">
          {user && (
            <Box mb={4} display="flex" className={classes.profile}>
              <Button
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
              >
                <AccountCircle />
                <Box ml={1}>
                  {user.first_name} {user.last_name}
                </Box>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
              >
                <MenuItem
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          )}
          <Box fontSize={10} textAlign="center" mb={2} sx={{ minHeight: '56px' }}>
            <Box>© {new Date().getFullYear()} by Alternaleaf.</Box>
            <Box>All rights reserved.</Box>
          </Box>
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default ButtonAppBar;
