import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import { getNurseCalendarConsultations } from '@/api/consultations/getNurseCalendarConsultations';
import { LOCAL_TIMEZONE } from '@/components/NurseFlexCalendar/hooks/useNurseFlexCalendar';
import type { FlexCalendarConsultationMapping } from '@/components/NurseFlexCalendar/NurseFlexCalendar.types';
import { QueryKey } from '@/data/query';
import type { NurseConsultation } from '@/types';

const getAllNurseCalendarConsultations = async (nurseIds: string[]) => {
  const response = await getNurseCalendarConsultations({
    selectedNurseIds: nurseIds,
    dateIsoString: dayjs().startOf('day').utc(true).local().tz(LOCAL_TIMEZONE).toISOString()
  });

  return response;
};

export const useFormatConsultsAsCalendarEvents = (
  nurseIds: string[],
  flexNurseConsultMap: FlexCalendarConsultationMapping[] | undefined
) => {
  const formatNurseConsultationsAsEvents = useCallback(
    (consultations: NurseConsultation[]) => {
      return consultations?.map((consultation) => {
        // Use the consult map to find out if that consult has been reassigned to a new nurse
        const reassignedNurse = flexNurseConsultMap?.find(
          (savedConsultation) => savedConsultation.consultation_id === consultation.id
        );

        const formattedConsultAsEvent = {
          id: consultation.id,
          // If it has been reassigned to a new nurse, then update the resourceId of the consult
          resourceId: reassignedNurse?.nurse_id || consultation.nurse_id,
          title: consultation.patient_name,
          start: consultation.start_time,
          end: consultation.end_time,
          constraint: {
            start: consultation.start_time,
            end: consultation.end_time
          },
          extendedProps: { ...consultation }
        };

        return formattedConsultAsEvent;
      });
    },
    [flexNurseConsultMap]
  );

  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: [QueryKey.NurseConsultations, nurseIds.toString()],
    queryFn: async () => {
      const { data: allConsults } = await getAllNurseCalendarConsultations(nurseIds);

      const allConsultsFormattedAsEvents = formatNurseConsultationsAsEvents(allConsults);

      return allConsultsFormattedAsEvents;
    }
  });

  return {
    data,
    isError,
    isLoading,
    refetch
  };
};
