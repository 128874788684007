import type { TypographyProps } from '@mui/material';
import { styled, Typography } from '@mui/material';
import { forwardRef } from 'react';

const StyledPageTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 400;
`;

export type PageTitleProps = TypographyProps;

export const PageTitle = forwardRef<HTMLHeadingElement, TypographyProps>(function PageTitle(props, ref) {
  return <StyledPageTitle variant="h1" {...props} ref={ref} />;
});
