import { Box } from '@mui/material';
import type { RefObject } from 'react';
import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const useScrollRef = (): RefObject<HTMLDivElement> => {
  const context = useContext(ScrollContext);

  if (context === null) {
    throw new Error('useScrollRef must be used within a ScrollProvider');
  }

  return context;
};

export const ScrollProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ScrollContext.Provider value={scrollRef}>
      <Box ref={scrollRef} height="100vh" sx={{ overflowX: 'hidden' }} flexGrow={1}>
        {children}
      </Box>
    </ScrollContext.Provider>
  );
};
