import { requestHeaders } from '@/api';
import { QueryKey } from '@/data/query';
import { API_URL } from '@/data/service/dataService';
import { useMutation } from '@tanstack/react-query';

const resendConsentForm = async (url: string, patientId?: string) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: 'POST',
      headers: requestHeaders(),
      body: JSON.stringify({ patientId })
    });

    if (!response.ok) {
      throw new Error('Failed to send Consent Form');
    }

    const result = await response.text();

    if (result !== 'Successfully sent patient consent form') {
      throw new Error('Unexpected response from server');
    }

    return result;
  } catch (_err) {
    throw new Error('Error sending consent form');
  }
};

export const usePostResendPatientConsent = (patientId?: string) => {
  const { mutate, isError, isSuccess } = useMutation({
    mutationKey: [QueryKey.ResendConsentForm],
    mutationFn: () => resendConsentForm('patient/consent-form/resend', patientId)
  });

  return {
    resendConsentForm: mutate,
    isError: isError,
    isSuccess: isSuccess
  };
};
