import { Box, useTheme } from '@mui/material';
import type { PropsWithChildren, RefObject } from 'react';
import { createContext, useContext, useRef } from 'react';

const StickyRefContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const useStickyRef = () => {
  const context = useContext(StickyRefContext);

  if (context === null) {
    throw new Error('useStickyRef must be used within a SupportHeaderStickyProvider');
  }

  return context;
};

const SupportHeaderStickyProvider = ({ children }: PropsWithChildren) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  return (
    <StickyRefContext.Provider value={stickyRef}>
      <Box
        ref={stickyRef}
        sx={{
          position: 'sticky',
          top: 0,
          pt: 4,
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.common.white
        }}
      >
        {children}
      </Box>
    </StickyRefContext.Provider>
  );
};

export default SupportHeaderStickyProvider;
