import type { RankTier } from '@montugroup/pms-api-contracts';

export type FilteredProductsPayloadType = {
  doctorId: number;
  isConcession?: boolean;
};

export interface FilteredProductByFormulation {
  [key: string]: FilteredProduct[] | FilteredProductByType;
}

export interface FilteredProductByType {
  [key: string]: FilteredProduct[] | FilteredProductByStrength;
}

export enum InhalationTypeValue {
  Day = 'Day',
  Night = 'Night',
  Balanced = 'Balanced'
}

export enum MedicationStrengthValue {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  ExtraHigh = 'Extra High',
  HighThc = 'High THC',
  LowThc = 'Low THC'
}

export interface FilteredProductByStrength {
  [key: string | MedicationStrengthValue | InhalationTypeValue]: FilteredProduct[];
}

export type FilterOption = {
  label: string;
  value: string;
  imageUrl?: string;
  filter?: ProductFilter;
};

export type ProductFilter = {
  id: string;
  label: string;
  options: FilterOption[];
};

export enum Attribute {
  Quantity = 'quantity',
  Repeats = 'repeats',
  Interval = 'interval',
  Dosage = 'dosage'
}

export type RepeatsType = {
  defaultRepeats: number;
  enforceRepeats: boolean;
};

export type ProductAttributeRange = {
  default: number;
  minimum: number | null;
  maximum: number | null;
};

export type Attributes = {
  interval: ProductAttributeRange;
  dosage: string;
  quantity: ProductAttributeRange;
  repeats: ProductAttributeRange;
};

export type PricingSchema = {
  normal?: number;
  discountPercentage?: string;
  discounted?: number;
};

export type FilteredProduct = {
  id: number;
  productName: string;
  supplierName: string;
  description: string;
  prices?: PricingSchema;
  // this will be full image URL
  productImage: string;
  decoration: ProductCardDecoration;
  isConcessionProduct: boolean;
  attributes: Attributes | null;
};

export interface ProductFilteredResponse {
  data: {
    productFilters: ProductFilter;
    products: FilteredProductByFormulation;
    errors?: Error[];
  };
}

export interface ProductByDeviceResponse {
  data: {
    products: FilteredProduct[];
    errors?: Error[];
  };
}

/**
 * Used in displaying a recommendation decoration with
 * rankings.
 */
export interface RankDecoration {
  tier: RankTier;
  label: string;
}

/**
 * Decorates a card with a out of stock decoration
 */
export interface StockDecoration {
  isOutOfStock: boolean;
  message: string;
}

/**
 * Decorates a card with an overriding label and message.
 * i.e. Low Stock
 */
export interface OverrideDecoration {
  overrideLabel: string;
  message: string;
}

export type ProductCardDecoration = RankDecoration | StockDecoration | OverrideDecoration | null;

/**
 * Type guard to determine whether this is a `RankDecoration` with tier and label
 */
export function isRankDecoration(decoration: ProductCardDecoration): decoration is RankDecoration {
  return (decoration as RankDecoration)?.tier !== undefined && typeof (decoration as RankDecoration).label === 'string';
}

/**
 * Type guard to determine whether this is a `StockDecoration` with the `isOutOfStock` prop.
 * Note that this specifically checks for `undefined` not falsy values
 */
export function isStockDecoration(decoration: ProductCardDecoration): decoration is StockDecoration {
  return (
    (decoration as StockDecoration)?.isOutOfStock !== undefined &&
    typeof (decoration as StockDecoration)?.message === 'string'
  );
}

/**
 * Type guard to determine whether this is a `OverrideDecoration`.
 */
export function isOverrideDecoration(decoration: ProductCardDecoration): decoration is OverrideDecoration {
  return (
    (decoration as OverrideDecoration)?.overrideLabel !== undefined &&
    typeof (decoration as OverrideDecoration)?.message === 'string'
  );
}
