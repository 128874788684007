import { Chip } from '@mui/material';

import { StyleSpan } from './StatusChip.styles';
import type { StatusChipProps } from './StatusChip.types';

/**
 * A chip component for displaying status value typically used
 * to indicate product stock status i.e high, low, moderate and out of stock
 *
 * @param label - text to display
 * @param color - list of options to create the menu items
 * @param variant - defaults to status
 * @param size - size of the component i.e small, medium and large
 * @param role - aria-role for the element
 * @param isTranparent - toggle to make the background transparent
 * @param showIcon - toggle to show/hide icon
 *
 * @returns JSXElement
 *
 */
export default function StatusChip({
  label,
  color = 'default',
  variant = 'status',
  size = 'medium',
  role,
  isTransparent = false,
  showIcon = true
}: StatusChipProps) {
  return (
    <Chip
      role={role}
      label={label}
      variant={variant}
      color={color}
      size={size}
      sx={{ ...(isTransparent && { backgroundColor: 'transparent' }) }}
      icon={showIcon ? <StyleSpan color={color} /> : undefined}
    />
  );
}
