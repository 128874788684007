import { FF_WA_NOTIFICATION_OF_TREATMENT } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useAppStore } from '@/state-management';
import { Alert, Box } from '@mui/material';

export const WaNotificationBanner = () => {
  const { flags } = useFeatureFlags();
  const {
    waHealth: { isWaPatient }
  } = useAppStore.use.prescriptionAssistant();
  const isWaNotificationOfTreatment = flags[FF_WA_NOTIFICATION_OF_TREATMENT];
  const showBanner = isWaNotificationOfTreatment && isWaPatient;
  if (!showBanner) {
    return null;
  }

  return (
    <Box my={5}>
      <Alert aria-label="WA Department of Health notification required" severity="warning">
        <strong>Action Required: </strong>
        <span>
          This patient resides in WA as a requirement a notification form must be completed and will be sent to WA
          Department of Health
        </span>
      </Alert>
    </Box>
  );
};
