import { useQuery } from '@tanstack/react-query';

import { requestHeaders } from '@/api';
import { QueryKey } from '@/data/query';

import settings from '../../data/constants';

export type UseGetProductSurveyResponse = {
  survey: {
    question: {
      name: string;
      subText: string;
      value: number;
      answers: {
        label: string;
        value: number;
      }[];
    };
  }[];
};

export const useGetProductSurvey = (doctorId: number) => {
  return useQuery({
    queryKey: [QueryKey.ProductSurvey, doctorId],
    queryFn: async () =>
      (
        await fetch(`${settings.url}/product-survey/questions-answers?doctorId=${doctorId}`, {
          method: 'GET',
          headers: requestHeaders()
        })
      ).json(),
    select: (response: UseGetProductSurveyResponse) => response
  });
};
