import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { BoxViewer, IframeViewer } from './NoticeOfTreatmentPDFViewer.styles';

interface NoticeOfTreatmentPDFViewerProps {
  isPdfLoading: boolean;
  pdfError: Error | null;
  pdfString: string | null;
}

export const NoticeOfTreatmentPDFViewer: React.FC<NoticeOfTreatmentPDFViewerProps> = ({
  isPdfLoading,
  pdfError,
  pdfString
}) => {
  if (isPdfLoading) {
    return (
      <BoxViewer>
        <CircularProgress />
      </BoxViewer>
    );
  }

  if (pdfError) {
    // @TODO: Unhappy path flow https://montugroup.atlassian.net/browse/B2C-5074
    return <BoxViewer>Error loading PDF: {pdfError.message}</BoxViewer>;
  }

  if (pdfString) {
    return <IframeViewer src={`${pdfString}#toolbar=0`} title="Notification of Treatment Form" />;
  }

  return null;
};
