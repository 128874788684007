import type { PaginationModel } from '@montugroup/design-system';
import { PaginationVariant, SearchTextField, Table } from '@montugroup/design-system';
import { Box, Link, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { SortingState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

import type { GetPrescriptionsQuery } from '@/api/prescriptions/getPrescriptions';
import { getPrescriptionUrl } from '@/api/prescriptions/getPrescriptionUrl';
import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { useGetPrescriptions } from '@/hooks/prescription/useGetPrescriptions';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import { Snackbar } from '@/shared-ui';
import type { Prescription } from '@/types';

import { useTableManualPagination } from './hooks/useTableManualPagination';

export enum PrescriptionTableColumnHeaders {
  PrescriptionID = 'Prescription ID',
  Date = 'Date',
  PatientName = 'Patient Name',
  DoctorName = 'Doctor Name',
  Medication = 'Medication',
  Quantity = 'Quantity',
  Consultation = 'Consultation',
  Repeats = 'Repeats',
  Status = 'Status',
  Script = 'Script',
  View = 'View'
}

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

const StyledList = styled(List)({
  listStyle: 'inherit'
});

const StyledListItem = styled(ListItem)({
  padding: 0,
  marginBottom: 5
});

const StyledSearchTextFieldContainer = styled(Box)({
  '.MuiTextField-root': {
    width: '400px'
  }
});

const getPrescriptionSortField = (propName: string) => {
  switch (propName) {
    case 'date':
      return 'order_date';
    case 'patient_name':
      return 'Patient.PatientUser.first_name';
    case 'doctor_name':
      return 'Doctor.DoctorUser.first_name';
    case 'consultation':
      return 'Consultation.consultation_code';
    default:
      return propName;
  }
};

export const PrescriptionsTable = () => {
  const { createColumnDefinition } = useTableUtils();
  const [snackbarProps, setSnackbarProps] = useState<Pick<ExtendedSnackbarProps, 'severity' | 'message' | 'open'>>({
    open: false,
    severity: 'error',
    message: ''
  });
  const [searchFilterText, setSearchFilterText] = useState<string>();
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const { paginationModel, setPaginationModel, shouldResetPageIndex, setShouldResetPageIndex } =
    useTableManualPagination({
      page: 0,
      pageSize: 50
    });

  const sortQuery: Pick<GetPrescriptionsQuery, 'order' | 'dir'> = useMemo(() => {
    return sortingState.length > 0
      ? {
          order: getPrescriptionSortField(sortingState[0].id),
          dir: sortingState[0].desc ? 'DESC' : 'ASC'
        }
      : {};
  }, [sortingState]);

  const { data, isLoading } = useGetPrescriptions({
    limit: `${paginationModel.pageSize}`,
    offset: `${paginationModel.page * paginationModel.pageSize}`,
    search: searchFilterText,
    ...sortQuery
  });

  const handleOpenPrescriptionUrl = async (prescriptionId: number) => {
    try {
      const response = await getPrescriptionUrl(prescriptionId);

      if (response?.url) {
        window.open(response.url);
      }
    } catch {
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: 'An error has occurred getting the prescription PDF url'
      });
    }
  };

  const formatOrderDate = (prescription: Prescription) => {
    if (!prescription.order_date) {
      return (
        <Typography
          sx={{
            color: 'error.main'
          }}
        >
          Invalid Date
        </Typography>
      );
    }

    return DateTime.fromISO(prescription.order_date).toFormat('dd-MMM-yyyy');
  };

  const formatPatientName = (prescription: Prescription) => {
    return `${prescription?.Patient?.PatientUser?.first_name} ${prescription?.Patient?.PatientUser?.last_name}`;
  };

  const formatDoctorName = (prescription: Prescription) => {
    return `${prescription?.Doctor?.DoctorUser?.first_name} ${prescription?.Doctor?.DoctorUser?.last_name}`;
  };

  const formatMedicationList = (prescription: Prescription) => {
    const listItemStyles = { display: prescription.Products.length > 1 ? 'list-item' : 'inherit' };

    return prescription.Products.length ? (
      <StyledList>
        {prescription.Products.map((medication) => {
          return (
            <StyledListItem key={medication.id} sx={listItemStyles}>
              {medication.name}
            </StyledListItem>
          );
        })}
      </StyledList>
    ) : (
      '-'
    );
  };

  const formatQuantity = (prescription: Prescription) => {
    return (
      <List>
        {(prescription.Products || []).map((medication) => {
          return <StyledListItem key={medication.id}>{medication.quantity}</StyledListItem>;
        })}
      </List>
    );
  };

  const formatRepeats = (prescription: Prescription) => {
    return (
      <List>
        {(prescription.Products || []).map((medication) => {
          return <StyledListItem key={medication.id}>{medication.repeats}</StyledListItem>;
        })}
      </List>
    );
  };

  const formatViewScriptLink = (prescription: Prescription) => {
    const prescriptionId = prescription.id;
    return prescriptionId ? (
      <Link onClick={() => handleOpenPrescriptionUrl(prescriptionId)} sx={{ textDecoration: 'none' }}>
        View Script
      </Link>
    ) : (
      '-'
    );
  };

  const formatViewLink = (prescription: Prescription) => {
    return (
      <Link href={`/patients/${prescription.patient_id}?show=3`} target={'_blank'}>
        View
      </Link>
    );
  };

  const formattedPrescriptionData = data?.prescriptions?.map((prescription) => {
    return {
      view_link: formatViewLink(prescription),
      prescription_id: prescription.order_code,
      date: formatOrderDate(prescription),
      patient_name: formatPatientName(prescription),
      doctor_name: formatDoctorName(prescription),
      medication: formatMedicationList(prescription),
      quantity: formatQuantity(prescription),
      consultation: prescription.Consultation?.consultation_code,
      repeats: formatRepeats(prescription),
      status: prescription.status,
      script: formatViewScriptLink(prescription)
    };
  });

  const columns = [
    createColumnDefinition('view_link', PrescriptionTableColumnHeaders.View, 40),
    createColumnDefinition('script', PrescriptionTableColumnHeaders.Script, 100),
    createColumnDefinition('prescription_id', PrescriptionTableColumnHeaders.PrescriptionID, 100),
    createColumnDefinition('date', PrescriptionTableColumnHeaders.Date, 100),
    createColumnDefinition('patient_name', PrescriptionTableColumnHeaders.PatientName, 150),
    createColumnDefinition('doctor_name', PrescriptionTableColumnHeaders.DoctorName, 150),
    createColumnDefinition('medication', PrescriptionTableColumnHeaders.Medication, 400),
    createColumnDefinition('quantity', PrescriptionTableColumnHeaders.Quantity, 70),
    createColumnDefinition('consultation', PrescriptionTableColumnHeaders.Consultation, 100),
    createColumnDefinition('repeats', PrescriptionTableColumnHeaders.Repeats, 70),
    createColumnDefinition('status', PrescriptionTableColumnHeaders.Status, 70)
  ];

  const onPaginationModelChange = (changedPaginationModel: PaginationModel) => {
    setPaginationModel(changedPaginationModel);
    setShouldResetPageIndex(false);
  };

  const onSortingChange = (changedSortingState: SortingState) => {
    setSortingState(changedSortingState);
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTextOrClearIfEmptyString = event.target.value === '' ? undefined : event.target.value.trim();
    setSearchFilterText(searchTextOrClearIfEmptyString);
    setShouldResetPageIndex(true);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  const handleSnackbarClose = () => {
    setSnackbarProps({ open: false, message: '' });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <StyledSearchTextFieldContainer>
        <SearchTextField
          handleChange={handleSearchOnChange}
          handleClear={handleClearSearchFilter}
          labelText="Search"
          placeholder={'Prescription or consult code, patient or doctor names'}
          enableClear={true}
        />
      </StyledSearchTextFieldContainer>
      <StyledTable
        columns={columns}
        data={formattedPrescriptionData || []}
        isLoading={isLoading}
        skeletonCount={paginationModel.pageSize}
        manualPagination={true}
        onPaginationModelChange={onPaginationModelChange}
        manualSorting={true}
        onSortingChange={onSortingChange}
        pageSize={paginationModel.pageSize}
        total={data?.count ? data.count : undefined}
        showPagination
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        isTableLayoutFixed={true}
        shouldResetPageIndex={shouldResetPageIndex}
      />
      <Snackbar
        {...snackbarProps}
        severity={'error'}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};
