import type { AxiosError } from 'axios';
import axios from 'axios';

import type { CommonErrorPayload } from '../hooks/rest/types';

export function isAxiosErrorWithCommonErrorPayload(err: unknown): err is AxiosError<CommonErrorPayload> {
  if (!axios.isAxiosError(err)) {
    return false;
  }
  // There is an issue with axios v0.22 helper that casts the error type to never when checking it even if the generic
  // typing has been applied.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorData = err.response?.data as any;
  if (errorData && typeof errorData.message === 'string') {
    return true;
  }
  return false;
}
