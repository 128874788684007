import { useEffect } from 'react';

import settings from '../../data/constants';

import useRequest from './useRequest';

export enum DiscountCodeStatus {
  valid = 'valid',
  expired = 'expired',
  invalid = 'invalid'
}

type DiscountCode = {
  discountCode: string;
};

type DiscountCodeError = {
  status: DiscountCodeStatus.invalid;
  errorMessage: string;
} & DiscountCode;

type DiscountCodeExpired = {
  status: DiscountCodeStatus.expired;
  expiredAt: string;
} & DiscountCode;

type DiscountCodeAmount = {
  status: DiscountCodeStatus.valid;
  discountAmount: number;
} & DiscountCode;

type DiscountCodePercent = {
  status: DiscountCodeStatus.valid;
  discountPercent: number;
} & DiscountCode;

export type DiscountType = (DiscountCodeAmount & DiscountCodePercent) | DiscountCodeExpired | DiscountCodeError;

export function useDiscountCode(discountCode: string) {
  const { loading, data, error, execute } = useRequest<DiscountType>({
    config: {
      url: `${settings.url}/discount/validate`,
      method: 'GET'
    }
  });

  useEffect(() => {
    if (discountCode) {
      execute({ params: { discountCode } });
    }
  }, [discountCode, execute]);

  return {
    loading,
    data,
    error,
    doGet: execute
  };
}

export default useDiscountCode;
