import { Grid } from '@mui/material';

const DassAssessmentScorePage = () => {
  return (
    <div>
      <Grid container alignItems="center" direction="column" justifyContent="center">
        <Grid item md={3} />
        <Grid item xs={12} md={6}>
          <div className="header-container">
            <h1 className="text-color">DASS Assessment Complete</h1>
            <p data-testid="thanksMsg">
              Your answers have been successfully submitted and will be reviewed by your Alternaleaf doctor.
            </p>
          </div>
        </Grid>
        <Grid item md={3} />
      </Grid>
    </div>
  );
};

export default DassAssessmentScorePage;
