import { Button } from '@montugroup/design-system';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import { ExportType } from '@/api/excelExports/postExcelExports';
import { useAccessControl } from '@/hooks';
import { useExportExcelWithSnackbarState } from '@/hooks/useDownloadFileWithSnackbarState';
import { Snackbar } from '@/shared-ui';
import { UserRole } from '@/types';

import DateRangeComp from '../common/DateRangeComp';

export const PrescriptionsPageHeader = () => {
  const [dateRangeCompOpen, setDateRangeCompOpen] = useState(false);
  const { allowAccess } = useAccessControl();
  const { snackbarProps, setSnackbarProps, downloadFile } = useExportExcelWithSnackbarState({
    exportType: ExportType.PRESCRIPTION
  });

  const renderExportButton = () => {
    return allowAccess(
      [UserRole.SuperAdmin],
      <Box component="div" ml={2} display="flex" data-testid={'export-prescriptions-btn'}>
        <Button variant="contained" color="secondary" onClick={() => setDateRangeCompOpen(true)}>
          Export
        </Button>
      </Box>
    );
  };

  return (
    <Box component="header" padding={4}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Prescriptions</Typography>
        {renderExportButton()}
      </Box>

      <Box component="div" display="flex" ml={2}>
        {dateRangeCompOpen && (
          <div>
            <DateRangeComp open={dateRangeCompOpen} setOpen={setDateRangeCompOpen} DownloadFile={downloadFile} />
          </div>
        )}
      </Box>
      <Snackbar
        {...snackbarProps}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => {
          setSnackbarProps({ open: false, message: '' });
        }}
      />
    </Box>
  );
};
