import type { SvgIconProps } from '@mui/material';

import { FF_ENABLE_DOCTORS_G_SCRIPT, FF_ENABLE_DOCTOR_OOS_RESCRIPT } from '@/constants/featureFlags';
import { StaffRoleIds, UserRole } from '@/types';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import ProductCatalogIcon from '@mui/icons-material/LocalMallOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import PatientsIcon from '@mui/icons-material/PersonOutline';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ConsultationsIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import type { LDFlagSet } from 'launchdarkly-react-client-sdk';

type MenuItem = {
  id: number | string;
  name: string;
  link?: string;
  featureFlag?: string;
  role_id?: number[];
  icon?: React.ReactElement<SvgIconProps>;
  activeLinkStateIncludesNested?: boolean;
  enableToolTip?: boolean;
  accessCheckMethod?: (user: any, flags: LDFlagSet) => boolean;
};

export type MenuItemWithChildren = MenuItem & {
  children?: MenuItemWithChildren[];
};

/**
 * List of default menu items.
 */
export const DEFAULT_MENUS: MenuItemWithChildren[] = [
  {
    id: 'administration',
    name: 'Administration',
    icon: <SecurityOutlinedIcon />,
    children: [
      {
        id: 'consultation-double-bookings',
        name: 'Double Bookings',
        link: '/consultation-double-bookings',
        role_id: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.Nurse]
      },
      {
        id: 8,
        name: 'Onboard New Nurse',
        link: '/onboard-new-nurse',
        role_id: [UserRole.SuperAdmin, UserRole.Manager]
      },
      {
        id: 'support-self-service',
        name: 'Support Self Service',
        link: '/support-self-service',
        role_id: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.Nurse]
      },
      {
        id: 'ihi-review-logs',
        name: 'IHI Review Logs',
        link: '/ihi-review-logs',
        role_id: [UserRole.SuperAdmin]
      }
    ]
  },
  {
    id: 6,
    name: 'Consultations',
    link: '/consultations',
    role_id: StaffRoleIds,
    icon: <ConsultationsIcon />
  },
  {
    id: 'doctorsMenu',
    name: 'Doctors',
    icon: <MedicalServicesOutlinedIcon />,
    children: [
      {
        id: 3,
        name: 'List of Doctors',
        link: '/doctors',
        role_id: [UserRole.SuperAdmin, UserRole.Nurse, UserRole.Admin, UserRole.Manager]
      },
      {
        id: 2,
        name: 'Doctor Calendar',
        link: '/calendar',
        role_id: StaffRoleIds
      },
      {
        id: 'doctors-script-review',
        name: 'Doctor Script Review',
        link: '/doctors/rescript',
        role_id: StaffRoleIds,
        accessCheckMethod: (_user, flags) => {
          const ffDrGScript = flags[FF_ENABLE_DOCTORS_G_SCRIPT];
          const ffOssRescript = flags[FF_ENABLE_DOCTOR_OOS_RESCRIPT];
          return ffDrGScript || ffOssRescript;
        }
      }
    ]
  },
  {
    id: 'nursesMenu',
    name: 'Nurses',
    icon: <HealthAndSafetyOutlinedIcon />,
    children: [
      {
        id: 'nurses',
        name: 'List of Nurses',
        link: '/nurses',
        role_id: [UserRole.SuperAdmin, UserRole.Nurse, UserRole.Admin, UserRole.Manager]
      },
      {
        id: 1,
        name: 'Nurse Calendar',
        link: '/nurse-calendar',
        role_id: [UserRole.SuperAdmin, UserRole.Nurse, UserRole.Admin, UserRole.Manager]
      },
      {
        id: 'nurse-scheduler',
        name: 'Nurse Scheduler',
        link: '/nurse-scheduler',
        role_id: [UserRole.SuperAdmin, UserRole.Nurse, UserRole.Admin, UserRole.Manager]
      }
    ]
  },
  {
    id: 4,
    name: 'Patients',
    link: '/patients',
    role_id: StaffRoleIds,
    icon: <PatientsIcon />
  },
  {
    id: 5,
    name: 'Prescriptions',
    link: '/prescriptions',
    role_id: StaffRoleIds,
    icon: <MedicationOutlinedIcon />
  },
  {
    id: 7,
    name: 'Product Catalog',
    link: '/product-catalog',
    role_id: [...StaffRoleIds, UserRole.Patient],
    icon: <ProductCatalogIcon />
  }
];
