import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  styled,
  TextField,
  Typography
} from '@mui/material';

import { makeGET, makePOST } from '../../data/service/dataService';
import { Container } from '../branding/Alternaleaf';

const Question = styled('ol')({
  listStyle: 'none',
  paddingLeft: '40px'
});

const QuestionItem = styled('li')(({ theme }) => ({
  counterIncrement: 'list-counter',
  position: 'relative',
  paddingLeft: '20px',
  fontWeight: 700,
  '> div': {
    paddingBottom: '1rem',
    '&::after': {
      content: 'counter(list-counter)',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: theme.palette.grey[500],
      color: '#FFF',
      position: 'absolute',
      '--size': '28px',
      left: 'calc(-1 * var(--size) - 10px)',
      lineHeight: 'var(--size)',
      width: 'var(--size)',
      height: 'var(--size)',
      top: 0,
      textAlign: 'center'
    },
    '&.c-dass_completed__content::after': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '&:not(:last-child) > div': {
    positon: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-25px',
      top: 0,
      height: '100%',
      width: '2px',
      backgroundColor: theme.palette.grey[300]
    }
  }
}));

const QuestionAndAnswer = (props) => {
  const { patientDetails } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { patient_id: patientId, uuid } = useParams();

  useEffect(() => {
    async function fetchDassQuestions() {
      const resp = await makeGET(`dass/dassQuestions`, 'fetchDassQuestions-DassAssessmentForm');
      if (resp) {
        resp.forEach((ele, index) => {
          ele.status = '';
          ele.answer = {};
        });
        setQuestionList(resp);
      }
    }
    async function fetchDassAnswers() {
      const resp = await makeGET(`dass/dassAnswers`, 'fetchDassAnswers-DassAssessmentForm');
      if (resp) {
        setAnswerList(resp);
      }
    }
    fetchDassQuestions();
    fetchDassAnswers();
  }, []);

  const findActiveIndex = () => {
    for (let i = questionList.length - 1; i >= 0; i--) {
      if (questionList[i].status === 'completed') {
        setActiveIndex(i + 1);
        break;
      }
    }
  };

  const handleAnswerChange = (queIndex, ans) => {
    questionList[queIndex].status = 'completed';
    questionList[queIndex].answer = ans;
    for (let i = 0; i <= queIndex; i++) {
      if (questionList[i].status === '') {
        questionList[i].status = 'inComplete';
      }
    }
    findActiveIndex();
    setQuestionList([...questionList]);
  };

  const getIconClass = (que, index) => {
    if (que.status === 'completed') {
      return 'c-dass_completed__content';
    }
    if (que.status === 'inComplete') {
      return 'c-dass_incomplete__content';
    }
    if (que.status === 'notCompleted') {
      return 'c-dass_notCompleted__content';
    } else if (index === activeIndex) {
      return 'c-dass_start__content';
    } else {
      return 'c-dass__content';
    }
  };

  const setNotCompletedStatus = () => {
    questionList.forEach((que) => {
      if (que.status !== 'completed') {
        que.status = 'notCompleted';
      }
    });
    setQuestionList([...questionList]);
  };

  const getDassData = () => {
    let array = [];
    questionList.forEach((ele, index) => {
      array.push({
        dassQuestionId: ele.id,
        answerValue: ele.answer.answer_value
      });
    });
    return array;
  };

  const handleDassFormSubmit = async () => {
    setShowSnackbar(false);
    setSubmitted(true);
    try {
      const array = questionList.filter((ele) => ele.status !== 'completed');
      if (array.length > 0) {
        setNotCompletedStatus();
        setSubmitted(false);
        return;
      }
      const sendBody = {
        patientId: patientId,
        userEmail: patientDetails.email ? patientDetails.email : '',
        dassData: getDassData(),
        uuid: uuid
      };
      const response = await makePOST('dass/createDassAssessment', sendBody, 'DassAssessmentForm-QuestionAnswer');
      if (response) {
        navigate(`/dash-assessment-score/${patientId}`);
      } else {
        setErrorMessage('Failed to Save!');
        setShowSnackbar(true);
        setSubmitted(false);
      }
    } catch (err) {
      setErrorMessage('An error occured! please try again.');
      setShowSnackbar(true);
    }
  };

  return (
    <Container>
      <Typography variant="h3" component="h2" sx={{ mb: 4, typography: { xs: 'h4' } }}>
        DASS Assessment
      </Typography>
      <Typography sx={{ mb: 10 }}>
        Patient Instructions: Please read each statement and enter a number which indicates how much the statement
        applied to you in the past week. There are no right or wrong answers
      </Typography>

      <Grid container rowSpacing={{ xs: 8, md: 12 }} columnSpacing={{ xs: 0, md: 24 }}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              variant="standard"
              label="Name"
              size="small"
              value={
                patientDetails.first_name
                  ? patientDetails.first_name + ' ' + (patientDetails.last_name ? patientDetails.last_name : '')
                  : ''
              }
              inputProps={{
                'data-testid': 'name'
              }}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              variant="standard"
              label="Email"
              size="small"
              value={patientDetails.email ? patientDetails.email : ''}
              inputProps={{
                'data-testid': 'email'
              }}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{ my: 8 }} />
      <Box>
        <Question>
          {questionList?.map((que, queIndex) => (
            <QuestionItem key={queIndex}>
              <div className={getIconClass(que, queIndex)}>
                <Typography>{que.question}</Typography>
                <RadioGroup row name="row-radio-buttons-group">
                  {answerList?.map((ans, ansIndex) => (
                    <FormControlLabel
                      key={ansIndex}
                      value={ans.answer}
                      control={<Radio />}
                      label={ans.answer}
                      onClick={() => handleAnswerChange(queIndex, ans)}
                    />
                  ))}
                </RadioGroup>
              </div>
            </QuestionItem>
          ))}
        </Question>
        <Button sx={{ mt: 4 }} disabled={submitted} variant="contained" onClick={() => handleDassFormSubmit()}>
          Submit
        </Button>
        <Snackbar
          open={showSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setShowSnackbar(false)}
          autoHideDuration={6000}
        >
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default QuestionAndAnswer;
