import { customString } from '@/components/Patient/Patient.schema.utils';
import { z } from 'zod';

export const schema = z.object({
  presentingComplaint: customString,
  symptoms: customString,
  trialledMedication: customString,
  currentMedicationList: customString,
  allergies: z.string().optional(),
  cardioDisease: z.string().optional(),
  medicalHistory: customString,
  drinksPerWeek: z.number().optional(),
  smokingEx: z.string().optional()
});
