import React from 'react';
import Box from '@mui/material/Box';

function NotFound(props) {
  return (
    <Box width="100%" height="100%">
      <Box width="50%" height="30vh" mx="auto" textAlign="center">
        <Box component="h1" mt={3}>
          Page not found
        </Box>
      </Box>
    </Box>
  );
}

export default NotFound;
