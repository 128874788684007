import { Table } from '@montugroup/design-system';
import type { PrescribedMedicationDossierItemSchema } from '@montugroup/prescription-contracts';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';

import { formatTableColumns } from '../PrescribedMedicationsTable/PrescribedMedicationsTable.config';

import type { PrescribedMedicationDossierTableProps } from './PrescribedMedicationDossierTable.types';

const StyledTable = styled(Table)({
  paddingBottom: 0,
  boxShadow: 'none'
});

const PrescribedMedicationDossierTable = ({ headings = [], products = [] }: PrescribedMedicationDossierTableProps) => {
  const formattedColumns = useMemo(
    () => formatTableColumns<PrescribedMedicationDossierItemSchema>(headings),
    [headings]
  );

  // do not display table if no header data is passed
  if (!headings.length) {
    return null;
  }

  return (
    <StyledTable
      columns={formattedColumns}
      data={products}
      hasRowBackgroundColor={false}
      isScrollable
      maxHeight={312}
    />
  );
};

export default PrescribedMedicationDossierTable;
