import { useEffect, useState } from 'react';

type ImgProps = JSX.IntrinsicElements['img'];

interface ImageProps extends ImgProps {
  altSrc?: string;
}

/**
 * The purpose of this component is to provide an image tag that handles
 * invalid product image url and displays a fallback image
 *
 * @typeParam altSrc - fallback image if the src image is un-available
 *
 * @returns Returns an HTMLImageElement with error handling for fallback image
 *
 */
export default function Image({ src, altSrc, ...rest }: ImageProps) {
  const [imageUrl, setImageUrl] = useState(src ?? '');

  const onError = () => {
    if (altSrc) {
      setImageUrl(altSrc);
    }
  };

  useEffect(() => {
    if (src) {
      setImageUrl(src);
    }
  }, [src]);

  return <img src={imageUrl} onError={onError} {...rest} />;
}
