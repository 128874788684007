import { useQuery } from '@tanstack/react-query';

import { getNurses } from '@/api/nurse/getNurses';
import { QueryKey } from '@/data/query';

export const useGetNurses = () => {
  const {
    data: nursesData,
    isError,
    isLoading,
    refetch
  } = useQuery({
    queryKey: [QueryKey.GetNurses],
    queryFn: async () => {
      const getNursesResponse = await getNurses();

      if (getNursesResponse.message) {
        throw new Error(getNursesResponse.message);
      }

      return getNursesResponse;
    }
  });

  return {
    nurses: nursesData,
    isError,
    isLoading,
    refetch
  };
};
