import { useQuery } from '@tanstack/react-query';

import type { ConsultationModel } from '@/api/consultations/Consultation.types';
import { getConsultationStatus } from '@/api/consultations/getConsultationStatus';
import { ConsultationStatus } from '@/data/constants';
import { QueryKey } from '@/data/query';

type ConsultationStatusMapped = {
  name: string;
  value: number;
};

type UseGetConsultationStatusResponse = {
  consultationStatuses: ConsultationStatusMapped[];
  isError: boolean;
  isLoading: boolean;
};

export const useGetConsultationStatus = (showConsultation: ConsultationModel): UseGetConsultationStatusResponse => {
  const {
    data: consultationStatuses,
    isError,
    isLoading
  } = useQuery({
    queryKey: [QueryKey.GetConsultationStatus],
    enabled: !!showConsultation,
    queryFn: async () => {
      const data = await getConsultationStatus();

      const mappedData = data
        ?.map((status) => ({ name: status.name, value: status.id }))
        .filter((status) => {
          if (status.value !== ConsultationStatus.ineligible) {
            return true;
          }
          if (!showConsultation || !showConsultation.Nurse) {
            return false;
          }
          if (showConsultation?.consult_type === 'Initial') {
            return true;
          }
          return false;
        });
      return mappedData;
    }
  });

  return {
    consultationStatuses: consultationStatuses!,
    isError,
    isLoading
  };
};
