import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { Box, Card, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';

import { FF_ENABLE_DISCOUNT_CODES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import type { CalendarData } from '@/hooks/rest/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';
import { GTM_EVENTS } from '@/utils/gtmEvents';

import { formatBookingDataToSha256HashedValues } from '@/utils/ga-hashing';
import PreConsultButton from './PreConsultButton';

const SummaryHeading = styled(Typography)({
  fontWeight: 600,
  marginBottom: 16
}) as typeof Typography;

const StyledCard = styled(Card)(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: theme.palette.pillCard.main,
  display: 'flex',
  flexDirection: 'column'
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 20,
    marginLeft: 0,
    width: '100%'
  },

  marginTop: 0
}));

export type PaymentSuccessProps = { data: CalendarData };

export default function PaymentSuccess({ data }: PaymentSuccessProps) {
  const { flags } = useFeatureFlags();

  const ffEnableDiscountCodes = flags[FF_ENABLE_DISCOUNT_CODES];
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  const consultation = ffEnableDiscountCodes ? JSON.parse(localStorage.getItem('consultation_summary') || '{}') : {};

  const isSuccessTrackingTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (!isSuccessTrackingTriggered.current) {
      const trackingData = {
        ...JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}'),
        payment_value: parseInt(consultation?.totalAmount?.replace(/\$/g, '')),
        /* SHA 256 Hashed values for GA tracking */
        ...formatBookingDataToSha256HashedValues(data)
      };

      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_SUBMIT, {
        id: GoogleAnalyticsEventId.PAYMENT_DETAILS_SUBMIT,
        status: 'Successful',
        ...trackingData
      });

      isSuccessTrackingTriggered.current = true;
    }
  }, [sendGoogleAnalyticsEvent, consultation?.totalAmount]);

  return (
    <Box>
      <StyledGridContainer
        container
        rowSpacing={{ xs: 5, sm: 20 }}
        columnSpacing={{ xs: 0, sm: 8 }}
        paddingTop={{ xs: 0, sm: 10 }}
        position={'relative'}
        mt={5}
      >
        <Grid item md={12}>
          <SummaryHeading
            component="h2"
            fontWeight={600}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            fontSize={'1.6rem'}
          >
            <TaskAltOutlinedIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
            Payment details saved
          </SummaryHeading>
          <Typography marginBottom={8}>
            Charges will <strong>only</strong> apply after an eligible nurse consultation.
          </Typography>
          <StyledCard>
            <Typography component="h2" fontSize={'1.3rem'} paddingBottom={5} fontWeight={600}>
              One final step
            </Typography>
            <Typography>
              Complete the pre-consultation form below to help our nurses and doctors properly assess your condition.
              This should take around 5 minutes.
            </Typography>
            <PreConsultButton gtmEventId={GTM_EVENTS.COMPLETE_PRE_CONSULT_SUCCESS} />
          </StyledCard>
        </Grid>
      </StyledGridContainer>
      <Alert variant="outlined" severity="info" sx={{ marginTop: 8, marginBottom: 15 }}>
        <strong>Notice Policy:</strong> 24-hour advance notice is required for cancellations or rescheduling. Late
        changes may incur a fee. This supports better access for all patients.
      </Alert>
    </Box>
  );
}
