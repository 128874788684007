import type { PrescriptionFormProduct } from '@/hooks/prescription/usePrescriptionForm';
import type { PreviewNotificationFormRequest } from '@montugroup/pms-api-contracts';
import { PreviewNotificationFormRequestSchema } from '@montugroup/pms-api-contracts';
import { useCallback, useState } from 'react';
import usePostPreviewNotificationForm from './usePostPreviewNotificationForm';

export interface FetchPdfParams {
  patientId: string;
  medicationProductsPayload: PrescriptionFormProduct[];
}

export const useNoticeOfTreatmentPdf = () => {
  const [pdfString, setPdfString] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { mutateAsync: previewNotificationForm } = usePostPreviewNotificationForm();

  // Function to convert Blob to base64 string
  // refactored this into a promise for better handling and used useCallback to memoize it
  // since it's a very stable function without any dependencies
  const generatePdfString = useCallback((pdfBlob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Failed to read PDF Blob'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Error reading PDF Blob'));
      };

      reader.readAsDataURL(pdfBlob);
    });
  }, []);

  const fetchPdf = useCallback(
    async (data: FetchPdfParams) => {
      const { patientId, medicationProductsPayload } = data;
      setIsLoading(true);
      setError(null);
      try {
        const transformedData: PreviewNotificationFormRequest = {
          patientId: Number(patientId),
          products: medicationProductsPayload.map((product) => ({
            productId: Number(product.product_id),
            dosage: product.dosage as string,
            interval: Number(product.interval),
            quantity: Number(product.quantity),
            repeats: Number(product.repeats)
          }))
        };

        // Validate the transformed data using Zod
        PreviewNotificationFormRequestSchema.parse(transformedData);

        const response = await previewNotificationForm({ body: transformedData });

        if (response.status !== 200 || !(response.body instanceof Blob)) {
          throw new Error('Failed to fetch PDF');
        }

        const pdfBlob = response.body;
        const base64data = await generatePdfString(pdfBlob);
        setPdfString(base64data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'An error occurred while fetching the PDF';
        setError(new Error(errorMessage));
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [previewNotificationForm, generatePdfString]
  );

  return { pdfString, isLoading, error, fetchPdf };
};
