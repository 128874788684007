import { Box, Tab, Tabs } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useScrollRef } from '@/context/scrollContext';

const formatTabHash = (hash: string) => hash.toLowerCase().replace(/[^a-z]/g, '');

interface Props {
  categories: string[];
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
}

const SupportTabButtons = ({ categories, tabIndex, setTabIndex }: Props) => {
  const scrollRef = useScrollRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = decodeURIComponent(formatTabHash(location.hash.replace('#tab=', '')));
    const categoryIndex = categories.map(formatTabHash).indexOf(hash);
    if (categoryIndex !== -1) {
      setTabIndex(categoryIndex);
    }
  }, [location.hash, categories, setTabIndex]);

  const handleTabChange = (_: unknown, i: number) => {
    setTabIndex(i);
    navigate(`#tab=${encodeURIComponent(formatTabHash(categories[i]))}`);
  };

  useEffect(() => {
    scrollRef.current?.scrollTo({
      top: 0
    });
  }, [tabIndex, scrollRef]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Support tasks by category">
        {categories.map((category) => (
          <Tab label={category} key={category} sx={{ fontWeight: 'bold' }} />
        ))}
      </Tabs>
    </Box>
  );
};

export default SupportTabButtons;
