import type { ProductFormulationDetails, ProductFullDetails } from '@/api/product/getProductDetails.types';
import { FF_USE_SHOPIFY_PRODUCT_IMAGES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import getProductImageUrl from '@/utils/getProductImageUrl';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LimitedAvailabilityChip from '@/shared-ui/LimitedAvailabilityChip/LimitedAvailabilityChip';
import Comingsoon from '../../assets/images/coming_soon.png';
import NewlowPrice from '../../assets/images/new_low_price.png';
import NewProduct from '../../assets/images/new_product.png';
import outOfStock from '../../assets/images/outofstock.png';
import { ConcessionProductCatalogueChip } from '../concession';

export type ProductImageTileProps = {
  product?: ProductFullDetails;
  type?: ProductFormulationDetails;
};

const ProductImageTile = ({ product, type }: ProductImageTileProps) => {
  const isConcessionProduct = product?.is_concession;
  const navigate = useNavigate();

  const { flags } = useFeatureFlags();
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES];

  const showProduct = () => {
    navigate(`/product-detail/${product?.id}`);
  };

  const renderLimitedAvailabilityChip = () => {
    return (
      ((product?.is_excluded_from_new_prescriptions ||
        product?.is_supply_chain_risk ||
        product?.is_generative_scripting) && (
        <div className="position-limited-tag">
          <LimitedAvailabilityChip label="Limited Availability" />
        </div>
      )) ||
      null
    );
  };

  return (
    <Box onClick={showProduct} sx={{ cursor: 'pointer' }} className="container">
      <Box
        position={'relative'}
        className="product-display-flex"
        style={{
          position: 'relative',
          minHeight: '300px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          src={getProductImageUrl(useShopifyProductImages, product?.image_url, product?.ProductImages)}
          alt={`${type?.id || ''}`}
          className="container-img"
        />
        {renderLimitedAvailabilityChip()}
        <div className="position-product">
          {product?.new_at_lowprice && (
            <img src={NewlowPrice} className="img-fluid  new-product-low-price" alt="New low price" />
          )}
          {product?.new_product && <img src={NewProduct} className="img-fluid new-product" alt="New product" />}
          {product?.coming_soon && <img src={Comingsoon} className="img-fluid  coming-soon" alt="Coming soon" />}
          {(product?.is_out_of_stock || product?.reasoning_toggle) && (
            <img src={outOfStock} className="img-fluid out-of-stock" alt="Out of stock" />
          )}
          {isConcessionProduct && <ConcessionProductCatalogueChip />}
        </div>
      </Box>
      <Box justifyContent={'center'}>
        <Box
          component="p"
          textAlign="center"
          fontWeight="bold"
          fontSize="20px"
          className="font-primary_light_color margin-top-product"
        >
          {product?.Suppliers[0]?.supplier_name}
        </Box>
        <Box
          component={'div'}
          justifyContent={'center'}
          display={'flex'}
          className="product-display-flex margin-top-product"
        >
          <Box component="h6" textAlign={'center'} fontSize={'14px'} alignItems={'center'} marginLeft={'20px'}>
            {product?.short_name || product?.name}
            <span>
              <img
                src={type?.image_url}
                alt={`${type?.id || ''}`}
                style={{ width: '22px', objectFit: 'contain', marginLeft: '9px' }}
              />
            </span>
          </Box>
        </Box>
        <Box component={'p'} textAlign={'center'} className=" margin-top-product" style={{ fontSize: '12px' }}>
          <b>{product?.productFiltersString && 'Filters: '}</b>
          {product?.productFiltersString}
        </Box>
        <Box component={'p'} textAlign={'center'} className=" margin-top-product" style={{ fontSize: '12px' }}>
          {product?.thc_strength_for_display &&
          product?.thc_strength_for_display !== '-' &&
          product?.cbd_strength_for_display &&
          product?.cbd_strength_for_display !== '-'
            ? `THC ${product?.thc_strength_for_display} : CBD ${product?.cbd_strength_for_display}`
            : ''}
        </Box>
        <Box component={'p'} textAlign={'center'} className=" margin-top-product" style={{ fontSize: '12px' }}>
          ${product?.price} {product?.size && product?.size !== '-' && `- ${product?.size}`}
        </Box>
      </Box>
    </Box>
  );
};
export default ProductImageTile;
