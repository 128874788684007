import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { GetConsultationsQuery } from '@/api/consultations/getConsultations';
import { getConsultations } from '@/api/consultations/getConsultations';
import { QueryKey } from '@/data/query';

export const useGetConsultations = (params?: GetConsultationsQuery) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [QueryKey.Consultations, { ...params }],
    queryFn: () => getConsultations(params),
    placeholderData: keepPreviousData
  });

  return {
    data,
    isError,
    isLoading
  };
};
