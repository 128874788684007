import type { FilteredProduct, FilteredProductByFormulation } from '@/api';
import type {
  AnswerDisplayPositionType,
  FeedbackPayload
} from '@/components/PatientPageV2/components/PrescriptionPad/PrescriptionPad.types';
import type { DeviceProduct, PrescriptionFormProduct } from '@/hooks/prescription/usePrescriptionForm';
import type { RadioOption } from '@/shared-ui/FeedbackRadioGroup/FeedbackRadioGroup';

import type { MedicationType } from '../ProductDetailsCard/ProductDetailsCard.types';

import type { PrescribeRequestBodySchema } from '@montugroup/prescription-contracts/build/schema/prescribe.schema';
import { PrescribeProductAction } from '@montugroup/prescription-contracts/build/schema/prescribe.schema';
import type { MedicationPadItemType } from './PrescriptionPad.types';

/**
 * Transform and merge medications and itemsToReissue
 * @param medications
 * @param itemsToReissue
 * @returns
 */
export const transformAndMergeMedicationsAndItemsToReissue = (
  medications: MedicationPadItemType[],
  itemsToReissue: MedicationPadItemType[]
) => {
  // try to find common items in medications and itemsToReissue
  // the unique item from the 2 arrays would be the one that have  "action: PrescribeProductAction.Create"
  // items inside itemsToReissue are the one to have action PrescribeProductAction.Reissue

  const reissueMap = new Map(itemsToReissue.map((item) => [item.medicationPadItem?.productId, item]));

  return medications.map((medication) => {
    const productId = medication.medicationPadItem?.productId;
    const isReissue = reissueMap.has(productId);

    return {
      interval: `${medication.medicationPadItem?.interval}`,
      action: isReissue ? PrescribeProductAction.Reissue : PrescribeProductAction.Create,
      productId: productId,
      quantity: medication.medicationPadItem?.quantity,
      dosage: medication.medicationPadItem?.dosage,
      repeats: medication.medicationPadItem?.repeats,
      isConcession: false
    };
  });
};

/**
 * Filters out any null or undefined DeviceProduct entries and transforms an array of DeviceProduct objects
 * into the format required by v4 Prescribe endpoint in PrescribeRequestBodySchema['devices'].
 * @param deviceProducts - An array of DeviceProduct objects to be transformed.
 * @returns An array of objects conforming to the v4 prescribe PrescribeRequestBodySchema['devices'] structure.
 */
export const transformV3DeviceProductsToV4DeviceProducts = (
  deviceProducts: DeviceProduct[]
): PrescribeRequestBodySchema['devices'] => {
  return deviceProducts
    .filter((d) => !!d)
    .map((d) => ({
      productId: d.product_id,
      quantity: d.quantity,
      action: PrescribeProductAction.Create
    }));
};

// transform the prescription POST request payload
export const transformPrescriptionsPayload = (medications: MedicationPadItemType[]): PrescriptionFormProduct[] =>
  medications.reduce((prescriptionFormProducts, { medicationPadItem }) => {
    if (!medicationPadItem) {
      return prescriptionFormProducts;
    }
    return [
      ...prescriptionFormProducts,
      {
        product_id: Number(medicationPadItem.productId),
        name: medicationPadItem.productName,
        dosage: medicationPadItem.dosage,
        // check if this product should be returned by API
        other_product: '',
        quantity: medicationPadItem.quantity,
        repeats: medicationPadItem.repeats,
        interval: medicationPadItem.interval,
        // this should be returned from API
        is_concession: false
      }
    ];
  }, [] as PrescriptionFormProduct[]);

export const transformPrescriptionToProduct = (
  prescription: MedicationType
): PrescriptionFormProduct | DeviceProduct => {
  if (prescription.vaporizer) {
    return {
      product_id: prescription.productId,
      name: prescription.productName,
      quantity: prescription.quantity,
      vaporizer: prescription.vaporizer
    };
  }
  return {
    product_id: prescription.productId,
    name: prescription.productName,
    // TODO - update in concession ticket
    is_concession: false,
    other_product: '',
    dosage: prescription.dosage,
    interval: prescription.interval, // this is a string on the server apparently
    quantity: prescription.quantity,
    repeats: prescription.repeats
  };
};

export const transformFeedbackPayload = (medications: MedicationPadItemType[]) =>
  medications
    .filter((medication) => {
      if (
        typeof medication.feedback?.questionAsked === 'number' &&
        typeof medication.feedback?.answerSelected === 'number'
      ) {
        return true;
      }
    })
    .map(({ medicationPadItem, feedback, filters = new Map() }) => ({
      productChosen: medicationPadItem?.productId ?? 0,
      questionAsked: feedback?.questionAsked ?? 0,
      answerSelected: feedback?.answerSelected ?? 0,
      answerDisplayPositions: feedback?.answerDisplayPositions ?? [],
      filters
    }));

/**
 * Created new functions to handle js map for feedback and
 * not to change 'transformFeedbackPayload' until Prescription Assistant Tool (PAT) V2 is released
 * @param feedbackFilters
 */
export const convertMapToJsObject = (feedbackFilters: Map<string, string>) => {
  return Object.fromEntries(feedbackFilters.entries());
};

export const feedbackPayloadConstructor = (feedback: FeedbackPayload[]) => {
  return feedback.map((fb) => {
    return {
      ...fb,
      filters: convertMapToJsObject(fb.filters)
    };
  });
};

/**
 * ------ 2 helper functions above need to be re-evaluated -----
 * ------ after PAT V2 is released ---------
 */

/**
 * Flattened filtered products into a single
 */
export const getFilteredProducts = (
  formulationProducts: FilteredProductByFormulation,
  savedFilteredProducts: FilteredProduct[] = []
): FilteredProduct[] => {
  Object.values(formulationProducts).map((parentNode) => {
    if (!Array.isArray(parentNode)) {
      return getFilteredProducts(parentNode, savedFilteredProducts);
    }

    savedFilteredProducts.push(...parentNode);
  });

  return savedFilteredProducts;
};

export const getFilteredProductById = (
  formulationProducts: FilteredProductByFormulation | undefined,
  productId: number | undefined
): FilteredProduct | null => {
  if (!formulationProducts || !productId) {
    return null;
  }

  const filteredProducts = getFilteredProducts(formulationProducts);

  return filteredProducts.find((product) => product.id === productId) || null;
};

// Fisher-Yates Shuffle Algorithm
export const shuffleSurveryAnswers = (options: RadioOption[], randomNum: number = Math.random()) => {
  const answers = [...options];
  for (let i = answers.length - 1; i > 0; i--) {
    const j = Math.floor(randomNum * (i + 1));
    [answers[i], answers[j]] = [answers[j], answers[i]];
  }
  return answers;
};

export const getAnswerDisplayPositions = (
  answers: RadioOption[],
  randomAnswers: RadioOption[]
): AnswerDisplayPositionType[] => {
  return answers
    .map((answer) => {
      const displayPosition =
        randomAnswers.findIndex((randomAnswer) => Number(randomAnswer.value) === Number(answer.value)) + 1;
      return { answerId: Number(answer.value), displayPosition };
    })
    .sort((a, b) => Number(a.answerId) - Number(b.answerId));
};

export const getProductDisplayName = (productName?: string, productDescription?: string, supplierName?: string) => {
  const displayProductDetails = `${productName ?? ''} ${productDescription ?? ''}`;

  if (!supplierName) {
    return displayProductDetails;
  }

  const displayName = `${supplierName} | ${displayProductDetails}`;
  // remove extra spaces
  return displayName.replace(/\s+/g, ' ').trim();
};
