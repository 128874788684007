import { Box, useTheme } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import SupportHeaderContent from '@/components/SupportSelfService/SupportHeaderContent';
import SupportHeaderSticky from '@/components/SupportSelfService/SupportHeaderSticky';
import SupportTabs from '@/components/SupportSelfService/SupportTabButtons';
import SupportTabContent from '@/components/SupportSelfService/SupportTabContent';
import SupportTask from '@/components/SupportSelfService/SupportTask';
import SupportTaskSubmenu from '@/components/SupportSelfService/SupportTaskSubmenu';
import settings from '@/data/constants';
import type { Category, SupportTasksProps } from '@/types/support-self-service.types';
import { buildIdFromEndpoint, groupTasksByCategory } from '@/utils/supportTasks';

export const SupportSelfService = () => {
  const [tasks, setTasks] = useState<SupportTasksProps[]>([]);
  const [teams, setTeams] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [highlightedTaskId, setHighlightedTaskId] = useState<string | null>(null);
  const theme = useTheme();

  const fetchTasks = async () => {
    try {
      const res = (await axios({
        method: 'get',
        url: `${settings.url}/support/self-service-tasks`
      })) as { data: { tasks: SupportTasksProps[]; teams: string[] } };
      setTasks(res.data.tasks);
      setTeams(res.data.teams);
    } catch (err) {
      const e = err as unknown as { message: string };
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchTasks();
    })();
  }, []);

  const tasksByCategory = groupTasksByCategory(tasks);
  const categories = Object.keys(tasksByCategory) as Category[];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 6, py: 4, maxWidth: theme.breakpoints.values.md }}>
      <SupportHeaderSticky>
        <SupportHeaderContent tasks={tasks} teams={teams} loading={loading} error={error} />

        {categories.length > 0 && (
          <>
            <SupportTabs categories={categories} tabIndex={tabIndex} setTabIndex={setTabIndex} />
            <SupportTaskSubmenu
              tasks={tasks.filter(({ category }) => category === categories[tabIndex])}
              setHighlightedTaskId={setHighlightedTaskId}
            />
          </>
        )}
      </SupportHeaderSticky>

      {categories.length > 0 &&
        categories.map((category, index) => (
          <SupportTabContent tabIndex={index} selectedTabIndex={tabIndex} key={index}>
            {tasksByCategory[category].map((task) => (
              <SupportTask
                key={task.title}
                highlight={highlightedTaskId === buildIdFromEndpoint(task.endpoint)}
                {...task}
              />
            ))}
          </SupportTabContent>
        ))}
    </Box>
  );
};

export default SupportSelfService;
