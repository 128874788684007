import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useEffect, useRef } from 'react';

import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';
import { GTM_EVENTS } from '@/utils/gtmEvents';

import PreConsultButton from './PreConsultButton';

export default function PaymentFailure() {
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const isTrackingTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (!isTrackingTriggered.current) {
      const trackingData = JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}');

      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_ERROR, {
        id: GoogleAnalyticsEventId.PAYMENT_DETAILS_ERROR,
        status: 'payment_error',
        payment_gateway: 'till',
        error_code: 'TILL_ERROR_FAILURE_PAGE',
        error_message:
          'There was an issue processing the card and you have not been charged. Please refresh page and try again. If the issue persists, please continue to complete the Pre-Consult Form.',
        ...trackingData
      });
      isTrackingTriggered.current = true;
    }
  }, [sendGoogleAnalyticsEvent]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={10}>
      <Alert severity="error" variant="outlined">
        There was an issue processing the card and you have not been charged. Please refresh page and try again. If the
        issue persists, please continue to complete the Pre-Consult Form.
      </Alert>

      <PreConsultButton gtmEventId={GTM_EVENTS.COMPLETE_PRE_CONSULT_FAILURE} />
    </Box>
  );
}
