import { useMutation } from 'urql';

import { graphql } from '../generated';

const REGISTER_PAYMENTS_MUTATION = graphql(`
  mutation RegisterPayment($registerPaymentInput: PaymentInput!) {
    registerPayment(registerPaymentInput: $registerPaymentInput) {
      success
      returnType
      redirectUrl
      redirectType
      errors {
        errorMessage
        errorCode
      }
    }
  }
`);

export function useRegisterPayment() {
  const [result, registerPaymentExecuteMutation] = useMutation(REGISTER_PAYMENTS_MUTATION);

  return {
    registerPaymentResult: result.data?.registerPayment,
    registerPaymentExecuteMutation
  };
}
