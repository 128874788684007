import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { Box, Dialog, styled, Typography } from '@mui/material';
import type { ReactNode } from 'react';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-container': {
    justifyContent: 'unset'
  },
  '& .MuiPaper-root': {
    margin: 'unset',
    background: 'unset',
    boxShadow: 'unset'
  },
  '.MuiBackdrop-root-MuiDialog-backdrop': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  }
});

const DescriptionBox = styled(Box)(({ theme }) => ({
  marginTop: '2.5rem',
  marginLeft: '4rem',
  '& .MuiSvgIcon-root': {
    fontSize: '7rem',
    transform: 'scaleX(-1)',
    color: theme.palette.common.white
  }
}));

interface NewFeatureDialogProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  heading?: string;
  description?: string;
}

export const NewFeatureDialog = ({ children, heading, description, isOpen, onClose }: NewFeatureDialogProps) => {
  return (
    <StyledDialog open={isOpen} onClose={onClose} TransitionProps={{ style: { backgroundColor: 'rgba(0,0,0,0.6)' } }}>
      {children}
      <DescriptionBox onClick={() => onClose()}>
        <ArrowOutwardRoundedIcon />
        {heading && (
          <Typography variant="h3" color="white" fontWeight={700}>
            {heading}
          </Typography>
        )}
        {description && (
          <Typography variant="h4" color="white" fontWeight={700}>
            {description}
          </Typography>
        )}
      </DescriptionBox>
    </StyledDialog>
  );
};
