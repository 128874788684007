import axios from 'axios';

import settings from '../data/constants';

const exportExcel = async (fileTitle, excelData) => {
  await axios({
    url: `${settings.url}/excel/download`,
    method: 'POST',
    data: { title: fileTitle, content: excelData },
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileTitle}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
};

export const exportAll = async (fileTitle, urlRoute, dateRange = null) => {
  await axios({
    url: `${settings.url}/excel/${urlRoute}`,
    method: 'POST',
    data: { title: fileTitle, from_date: dateRange.from_date, to_date: dateRange.to_date },
    responseType: 'blob'
  }).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileTitle}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
};

export default exportExcel;
