import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, styled, Typography } from '@mui/material';

import { StyledButton } from '../../shared/components/styled-components';

/**
 * No Availability Overlay component
 *
 * @remarks
 * A small modal which appears over the DatePicker when there is no availability for any of the days in the month
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'NoAvailabilityOverlay'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/calendars/DatePicker.tsx
 *
 * @param month - The calendar month displayed in the Datepicker
 * @param nextMonthButton - A callback which moves the DatePicker month ahead by one month
 *
 * @returns JSXElement
 *
 */

const StyledNoAvailabilityContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '65%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.common.white,
  padding: '10px 20px 20px',
  width: '300px',
  boxShadow: '0 0 20px 6px rgba(0, 0, 0, .2)'
}));

const NoAvailabilityOverlay = ({ month, nextMonthButton }: { month: string | null; nextMonthButton: () => void }) => {
  return (
    <StyledNoAvailabilityContainer>
      <Typography variant="h6" mb={3} textAlign={'center'}>{`No availability in ${month}`}</Typography>
      <StyledButton
        onClick={nextMonthButton}
        color="primary"
        endIcon={<KeyboardArrowRightIcon />}
        data-testid="view_next_month"
      >
        View next month
      </StyledButton>
    </StyledNoAvailabilityContainer>
  );
};

export default NoAvailabilityOverlay;
