import type { DoctorModel } from '@/types/doctor.types';

import type { ISODateString } from './common';
import type { NurseModel } from './nurse.types';

/**
 * The consultation status object. FE shape looks like this, but it's likely
 * that BE's ConsultationStatus model contains more info.
 */
export type ConsultationStatusType = {
  // TODO - find out which enums
  name: string;
};

export type ConsultationSubStatusType = any;

export interface ConsultationType {
  duration_mins: number;
  type: string;
}

/**
 * Represents the consultation status. This is created based on console logging out
 * `showConsultation` prop in `ConsultationTab`.
 *
 * "Looks" similar to `Consultation` model and `ConsultationAttribute` in `pms-service`
 *
 * TODO - improve the props appropriately when we have more confidence in what each prop should do exactly
 */
export interface ConsultationModel {
  ConsultationStatus: ConsultationStatusType;
  ConsultationSubStatus: ConsultationSubStatusType | null;
  ConsultationType: ConsultationType;
  Doctor: DoctorModel | null;
  Nurse: NurseModel | null;
  calcom_consultation_uuid: string | null;
  calendly_consultation_uuid: string | null;
  calendly_edit_uuid: string | null;
  consult_type: string;
  consultation_code: string;
  doctor_id: number | null;
  end_time: ISODateString | null;
  /**
   * Consultation ID
   */
  id: number;
  /**
   * Consult notes
   */
  notes: string;
  paid_date: ISODateString | null;
  // TODO - consider finding out which one we want to go for...
  paymentStatus?: {
    preAuthTransactionExists: boolean;
    debitTransactionExists: boolean;
    paymentStatus: string;
  };
  payment_status: string;
  payment_queued: boolean;
  start_time: ISODateString | null;
  status_id: number;
  sub_status_id: number | null;
  xero_consultation_code: string;
  zoom_url: string | null;
  payment_id?: string | null;
}

export enum ConsultationTableColumnHeaders {
  ConsultID = 'Consult. ID',
  AppointmentTime = 'Appointment Time',
  Practitioner = 'Practitioner',
  PatientName = 'Patient Name',
  PatientPhone = 'Patient Phone',
  PatientEmail = 'Patient Email',
  PatientId = 'Patient ID',
  PatientDob = 'D.O.B.',
  ConsultationType = 'Consult. Type',
  ConsultationStatus = 'Consult. Status',
  PaymentStatus = 'Payment status',
  PaymentType = 'Payment type',
  ViewLink = 'View',
  RescheduleLink = 'Reschedule',
  CancelLink = 'Cancel'
}

export type DoctorConsultation = {
  id: number;
  consultation_code: string;
  patient_name: string;
  doctor_name: string;
  doctor_calendly_slug: string | null;
  patient_email: string;
  patient_phone: string;
  start_time: ISODateString;
  end_time: ISODateString;
  duration: string;
  zoom_link: string | null;
  patient_code: string;
  patient_id: number;
  notes: string | null;
  consult_type: string;
  payment_status: string | null;
  payment_queued: boolean;
  paid_date: ISODateString | null;
  calendly_uuid: string | null;
  calendly_edit_uuid: string | null;
  calcom_consultation_uuid: string;
  patient_dob: string | null;
  doctor_id: number;
  Prescriptions: string[];
  status: string;
  discount_price: number | null;
  total_price: number | null;
  has_red_flag: boolean | null;
  red_flag_comment: string | null;
  isPatientTransferred: boolean;
  calcom_timezone: string;
  payment_id: string | null;
};

export type NurseConsultation = {
  id: number;
  consultation_code: string;
  patient_name: string;
  nurse_id: string;
  nurse_name: string;
  nurse_calendly_slug: string;
  patient_email: string;
  patient_phone: string;
  start_time: string;
  end_time: string;
  duration: string;
  zoom_link: string | null;
  patient_code: string;
  patient_id: number;
  calendly_edit_uuid: string | null;
  notes: string | null;
  consult_type: string;
  payment_status: string | null;
  paid_date: null;
  consultation_type: string;
  calendly_uuid: null;
  calcom_consultation_uuid: string;
  patient_dob: null;
  status: string;
  has_red_flag: null;
  red_flag_comment: null;
  timezone: string | null;
};
