import type { EventClickArg, EventDropArg } from '@fullcalendar/core';
import type { EventImpl } from '@fullcalendar/core/internal';
import type FullCalendar from '@fullcalendar/react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import type { RefObject } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useFormatConsultsAsCalendarEvents } from '@/components/NurseFlexCalendar/hooks/useFormatConsultsAsCalendarEvents';
import { useGetNurseFlexCalendar } from '@/components/NurseFlexCalendar/hooks/useGetNurseFlexCalendar';
import type {
  NurseFlexCalendarEvent,
  SelectableNurse,
  SelectedEvent
} from '@/components/NurseFlexCalendar/NurseFlexCalendar.types';
import { getMatchingNurses } from '@/components/NurseFlexCalendar/NurseFlexCalendar.utils';
import settings from '@/data/constants';
import { QueryKey } from '@/data/query';

export const LOCAL_TIMEZONE = 'Australia/Brisbane';
const FLEX_CALENDAR_URL = `${settings.url}/nurse/flex-calendar`;

type UseNurseFlexCalendarParams = {
  nurseListSelectOptions: SelectableNurse[];
  nurseListLoading: boolean;
  calendarRef: RefObject<FullCalendar>;
};

const useNurseFlexCalendar = ({
  nurseListSelectOptions,
  nurseListLoading,
  calendarRef
}: UseNurseFlexCalendarParams) => {
  const [leaveNurses, setLeaveNurses] = useState<SelectableNurse[]>([]);
  const [flexNurses, setFlexNurses] = useState<SelectableNurse[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<{ type: string; message: string } | boolean>(false);
  const [events, setEvents] = useState<NurseFlexCalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | undefined>();
  const [openEventDrawer, setOpenEventDrawer] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: nurseFlexCalendarData,
    isLoading: isLoadingCalendarData,
    isRefetching: isRefetchingCalendarData
  } = useGetNurseFlexCalendar();

  // Populate the nurse multiselect boxes with nurses from the flex and leave saved state
  useEffect(() => {
    if (!nurseListLoading) {
      const savedFlexNurses = getMatchingNurses(nurseFlexCalendarData?.nurse_flex_ids, nurseListSelectOptions);
      const savedLeaveNurses = getMatchingNurses(nurseFlexCalendarData?.nurse_leave_ids, nurseListSelectOptions);

      setFlexNurses(savedFlexNurses as SelectableNurse[]);
      setLeaveNurses(savedLeaveNurses as SelectableNurse[]);
    }
  }, [
    nurseListSelectOptions,
    nurseListLoading,
    nurseFlexCalendarData?.nurse_flex_ids,
    nurseFlexCalendarData?.nurse_leave_ids
  ]);

  const selectedNurseFlexIds = useMemo(() => {
    return flexNurses?.map((nurse) => nurse?.id) || [];
  }, [flexNurses]);

  const selectedNurseLeaveIds = useMemo(() => {
    return leaveNurses?.map((nurse) => nurse?.id) || [];
  }, [leaveNurses]);

  const allSelectedNurseIds = useMemo(() => {
    return [...selectedNurseFlexIds, ...selectedNurseLeaveIds];
  }, [selectedNurseFlexIds, selectedNurseLeaveIds]);

  const isEventAssignedToFloatingNurse = (eventResourceId: string) => {
    return selectedNurseFlexIds.includes(eventResourceId || '');
  };

  const isEventExistingWithFloatingNurse = (
    event: EventImpl,
    nurseResourceId: string,
    isAssignedToFloatingNurse: boolean
  ) => {
    const consultsOriginalNurse = event.extendedProps.nurse_id;
    const consultsCurrentAssignedNurse = parseInt(nurseResourceId);
    const isNotReassignedToNewNurse = consultsOriginalNurse === consultsCurrentAssignedNurse;

    // If the consult exists with a floating nurse AND it's not been reassigned to a new nurse
    // then we can assume that the consult is an existing consult with the floating nurse
    return isAssignedToFloatingNurse && isNotReassignedToNewNurse;
  };

  const { data: allNurseConsults, refetch: refetchAllNurseConsults } = useFormatConsultsAsCalendarEvents(
    allSelectedNurseIds,
    nurseFlexCalendarData?.consultations
  );

  // Store calendar events in state so we can update them with callback functions if needed (see handleEventDrop, handleResetCalendar)
  useEffect(() => {
    setEvents(allNurseConsults as NurseFlexCalendarEvent[]);
  }, [allNurseConsults]);

  const handleFlexNurseSelected = (selectedNurses: SelectableNurse[]): void => {
    setFlexNurses(selectedNurses);
  };

  const handleLeaveNurseSelected = (selectedNurses: SelectableNurse[]): void => {
    setLeaveNurses(selectedNurses);
  };

  const handleEventClicked = (clickArg: EventClickArg) => {
    const event = clickArg.event;
    const selectedEventProps = clickArg.event._def;
    const resources = clickArg.event.getResources();
    const resourceId = resources.map((resource) => resource.id)[0];
    const nurseResourceId = resources.map((resource) => resource.id)[0];
    const isAssignedToFloatingNurse = isEventAssignedToFloatingNurse(resourceId);
    const isExistingFloatingNurseEvent = isEventExistingWithFloatingNurse(
      event,
      nurseResourceId,
      isAssignedToFloatingNurse
    );

    setSelectedEvent({ ...selectedEventProps, isAssignedToFloatingNurse, isExistingFloatingNurseEvent });
    setOpenEventDrawer(true);
  };

  const handleEventDrop = (droppedEventArgs: EventDropArg) => {
    const eventDetails = droppedEventArgs.oldEvent._def.extendedProps;
    const eventID = eventDetails.id;
    const newNurseID = droppedEventArgs?.newResource?._resource.id;

    const updatedEvents = events?.map((event) => {
      return {
        ...event,
        resourceId: eventID === event?.extendedProps?.id ? newNurseID : event.resourceId
      };
    });

    setEvents(updatedEvents as NurseFlexCalendarEvent[]);
  };

  const handleSaveCalendar = async () => {
    const apiEvents = calendarRef?.current?.getApi().getEvents();

    const consultationsEventMapping = apiEvents
      ?.map((event) => {
        const resources = event.getResources();
        const resourceIds = resources.map((resource) => resource?.id || null).filter(Boolean);
        const resourceId = resourceIds[0];

        if (resourceId) {
          return {
            consultation_id: event.extendedProps.id,
            nurse_id: parseInt(resourceId)
          };
        }
      })
      .filter(Boolean);

    const payload = {
      consultations: consultationsEventMapping,
      nurse_flex_ids: selectedNurseFlexIds,
      nurse_leave_ids: selectedNurseLeaveIds
    };

    const response = await axios.put(FLEX_CALENDAR_URL, payload);

    if (response.status === 201) {
      setShowSnackbar({ type: 'success', message: 'Calendar Saved' });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GetNurseFlexCalendar]
      });
    } else {
      setShowSnackbar({ type: 'error', message: 'An error has occurred while saving the calendar' });
    }
  };

  const handleToggleDrawer = (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
    const isKeyDown = e.type === 'keydown';
    const isKeyTab = (e as React.KeyboardEvent).key === 'Tab';
    const isKeyShift = (e as React.KeyboardEvent).key === 'Shift';

    if (isKeyDown && (isKeyTab || isKeyShift)) {
      return;
    }

    setOpenEventDrawer(open);
  };

  const handleResetCalendar = () => {
    calendarRef?.current?.getApi().removeAllEvents();
    setEvents([]);
    setFlexNurses([]);
    setLeaveNurses([]);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return {
    handleSaveCalendar,
    handleFlexNurseSelected,
    handleLeaveNurseSelected,
    handleEventDrop,
    handleEventClicked,
    handleToggleDrawer,
    handleResetCalendar,
    handleSnackbarClose,
    isEventAssignedToFloatingNurse,
    isEventExistingWithFloatingNurse,
    refetchAllNurseConsults,
    isLoadingCalendarData,
    isRefetchingCalendarData,
    showSnackbar,
    selectedEvent,
    openEventDrawer,
    leaveNurses,
    flexNurses,
    events
  };
};

export default useNurseFlexCalendar;
