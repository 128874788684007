import type { AlertModalProps } from '@/shared-ui';
import { AlertSeverity } from '@/shared-ui';

export const DEFAULT_CANCEL_ALERT_MODAL_PROPS: Omit<AlertModalProps, 'onClose' | 'onConfirm' | 'isOpen'> = {
  title: 'Cancel Prescription',
  body: 'Are you sure you want to cancel these prescriptions:',
  cancelText: 'Not now',
  confirmText: 'Cancel Prescription',
  severity: AlertSeverity.Warning,
  showIcon: true
};
