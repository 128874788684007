import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useRef, useState } from 'react';

import type { ConsultationFields } from '@/components/PatientPageV2/ConsultationTab.types';
import type { PmsIhiComparisonResult } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/IhiVerifyAndUpdateModals.utils';
import {
  compareValuesPMSVersusIHI,
  getIHINumberFromUrl
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/IhiVerifyAndUpdateModals.utils';
import {
  IhiModalFieldRadioGroup,
  StyledIhiModalField
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalFields/IhiModalFieldRadioGroup';
import type { RadioSelectionState } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ihiVerifyAndUpdateModals.types';
import { ModalIhiFieldNames } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ihiVerifyAndUpdateModals.types';
import type {
  HandleUpdateIhiDetailsArgs,
  PatientIHISavePayload,
  PatientProfileUpdatePayload,
  PatientVerifyIhiResponseData
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/hooks/usePatientIhi';
import { LUXON_FORMAT_DAY_MONTH_YEAR, ZONE_UTC } from '@/utils/luxon';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Paper, Stack, Typography, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import { brown } from '@mui/material/colors';
import { DateTime } from 'luxon';

export type ModalSelectMultipleIhiFieldsProps = {
  patientDetailsInPMS: ConsultationFields;
  patientVerifyIhiResponseData: PatientVerifyIhiResponseData;
  handleUpdateIhiDetails: (args: HandleUpdateIhiDetailsArgs) => void;
  handleModalClose: () => void;
};

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll'
});

const StyledModalContents = styled(Paper)({
  padding: 15,
  width: 800,
  outline: 'none'
});

const formatPatientDobIhiModals = (patientDobISO: string) => {
  return patientDobISO ? DateTime.fromISO(patientDobISO, { zone: ZONE_UTC }).toFormat(LUXON_FORMAT_DAY_MONTH_YEAR) : '';
};

/**
 * ModalSelectMultipleIhiFields
 *
 * This component renders a modal that allows users to select multiple fields from the IHI (Individual Healthcare Identifier)
 * data to update the patient's profile in the PMS. The fields presented are those that have
 * discrepancies between the PMS and IHI data.
 *
 * @param {ModalSelectMultipleIhiFieldsProps} props - The properties object.
 * @param {ConsultationFields} props.patientDetailsInPMS - The patient's details stored in the PMS.
 * @param {PatientVerifyIhiResponseData} props.patientVerifyIhiResponseData - The response data from verifying the patient's IHI.
 * @param {(args: HandleUpdateIhiDetailsArgs) => void} props.handleUpdateIhiDetails - Function to handle the updating of IHI details.
 * @param {() => void} props.handleModalClose - Function to handle closing the modal.
 *
 * @returns {JSX.Element} The rendered component.
 */

export const ModalSelectMultipleIhiFields = (props: ModalSelectMultipleIhiFieldsProps) => {
  const [radioSelectionState, setRadioSelectionState] = useState<RadioSelectionState | undefined>();
  const { patientDetailsInPMS, patientVerifyIhiResponseData, handleUpdateIhiDetails, handleModalClose } = props;
  const [ihiFieldsWithDifferences, setIhiFieldsWithDifferences] = useState({} as PmsIhiComparisonResult);
  const handleUpdateSubmitCalled = useRef(false);

  const patientInIHI = patientVerifyIhiResponseData.patient;

  useEffect(() => {
    const differences = compareValuesPMSVersusIHI({
      patientInPMS: patientDetailsInPMS,
      patientInIHI: patientVerifyIhiResponseData.patient
    });
    setIhiFieldsWithDifferences(differences);

    const derivedRadioButtonState = Object.fromEntries(
      Object.entries(differences)
        .filter(([_key, value]) => value)
        .map(([key]) => [key, true])
    );
    setRadioSelectionState(derivedRadioButtonState);

    const keys = Object.keys(derivedRadioButtonState);
    // If there are no differences, or 1 difference only that's ihiNumber and current ihi is null
    // directly call handleUpdateSubmit
    if (
      !handleUpdateSubmitCalled.current &&
      (keys.length === 0 || (keys.length === 1 && keys.includes('ihiNumber') && !patientDetailsInPMS.ihi_number))
    ) {
      handleUpdateSubmitCalled.current = true;
      handleUpdateSubmit();
    }
  }, [patientDetailsInPMS, patientVerifyIhiResponseData]);

  const handleUpdateSubmit = () => {
    const updateProfile: PatientProfileUpdatePayload = {
      ...(radioSelectionState?.firstName && patientInIHI?.firstName !== null && { firstName: patientInIHI?.firstName }),
      ...(radioSelectionState?.lastName && patientInIHI?.lastName !== null && { lastName: patientInIHI?.lastName }),
      ...(radioSelectionState?.medicareNumber &&
        patientInIHI?.medicareNumber !== null && { medicareNumber: patientInIHI?.medicareNumber }),
      ...(radioSelectionState?.medicareRefNumber &&
        patientInIHI?.medicareRefNumber !== null && { medicareRefNumber: Number(patientInIHI?.medicareRefNumber) }),
      ...(radioSelectionState?.dob &&
        patientInIHI?.doB !== null && { dob: patientInIHI?.doB ? new Date(patientInIHI.doB) : undefined }),
      ...(radioSelectionState?.gender && patientInIHI?.sex !== null && { gender: patientInIHI?.sex })
    };

    const updatedPatientDetailsInIHI: PatientIHISavePayload = {
      requestMessageID: patientVerifyIhiResponseData.requestMessageID ?? null,
      responseMessageID: patientVerifyIhiResponseData.responseMessageID ?? null,
      errorCode: patientVerifyIhiResponseData.errorCode,
      ihiStatus: patientVerifyIhiResponseData.ihiStatus ? patientVerifyIhiResponseData.ihiStatus : null,
      ihiRecordStatus: patientVerifyIhiResponseData.ihiRecordStatus
        ? patientVerifyIhiResponseData.ihiRecordStatus
        : null,
      patient: {
        ihiNumber: patientVerifyIhiResponseData.patient?.ihiNumber as string
      }
    };

    handleUpdateIhiDetails({
      patientIHISavePayload: updatedPatientDetailsInIHI,
      updateProfile
    });

    if (props.handleModalClose) {
      props.handleModalClose();
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const booleanValue = JSON.parse(value);

    setRadioSelectionState((prevState) => ({
      ...prevState,
      [name]: booleanValue
    }));
  };

  // Don't render the ui if no mismatch
  if (Object.keys(radioSelectionState || {}).length === 0) {
    return null;
  }

  return (
    <StyledModal open={true} onClose={props.handleModalClose} closeAfterTransition>
      <StyledModalContents>
        <Box padding={'1rem'}>
          <Stack direction={'row'} alignItems={'baseline'} paddingBottom={5}>
            <Box paddingRight={4}>
              <WarningAmberIcon color={'warning'} />
            </Box>
            <Typography variant={'h4'} color={brown[700]}>
              Selection Required
            </Typography>
          </Stack>
          <Box paddingBottom={4}>
            <Typography variant={'body1'}>
              Please choose the patient information you wish to align with the HI Service.
            </Typography>
          </Box>
          {ihiFieldsWithDifferences?.firstName && (
            <IhiModalFieldRadioGroup
              labelPartial={ModalIhiFieldNames.FirstName}
              fieldName={'firstName'}
              pmsFieldValue={patientDetailsInPMS.FirstName}
              ihiFieldValue={patientInIHI?.firstName}
              radioSelectionState={radioSelectionState || {}}
              handleRadioChange={handleRadioChange}
            />
          )}
          {ihiFieldsWithDifferences?.lastName && (
            <IhiModalFieldRadioGroup
              labelPartial={ModalIhiFieldNames.LastName}
              fieldName={'lastName'}
              pmsFieldValue={patientDetailsInPMS.LastName}
              ihiFieldValue={patientInIHI?.lastName}
              radioSelectionState={radioSelectionState || {}}
              handleRadioChange={handleRadioChange}
            />
          )}
          {ihiFieldsWithDifferences?.medicareNumber && (
            <IhiModalFieldRadioGroup
              labelPartial={ModalIhiFieldNames.MedicareNumber}
              fieldName={'medicareNumber'}
              pmsFieldValue={patientDetailsInPMS.medicareNumber}
              ihiFieldValue={patientInIHI?.medicareNumber}
              radioSelectionState={radioSelectionState || {}}
              handleRadioChange={handleRadioChange}
            />
          )}
          {ihiFieldsWithDifferences?.medicareRefNumber && (
            <IhiModalFieldRadioGroup
              labelPartial={ModalIhiFieldNames.MedicareReferenceNumber}
              fieldName={'medicareRefNumber'}
              pmsFieldValue={patientDetailsInPMS.medicare_ref_number}
              ihiFieldValue={patientInIHI?.medicareRefNumber}
              radioSelectionState={radioSelectionState || {}}
              handleRadioChange={handleRadioChange}
            />
          )}
          {ihiFieldsWithDifferences?.gender && (
            <IhiModalFieldRadioGroup
              labelPartial={ModalIhiFieldNames.Gender}
              fieldName={'gender'}
              pmsFieldValue={patientDetailsInPMS.gender}
              ihiFieldValue={patientInIHI?.sex}
              radioSelectionState={radioSelectionState || {}}
              handleRadioChange={handleRadioChange}
            />
          )}
          {ihiFieldsWithDifferences?.dob && (
            <IhiModalFieldRadioGroup
              labelPartial={ModalIhiFieldNames.DateOfBirth}
              fieldName={'dob'}
              pmsFieldValue={formatPatientDobIhiModals(patientDetailsInPMS.DoB)}
              ihiFieldValue={formatPatientDobIhiModals(patientInIHI?.doB as string)}
              radioSelectionState={radioSelectionState || {}}
              handleRadioChange={handleRadioChange}
            />
          )}
          {ihiFieldsWithDifferences?.ihiNumber && (
            <Stack direction={'row'} spacing={6} marginBottom={6} marginTop={3} position={'relative'}>
              <StyledIhiModalField
                label={`${ModalIhiFieldNames.IHINumber} in Patient Info (PMS)`}
                value={patientDetailsInPMS.ihi_number}
              />
              <StyledIhiModalField
                label={`${ModalIhiFieldNames.IHINumber} in HI Service`}
                value={getIHINumberFromUrl(patientInIHI?.ihiNumber)}
              />
              <Tooltip
                title={
                  <Stack>
                    <Typography>Why is this radio button disabled?</Typography>
                    <Typography variant={'caption'}>
                      Because the IHI number must be updated by default when IHI related information is updated
                    </Typography>
                  </Stack>
                }
                placement={'top'}
              >
                <Box position={'absolute'} right={13} top={7}>
                  <Radio checked disabled />
                </Box>
              </Tooltip>
            </Stack>
          )}
          <Alert severity={'warning'}>
            <Typography>
              <strong>Note:</strong> Please ask the patient to verify the correct information before you choose the
              patient’s information you wish to align with the HI Service.
            </Typography>
          </Alert>
          <Stack direction={'row'} justifyContent={'end'} paddingTop={6}>
            <Box marginRight={5}>
              <Button onClick={handleModalClose} color="secondary" variant="text">
                <Typography>Cancel</Typography>
              </Button>
            </Box>
            <Button onClick={handleUpdateSubmit} variant="contained" color="secondary">
              <Typography>Update</Typography>
            </Button>
          </Stack>
        </Box>
      </StyledModalContents>
    </StyledModal>
  );
};
