import { SearchTextField, Table } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import iziToast from 'izitoast';
import { useState } from 'react';

import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { FF_ENABLE_ALTERNATE_PENDING_CANCELLATION } from '@/constants/featureFlags';
import { getAuthData } from '@/data/service/authService';
import { useFeatureFlags } from '@/hooks';
import { useGetNurseCalendarConsultations } from '@/hooks/consult/useGetNurseCalendarConsultations';
import type { ISODateString, NurseConsultation } from '@/types';
import { ConsultationTableColumnHeaders } from '@/types';

import {
  createCancelLink,
  createRescheduleLink,
  createViewLink,
  formatPatientDob,
  formatPatientName,
  formatStartTime
} from './components/SharedValuesCalendarColumns';

const StyledBox = styled(Box)({
  marginTop: 10,
  width: '100%'
});

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

type ConsultationTabOverviewTableProps = {
  getQueryParams: {
    selectedNurseIds: (string | number)[];
    dateIsoString: ISODateString;
  };
};

/**
 * NurseCalendarTable component
 *
 * @remarks
 * Renders a table of consultations for nurses on the Nurse Calendar page
 *
 * @typedef {object} GetQueryParams
 * @property {string} selectedNurseIds - An array of nurse IDs
 * @property {string} date - The date of the consultations
 *
 * @param {GetQueryParams} getQueryParams - Contains 'selectedNurseIds' and 'date' used as params for the GET query
 *
 * @returns {JSX.Element} JSXElement
 */

export const NurseCalendarTable = ({ getQueryParams }: ConsultationTabOverviewTableProps): JSX.Element => {
  const { flags } = useFeatureFlags();
  const ffAlterativePendingFlow = flags[FF_ENABLE_ALTERNATE_PENDING_CANCELLATION];
  const [searchFilterText, setSearchFilterText] = useState('');
  const { isDoc } = getAuthData();
  const { createColumnDefinition } = useTableUtils();

  const {
    nurseCalendarConsultations,
    isError: isErrorNurseConsultations,
    isLoading: isLoadingNurseConsultations,
    refetch: refetchGetNursesConsultations
  } = useGetNurseCalendarConsultations({
    ...getQueryParams
  });

  const resultsNotFoundMessage = searchFilterText.length
    ? 'No matching consultations found for that search'
    : 'No consultations found';

  if (isErrorNurseConsultations) {
    iziToast.error({
      title: 'Error!',
      message: 'There has been an error loading the Nurse Consultations',
      position: 'topRight'
    });
  }

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  const formattedNurseConsultations = nurseCalendarConsultations?.map((consultation: NurseConsultation) => {
    return {
      id: consultation.consultation_code,
      appointment_time: formatStartTime(consultation),
      practitioner: consultation.nurse_name,
      patient_name: formatPatientName(consultation),
      patient_phone: consultation.patient_phone,
      patient_email: consultation.patient_email,
      patient_code: consultation.patient_code,
      patient_dob: formatPatientDob(consultation),
      consultation_type: `${consultation.consultation_type} - Nurse`,
      consultation_status: consultation.status,
      view: createViewLink(consultation),
      reschedule: createRescheduleLink(consultation, refetchGetNursesConsultations),
      cancel: createCancelLink(consultation, ffAlterativePendingFlow, refetchGetNursesConsultations)
    };
  });

  const columns = [
    createColumnDefinition('view', ConsultationTableColumnHeaders.ViewLink),
    createColumnDefinition('id', ConsultationTableColumnHeaders.ConsultID),
    createColumnDefinition('appointment_time', ConsultationTableColumnHeaders.AppointmentTime),
    createColumnDefinition('practitioner', ConsultationTableColumnHeaders.Practitioner),
    createColumnDefinition('patient_name', ConsultationTableColumnHeaders.PatientName),
    createColumnDefinition('patient_phone', ConsultationTableColumnHeaders.PatientPhone),
    createColumnDefinition('patient_email', ConsultationTableColumnHeaders.PatientEmail),
    createColumnDefinition('patient_code', ConsultationTableColumnHeaders.PatientId),
    createColumnDefinition('patient_dob', ConsultationTableColumnHeaders.PatientDob),
    createColumnDefinition('consultation_type', ConsultationTableColumnHeaders.ConsultationType),
    createColumnDefinition('consultation_status', ConsultationTableColumnHeaders.ConsultationStatus),
    createColumnDefinition('reschedule', ConsultationTableColumnHeaders.RescheduleLink),
    createColumnDefinition('cancel', ConsultationTableColumnHeaders.CancelLink)
  ];

  return (
    <StyledBox>
      <SearchTextField
        handleChange={handleSearchOnChange}
        handleClear={handleClearSearchFilter}
        labelText="Search"
        enableClear={true}
      />
      <StyledTable
        data={formattedNurseConsultations || []}
        columns={columns}
        isLoading={isLoadingNurseConsultations}
        pageSize={20}
        globalFilter={searchFilterText}
        noDataFoundMessage={resultsNotFoundMessage}
        columnVisibility={{
          reschedule: !isDoc, // 'Reschedule' is hidden to doctors
          cancel: !isDoc // 'Cancel' is hidden to doctors
        }}
        showPagination
        pageSizeOptions={[5, 10, 20, 50]}
      />
    </StyledBox>
  );
};
