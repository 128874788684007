import { useCallback, useEffect, useMemo, useState } from 'react';

import type { FilteredProduct, FilteredProductByFormulation, FilteredProductByType } from '@/api';
import type { Filters } from '@/shared-ui/ProductFilter/ProductFilter';
import { useAppStore } from '@/state-management';

import { usePrescriptionItemFeatureFlags } from './usePrescriptionItemFeatureFlags';

export type UseFilteredMedicationOptionsProps = {
  /**
   * Initial set of filters to start with. Example of this use, is when there are
   * pre-selected filters from the Prescription context
   */
  initialFilters?: Filters;
  /**
   * The filtered product payload to be used to calculate the medication options from
   */
  medicationDataSource: FilteredProductByFormulation;
  /**
   * By default `filteredMedicationOptions` only the top recommended options are displayed. Setting this
   * true will display all available options
   *
   */
  displayAllOptions?: boolean;
  /**
   * Currently prescribed medication. This is used to ensure that the `filteredMedicationOptions` will
   * not exclude the medication from selection
   */
  prescribedMedicationId?: number;
};

/**
 * Handles the filter change logic, calculates available options.
 */
export const useFilteredMedicationOptions = ({
  initialFilters,
  medicationDataSource,
  displayAllOptions,
  prescribedMedicationId
}: UseFilteredMedicationOptionsProps) => {
  const { medicationPadItems: alreadyPrescribedMedicationPadItems } =
    useAppStore.use.prescriptionAssistant().prescriptionPad;
  const { recommendedMedicationsCount } = usePrescriptionItemFeatureFlags();
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [medicationOptions, setMedicationOptions] = useState<FilteredProduct[]>([]);
  const [isReady, setIsReady] = useState(false);

  /**
   * main function to calculated the medication options based on the filters.
   */
  const calculateMedicationOptionsFromFilters = useCallback(
    (changedFilters: Filters) => {
      setIsReady(false);
      setSelectedFilters(changedFilters);

      // find available options based on the selected filters
      const optionsByFilters = Array.from(changedFilters.values()).reduce<
        FilteredProductByFormulation | FilteredProduct[] | FilteredProductByType
      >((filteredProductOptions, filterName) => {
        if (Array.isArray(filteredProductOptions)) {
          return filteredProductOptions;
        }
        return filteredProductOptions[filterName];
      }, medicationDataSource);

      // if it doesn't yield any medication options (not an array), set to empty
      if (!Array.isArray(optionsByFilters)) {
        if (medicationOptions.length > 0) {
          setMedicationOptions([]);
        }
        setIsReady(true);
        return;
      }

      setMedicationOptions(optionsByFilters);
      setIsReady(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [medicationDataSource, medicationOptions.length]
  );

  // evaluate options on init.
  useEffect(() => {
    if (!initialFilters) {
      return;
    }
    calculateMedicationOptionsFromFilters(initialFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters]);

  /**
   * Filter out prescribed medications from the available medication options,
   * so that user won't be able to select the same options in a prescription
   */
  const filteredMedicationOptions = useMemo(() => {
    if (medicationOptions.length === 0) {
      return [];
    }
    const alreadyPrescribedMedicationIds = alreadyPrescribedMedicationPadItems.map((item) =>
      Number(item?.medicationPadItem?.productId)
    );
    let displayedMedications = [...medicationOptions];
    if (!displayAllOptions) {
      displayedMedications = medicationOptions.slice(0, recommendedMedicationsCount);
    }
    // available options should exclude other prescribed medications (but include the ones prescribed in this prescription item)
    return displayedMedications.filter((medication) => {
      return medication.id === prescribedMedicationId || !alreadyPrescribedMedicationIds.includes(medication.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyPrescribedMedicationPadItems, prescribedMedicationId, medicationOptions, displayAllOptions]);

  // memoising the result to ensure no unnecessary re-renders when used as dependency
  const memoisedState = useMemo(() => {
    return {
      selectedFilters,
      /**
       * Available medications to select from based on the selected filters
       */
      medicationOptions
    };
  }, [selectedFilters, medicationOptions]);

  return {
    ...memoisedState,
    /**
     * All available medication options excluding the ones that have already been prescribed,
     * so that user won't be able to select the same options in a prescription
     */
    filteredMedicationOptions,
    calculateMedicationOptionsFromFilters,
    /**
     * This is true when the filter calculation is done,
     * and medication options have been calculated.
     */
    isReady
  };
};
