import { Box, Tooltip } from '@mui/material';
import type { RowData } from '@tanstack/react-table';

export const renderHeader = (headerName: string) => {
  return (
    <Tooltip title={headerName}>
      <Box fontWeight="bold">{headerName}</Box>
    </Tooltip>
  );
};

export const dateColumnAccessor =
  <TData extends RowData = any>(columnName: keyof TData) =>
  (row: TData) => {
    const dateString = row?.[columnName];
    if (!dateString || typeof dateString !== 'string') {
      return null;
    }

    return new Date(dateString);
  };
