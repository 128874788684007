import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import { Box, Card, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import useConsultationPrice from '@/hooks/consult/useConsultationPrice';
import type { CalendarData } from '@/hooks/rest/types';
import type { DiscountType } from '@/hooks/rest/useDiscountCode';
import { DiscountCodeStatus } from '@/hooks/rest/useDiscountCode';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import theme from '@/theme';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';

import { formatBookingDataToSha256HashedValues } from '@/utils/ga-hashing';
import PaymentAccordion from './PaymentAccordion';

export type Props = {
  data: CalendarData;
  discount: DiscountType | null;
  isPaymentTrackingTriggered: {
    current: boolean;
  };
};

const StyledAppointmentHeading = styled(Box)({
  padding: 8,
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    padding: 20,
    display: 'block'
  }
});

const StyledAppointmentContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 12,
  paddingBottom: 12,
  backgroundColor: theme.palette.pillCard.dark
});

const AppointmentCard = styled(Card)({
  padding: 0,
  borderRadius: '20px',
  backgroundColor: theme.palette.pillCard.main
});

const StyledHeadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'start'
});

const StyledHeading = styled((props) => <Typography {...props} component="h3" />)({
  fontWeight: 600,
  fontSize: '1.3rem'
}) as typeof Typography;

const StyledHeadingSmall = styled((props) => <Typography {...props} component="h3" />)({
  fontWeight: 600,
  fontSize: '1.1rem'
}) as typeof Typography;

const Currency = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD'
});

export const DiscountBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: green[100],
  color: theme.palette.success.main,
  padding: '1rem 1.2rem'
});

export default function AppointmentSummary({ data, discount, isPaymentTrackingTriggered }: Props) {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const { consultationPrice, formattedConsultationPrice } = useConsultationPrice();

  const { assignedTo, startTime, promocode, isEwayPayment, email, fullName } = data;
  const enableEwayPayments = !!isEwayPayment;
  const isDiscountCodeValid = discount?.status == DiscountCodeStatus.valid;

  const nurseFirstNameOnly = (assignedTo || '').split(' ')[0];

  const discountAmount = isDiscountCodeValid ? discount?.discountAmount : 0;

  const discountPercentageAmount = isDiscountCodeValid
    ? (discount?.discountPercent * Number(consultationPrice)) / 100
    : 0;

  const totalAmount = discountAmount
    ? Number(consultationPrice) - discountAmount
    : Number(consultationPrice) - discountPercentageAmount;

  const discountText = isDiscountCodeValid
    ? `${discount.discountCode} ${discount?.discountPercent ? `(%${discount?.discountPercent})` : ''}`
    : '';

  const formattedDiscountAmount = isDiscountCodeValid
    ? `-${Currency.format(discount?.discountAmount || discountPercentageAmount)}`
    : '';
  const formattedTotalAmount = Currency.format(totalAmount);

  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  useEffect(() => {
    // This var is used because 'discountCodeLoading' from 'useDiscountCode' hook does not give
    // a reliable boolean of when the discount code data is actually loaded. It checks IF there
    // is a 'promocode' in the url && the discount code has loaded then promocode logic has loaded
    const promocodeDiscountLoaded = promocode && discount?.discountCode;
    // This prevents the tracking data from firing unless all the promocode
    // logic has completed (i.e. external call for validation of promocode)
    // So IF the promocode logic has loaded OR there is NOT a promocode then fire tracking
    const promocodeLogicIsLoaded = promocodeDiscountLoaded || !promocode;

    if (!isPaymentTrackingTriggered.current && promocodeLogicIsLoaded) {
      const trackingData = {
        payment_value: totalAmount.toString(),
        consultant_name: assignedTo,
        payment_gateway: isEwayPayment ? 'eway' : 'till',
        ...(promocode ? { promocode } : {}),
        /* SHA 256 Hashed values for GA tracking */
        ...formatBookingDataToSha256HashedValues(data)
      };

      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_START, {
        id: GoogleAnalyticsEventId.PAYMENT_DETAILS_START,
        ...trackingData
      });

      localStorage.setItem('tracking_consultation_summary', JSON.stringify(trackingData));

      isPaymentTrackingTriggered.current = true;
    }
  }, [
    sendGoogleAnalyticsEvent,
    totalAmount,
    assignedTo,
    discount?.discountCode,
    promocode,
    isEwayPayment,
    isPaymentTrackingTriggered
  ]);

  useEffect(() => {
    localStorage.setItem(
      'consultation_summary',
      JSON.stringify({
        discountText,
        discountAmount: formattedDiscountAmount,
        totalAmount: formattedTotalAmount
      })
    );
  }, [formattedDiscountAmount, discountText, formattedTotalAmount]);

  const handleAccordionOnChange = (expanded: boolean) => {
    setAccordionExpanded(expanded);
  };

  const ewayAppointmentSummaryContent = (
    <AppointmentCard>
      <Box display="flex" flexDirection="column">
        <StyledAppointmentHeading>
          <StyledHeading marginBottom={{ sm: 6 }}>Appointment Summary</StyledHeading>
          <Typography variant="body2">Date & Time</Typography>
          <Typography>{startTime && new Date(startTime).toLocaleString()}</Typography>
        </StyledAppointmentHeading>
        <StyledAppointmentContent>
          <Box display="flex" justifyContent="space-between" marginBottom={4} px={5}>
            <StyledHeadingSmall>Initial Consultation</StyledHeadingSmall>
            <StyledHeadingSmall fontWeight={600} fontSize={'1.1rem'}>
              {formattedConsultationPrice}
            </StyledHeadingSmall>
          </Box>
          {isDiscountCodeValid && (
            <DiscountBox>
              <Typography fontWeight={600} display="flex" alignItems="center" flexWrap="wrap">
                <DiscountOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
                {discountText}
              </Typography>
              <StyledHeadingSmall>{formattedDiscountAmount}</StyledHeadingSmall>
            </DiscountBox>
          )}
          <Box display="flex" justifyContent="space-between" my={4} mx={5}>
            <StyledHeading>Total</StyledHeading>
            <StyledHeading>{formattedTotalAmount}</StyledHeading>
          </Box>
          <Typography component="small" variant="body2" marginTop={4} mx={5}>
            Your card will only be charged following your nurse consultation if you qualify.
          </Typography>
          <Typography component="small" variant="body2" marginTop={4} mx={5}>
            Prices are in AUD and include GST. Alternaleaf consultations cannot be claimed through Medicare.
          </Typography>
        </StyledAppointmentContent>
      </Box>
    </AppointmentCard>
  );

  const accordionHeading = (
    <StyledHeadingContainer>
      <Box display={'flex'} flexDirection={'column'}>
        <StyledHeading>Appointment Summary</StyledHeading>
        <Typography>{startTime && new Date(startTime).toLocaleString()}</Typography>
      </Box>
      <StyledHeading mr={3}>{formattedTotalAmount}</StyledHeading>
    </StyledHeadingContainer>
  );

  return enableEwayPayments ? (
    <>
      <PaymentAccordion
        accordionHeading={accordionHeading}
        accordionContent={ewayAppointmentSummaryContent}
        handleAccordionOnChange={handleAccordionOnChange}
        isExpanded={accordionExpanded}
      />
      <Box display={{ xs: 'none', sm: 'block' }}>{ewayAppointmentSummaryContent}</Box>
    </>
  ) : (
    <Box marginTop={5}>
      <AppointmentCard data-testid={'appointment-summary-treatment'}>
        <Box display="flex" flexDirection="column">
          <Box padding={8}>
            <StyledHeading>Appointment Summary</StyledHeading>
            <Box marginTop={4}>
              <Typography variant="body2" color={grey[600]}>
                Appointment Nurse
              </Typography>
              <Typography>{nurseFirstNameOnly}</Typography>
            </Box>
            <Box marginTop={4}>
              <Typography variant="body2" color={grey[600]}>
                Date & Time
              </Typography>
              <Typography>{startTime && new Date(startTime).toLocaleString()}</Typography>
            </Box>
            <Box marginTop={4}>
              <Typography variant="body2" color={grey[600]}>
                Full Name
              </Typography>
              <Typography>{fullName}</Typography>
            </Box>
            <Box marginTop={4}>
              <Typography variant="body2" color={grey[600]}>
                Email
              </Typography>
              <Typography>{email}</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" py={8} bgcolor="pillCard.dark">
            <Box display="flex" justifyContent="space-between" marginBottom={2} px={8}>
              <StyledHeading>Consultation Amount</StyledHeading>
              <StyledHeading>{formattedConsultationPrice}</StyledHeading>
            </Box>
            {isDiscountCodeValid && (discount?.discountAmount || discountPercentageAmount) > 0 && (
              <DiscountBox>
                <Typography fontWeight={600} display="flex" alignItems="center" flexWrap="wrap">
                  <DiscountOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
                  {discountText}
                </Typography>
                <StyledHeading>{formattedDiscountAmount}</StyledHeading>
              </DiscountBox>
            )}
            <Typography component="small" variant="body2" marginTop={4} px={8}>
              Prices are in AUD and include GST. Alternaleaf consultations cannot be claimed through Medicare.
            </Typography>
          </Box>
        </Box>
      </AppointmentCard>
    </Box>
  );
}
