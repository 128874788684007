import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import type { UseFieldArrayRemove } from 'react-hook-form';

/**
 * Remove Time Button component
 *
 * @remarks
 * Used within the Availability Scheduler to allow users to remove a time range from the form fields
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'RemoveTimeButton'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param disabled - Boolean for if the day is disabled
 * @param onClick - On click, the times are copied
 * @param onCancel - On cancel, the form is closed
 * @param weekStart - By default = 0. Used to find the index of the current day. See 'weekdayIndex'
 *
 * @returns JSXElement
 *
 */

const RemoveTimeButton = ({ index, remove }: { index: number | number[]; remove: UseFieldArrayRemove }) => {
  return (
    <Button type="button" onClick={() => remove(index)}>
      <DeleteIcon />
    </Button>
  );
};

export default RemoveTimeButton;
