import { TextField } from '@mui/material';

import Autocomplete from '@/shared-ui/Autocomplete/Autocomplete';

export type RescriptingTableFilterProps = {
  label?: string;
  placeholder?: string;
  size?: 'small' | 'medium';
  options: string[];
  onSelect: (selected: string[]) => void;
  disabled?: boolean;
};

export const RescriptingTableFilter = ({
  label,
  placeholder,
  options,
  onSelect,
  disabled
}: RescriptingTableFilterProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (_event: React.SyntheticEvent<Element, Event>, values: string[]) => {
    onSelect(values);
  };

  return (
    <Autocomplete
      sx={{ minWidth: '240px' }}
      options={options}
      filterSelectedOptions
      multiple
      ChipProps={{
        color: 'primary'
      }}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} size="small" disabled={disabled} />
      )}
    />
  );
};
