import { useQueryClient } from '@tanstack/react-query';

import { prescribedProductClient } from '@/api/prescribedProducts/prescribedProductClient';
import { QueryKey } from '@/data/query';

const usePutCancelPrescribedProducts = () => {
  const queryClient = useQueryClient();
  const queryKey = [QueryKey.CancelPrescribedProducts];

  return prescribedProductClient.cancelPrescribedMedications.useMutation({
    mutationKey: queryKey,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PrescribedProducts] });
    }
  });
};

export default usePutCancelPrescribedProducts;
