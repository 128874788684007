import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { makeGET } from '../data/service/dataService';

import '../assets/scss/DischargePatient.scss';

export default function DischargePatientModal(props) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { open, setOpen, data, patient_id, userId, submitDischargePatient } = props;
  const [showAlert, setShowAlert] = React.useState(false);
  const [reasonList, setReasonList] = React.useState([]);
  const [dischargeReason, setDischargeReason] = React.useState('');
  const [dischargeReasonData, setDischargeReasonData] = React.useState({
    id: 0,
    reason: '',
    reason_code: ''
  });
  const [submitControl, setSubmitControl] = React.useState(false);

  const refresh = () => {
    setShowAlert(false);
    setDischargeReasonData({
      id: 0,
      reason: '',
      reason_code: ''
    });
    setDischargeReason('');
    setSubmitControl(false);
  };

  const handleClose = () => {
    if (showAlert) {
      setShowAlert(false);
    } else {
      setOpen(false);
      refresh();
    }
  };

  const handleReasonChange = (e) => {
    setDischargeReason(e.reason);
    setDischargeReasonData(e);
  };

  const handleDischargePatient = () => {
    setShowAlert(true);
  };

  const getDischargeReasons = async () => {
    const resp = await makeGET(`patient/dischargeReasons`, 'getDischargeReasons');
    if (resp) {
      setReasonList(resp);
    }
  };

  const handleDischargeSubmit = async () => {
    if (!submitControl) {
      setSubmitControl(true);
      const body = {
        email: data.email,
        patientId: patient_id,
        userId: data.patient_user_id,
        dischargeStatus: dischargeReasonData?.category,
        dischargeReason: dischargeReasonData?.reason,
        dischargeReasonCode: dischargeReasonData?.reason_code,
        dischargedBy: userId
      };

      submitDischargePatient(body);
      setOpen(false);
      refresh();
    }
  };

  React.useEffect(() => {
    getDischargeReasons();
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="dp_modal"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className="dp_paper">
          <div className="dp_header">
            <Typography variant="h5" className="dp_headerName" data-testid="header">
              {!showAlert
                ? `Discharge Patient - ${data?.FirstName} ${data?.LastName} (${data?.patient_code})`
                : 'Confirm Patient Discharge'}
            </Typography>
            <CloseIcon className="dp_cancelIcon" onClick={handleClose} />
          </div>
          <hr className="dp_hrClass"></hr>
          {!showAlert ? (
            <>
              <RadioGroup aria-label="gender" name="gender1" value={dischargeReason}>
                {reasonList?.map((ele, index) => (
                  <FormControlLabel
                    data-testid={`radiobtn-${index}`}
                    key={ele.id}
                    value={ele.reason}
                    control={<Radio />}
                    label={ele.reason}
                    onClick={() => handleReasonChange(ele)}
                  />
                ))}
              </RadioGroup>
              <div className="dp_contentDiv">
                <Button variant="outlined" className="dp_cancel_btn" onClick={handleClose} data-testid="cancelbtn">
                  cancel
                </Button>
                <Button
                  className="dp_discharge_btn"
                  onClick={handleDischargePatient}
                  disabled={dischargeReason ? false : true}
                  data-testid="dischargebtn"
                >
                  Discharge
                </Button>
              </div>
            </>
          ) : (
            <div>
              <p data-testid="alertText">
                Are you sure you want to discharge this patient{' '}
                <strong>
                  {data?.FirstName} {data?.LastName}
                </strong>{' '}
                - <strong>{data?.patient_code}</strong> ?
              </p>
              <div className="dp_contentDiv">
                <Button
                  data-testid="yesBtn"
                  className="dp_yes_btn"
                  disabled={submitControl}
                  onClick={handleDischargeSubmit}
                >
                  Yes
                </Button>
                <Button variant="outlined" className="dp_cancel_btn" onClick={handleClose} data-testid="alert_cacel">
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
