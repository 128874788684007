import {
  CALENDAR_SLOT_MAX_TIME,
  CALENDAR_SLOT_MIN_TIME
} from '@/components/NurseFlexCalendar/NurseFlexCalendar.constants';
import type { SelectableNurse } from '@/components/NurseFlexCalendar/NurseFlexCalendar.types';
import type { MutableRefObject } from 'react';

export const getMatchingNurses = (nurseIds: number[] | undefined, listOfAllNurses: SelectableNurse[]) => {
  const matchingNurses = nurseIds
    ?.map((id: number) => {
      return listOfAllNurses.find((nurse) => parseInt(nurse.id) == id);
    })
    .filter(Boolean);

  return matchingNurses || [];
};

export const calulateScrollTimePosition = (scrollPositionRef: MutableRefObject<string | null>) => {
  // Get elements
  const scrollElements = document.querySelectorAll('.fc-scroller.fc-scroller-liquid-absolute');
  const scrollParent = scrollElements[1];
  const scrollChild = document.querySelector('.fc-timeline-body');

  // Calculate width of each time interval in child div
  const totalHours = CALENDAR_SLOT_MAX_TIME - CALENDAR_SLOT_MIN_TIME;
  const scrollChildWidth = (scrollChild as HTMLDivElement).offsetWidth;
  const intervalWidth = scrollChildWidth / totalHours;

  // Function to calculate mapped time
  function calculateMappedTime() {
    // Get scroll position of parent div
    const scrollLeft = scrollParent.scrollLeft;

    // Calculate position of left edge of scroll parent relative to scroll position
    const leftEdgePosition = scrollLeft;

    // Map position to corresponding time on scroll child
    const mappedTime = leftEdgePosition / intervalWidth + CALENDAR_SLOT_MIN_TIME; // Adding X to get actual time starting from X am (i.e. 7am)

    // Convert mapped time to hours and minutes format
    const hours = Math.floor(mappedTime);
    const minutesDecimal = (mappedTime % 1) * 60;
    const minutes = Math.floor(minutesDecimal);
    const formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes; // Add leading zero for minutes < 10

    // Store the mapped time in the scrollPositionRef
    scrollPositionRef.current = formattedTime;
  }

  // Attach scroll event listener to parent scroll child
  scrollParent.addEventListener('scroll', calculateMappedTime);
  scrollParent.addEventListener('resize', calculateMappedTime);

  // Initial calculation
  calculateMappedTime(); // Calculate mapped time initially
};
