import { IhiModalField } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalFields/IhiModalField';
import type { IhiFieldsWithDifferences } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ihiVerifyAndUpdateModals.types';
import { Stack, styled } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export type IhiModalFieldGroupProps = {
  labelPartial: string;
  fieldName: keyof IhiFieldsWithDifferences;
  pmsFieldValue: string | number | null | undefined;
  ihiFieldValue: string | number | null | undefined;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  radioSelectionState: { [key in keyof IhiFieldsWithDifferences]: boolean };
};

export const StyledIhiModalField = styled(IhiModalField)({
  flex: 1
});

const StyledFormControl = styled(FormControl)({
  width: '100%'
});

/**
 * IhiModalFieldRadioGroup
 *
 * This component renders a pair of IhiModalField components along with radio buttons for selection.
 * It displays the provided PMS and HI Service field values, and allows the user to select which value to use.
 *
 * @param {IhiModalFieldGroupProps} props - The properties object.
 * @param {string} props.labelPartial - The label for the field, used as a base for both PMS and HI Service labels.
 * @param {keyof IhiFieldsWithDifferences} props.fieldName - The name of the field being compared.
 * @param {string | number | null | undefined} props.pmsFieldValue - The value from the PMS system.
 * @param {string | number | null | undefined} props.ihiFieldValue - The value from the HI Service.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.handleRadioChange - Function to handle radio button changes.
 * @param {{ [key in keyof IhiFieldsWithDifferences]: boolean }} props.radioSelectionState - The state of radio button selections.
 *
 * @returns {JSX.Element} The rendered component.
 */

export const IhiModalFieldRadioGroup = (props: IhiModalFieldGroupProps) => {
  const { labelPartial, fieldName, pmsFieldValue, ihiFieldValue, handleRadioChange, radioSelectionState } = props;

  const radioValue = radioSelectionState[fieldName] ?? false;

  return (
    <StyledFormControl>
      <RadioGroup name={fieldName} value={radioValue} onChange={handleRadioChange}>
        <Stack direction={'row'} spacing={6} marginY={3}>
          <Box position={'relative'} flex={1}>
            <IhiModalField label={`${labelPartial} in Patient Info (PMS)`} value={pmsFieldValue} />
            <Box position={'absolute'} right={-4} top={8}>
              <FormControlLabel value={false} control={<Radio />} label={null} />
            </Box>
          </Box>
          <Box position={'relative'} flex={1}>
            <IhiModalField label={`${labelPartial} in HI Service`} value={ihiFieldValue} />
            <Box position={'absolute'} right={-4} top={8}>
              <FormControlLabel value={true} control={<Radio />} label={null} />
            </Box>
          </Box>
        </Stack>
      </RadioGroup>
    </StyledFormControl>
  );
};
