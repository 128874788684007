import { Box, styled } from '@mui/material';
import type { ControllerRenderProps } from 'react-hook-form';

import LazySelect from './LazySelect';

/**
 * TimeRangeField component
 *
 * @remarks
 * Renders two select boxes with are for the start and end times for a given Time Range
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'TimeRangeField'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param value - The time value chosen
 * @param onChange - A callback to be triggered when the select box is changed
 *
 * @returns JSXElement
 *
 */

const StyledTimeRangeField = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 3
});

const StyledSpacer = styled(Box)({
  marginLeft: 10,
  marginRight: 10
});

const TimeRangeField = ({ value, onChange }: ControllerRenderProps) => {
  return (
    <StyledTimeRangeField>
      <LazySelect
        value={value.start}
        max={value.end}
        onChange={(option) => {
          onChange({ ...value, start: new Date(option?.value as number) });
        }}
      />
      <StyledSpacer> - </StyledSpacer>
      <LazySelect
        value={value.end}
        min={value.start}
        onChange={(option) => onChange({ ...value, end: new Date(option?.value as number) })}
      />
    </StyledTimeRangeField>
  );
};

export default TimeRangeField;
