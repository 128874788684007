import TagManager from 'react-gtm-module';

import { Logger } from '@/utils/logger';

const logger = new Logger('googleTagManager.ts');

function configureGTM({
  googleTagManagerId,
  googleTagManagerAuth
}: {
  googleTagManagerId: string;
  googleTagManagerAuth: string;
}) {
  let initialised = false;
  const init = () => {
    if (googleTagManagerId && !initialised) {
      logger.debug(`Initializing GoogleTagManager`);
      TagManager.initialize({
        gtmId: googleTagManagerId,
        auth: googleTagManagerAuth || ''
      });
      initialised = true;
    }
  };

  init();
}

export default configureGTM;
