import settings from '../../data/constants';

import type { NurseRoundRobinResponse } from './useGetRoundRobinNurses';
import useRequest from './useRequest';

export function useUpdateRoundRobinNurses() {
  const { loading, data, error, execute } = useRequest<NurseRoundRobinResponse>({
    config: {
      url: `${settings.url}/calcom/nurse-roundrobin`,
      method: 'PUT'
    }
  });

  const doPut = async (body: { hosts: number[] }) => {
    return execute({ data: body });
  };

  return {
    loading,
    data,
    error,
    doPut
  };
}

export default useUpdateRoundRobinNurses;
