import { Link, Typography } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

import { useScrollRef } from '@/context/scrollContext';
import type { SupportTasksProps } from '@/types/support-self-service.types';
import { buildIdFromEndpoint } from '@/utils/supportTasks';

import { useStickyRef } from './SupportHeaderSticky';

interface Props {
  tasks: SupportTasksProps[];
  setHighlightedTaskId: Dispatch<SetStateAction<string | null>>;
}

const SupportTaskSubmenu = ({ tasks, setHighlightedTaskId }: Props) => {
  const scrollRef = useScrollRef();
  const stickyRef = useStickyRef();
  const highlightSupportTask = (id: string) => {
    setHighlightedTaskId(id);
    setTimeout(() => setHighlightedTaskId(null), 300);
  };

  const handleSubmenuClick = (id: string) => {
    const taskElement = document.getElementById(id);

    if (taskElement && scrollRef.current && stickyRef?.current) {
      const elementPosition = taskElement.getBoundingClientRect().top + scrollRef.current.scrollTop;
      const offsetPosition = elementPosition - stickyRef.current.offsetHeight;

      highlightSupportTask(id);

      scrollRef.current.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  // Don't show a submenu for just a single item
  if (tasks.length <= 1) {
    return null;
  }

  return (
    <Typography component="nav" aria-label="support tasks sub menu" sx={{ px: 4, py: 2 }}>
      Jump to:
      {tasks.map((task, i) => {
        const id = buildIdFromEndpoint(task.endpoint);
        return (
          // eslint-disable-next-line react/jsx-key
          <Link
            component="a"
            color="secondary"
            underline="hover"
            href={`#${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleSubmenuClick(id);
            }}
            sx={{ px: 2, borderLeft: i !== 0 ? '1px solid #ccc' : undefined }}
          >
            {task.title}
          </Link>
        );
      })}
    </Typography>
  );
};

export default SupportTaskSubmenu;
