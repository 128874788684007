/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Button, IconButton, Input, InputAdornment } from '@mui/material';

import { ReactComponent as LeftArrowIcon } from '../assets/images/LeftArrowIcon.svg';
import { ReactComponent as TickGreyIcon } from '../assets/images/tick.svg';
import { ReactComponent as TickWhiteIcon } from '../assets/images/tick-white.svg';

export default function MergePatients(props) {
  const {
    setOpen,
    getPatientDetail,
    handlePrimaryAlpat,
    handleSecondaryAlpat,
    primary_patient_detail,
    secondary_patient_detail,
    primary_btn_status,
    secondary_btn_status,
    primary_alpat,
    secondary_alpat,
    handleMergePatient,
    primary_not_found_err,
    secondary_not_found_err,
    refresh
  } = props;

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  return (
    <div>
      <p className="mp_bodyContent">
        Consultations, Prescriptions, and Documents on the Secondary profile will be merged into the Primary profile.
        <br /> Patient Details & information of the Secondary profile won't be merged with the Primary profile.
      </p>
      <div className="mp_contentDiv">
        <div className="mp_patient_detail mp_inlineBlock mp_primary_d">
          <p className="mp_cnt_txt mp_primary_color">Primary Profile</p>
          <Input
            id="standard-adornment-password"
            type={'text'}
            className="mp_input"
            placeholder="Enter ALPAT ID"
            value={primary_alpat}
            onChange={handlePrimaryAlpat}
            autoComplete="off"
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                {primary_btn_status === 1 && (
                  <IconButton className="mp_input_submit_grey" aria-label="tick_grey_icon" size="large">
                    <TickGreyIcon />
                  </IconButton>
                )}
                {primary_btn_status === 2 && (
                  <IconButton
                    className="mp_input_submit"
                    aria-label="tick_grey_icon"
                    onClick={() => getPatientDetail('primary_patient')}
                    size="large"
                  >
                    <TickWhiteIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
          {primary_not_found_err && <p className="mp_alert_msg">Profile Not Found</p>}
          <p className="mp_cnt_txt">{primary_patient_detail.name}</p>
          <p style={{ fontSize: '13px' }}>{primary_patient_detail.email}</p>
        </div>
        <div className="mp_inlineBlock mp_left_arrow_icon">
          <LeftArrowIcon />
        </div>
        <div className="mp_patient_detail mp_inlineBlock mp_secondary_d">
          <p className="mp_cnt_txt mp_secondary_color">Secondary Profile</p>
          <Input
            id="standard-adornment-password"
            type={'text'}
            className="mp_input"
            placeholder="Enter ALPAT ID"
            value={secondary_alpat}
            autoComplete="off"
            onChange={handleSecondaryAlpat}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                {secondary_btn_status === 1 && (
                  <IconButton className="mp_input_submit_grey" aria-label="tick_grey_icon" size="large">
                    <TickGreyIcon />
                  </IconButton>
                )}
                {secondary_btn_status === 2 && (
                  <IconButton
                    className="mp_input_submit"
                    aria-label="tick_grey_icon"
                    onClick={() => getPatientDetail('secondary_patient')}
                    size="large"
                  >
                    <TickWhiteIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
          {secondary_not_found_err && <p className="mp_alert_msg">Profile Not Found</p>}
          {secondary_patient_detail?.name && <p className="mp_cnt_txt">{secondary_patient_detail.name}</p>}
          {secondary_patient_detail.email && <p style={{ fontSize: '13px' }}>{secondary_patient_detail.email}</p>}
          {secondary_alpat?.length > 0 && !secondary_not_found_err && (
            <p className="mp_alert_msg">This profile will be removed</p>
          )}
        </div>
        <div className="mp_contentDiv">
          <Button variant="outlined" className="mp_cancel_btn" onClick={handleClose}>
            cancel
          </Button>
          <Button
            className="mp_merge_btn"
            disabled={
              secondary_patient_detail.patient_code &&
              primary_patient_detail.patient_code &&
              primary_alpat !== secondary_alpat
                ? false
                : true
            }
            onClick={handleMergePatient}
          >
            Merge Profiles
          </Button>
        </div>
      </div>
    </div>
  );
}
