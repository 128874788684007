import { Box, CircularProgress, styled } from '@mui/material';

const VideoWrapper = styled(Box)({
  position: 'relative',
  paddingBottom: '56.25%',
  height: '0',
  marginTop: '2rem'
});

const StyledIFrame = styled('iframe')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  border: '0px'
});

const CenteredLoader = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '-1'
});

export const TutorialVideo = () => {
  return (
    <VideoWrapper>
      <StyledIFrame
        src="https://www.loom.com/embed/0a8845170c834aab9bcc47f54703adbf?sid=fa43f90f-57f9-491d-80d6-8c052283ccb4?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
        allowFullScreen
      ></StyledIFrame>

      <CenteredLoader />
    </VideoWrapper>
  );
};
