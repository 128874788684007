import type { ChipTypeMap } from '@mui/material';
import type { AutocompleteProps } from '@mui/material/Autocomplete';
import MuiAutocomplete from '@mui/material/Autocomplete';

/**
 * currently a wrapper for the MUI Autocomplete but will restrict/extend
 * the amount of props that can be passed on to the autocomplete component in the future
 *
 */
const Autocomplete = <
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  renderInput,
  ...props
}: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) => {
  return <MuiAutocomplete renderInput={renderInput} {...props} />;
};

export default Autocomplete;
