import type { RumInitConfiguration } from '@datadog/browser-rum';

import type { AppEnv } from '../constants/env';
import { getAppEnv, viteAppBaseUrl, viteProductImageBaseUrl } from '../constants/env';

type Country = 'australia';

type CommonSettings = {
  screenResolution: {
    small: number;
    medium: number;
    large: number;
    extralarge: number;
  };
  consultationStatus: {
    pending: number;
    awaitingDocument: number;
    scheduled: number;
    cancelled: number;
    noShow: number;
    completed: number;
    reScheduled: number;
    ineligible: number;
  };
  patientDocumentUploadType: {
    sas: number;
    consentForm: number;
    labResult: number;
    other: number;
    patientNote: number;
  };
  localisation: {
    phoneCountryCodes: {
      [key in Country]: string;
    };
  };
};

type DataDogConfig = { env: string } & Pick<
  RumInitConfiguration,
  'sessionSampleRate' | 'sessionReplaySampleRate' | 'allowedTracingUrls'
>;

type GoogleTagManagerEnvironments = 'staging' | 'prod';

type GoogleTagManagerSettings = {
  googleTagManagerId: string;
  googleTagManagerAuth: string;
};

type EnvSpecificSettings = {
  baseXApiUrl: string;
  url: string;
  calendlyBaseURL: string;
  nurseBooking: string;
  doctorBooking: string;
  nurseFollowupBooking: string;
  googleLoginClientID: string;
  googlePlacesApiKey?: string;
  productImageUrl: string;
  FeatureFlagSDKKey: string;
  tillSdkKey: string;
  tillSdkUrl: string;
  ewaySdkUrl: string;
  ewayPublicKey: string;
  paymentGqlURL: string;
  paymentRestURL: string;
  mixpanelKey?: string; // Optional since UAT env has no analytics
  mixpanelApiUrl?: string; // Optional since UAT env has no analytics
  typeFormUrl: string;
  brazeApiKey: string;
  brazeBaseUrl: string;
  fullCalendarKey: string;
  preConsultUrl: string;
  preScreeningUrl: string;
  calendar: {
    nurseDefaultEvent: string;
    doctorSpecificDoctor: string;
    doctorRoundRobin: string;
    calOrigin: string;
    embedJsUrl: string;
  };
  datadog?: DataDogConfig;
  launchDarkly?: {
    clientSideID: string;
  };
};

export type EnvSettings = CommonSettings & EnvSpecificSettings;

const common: CommonSettings = {
  screenResolution: {
    small: 576,
    medium: 720,
    large: 960,
    extralarge: 1140
  },
  consultationStatus: {
    pending: 1,
    awaitingDocument: 2,
    scheduled: 3,
    cancelled: 4,
    noShow: 5,
    completed: 6,
    reScheduled: 7,
    ineligible: 8
  },
  patientDocumentUploadType: {
    sas: 1,
    consentForm: 2,
    labResult: 3,
    other: 4,
    patientNote: 8
  },
  localisation: {
    phoneCountryCodes: {
      australia: '+61'
    }
  }
};

const API_LOCAL_BASE_URL = viteAppBaseUrl() ?? 'http://localhost:9000';
const PRODUCT_IMAGE_LOCAL_BASE_URL = viteProductImageBaseUrl() ?? 'https://circuit.staging.montu.com.au';

const local: EnvSettings = {
  baseXApiUrl: API_LOCAL_BASE_URL, // for use with new Xapi contracts
  url: `${API_LOCAL_BASE_URL}/api/v1`,
  calendlyBaseURL: 'https://calendly.com',
  nurseBooking: 'snkrraj108/nurse-consultation',
  doctorBooking: 'snkrraj108/doctor-consultation',
  nurseFollowupBooking: 'snkrraj108/nurse-consultation',
  googleLoginClientID: '413997005731-6ods1vug2om4ve746uesbl5tjjget3cq.apps.googleusercontent.com',
  googlePlacesApiKey: 'AIzaSyACW3m9lYSj_C5k9PV-6dLSnGvZe349C-k',
  productImageUrl: `${PRODUCT_IMAGE_LOCAL_BASE_URL}/assets/images/products/`,
  FeatureFlagSDKKey: '16f03602-41bf-42bf-b095-9382cf08161e',
  ...common,
  tillSdkKey: 'v7pj4NMk2rVrUhRMmMjs',
  tillSdkUrl: 'https://test-gateway.tillpayments.com/js/integrated/payment.1.3.min.js',
  ewaySdkUrl: 'https://secure.ewaypayments.com/scripts/eWAY.min.js',
  ewayPublicKey: 'epk-7CB1F3DD-44DC-4150-8540-590A2F2624D3',
  paymentGqlURL: 'http://localhost:3003/dev/graphql',
  paymentRestURL: 'http://localhost:3000/dev',
  mixpanelKey: '5e2cfddaedf3d2c621f93d5085b2e03d',
  mixpanelApiUrl: 'https://api.mixpanel.com',
  typeFormUrl: 'https://q3xyhubvli0.typeform.com/to/Err1shDw',
  brazeApiKey: '4258fa44-3379-4de3-89e9-dfffba600dc9',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  fullCalendarKey: '0972052574-fcs-1686190369',
  preConsultUrl: 'http://localhost:3002/pre-consultation',
  preScreeningUrl: 'http://localhost:3002/pre-screening',
  calendar: {
    nurseDefaultEvent: '/team/alternaleaf-nurses/initial-consultation',
    doctorSpecificDoctor: '/team/alternaleaf-doctors',
    doctorRoundRobin: '/team/alternaleaf-doctors/medical-cannabis-consultation',
    calOrigin: 'https://bookings.staging.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
  },
  launchDarkly: {
    clientSideID: '64867d808ed097125e3ca78c'
  }
};

const API_STAGING_BASE_URL = viteAppBaseUrl() ?? 'https://pms.staging.alternaleaf.com.au';
const PRODUCT_IMAGE_STAGING_BASE_URL = viteProductImageBaseUrl();

const staging: EnvSettings = {
  baseXApiUrl: API_STAGING_BASE_URL, // for use with new Xapi contracts
  url: `${API_STAGING_BASE_URL}/api/v1`,
  calendlyBaseURL: 'https://calendly.com',
  nurseBooking: 'montutestnurse004/staging-initial-nurse-consult',
  doctorBooking: 'montutestdoc004/staging-initial-doctor-consult',
  nurseFollowupBooking: 'montutestnurse004/staging-initial-nurse-consult',
  googleLoginClientID: '413997005731-6ods1vug2om4ve746uesbl5tjjget3cq.apps.googleusercontent.com',
  googlePlacesApiKey: 'AIzaSyACW3m9lYSj_C5k9PV-6dLSnGvZe349C-k',
  productImageUrl: `${PRODUCT_IMAGE_STAGING_BASE_URL}/assets/images/products/`,
  ...common,
  FeatureFlagSDKKey: '222da7de-c3f4-4437-a75b-b95f50e24874',
  tillSdkKey: 'v7pj4NMk2rVrUhRMmMjs',
  tillSdkUrl: 'https://test-gateway.tillpayments.com/js/integrated/payment.1.3.min.js',
  ewaySdkUrl: 'https://secure.ewaypayments.com/scripts/eWAY.min.js',
  ewayPublicKey: 'epk-7CB1F3DD-44DC-4150-8540-590A2F2624D3',
  paymentGqlURL: 'https://api.staging.montu.com.au/payments/graphql',
  paymentRestURL: 'https://api.staging.montu.com.au/payments',
  mixpanelKey: '66ef8d1132cefa10da498a048fca8b8b',
  mixpanelApiUrl: 'https://api.mixpanel.com',
  typeFormUrl: 'https://q3xyhubvli0.typeform.com/to/Err1shDw',
  brazeApiKey: '4258fa44-3379-4de3-89e9-dfffba600dc9',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  fullCalendarKey: '0972052574-fcs-1686190369',
  preConsultUrl: 'https://pre-consult.staging.alternaleaf.com.au',
  preScreeningUrl: 'https://pre-screening.staging.alternaleaf.com.au',
  calendar: {
    nurseDefaultEvent: '/team/alternaleaf-nurses/initial-consultation',
    doctorSpecificDoctor: '/team/alternaleaf-doctors',
    doctorRoundRobin: '/team/alternaleaf-doctors/medical-cannabis-consultation',
    calOrigin: 'https://bookings.staging.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
  },
  datadog: {
    env: 'staging',
    sessionSampleRate: 100, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 0, // percentage of sessions capturing RUM, AND session replays
    allowedTracingUrls: [`${API_STAGING_BASE_URL}/api/v1`]
  },
  launchDarkly: {
    clientSideID: '64867d79e302dd1366cb8d8a'
  }
};

const API_PRODUCTION_BASE_URL = viteAppBaseUrl() ?? 'https://pms.alternaleaf.com.au';
const PRODUCT_IMAGE_PRODUCTION_BASE_URL = viteProductImageBaseUrl() ?? 'https://circuit.montu.com.au';

const prod: EnvSettings = {
  baseXApiUrl: API_PRODUCTION_BASE_URL, // for use with new Xapi contracts
  url: `${API_PRODUCTION_BASE_URL}/api/v1`,
  calendlyBaseURL: 'https://calendly.com',
  nurseBooking: 'alternaleaf/consultation',
  doctorBooking: 'alternaleaf-doctors/medical-cannabis-consultation',
  nurseFollowupBooking: 'alternaleaf/followup',
  googleLoginClientID: '178327000471-p19h3p48hjs6kblrao1d766agc72hgov.apps.googleusercontent.com',
  googlePlacesApiKey: 'AIzaSyA73sbl-Lot0ta6DyHD8NmVYKbOTS2BtcQ',
  productImageUrl: `${PRODUCT_IMAGE_PRODUCTION_BASE_URL}/assets/images/products/`,
  ...common,
  FeatureFlagSDKKey: '87fd7d6e-1c3d-4ddf-ac82-2cc3629737da',
  tillSdkKey: 's8VqwJF7uc4VBPJOPDuB',
  tillSdkUrl: 'https://gateway.tillpayments.com/js/integrated/payment.1.3.min.js',
  ewaySdkUrl: 'https://secure.ewaypayments.com/scripts/eWAY.min.js',
  ewayPublicKey: 'epk-EA75A2A4-5F39-43F9-80E0-5E2FF23392CA',
  paymentGqlURL: 'https://api.montu.com.au/payments/graphql',
  paymentRestURL: 'https://api.montu.com.au/payments',
  mixpanelKey: '241f73210ec70968b1d76f48d076b357',
  mixpanelApiUrl: 'https://api.mixpanel.com',
  typeFormUrl: 'https://alternaleaf.typeform.com/to/FI1WVHu0',
  brazeApiKey: '1d23e147-6549-437b-bacd-098aed86bbce',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  fullCalendarKey: '0972052574-fcs-1686190369',
  preConsultUrl: 'https://pre-consult.alternaleaf.com.au',
  preScreeningUrl: 'https://pre-screening.alternaleaf.com.au',
  calendar: {
    nurseDefaultEvent: '',
    doctorSpecificDoctor: '/team/alternaleaf-doctors',
    doctorRoundRobin: '/team/alternaleaf-doctors/medical-cannabis-consultation',
    calOrigin: 'https://bookings.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.alternaleaf.com.au/embed/embed.js'
  },
  datadog: {
    env: 'production',
    sessionSampleRate: 100, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 50, // percentage of sessions capturing RUM, AND session replays
    allowedTracingUrls: [`${API_PRODUCTION_BASE_URL}/api/v1`]
  },
  launchDarkly: {
    clientSideID: '64867d71418ca9139db030e5'
  }
};

const googleTagManagerCredentials: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-MTR44XF',
  googleTagManagerAuth: ''
};

const googleTagManager: Record<GoogleTagManagerEnvironments, GoogleTagManagerSettings> = {
  staging: { ...googleTagManagerCredentials, googleTagManagerAuth: '-rbKmofROQqL4OPlzxMC0g' },
  prod: { ...googleTagManagerCredentials, googleTagManagerAuth: '' }
};

function getGoogleTagManagerEnvironment(env: AppEnv): GoogleTagManagerEnvironments {
  return env === 'production' ? 'prod' : 'staging';
}

function mergeGoogleTagManagerSettings(settings: EnvSettings, env: AppEnv): EnvSettings {
  const gtmEnv = getGoogleTagManagerEnvironment(env);
  const gtmSettings = googleTagManager[gtmEnv];

  if (googleTagManager[gtmEnv] && gtmSettings) {
    return {
      ...settings,
      ...googleTagManager[gtmEnv]
    };
  }
  return settings;
}

function getEnvironmentSettings(): EnvSettings {
  const env: AppEnv = getAppEnv();
  const baseSettings: Record<AppEnv, EnvSettings> = {
    development: local,
    staging: staging,
    production: prod
  };

  return mergeGoogleTagManagerSettings(baseSettings[env] || local, env);
}

export enum ConsultationStatus {
  no_show = 5,
  completed = 6,
  pending = 1,
  awaiting_document = 2,
  scheduled = 3,
  cancelled = 4,
  re_scheduled = 7,
  ineligible = 8
}

export const ineligibleStatuses = [
  { name: 'Bipolar', value: 1 },
  { name: 'Psychosis', value: 2 },
  { name: 'Does not meet TGA eligibility criteria', value: 3 },
  { name: 'Substance use disorders', value: 4 },
  { name: 'Under 18 years', value: 5 },
  { name: 'Pregnant or breastfeeding', value: 6 },
  { name: 'Inappropriate', value: 7 }
] as const;

const settings = getEnvironmentSettings();
export default settings;
