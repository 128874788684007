/* eslint-disable @typescript-eslint/no-use-before-define */
import { datadogRum } from '@datadog/browser-rum';
import { Button, LocationInput } from '@montugroup/design-system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Flag from '@mui/icons-material/Flag';
import {
  Alert,
  Chip,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import iziToast from 'izitoast';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { listSorter } from '@/assets/js/util';
import {
  formatExpirationForAnalytics,
  formatExpirationForCardType,
  parseMomentYearMonthDay
} from '@/components/concession/utils';
import AcknowledgementModal from '@/components/PatientPageV2/components/AcknowledgementModal/AcknowledgementModal.tsx';
import { getAuthData, isDoctor } from '@/data/service/authService';
import { makeGET, makePOST, makePUT } from '@/data/service/dataService';
import { useFeatureFlags } from '@/hooks';
import { Tab } from '@/shared-ui/Tabs/Tab';
import { TabPanel } from '@/shared-ui/Tabs/TabPanel';
import { Tabs } from '@/shared-ui/Tabs/Tabs';
import { generateUUid } from '@/utils/generateUUID';
import { isWaPatient } from '@/utils/waPatient/isWaPatient';

import { useAppStore } from '@/state-management';
import { PARCEL_LOCKER_ERROR_MESSAGE, validateParcelLocker } from '@montu-web/utilities';
import useStyles from '../assets/js/patientsV2';
import { ManageConcession } from '../components/concession';
import CheckBoxField from '../components/fields/CheckBoxField';
import DateField from '../components/fields/DateField';
import InputField from '../components/fields/InputField';
import SearchableSelect from '../components/fields/SearchableSelect';
import SelectField from '../components/fields/SelectField';
import ConsultationTab from '../components/PatientPageV2/ConsultationTab';
import DocumentsTab from '../components/PatientPageV2/DocumentsTab';
import MedicalCondition from '../components/PatientPageV2/MedicalCondition';
import PrescriptionsTab from '../components/PatientPageV2/PrescriptionTab';
import QOL, { headings as qolHeadings } from '../components/PatientPageV2/qol';
import settings from '../data/constants';
import useGetPatientConcessionCard from '../hooks/rest/useGetPatientConcessionCard';
import Mixpanel from '../utils/mixpanelServices';

import { FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE } from '../constants/featureFlags';
import DischargePatientModal from './DischargePatientModal';

import { IhiNumberStatusBanner } from '@/components/PatientPageV2/components/IhiNumberStatusBanner/IhiNumberStatusBanner';
import { IhiVerifyAndUpdateModals } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/IhiVerifyAndUpdateModals';
import { usePatientIhi } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/hooks/usePatientIhi';
import { getIhiStatus } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/IhiVerifyAndUpdateModals.utils';
import { z } from 'zod';
import '../assets/scss/onboardPatients.scss';
const API_URL = settings.url;

const StyledSnackbar = styled(Snackbar)({
  marginTop: 70
});

export const isPostcode = (postCode) => typeof postCode === 'string' && /^\d{4}$/.test(postCode);

const formatResidentialAddress = (address) => {
  if (!address) {
    return 'None listed';
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { street_address, suburb, state, postcode } = address;

  return [street_address, suburb, state, postcode].join(' ');
};

const CheckRequiredFieldSchema = z
  .object({
    isActive: z.literal(true),
    dischargeProgressStatus: z.literal(false),
    street_line1: z
      .string()
      .min(1, 'Street address is required')
      .refine((val) => validateParcelLocker(val), {
        message: PARCEL_LOCKER_ERROR_MESSAGE
      }),
    suburb: z.string().min(1),
    postcode: z.string().min(1),
    state: z.coerce.string().min(1),
    homeDelivery: z.boolean(),
    trustedPharmacy: z.coerce.string().nullish()
  })
  .refine((data) => {
    if (!data.homeDelivery && !data.trustedPharmacy) {
      return false;
    }
    return true;
  });

const Patient = () => {
  const { id: patientId } = useParams();

  const { search: searchParameters } = useLocation(); // could be '?foo=bar'
  const parsedParameters = new URLSearchParams(searchParameters);
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [data, setData] = useState({
    isActive: true,
    FirstName: '',
    LastName: '',
    DoB: '',
    calculatedAge: '',
    gender: '',
    atsi_status: '',
    consent: false,
    phone: '',
    email: '',
    emergency_contact_first_name: '',
    emergency_contact_last_name: '',
    emergency_contact_relationship: '',
    emergency_contact_phone_number: '',
    street: { line1: '', line2: '' },
    suburb: '',
    state: '',
    postcode: '',
    medicareExpiration: '',
    medicareNumber: '',
    HomeDelivery: false,
    trustedPharmacy: '',
    preferedPharmacy: '',
    presentingComplaint: '',
    trialledMedication: '',
    currentMedicationList: '',
    allergies: '',
    cardioDisease: '',
    medicalHistory: '',
    drinksPerWeek: '',
    smokingEx: '',
    doctorName: '',
    doctorEmail: '',
    doctorClinic: '',
    doctorPhone: '',
    detailsConfirmed: false,
    medicalSummary: false,
    PaymentVerified: false,
    PsychosisHx: false,
    DrugDrug: false,
    SubstanceAbuse: false,
    falls: false,
    Lactating: false,
    RecentlyIncarcerated: false,
    SiTosh: false,
    psycosis: '',
    DrugDrugComment: '',
    fallsComment: '',
    LactatingComment: '',
    SubstanceAbuseComment: '',
    RecentlyIncarceratedComment: '',
    SiToshComment: '',
    treatmentGoal: '',
    MCprocess: false,
    notes: '',
    patient_code: '',
    symptoms: '',
    pmsDoctor: '',
    redFlag: false,
    redFlagComment: '',
    dvaNumber: '',
    ihi_number: '',
    ihi_number_status: '',
    ihi_record_status: '',
    medical_condition_1: [],
    medical_condition_2: '',
    medical_condition_3: '',
    medical_condition_4: '',
    others_1_comment: '',
    others_2_comment: '',
    patient_user_id: 0,
    discharge_status: '',
    isCurrentMedicationList: false,
    dass: null
  });
  const setIsWaPatient = useAppStore.use.setIsWaPatient();
  const patientIhiStatus = getIhiStatus(data.ihi_number, data.ihi_number_status, data.ihi_record_status);
  useEffect(() => {
    setIsWaPatient(isWaPatient(data.state));
  }, [data.state]);

  const [TabValue, setTab] = useState(isNaN(parsedParameters.get('show')) ? 0 : Number(parsedParameters.get('show')));
  const [contentChanged, setChanged] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [deliveryOption, setDelivery] = useState('0');
  const { isAdmin, isNurse, user, isSuperAdmin, isDoc, isAdminOnly, isManager } = getAuthData();
  const [showConsultation, setShowConsultation] = useState(null);
  const [consultationSubStatus, setConsultationSubStatus] = useState(null); //?  can be null | {name: string, subStatusId: number}
  const [prevEmail, setPrevEmail] = useState('');

  const [contents, setContents] = useState({
    doctor: {},
    patient: {},
    consultations: [],
    prescriptions: [],
    patientNotes: [],
    qol: [],
    isDoctorTransferred: {},
    isVersion2Prescription: false,
    showConsentFormResendLink: false
  });
  // this state is only used to force a refresh of the documents data.
  const [refreshDocumentsData, setRefreshDocumentsData] = useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    severity: 'success',
    open: false,
    message: ''
  });

  const ldClient = useLDClient();
  const { flags } = useFeatureFlags();
  const isGooglePlacesAutocompleteEnabled = flags[FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE];

  const {
    data: concessionCardData,
    loading: concessionCardLoading,
    setData: setConcessionCardData
  } = useGetPatientConcessionCard({ patientId: patientId });

  function forceRefreshDocumentsData() {
    setRefreshDocumentsData((prev) => !prev);
  }

  const handleSnackbarAlerts = (props) => {
    setSnackbarProps(props);
  };

  const handleSnackbarClose = () => {
    setSnackbarProps(null);
  };

  const handleChangeConcessionCard = (newCard) => {
    setContents((prevContents) => ({
      ...prevContents,
      patient: {
        ...prevContents.patient,
        // Safe to assume true as the form cannot be submitted with invalid details
        hasValidConcessionCard: true
      }
    }));
    setConcessionCardData(newCard);
    Mixpanel('Concession Card Submitted', {
      cardId: newCard.id,
      cardType: newCard.cardType,
      cardExpiry: formatExpirationForAnalytics(
        parseMomentYearMonthDay(newCard.expiryYear, newCard.expiryMonth, newCard.expiryDay),
        newCard.cardType
      ),
      patientId: patientId,
      patientEmail: data.email
    });
    forceRefreshDocumentsData();
  };

  const isConcessionValid = contents.patient.hasValidConcessionCard;

  const [pharmacyList, setPharmacyList] = useState([]);
  const [doctorDropdownList, setDoctorDropdownList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [qolData, setQol] = useState({
    created_date: [],
    commuting_ease: [],
    daily_jobs_ease: [],
    mobility_ease: [],
    self_care_ease: [],
    agitation: [],
    calm_and_tranquil: [],
    control: [],
    coping: [],
    despair: [],
    energy: [],
    sad: [],
    worry: [],
    communication_quality: [],
    hearing_quality: [],
    pain_frequency: [],
    pain_interferance: [],
    pain_level: [],
    sight_quality: [],
    Initimate_relationships: [],
    community_relationships: [],
    family_relationships: []
  });
  const [acknowledgementModalOpen, setAcknowledgementModalOpen] = useState(false);
  const [medicationData, setMedicationData] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [summary, setSummary] = useState(true);
  const [medicalConditionDisabled, setMedicalConditionDisabled] = useState(false);
  const [medicalConditionList, setMedicalConditionList] = useState([]);
  const [medicalConditionOtherId, setMedicalConditionOtherId] = useState();
  const [medicalConditionChanged, setMedicalConditionChanged] = useState(false);
  let value;
  const emptyDassAssessmentDetails = {
    patient_id: patientId,
    link_send_count: 0,
    days_link_valid: 0,
    is_submitted: false,
    elapsed_hours: 0,
    submitted_date: ''
  };
  const emptyDassAssessmentScore = {
    anxiety: {
      score: 0,
      level: ''
    },
    depression: {
      score: 0,
      level: ''
    },
    stress: {
      score: 0,
      level: ''
    }
  };
  const [dassAssessmentDetails, setDassAssessmentDetails] = useState(emptyDassAssessmentDetails);
  const [dassAssessmentScoreDetail, setDassAssessmentScoreDetail] = useState(emptyDassAssessmentScore);
  const SearchableSelectFields = ['state', 'trustedPharmacy', 'pmsDoctor'];
  const checkboxFields = [
    'medicalSummary',
    'PaymentVerified',
    'detailsConfirmed',
    'HomeDelivery',
    'consent',
    'Lactating',
    'falls',
    'SubstanceAbuse',
    'DrugDrug',
    'PsychosisHx',
    'MCprocess',
    'RecentlyIncarcerated',
    'SiTosh'
  ];

  const atsiOptions = ['No', 'Yes', 'Yes, Aboriginal', 'Yes, Torres Strait Islander', 'Yes, both', 'Prefer not to say'];

  if (!data.atsi_status) {
    // Don't default to no for patients who don't have data
    atsiOptions.unshift(undefined);
  }

  const [dischargeModalOpen, setDischargeModal] = React.useState(false);
  const [dischargeProgressStatus, setDischargeProgressStatus] = React.useState(false);
  const [allergies, setAllergies] = React.useState(false);
  const [cardioDisease, setCardioDisease] = React.useState(false);
  const [doctorsList, setDoctorsList] = React.useState([]);

  React.useEffect(() => {
    if (pharmacyList.length === 0 && deliveryOption === '2') {
      makeGET('circuit/pharmacies?type=dropdown', 'getPharmacies-PatientDetails').then((res) => {
        setPharmacyList(res);
      });
    }
  }, [deliveryOption]);

  const handleDischargeModalClose = (status) => {
    setDischargeModal(status);
    getPatientData();
  };

  const handleDataInput = (prop) => (event) => {
    if (prop === 'DoB') {
      if (event) {
        const formattedDate = new Date(Date.UTC(event.year(), event.month(), event.date()));
        value = formattedDate.toISOString();
      } else {
        value = null;
      }
    } else if (checkboxFields.indexOf(prop) > -1) {
      value = event.target.checked;
    } else if (SearchableSelectFields.indexOf(prop) > -1) value = event ? event.value : '';
    else value = event.target.value;

    // If any details relating to IHI change recheck IHI
    const ihiRelatedValues = [
      'ihi_number',
      'FirstName',
      'DoB',
      'gender',
      'LastName',
      'dvaNumber',
      'medicareNumber',
      'suburb',
      'postcode',
      'state',
      'medicare_ref_number'
    ];
    if (ihiRelatedValues.includes(prop)) {
      setPatientIhiNeedsUpdate(true);
    }

    setData({
      ...data,
      [prop]: value
    });

    if (!contentChanged && prop !== 'email') setChanged(true);
  };

  const handleAddressInput = (prop) => (event) => {
    const inputValue = event ? event.target.value : '';
    setData({ ...data, street: { ...data.street, [prop]: inputValue } });
    // If any details relating to IHI change recheck IHI
    setPatientIhiNeedsUpdate(true);
    setChanged(true);
  };

  const handleDocNotes = (val) => {
    setShowConsultation({ ...showConsultation, notes: val });
    if (!contentChanged) setChanged(true);
  };

  const handleAcknowledgementModalClose = () => {
    setAcknowledgementModalOpen(false);
  };

  useEffect(() => {
    const getDoctors = async () => {
      const doctors = await makeGET('doctor?raw=true');
      if (doctors) {
        setDoctorsList(doctors);
      }
    };
    getDoctors();
  }, []);

  useEffect(() => {
    if (doctorDropdownList?.length === 0) {
      makeGET('location/states', 'getStates-PatientDetails').then((res) => {
        setStateList(res);
      });
      makeGET('dropdown/doctor', 'getDoctors-PatientDetails').then((res) => {
        setDoctorDropdownList(res);
      });
    }
  }, [doctorDropdownList]);

  useEffect(() => {
    if (contents.qol.length > 0) {
      let qol = qolData;
      for (const entry of contents.qol) {
        qol.created_date.push(entry.created_date);
        qolHeadings.list.forEach((heading) => {
          qolHeadings[`${heading}_list`].forEach((subheading) => {
            qol[subheading].push(entry[heading][subheading]);
          });
        });
      }
      setQol(qol);
    }
  }, [contents.qol, qolData]);

  const getPatientData = () => {
    makeGET(`patient/details/${patientId}`, 'getPatientDetails')
      .then(
        /**
         *
         * @param {import('@/types/patient.types').PatientModel} resp
         */
        (resp) => {
          // this won't return status 404, consider refactoring.
          if (resp && resp.status === 404) {
            navigate('/404');
          } else {
            setContents({
              consultations: resp.Consultations,
              prescriptions: resp.Prescriptions,
              doctor: resp.Doctor,
              patient: {
                ihi_number: resp.ihi_number,
                ihi_number_status: resp.ihi_number_status,
                ihi_record_status: resp.ihi_record_status,
                dob: moment(resp.dob).format('DD MMM YYYY'),
                medicare_number: resp.medicare_number,
                medicare_ref_number: resp.medicare_ref_number,
                allergies: resp.allergies,
                ...resp.PatientUser,
                // resp ID is the patient's ID.
                id: resp.id,
                hasValidConcessionCard: Boolean(resp.has_valid_concession_card)
              },
              dass: resp.Dass,
              qol: resp.PatientQol || [],
              patient_qol_completed_date: moment(resp.patient_qol_completed_date).format('DD-MM-YYYY') || '',
              is_patient_qol_completed: resp.is_patient_qol_completed,
              patientNotes: resp.Notes,
              isDoctorTransferred: resp.isDoctorTransferred,
              isVersion2Prescription: resp.isVersion2Prescription,
              showConsentFormResendLink: resp.showConsentFormResend,
              propertiesNeedingAttentionBeforeOrdering: resp.propertiesNeedingAttentionBeforeOrdering
            });
            setPrevEmail(resp.PatientUser.email);
            setAllergies(resp.is_allergy ? true : false);
            setCardioDisease(resp.is_cardio_disease ? true : false);
            const userAddress = data.street;
            if (resp.PatientUser.address) {
              const lineAddress = resp.PatientUser.address.split('$$$$');
              userAddress.line1 = lineAddress[0];
              userAddress.line2 = lineAddress.length > 1 ? lineAddress[1] : '';
            }

            setData((prevData) => ({
              ...prevData,
              isActive: resp.PatientUser.active,
              FirstName: resp.PatientUser.first_name,
              LastName: resp.PatientUser.last_name,
              email: resp.PatientUser.email,
              phone: resp.PatientUser.phone,
              street: userAddress,
              suburb: resp.PatientUser.city,
              state: resp.PatientUser.state_id,
              postcode: resp.PatientUser.zip_code,
              pmsDoctor: resp.doctor_id,
              medicareNumber: resp.medicare_number,
              HomeDelivery: resp.home_delivery,
              preferedPharmacy: resp.preferred_pharmacy,
              gender: resp.gender,
              trustedPharmacy: resp.pharmacy_id,
              atsi_status: resp.atsi_status,
              consent: resp.consent,
              medicareReferenceNumber: resp.medicare_ref_number,
              DoB: resp.dob,
              calculatedAge: resp.dob ? moment().diff(new Date(resp.dob), 'years') : '',
              medicareExpiration: resp.medicare_exp_date,
              presentingComplaint: resp.conditions,
              symptoms: resp.symptoms,
              trialledMedication: resp.medications,
              currentMedicationList: resp.current_medications,
              cannabisExperience: resp.tried_cannabis,
              smokingEx: resp.smoking,
              drinksPerWeek: resp.alcohol_per_week,
              cardioDisease: resp.cardio_disease,
              allergies: resp.allergies,
              psycosis: resp.psycosis,
              medicalHistory: resp.comments,
              patient_code: resp.patient_code,
              doctorName: resp.gp_name,
              doctorPhone: resp.gp_phone,
              doctorEmail: resp.gp_email,
              doctorClinic: resp.gp_clinic,
              redFlag: Boolean(resp.has_red_flag),
              redFlagComment: resp.red_flag,
              patientNote: resp.notes,
              ihi_number: resp.ihi_number,
              ihi_number_status: resp.ihi_number_status,
              ihi_record_status: resp.ihi_record_status,
              dvaNumber: resp.dva_number,
              medicare_ref_number: resp.medicare_ref_number,
              medical_condition_1: resp.medical_condition_1 ? JSON.parse(resp.medical_condition_1) : [],
              medical_condition_2: resp.medical_condition_2 ? Number(resp.medical_condition_2) : '',
              medical_condition_3: resp.medical_condition_3 ? Number(resp.medical_condition_3) : '',
              medical_condition_4: resp.medical_condition_4 ? Number(resp.medical_condition_4) : '',
              others_1_comment: resp.others_1_comment ? resp.others_1_comment : '',
              others_2_comment: resp.others_2_comment ? resp.others_2_comment : '',
              patient_user_id: resp.user_id,
              emergency_contact_first_name: resp.emergency_contact_first_name,
              emergency_contact_last_name: resp.emergency_contact_last_name,
              emergency_contact_relationship: resp.emergency_contact_relationship,
              emergency_contact_phone_number: resp.emergency_contact_phone_number,
              residential_address: resp.residential_address,
              discharge_status: resp.discharge_status,
              ...(resp.PatientAssessment && {
                detailsConfirmed: resp.PatientAssessment.patient_details_confirmed,
                medicalSummary: resp.PatientAssessment.medical_summary_verified,
                PaymentVerified: resp.PatientAssessment.payment_verified,
                PsychosisHx: Boolean(resp.PatientAssessment.has_pschosis),
                DrugDrug: Boolean(resp.PatientAssessment.has_drug_drug),
                SubstanceAbuse: Boolean(resp.PatientAssessment.has_substance_abuse),
                RecentlyIncarcerated: Boolean(resp.PatientAssessment.has_recently_incarcerated),
                SiTosh: Boolean(resp.PatientAssessment.has_si_tosh),
                falls: Boolean(resp.PatientAssessment.has_falls),
                Lactating: Boolean(resp.PatientAssessment.has_pregnant_lactating),
                DrugDrugComment: resp.PatientAssessment.drug_drug,
                fallsComment: resp.PatientAssessment.falls,
                LactatingComment: resp.PatientAssessment.pregnant_lactating,
                SubstanceAbuseComment: resp.PatientAssessment.substance_abuse,
                RecentlyIncarceratedComment: resp.PatientAssessment.recently_incarcerated,
                SiToshComment: resp.PatientAssessment.si_tosh,
                treatmentGoal: resp.PatientAssessment.treatment_goal,
                MCprocess: Boolean(resp.PatientAssessment.mc_process_explained),
                notes: resp.PatientAssessment.notes
              })
            }));
            const medicalCondition1val = resp?.medical_condition_1 ? JSON.parse(resp?.medical_condition_1) : [];

            setMedicalConditionDisabled(
              isNurse ||
                isAdmin ||
                isSuperAdmin ||
                (medicalCondition1val.length === 0 &&
                  !resp?.medical_condition_2 &&
                  !resp?.medical_condition_3 &&
                  !resp?.medical_condition_4)
                ? false
                : true
            );
            fetchDassAssessment();
            if (!resp.home_delivery) {
              setDelivery('2');
            }
          }
          setLoading(false);
        }
      )
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getPatientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, patientId, qolData]);

  const {
    modalDataVerifyIhiNumber,
    modalDataSelectMultipleFields,
    modalDataChooseIhiNumber,
    modalDataUnableToVerifyIhi,
    setPatientIhiNeedsUpdate,
    handleUpdateIhiDetails,
    handleCloseModalVerifyIhiNumber,
    handleCloseModalSelectMultipleFields,
    handleCloseModalConfirmIhiNumber,
    handleCloseModalUnableToVerify,
    updateIHIData
  } = usePatientIhi({
    patientData: data,
    getPatientData,
    patientId,
    handleAlerts: handleSnackbarAlerts,
    setData
  });

  const HandleTabs = (event, newValue) => {
    setTab(newValue);
  };

  const validateEmail = (email) => {
    const pattern = /\S+@\S+\.\S+/;
    return pattern.test(email);
  };

  const updateEmail = async () => {
    if (data.email === '' || !validateEmail(data.email)) {
      setChangeEmail(true);
      iziToast.error({
        title: 'Invalid!',
        message: 'Enter a valid Email Address!',
        position: 'topRight'
      });
      return;
    }
    setChangeEmail(false);
    const sendBody = {
      email: data.email,
      prevEmail: prevEmail
    };
    const updatePatientEmail = await makePOST(`patient/update-email/${patientId}`, sendBody, 'updatePatientEmail');
    if (updatePatientEmail) {
      iziToast.success({
        title: 'Patient Email Updated',
        position: 'topRight'
      });
    } else {
      setChangeEmail(true);
      iziToast.error({
        title: 'Failed to Update Email!',
        message: 'Please try again later.',
        position: 'topRight'
      });
    }
  };

  const closeUpdateEmail = () => {
    setData({
      ...data,
      email: prevEmail
    });
    setChangeEmail(false);
  };

  const checkPDAisAvailable = () => {
    const checkMC = data?.medical_condition_1;
    let mcondition = false;
    for (let cmc of checkMC) {
      if (cmc === 1 || cmc === 2 || cmc === 3) {
        mcondition = true;
        break;
      }
    }

    if (
      Number(data?.medical_condition_2) === 1 ||
      Number(data?.medical_condition_2) === 2 ||
      Number(data?.medical_condition_2) === 3
    )
      mcondition = true;
    if (
      Number(data?.medical_condition_3) === 1 ||
      Number(data?.medical_condition_3) === 2 ||
      Number(data?.medical_condition_3) === 3
    )
      mcondition = true;
    if (
      Number(data?.medical_condition_4) === 1 ||
      Number(data?.medical_condition_4) === 2 ||
      Number(data?.medical_condition_4) === 3
    )
      mcondition = true;
    return mcondition;
  };

  const createDass = async () => {
    const sendBody = {
      patientId: patientId,
      userEmail: user?.email,
      uuid: dassAssessmentDetails?.uuid || generateUUid(),
      daysLinkValid: 5,
      patientEmail: data?.email,
      patientName: data?.FirstName
    };
    const resp = await makePOST(`dass/createDass`, sendBody, 'triggerDassAssessmentForm/createDass');
    if (resp?.status === 200) {
      iziToast.success({
        title: 'Sent Dass Assessment Email',
        position: 'topRight'
      });
    } else {
      iziToast.success({
        title: 'Failed to Sent Dass Assessment Email',
        position: 'topRight'
      });
    }
  };

  const deactivateDass = async () => {
    const sendBody = {
      patientId: patientId,
      userEmail: user?.email
    };
    await makePUT('dass/deactivateDass', sendBody, 'triggerDassAssessmentForm/deactivateDass');
  };

  const triggerDassAssessmentForm = async () => {
    if (
      (!medicalConditionDisabled && !dassAssessmentDetails?.link_send_count) ||
      dassAssessmentDetails?.link_send_count
    ) {
      const mcondition = checkPDAisAvailable();
      if (mcondition) {
        if (!medicalConditionDisabled && !dassAssessmentDetails?.link_send_count) {
          await createDass();
        }
      } else {
        if (dassAssessmentDetails?.link_send_count) {
          await deactivateDass();
        }
      }
    }
  };

  const checkMedicalConditionChanged = async () => {
    const validateFields = CheckRequiredFieldSchema.safeParse({
      isActive: data.isActive,
      dischargeProgressStatus: dischargeProgressStatus,
      street_line1: data.street.line1,
      suburb: data.suburb,
      postcode: data.postcode,
      state: data.state,
      homeDelivery: data.HomeDelivery,
      trustedPharmacy: data.trustedPharmacy
    });

    if (!validateFields.success) {
      const error = validateFields.error.issues.map((i) => i.path.join('.')).join(', ');
      if (validateFields.error.issues.find((i) => i.message === PARCEL_LOCKER_ERROR_MESSAGE)) {
        iziToast.error({
          title: PARCEL_LOCKER_ERROR_MESSAGE,
          position: 'topRight'
        });
      } else {
        iziToast.error({
          title: `Unable to save the patient details, please fill in the required fields - ${error}`,
          position: 'topRight'
        });
      }
      return;
    }

    try {
      setIsSaving(true);
      if (medicalConditionChanged) {
        await new Promise((resolve) => {
          iziToast.question({
            id: 'save-medical-condition',
            timeout: false,
            displayMode: 1,
            zindex: 999,
            overlay: true,
            message: 'Are you sure you want to update the medical condition of this patient?',
            position: 'center',
            buttons: [
              [
                '<button><b>Yes</b></button>',
                function (instance, toast) {
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'Yes');
                  resolve(onSave());
                },
                true
              ],
              [
                '<button>Cancel</button>',
                function (instance, toast) {
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'No');
                  resolve();
                }
              ]
            ]
          });
        });
      } else {
        await onSave();
      }
      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
      throw e;
    }
  };

  const onSave = async (saveConsult = true) => {
    iziToast.destroy();

    if (!data.isActive || dischargeProgressStatus) {
      return;
    }
    const sendBody = {
      user_details: {
        first_name: data.FirstName,
        last_name: data.LastName,
        email: data.email,
        phone: data.phone,
        address: `${data.street.line1}$$$$${data.street.line2}`,
        city: data.suburb,
        zip_code: data.postcode,
        state_id: data.state
      },
      patient_details: {
        medicare_number: data.medicareNumber,
        medicare_exp_date: data.medicareExpiration,
        medicare_ref_number: data.medicare_ref_number,
        dob: data.DoB,
        conditions: data.presentingComplaint,
        symptoms: data.symptoms,
        medications: data.trialledMedication,
        current_medications: data.currentMedicationList,
        tried_cannabis: data.cannabisExperience,
        smoking: data.smokingEx,
        alcohol_per_week: data.drinksPerWeek,
        allergies: data.allergies,
        cardio_disease: data.cardioDisease,
        psycosis: data.psycosis,
        comments: data.medicalHistory,
        atsi_status: data.atsi_status,
        consent: data.consent,
        gender: data.gender,
        preferred_pharmacy: data.preferedPharmacy,
        home_delivery: data.HomeDelivery,
        pharmacy_id: data.trustedPharmacy,
        gp_clinic: data.doctorClinic,
        gp_email: data.doctorEmail,
        gp_name: data.doctorName,
        gp_phone: data.doctorPhone,
        doctor_id: data.pmsDoctor,
        has_red_flag: data.redFlag,
        red_flag: data.redFlagComment,
        dva_number: data.dvaNumber,
        medical_condition_1: JSON.stringify(data.medical_condition_1),
        medical_condition_2: `${data.medical_condition_2}`,
        medical_condition_3: `${data.medical_condition_3}`,
        medical_condition_4: `${data.medical_condition_4}`,
        others_1_comment: data.others_1_comment,
        others_2_comment: data.others_2_comment,
        is_allergy: allergies,
        is_cardio_disease: cardioDisease,
        emergency_contact_first_name: data.emergency_contact_first_name,
        emergency_contact_last_name: data.emergency_contact_last_name,
        emergency_contact_relationship: data.emergency_contact_relationship,
        emergency_contact_phone_number: data.emergency_contact_phone_number
      },
      patient_assessment: {
        patient_details_confirmed: data.detailsConfirmed,
        medical_summary_verified: data.medicalSummary,
        payment_verified: data.PaymentVerified,
        drug_drug: data.DrugDrugComment,
        falls: data.fallsComment,
        pregnant_lactating: data.LactatingComment,
        substance_abuse: data.SubstanceAbuseComment,
        recently_incarcerated: data.RecentlyIncarceratedComment,
        si_tosh: data.SiToshComment,
        mc_process_explained: data.MCprocess,
        treatment_goal: data.treatmentGoal,
        has_pschosis: data.PsychosisHx,
        has_drug_drug: data.DrugDrug,
        has_substance_abuse: data.SubstanceAbuse,
        has_falls: data.falls,
        has_pregnant_lactating: data.Lactating,
        has_recently_incarcerated: data.RecentlyIncarcerated,
        has_si_tosh: data.SiTosh,
        notes: data.notes
      }
    };

    const postPatient = await savePatientDetail(patientId, sendBody);

    if (!postPatient) {
      return;
    }

    await Promise.all([triggerDassAssessmentForm(), saveConsult && saveConsultation(null, false, false)]);
    iziToast.success({
      title: 'Patient Details Updated',
      position: 'topRight'
    });
    setChanged(false);
    setMedicalConditionChanged(false);
    setChangeEmail(false);

    await updateIHIData();

    getPatientData();
  };

  const savePatientDetail = async (patientId, body) => {
    try {
      const response = await axios.post(`${API_URL}/patient/${patientId}`, body);
      return response.data;
    } catch (err) {
      iziToast.error({
        title: 'Failed to Update!',
        message: err.response?.data?.message ?? err.message,
        position: 'topRight'
      });
    }
  };

  const saveConsultation = async (consultationData, askConfirmation = true, fetchData = true) => {
    if (!showConsultation) return;
    if (!askConfirmation) {
      makeSaveConsultationRequest(consultationData).then(() => {
        if (fetchData) {
          getPatientData();
        }
      });
      return;
    }
    if (consultationData.status_id === settings.consultationStatus.ineligible && !consultationData.sub_status_id) {
      iziToast.error({
        title: 'You must select an Ineligibility Reason',
        message: 'Please use the drop down to select the reason and then resubmit',
        position: 'center'
      });
      return;
    }
    iziToast.question({
      id: 'save-consultation-question',
      timeout: false,
      displayMode: 1,
      zindex: 999,
      overlay: true,
      title: 'Save Consultation',
      message:
        contents.consultations.find((x) => x.id === showConsultation.id).status_id === showConsultation.status_id
          ? 'Are you sure to proceed with the current consultation status?'
          : 'Are you sure to update the consultation status?',
      position: 'center',
      buttons: [
        [
          '<button><b>Yes</b></button>',
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'Yes');
          },
          true
        ],
        [
          '<button>Cancel</button>',
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'No');
          }
        ]
      ],

      onClosed: async (instance, toast, closedBy) => {
        if (closedBy === 'Yes') {
          try {
            /* Adding the try catch here instead of onSave to not modify any
                           existing functionality as its used in a few others places
                           that we are not touching at the moment. */
            await onSave(false);
          } catch (err) {
            console.error(err);
          }
          makeSaveConsultationRequest(consultationData).then(() => {
            if (fetchData) {
              getPatientData();
            }
          });
          setShowConsultation(null);
          setConsultationSubStatus(null);
        }
      }
    });
  };

  const makeSaveConsultationRequest = (consultationData) =>
    makePOST(
      `consultation/${showConsultation.id}`,
      {
        data: consultationData
          ? consultationData
          : {
              id: showConsultation.id,
              notes: showConsultation.notes,
              status_id: showConsultation.status_id,
              sub_status_id: consultationSubStatus?.subStatusId
            }
      },
      'saveConsultation-PatientDetials'
    ).then((saveConsult) => {
      if (saveConsult) {
        iziToast.success({
          title: 'Consultation Saved.',
          position: 'topRight'
        });
        setChanged(false);
        ldClient?.track('consultation-complete');
        datadogRum.addAction('consultation-complete');
      } else
        iziToast.error({
          title: 'Failed to Save Consultation!',
          message: 'Please try again later.',
          position: 'topRight'
        });
    });

  useEffect(() => {
    if (isDoctor() && contents?.isDoctorTransferred?.status) {
      const prescriptionData = contents?.prescriptions ? listSorter(contents?.prescriptions, 'order_date') : [];
      const presData = [];
      for (let pres of prescriptionData) {
        for (let item of pres.PrescribedProducts) {
          if (item.Product && item.Product.formulation_id !== 5) {
            presData.push([
              item.created_date,
              (item.Product && item.Product.name) || item.other_product,
              item.quantity,
              item.interval,
              item.repeats
            ]);
          }
        }
      }
      setMedicationData(presData);
      if (presData.length > 0) {
        setAcknowledgementModalOpen(true);
      }
    }
  }, [contents?.prescriptions, contents?.isDoctorTransferred]);

  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === 'medical_condition_panel') {
      setExpanded(isExpanded);
    } else if (panel === 'medical_summary_panel') {
      setSummary(isExpanded);
    }
  };

  useEffect(() => {
    makeGET('dass/medicalConditions', 'getMedicalConditions-PatientDetails').then((res) => {
      setMedicalConditionOtherId(
        res.filter((ele) => ele.condition_name.toLowerCase() === 'other').map((val) => val.id)[0]
      );
      setMedicalConditionList(res);
    });
  }, []);

  async function fetchDassAssessmentCalculation() {
    const dassResp = await makeGET(
      `dass/dassAssessmentCalculation?patientId=${patientId}`,
      'PatientPageV2/MedicalCondition-dassAssessmentCalculation'
    );
    if (dassResp) {
      setDassAssessmentScoreDetail(dassResp);
    } else {
      setDassAssessmentScoreDetail(emptyDassAssessmentScore);
    }
  }

  async function fetchDassAssessment() {
    try {
      const resp = await makeGET(
        `dass/getDass?patientId=${patientId}`,
        'PatientPageV2/MedicalCondition/fetchDassAssessment'
      );
      if (resp) {
        const patientValidityResp = await makeGET(`dass/patientDassValidity?patientId=${patientId}&uuid=${resp.uuid}`);
        setDassAssessmentDetails(patientValidityResp);

        if (resp.is_submitted) {
          fetchDassAssessmentCalculation();
        }
      } else {
        setDassAssessmentDetails(emptyDassAssessmentDetails);
        fetchDassAssessmentCalculation();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handledischargePatient = async (body) => {
    if (body.dischargeReasonCode !== 'D_ACTIVE') {
      setDischargeProgressStatus(true);
    }
    const resp = await makePUT('patient/updateDischargeStatus', body, 'MergeProfile/handleDischargeSubmit');
    setDischargeProgressStatus(false);
    getPatientData();
    if (resp) {
      iziToast.success({
        title: 'Success',
        timeout: 3000,
        message: `${data?.FirstName} ${data?.LastName} - ${data?.patient_code} discharged successfully`,
        position: 'topRight'
      });
    } else {
      iziToast.error({
        title: 'Error',
        timeout: 3000,
        message: `${data?.FirstName} ${data?.LastName} - ${data?.patient_code} discharge Failed`,
        position: 'topRight'
      });
    }
  };

  // Manually update state from this data to prevent a full network request/refresh, this also prevents wiping dirty
  // fields like notes.
  function handleCreditCardHasBeenCharged(consultationId) {
    setContents({
      ...contents,
      consultations: contents.consultations.map((consult) => {
        if (consult.id === consultationId) {
          return {
            ...consult,
            payment_queued: true
          };
        }
        return consult;
      })
    });
    if (showConsultation) {
      setShowConsultation((prevShowConsultation) => ({ ...prevShowConsultation, payment_queued: true }));
    }
  }

  // Manually update state from this data to prevent a full network request/refresh, this also prevents wiping dirty
  // fields like notes.
  function handleInvoiceHasBeenCreated(consultationId, invoiceCode) {
    setContents({
      ...contents,
      consultations: contents.consultations.map((consult) => {
        if (consult.id === consultationId) {
          return {
            ...consult,
            xero_consultation_code: invoiceCode
          };
        }
        return consult;
      })
    });
    if (showConsultation) {
      setShowConsultation((prevShowConsultation) => ({
        ...prevShowConsultation,
        xero_consultation_code: invoiceCode
      }));
    }
  }

  const reSendConsentForm = async () => {
    const response = await makePOST('patient/consent-form/resend', {
      patientId: patientId
    });
    if (response) {
      iziToast.success({ message: 'Consent Form Link is sent via Email', position: 'topRight' });
      setContents({ ...contents, showConsentFormResendLink: false });
    } else {
      iziToast.error({ message: 'Failed to send Consent Form. please try again.', position: 'topRight' });
    }
  };

  const PotentialTokenIssuesInfo = ({ issues }) => {
    if (!issues || !issues.length) {
      return;
    }

    return (
      <>
        <Alert sx={{ my: 7 }} severity="error">
          <b>eScript fail:</b> eScript token creation may not be possible for this patient due to:
        </Alert>
        <List style={{ backgroundColor: '#fff', padding: '0' }}>
          {issues.map((property) => (
            <ListItem key={property} style={{ borderBottom: '1px solid #eee', padding: '2px 0' }}>
              <ListItemText primary={property} style={{ color: '#444', margin: '0', padding: '0' }} />
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  const handleSelectedAddress = (addressData) => {
    const formattedAddress = `${addressData.subpremise} ${addressData.street_number} ${addressData.address}`.trim();
    setData({
      ...data,
      street: {
        line1: formattedAddress,
        line2: ''
      },
      suburb: addressData.suburb,
      postcode: addressData.postcode,
      state: stateList?.find((x) => x.name === addressData.state)?.id || ''
    });
  };

  const handleReset = () => {
    setData({
      ...data,
      street: {
        line1: '',
        line2: ''
      },
      suburb: '',
      postcode: '',
      state: ''
    });
  };

  return (
    <>
      <Grid
        container
        className="inputfield-container-v2"
        paddingX={{
          sm: 1,
          md: 3,
          lg: 6
        }}
        paddingY={3}
        columnSpacing={{
          xs: 0,
          sm: 4,
          md: 7,
          lg: 7,
          xl: 8
        }}
      >
        {/* Patient medical condition and summary */}
        <Grid xs={4} item>
          <Box>
            <Box mx={1}>
              {/* "Header" */}
              <Box>
                {/** Actions: Discharge patient & Save Changes */}
                <Box
                  display="flex"
                  flexDirection={{
                    xs: 'column-reverse',
                    sm: 'column-reverse',
                    md: 'row',
                    lg: 'row'
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                  py={2}
                  gap={1}
                >
                  {!dischargeProgressStatus && data.isActive && (isNurse || isSuperAdmin || isManager) && (
                    <>
                      {!isAdminOnly && data?.discharge_status !== 'Active' && (
                        <>
                          <Link
                            onClick={() => setDischargeModal(true)}
                            sx={{
                              color: 'warning',
                              textDecoration: 'underline !important',
                              cursor: 'pointer',
                              fontWeight: '600',
                              fontSize: '14px'
                            }}
                          >
                            Discharge Patient
                          </Link>
                          <DischargePatientModal
                            open={dischargeModalOpen}
                            setOpen={handleDischargeModalClose}
                            data={data}
                            patient_id={patientId}
                            userId={user?.id}
                            submitDischargePatient={handledischargePatient}
                          />
                        </>
                      )}
                    </>
                  )}

                  <Box>
                    {data.isActive ? (
                      <Button
                        sx={{ textTransform: 'capitalize', fontSize: '14px', fontWeight: '500' }}
                        variant="contained"
                        size="medium"
                        color="secondary"
                        data-testid="saveBtn"
                        onClick={checkMedicalConditionChanged}
                        disabled={isSaving || !data.isActive || dischargeProgressStatus}
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Box className={classes.statusBullet}>
                        {data.discharge_status ? data.discharge_status : 'Inactive'}
                      </Box>
                    )}
                    {dischargeProgressStatus ? (
                      <Box pt={0.5} fontSize={12} textAlign="center">
                        Discharge In-Progress
                      </Box>
                    ) : (
                      ''
                    )}
                    {contentChanged ? (
                      <Box pt={0.5} fontSize={8} textAlign="center" color="gray">
                        Unsaved Changes
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>

                {/** Patient Names and basic info */}
                <Box pl={0.5} my={3} letterSpacing={0.15}>
                  <Box fontSize={20} fontWeight={600} lineHeight={1.6}>
                    {isLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <CircularProgress size={25} />
                        <Typography variant="h4">Loading</Typography>
                      </Box>
                    )}
                    {data.FirstName} {data.LastName}
                    {data.redFlag && (
                      <Tooltip title={data.redFlagComment || ''}>
                        <Flag fontSize="small" color="error" />
                      </Tooltip>
                    )}
                  </Box>
                  <Box fontSize={14} lineHeight={1.5} data-dd-privacy="allow">
                    {data.patient_code}
                  </Box>
                  <Box fontSize={14} lineHeight={1.5}>
                    DoB: {moment(data.DoB).format('DD-MMM-YYYY')}
                  </Box>
                  <Box fontSize={14} lineHeight={1.5}>
                    Age: {data.calculatedAge}
                  </Box>
                </Box>
              </Box>
              {/** Concession Status */}

              <Box marginTop={1}>
                <ManageConcession
                  patientId={patientId}
                  ready={!concessionCardLoading}
                  concessionCard={concessionCardData}
                  isConcessionCardValid={isConcessionValid}
                  onChangeConcessionCard={handleChangeConcessionCard}
                />
              </Box>

              {isAdminOnly && (
                <div style={{ marginTop: '15px' }}>
                  <Accordion expanded={expanded} onChange={handleChange('dass_completion_panel')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Box mt={3} p={0.5} component="h2" className={classes.heading}>
                        DASS Completion
                      </Box>
                    </AccordionSummary>
                    <div className={classes.accordionDetail}>
                      {dassAssessmentDetails.is_submitted ? (
                        <Chip
                          color="success"
                          label={`DASS Completed ${moment(dassAssessmentDetails.submitted_date).format('DD-MM-YYYY')}`}
                        />
                      ) : (
                        <Chip color="warning" label="DASS Not Completed" />
                      )}
                    </div>
                  </Accordion>
                </div>
              )}

              {!isAdminOnly && (
                <div style={{ marginTop: '15px' }}>
                  <Accordion expanded={expanded} onChange={handleChange('medical_condition_panel')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Box mt={3} p={0.5} component="h2" className={classes.heading}>
                        Medical Condition
                      </Box>
                    </AccordionSummary>
                    <MedicalCondition
                      data={data}
                      setData={setData}
                      medicalConditionDisabled={medicalConditionDisabled || !data.isActive || dischargeProgressStatus}
                      setChanged={setChanged}
                      medicalConditionList={medicalConditionList}
                      medicalConditionOtherId={medicalConditionOtherId}
                      dassAssessmentDetails={dassAssessmentDetails}
                      dassAssessmentScoreDetail={dassAssessmentScoreDetail}
                      patientId={patientId}
                      userEmail={user?.email}
                      isDoc={isDoc}
                      setMedicalConditionChanged={setMedicalConditionChanged}
                      checkPDAisAvailable={checkPDAisAvailable}
                      fetchDassAssessment={fetchDassAssessment}
                    />
                  </Accordion>

                  <Accordion
                    expanded={summary}
                    style={{ marginTop: '10px' }}
                    onChange={handleChange('medical_summary_panel')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box mt={3} p={0.5} component="h2" className={classes.heading}>
                        Medical Summary
                      </Box>
                    </AccordionSummary>
                    <div className={classes.accordionDetail}>
                      <InputField
                        id="presenting-complaint"
                        label="Presenting Complaint"
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        disabled={!data.isActive || dischargeProgressStatus}
                        margin={0.5}
                        InputClasses="textarea-h-25-px"
                        value={data.presentingComplaint}
                        onChange={handleDataInput('presentingComplaint')}
                      />
                      <InputField
                        id="symptoms"
                        label="Symptoms"
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        disabled={!data.isActive || dischargeProgressStatus}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        margin={0.5}
                        InputClasses="textarea-h-50-px"
                        value={data.symptoms}
                        onChange={handleDataInput('symptoms')}
                      />
                      <InputField
                        id="trialled-medication"
                        disabled={!data.isActive || dischargeProgressStatus}
                        label="Trialled Medication"
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        margin={0.5}
                        InputClasses="textarea-h-50-px"
                        value={data.trialledMedication}
                        onChange={handleDataInput('trialledMedication')}
                      />
                      <InputField
                        id="medication-list"
                        label="Current Medication List"
                        disabled={!data.isActive || dischargeProgressStatus}
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        margin={0.5}
                        InputClasses="textarea-h-50-px"
                        value={data.currentMedicationList}
                        onChange={handleDataInput('currentMedicationList')}
                      />
                      <InputField
                        id="allergies"
                        label="Allergies"
                        disabled={!data.isActive || dischargeProgressStatus}
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        margin={0.5}
                        InputClasses="textarea-h-25-px"
                        value={data.allergies}
                        onChange={handleDataInput('allergies')}
                        isSwitch={true}
                        checked={allergies}
                        setRadioButton={setAllergies}
                        isBlank={!allergies}
                        name="allergies"
                      />
                      <InputField
                        id="cardio-problems"
                        disabled={!data.isActive || dischargeProgressStatus}
                        label="Cardiovascular Disease"
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        margin={0.5}
                        InputClasses="textarea-h-25-px"
                        value={data.cardioDisease}
                        onChange={handleDataInput('cardioDisease')}
                        isSwitch={true}
                        checked={cardioDisease}
                        setRadioButton={setCardioDisease}
                        isBlank={!cardioDisease}
                      />
                      <InputField
                        id="medical-history"
                        label="Other Medical History"
                        disabled={!data.isActive || dischargeProgressStatus}
                        type="text"
                        placeholder="-"
                        customGrid={[12, 12]}
                        display="block"
                        multiline
                        labelPadding="5px 0px 2px 0px"
                        margin={0.5}
                        InputClasses="textarea-h-50-px"
                        value={data.medicalHistory}
                        onChange={handleDataInput('medicalHistory')}
                      />
                      <InputField
                        id="weekly-drinks"
                        disabled={!data.isActive || dischargeProgressStatus}
                        label="ETOH/Week"
                        type="text"
                        placeholder="-"
                        labelPadding="7px 5px"
                        InputClasses="custom-details-field"
                        value={data.drinksPerWeek}
                        onChange={handleDataInput('drinksPerWeek')}
                      />
                      <InputField
                        id="smoking"
                        disabled={!data.isActive || dischargeProgressStatus}
                        label="Smoking Hx"
                        type="text"
                        placeholder="-"
                        labelPadding="7px 5px"
                        InputClasses="custom-details-field"
                        value={data.smokingEx}
                        onChange={handleDataInput('smokingEx')}
                      />
                    </div>
                  </Accordion>
                </div>
              )}
            </Box>
          </Box>
        </Grid>
        {/* END Patient medical condition and summary */}
        {/* Tab Panel column  */}
        <Grid item xs={8}>
          <Box pb={5} minHeight="87vh" className={classes.TabsRoot}>
            <AppBar position="static" classes={{ root: classes.Tabs }} color="transparent">
              <Tabs
                value={TabValue}
                onChange={HandleTabs}
                centered
                variant={isSmallerScreen ? 'scrollable' : 'fullWidth'}
                scrollButtons="auto"
              >
                <Tab label="Patient Details" />
                <Tab label="Consultations" />
                <Tab label="QOL" />
                <Tab label="Prescriptions" />
                <Tab label="Patient Documentation" />
              </Tabs>
            </AppBar>
            <TabPanel value={TabValue} index={0} className="less-padding">
              {!isLoading && <IhiNumberStatusBanner ihiStatus={patientIhiStatus} />}
              <Typography mt={7} variant="h2" className={classes.heading}>
                Patient
              </Typography>
              <Box width="100%" display="flex" my={7}>
                <Box width="50%" pr={10}>
                  <InputField
                    id="first-name"
                    label="First Name"
                    disabled={!data.isActive || dischargeProgressStatus}
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.FirstName}
                    onChange={handleDataInput('FirstName')}
                  />
                  <DateField
                    id="dob"
                    label="DoB"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    variant="outlined"
                    disabled={!data.isActive || dischargeProgressStatus}
                    value={data.DoB}
                    onChange={handleDataInput('DoB')}
                  />
                  <InputField
                    id="gender"
                    label="Gender"
                    type="text"
                    placeholder="-"
                    disabled={!data.isActive || dischargeProgressStatus}
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.gender}
                    onChange={handleDataInput('gender')}
                  />
                  <SelectField
                    label="Aboriginal / Torres Strait Islander"
                    aria-label="Aboriginal and/or Torres Strait Islander"
                    id="atsi-status"
                    options={atsiOptions.map((fieldValue) => ({ name: fieldValue, value: fieldValue }))}
                    onChange={handleDataInput('atsi_status')}
                    value={data.atsi_status}
                    varient="outlined"
                    disabled={!data.isActive || dischargeProgressStatus}
                    customGrid={[12, 12]}
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                  />
                </Box>
                <Box width="50%">
                  <InputField
                    id="last-name"
                    disabled={!data.isActive || dischargeProgressStatus}
                    label="Last Name"
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.LastName}
                    onChange={handleDataInput('LastName')}
                  />
                  <InputField
                    id="dvaNumber"
                    label="DVA Number"
                    type="text"
                    disabled={!data.isActive || dischargeProgressStatus}
                    placeholder="-"
                    data-testid="dvaNumber"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.dvaNumber}
                    onChange={handleDataInput('dvaNumber')}
                  />
                  {(isAdmin || isNurse || isManager) && (
                    <Box component={Grid} width="100%" display="flex">
                      <Grid xs={4} item={true}>
                        <Box
                          textAlign={'left'}
                          p={'7px 5px 7px 0'}
                          fontWeight={600}
                          margin="0.75rem 0.25rem 0.75rem 0"
                          display="flex"
                        >
                          <label>{'Doctor'}</label>
                        </Box>
                      </Grid>
                      <Grid xs={8} item={true}>
                        <SearchableSelect
                          id="doctor-pms"
                          label="Doctor"
                          options={doctorDropdownList?.map((itm) => ({
                            name: `${itm.name} - ${itm.doctor_code}`,
                            id: itm.id
                          }))}
                          labelPadding="7px 5px 7px 0"
                          margin="0.75rem 0.25rem 0.75rem 0"
                          disabled={!data.isActive || dischargeProgressStatus}
                          value={data.pmsDoctor}
                          onChange={handleDataInput('pmsDoctor')}
                          varient="outlined"
                          lessPadding
                          customGrid={[0, 12]}
                        />
                      </Grid>
                    </Box>
                  )}
                  <CheckBoxField
                    id="consent"
                    disabled={true}
                    label="Consent"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    checkboxClasses={{ root: classes.checkbox }}
                    data-dd-privacy="allow"
                    icon={
                      <Box width="15px" height="15px" m={0.5} border={1} borderRadius="2px" borderColor="#c4c4c4" />
                    }
                    labelFontWeight={900}
                    checked={data.consent}
                    InputClasses="custom-adress-field"
                    checkBoxSuffix={
                      contents.showConsentFormResendLink && (
                        <Typography
                          variant="a"
                          mt={0.5}
                          color="secondary.main"
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={reSendConsentForm}
                        >
                          Resend Consent Form
                        </Typography>
                      )
                    }
                  />
                </Box>
              </Box>
              <Typography variant="h2" mt={1} className={classes.heading}>
                Contact Details
              </Typography>
              <Box width="100%" display="flex" my={7}>
                <Box width="50%" pr={10}>
                  <InputField
                    id="phone"
                    label="Phone"
                    disabled={!data.isActive || dischargeProgressStatus}
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.phone}
                    onChange={handleDataInput('phone')}
                  />
                </Box>
                <Box width="47%">
                  <InputField
                    id="email"
                    label="Email"
                    type="text"
                    placeholder="-"
                    disabled={!data.isActive || dischargeProgressStatus}
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.email}
                    onChange={(isAdmin || isNurse || isManager) && changeEmail ? handleDataInput('email') : () => {}}
                  />
                </Box>
                {(isAdmin || isNurse || isManager) && (
                  <Box width="2%" pr={2} mt={3}>
                    {changeEmail ? (
                      <Box ml={3} mt={1.75}>
                        <Box
                          className={`fa fa-check-circle ${classes.cursorPointer}`}
                          onClick={updateEmail}
                          color="#194EFF"
                        />
                        <Box
                          className={`fa fa-times-circle ${classes.cursorPointer}`}
                          onClick={closeUpdateEmail}
                          color="#FF0000"
                        />
                      </Box>
                    ) : (
                      <Box
                        ml={3}
                        mt={2}
                        fontSize={12}
                        color="#194EFF"
                        className={`fa fa-edit ${classes.cursorPointer}`}
                        onClick={() => setChangeEmail(true)}
                      ></Box>
                    )}
                  </Box>
                )}
              </Box>

              <Typography variant="h2" mt={1} className={classes.heading}>
                Emergency Contact
              </Typography>
              <Box width="100%" display="flex" my={7}>
                <Box width="50%" pr={10}>
                  <InputField
                    id="emegency-contact-first-name"
                    label="First Name"
                    disabled={!data.isActive || dischargeProgressStatus}
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.emergency_contact_first_name}
                    onChange={handleDataInput('emergency_contact_first_name')}
                  />
                  <InputField
                    id="emegency-contact-relationship"
                    label="Relationship"
                    disabled={!data.isActive || dischargeProgressStatus}
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.emergency_contact_relationship}
                    onChange={handleDataInput('emergency_contact_relationship')}
                  />
                </Box>
                <Box width="50%">
                  <InputField
                    id="emegency-contact-last-name"
                    label="Last Name"
                    disabled={!data.isActive || dischargeProgressStatus}
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.emergency_contact_last_name}
                    onChange={handleDataInput('emergency_contact_last_name')}
                  />
                  <InputField
                    id="emegency-contact-phone"
                    label="Phone"
                    disabled={!data.isActive || dischargeProgressStatus}
                    type="tel"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.emergency_contact_phone_number}
                    onChange={handleDataInput('emergency_contact_phone_number')}
                  />
                </Box>
              </Box>

              <Typography variant="h2" mt={1} className={classes.heading}>
                Address
              </Typography>
              <Box width="100%" my={7}>
                <InputField
                  id="residentialAddress"
                  label="Residence"
                  type="text"
                  customGrid={[2, 10]}
                  value={formatResidentialAddress(data.residential_address)}
                  helperText={`${
                    data.residential_address?.validated ? 'IHI verified address, to' : 'To'
                  } be used when the patient has a PO Box listed below`}
                  IsReadOnly
                  disabled
                  labelPadding="7px 5px 7px 0"
                  margin="0.75rem 0.25rem 0.75rem 0"
                />

                {isGooglePlacesAutocompleteEnabled ? (
                  <Box component={Grid} width="100%" display="flex">
                    <Grid xs={2} item>
                      <Box component="div" display="flex" padding="7px 5px 7px 0">
                        <label style={{ fontWeight: 600 }}>Street Address *</label>
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <LocationInput
                        googlePlacesApiKey={settings.googlePlacesApiKey}
                        placeholder="-"
                        value={data.street.line1 || ''}
                        onSelectedAddress={(addressData) => {
                          handleSelectedAddress(addressData);
                        }}
                        onReset={() => handleReset()}
                        manualAddressFn={(x) => x}
                      />
                    </Grid>
                  </Box>
                ) : (
                  <InputField
                    id="street"
                    label="Street Address *"
                    type="text"
                    disabled={!data.isActive || dischargeProgressStatus}
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    customGrid={[2, 10]}
                    InputClasses="custom-adress-field"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    value={data.street.line1 || ''}
                    onChange={handleAddressInput('line1')}
                    error={data.street.line1 === '' && !isLoading}
                    helperText={data.street.line1 === '' && !isLoading ? 'Street Address is required' : ''}
                  />
                )}
                <InputField
                  id="address-line-2"
                  label="Address Line 2"
                  type="text"
                  disabled={!data.isActive || dischargeProgressStatus}
                  placeholder="-"
                  labelPadding="7px 5px 7px 0"
                  margin="0.75rem 0.25rem 0.75rem 0"
                  customGrid={[2, 10]}
                  InputClasses="custom-address-field"
                  value={data.street.line2 || ''}
                  onChange={handleAddressInput('line2')}
                />
                <Box width="100%" display="flex">
                  <Box width="50%" pr={10}>
                    <InputField
                      id="suburb"
                      disabled={!data.isActive || dischargeProgressStatus}
                      label="Suburb *"
                      type="text"
                      placeholder="-"
                      labelPadding="7px 5px 7px 0"
                      margin="0.75rem 0.25rem 0.75rem 0"
                      value={data.suburb}
                      onChange={handleDataInput('suburb')}
                      error={data.suburb === '' && !isLoading}
                      helperText={data.suburb === '' && !isLoading ? 'Suburb is required' : ''}
                    />
                    <InputField
                      id="postcode"
                      label="Postcode *"
                      type="text"
                      placeholder="-"
                      disabled={!data.isActive || dischargeProgressStatus}
                      labelPadding="7px 5px 7px 0"
                      margin="0.75rem 0.25rem 0.75rem 0"
                      value={data.postcode}
                      onChange={handleDataInput('postcode')}
                      error={data.postcode === '' && !isLoading}
                      helperText={data.postcode === '' && !isLoading ? 'Postcode is required' : ''}
                    />
                  </Box>
                  <Box width="50%">
                    <Box component={Grid} container width="100%" display="flex">
                      <Grid item xs={4}>
                        <Box
                          textAlign={'left'}
                          p={'7px 5px 7px 0'}
                          fontWeight={600}
                          margin="0.75rem 0.25rem 0.75rem 0"
                          display="flex"
                        >
                          <label>{'State *'}</label>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <SearchableSelect
                          id="state"
                          label="State *"
                          options={stateList}
                          disabled={!data.isActive || dischargeProgressStatus}
                          labelPadding="7px 5px 7px 0"
                          margin="0.75rem 0.25rem 0.75rem 0"
                          value={data.state}
                          onChange={handleDataInput('state')}
                          varient="outlined"
                          lessPadding
                          customGrid={[0, 12]}
                          error={data.state === '' && !isLoading}
                          touched={true}
                          helperText={data.state === '' && !isLoading ? 'State is required' : ''}
                        />
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {!isAdminOnly && (
                <>
                  <Typography variant="h2" mt={1.5} className={classes.heading}>
                    Treating Doctor
                  </Typography>
                  <Box width="100%" display="flex" my={7}>
                    <Box width="50%" pr={10}>
                      <InputField
                        id="doctor-name"
                        label="Full Name"
                        type="text"
                        placeholder="-"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        disabled={!data.isActive || dischargeProgressStatus}
                        value={data.doctorName}
                        onChange={handleDataInput('doctorName')}
                      />
                      <InputField
                        id="clinic-name"
                        label="Clinic Name"
                        type="text"
                        placeholder="-"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        disabled={!data.isActive || dischargeProgressStatus}
                        value={data.doctorClinic}
                        onChange={handleDataInput('doctorClinic')}
                      />
                    </Box>
                    <Box width="50%">
                      <InputField
                        id="doctor-email"
                        label="Email"
                        type="text"
                        disabled={!data.isActive || dischargeProgressStatus}
                        placeholder="-"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        value={data.doctorEmail}
                        onChange={handleDataInput('doctorEmail')}
                      />
                      <InputField
                        id="doctor-phone"
                        label="Phone"
                        type="text"
                        disabled={!data.isActive || dischargeProgressStatus}
                        placeholder="-"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        value={data.doctorPhone}
                        onChange={handleDataInput('doctorPhone')}
                      />
                    </Box>
                  </Box>
                </>
              )}
              <Typography variant="h2" mt={1.5} className={classes.heading}>
                Medicare
              </Typography>
              <Box width="100%" display="flex" my={7}>
                <Box width="50%" pr={10}>
                  <InputField
                    id="medicare-number"
                    label="Number"
                    type="text"
                    placeholder="-"
                    disabled={!data.isActive || dischargeProgressStatus}
                    labelPadding="7px 5px 7px 0"
                    value={data.medicareNumber}
                    onChange={handleDataInput('medicareNumber')}
                    margin="0.75rem 0.25rem 0.75rem 0"
                  />
                  <InputField
                    id="medicare_ref_number"
                    label="Card IRN"
                    type="text"
                    placeholder="-"
                    disabled={!data.isActive || dischargeProgressStatus}
                    labelPadding="7px 5px 7px 0"
                    value={data.medicare_ref_number}
                    onChange={handleDataInput('medicare_ref_number')}
                    margin="0.75rem 0.25rem 0.75rem 0"
                  />
                </Box>
                <Box width="50%">
                  <InputField
                    id="medicare-expiration"
                    label="Expiration"
                    type="text"
                    placeholder="-"
                    disabled={!data.isActive || dischargeProgressStatus}
                    labelPadding="7px 5px 7px 0"
                    value={data.medicareExpiration}
                    onChange={handleDataInput('medicareExpiration')}
                    margin="0.75rem 0.25rem 0.75rem 0"
                  />
                </Box>
              </Box>
              {concessionCardData && (
                <>
                  <Typography variant="h2" mt={1.5} className={classes.heading}>
                    Concession card
                  </Typography>
                  <Box width="100%" display="flex" my={7}>
                    <Box width="50%" pr={10}>
                      <InputField
                        label="Card number"
                        type="text"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        disabled={true}
                        placeholder="-"
                        value={concessionCardData?.cardNumber}
                      />
                      <InputField
                        label="Status"
                        type="text"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        disabled={true}
                        placeholder="-"
                        value={isConcessionValid ? 'Active' : 'Expired'}
                      />
                    </Box>
                    <Box width="50%">
                      <InputField
                        label="Card type"
                        type="text"
                        placeholder="-"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        disabled={true}
                        value={concessionCardData?.cardType}
                      />
                      <InputField
                        label="Expiration"
                        type="text"
                        placeholder="-"
                        labelPadding="7px 5px 7px 0"
                        margin="0.75rem 0.25rem 0.75rem 0"
                        disabled={true}
                        value={
                          concessionCardData?.expiryDay &&
                          concessionCardData?.expiryMonth &&
                          concessionCardData?.expiryDay &&
                          concessionCardData?.cardType &&
                          formatExpirationForCardType(
                            parseMomentYearMonthDay(
                              concessionCardData.expiryYear,
                              concessionCardData.expiryMonth,
                              concessionCardData.expiryDay
                            ),
                            concessionCardData.cardType
                          )
                        }
                      />
                    </Box>
                  </Box>
                </>
              )}
              <Typography variant="h2" mt={1.5} className={classes.heading}>
                IHI information
              </Typography>
              <Box width="100%" display="flex" my={7}>
                <Box width="50%" pr={10}>
                  <InputField
                    id="ihiNumber"
                    label="IHI Number"
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    disabled={!data.isActive || dischargeProgressStatus}
                    onChange={handleDataInput('ihi_number')}
                    value={data.ihi_number}
                  />
                  <InputField
                    id="ihiNumberStatus"
                    label="Number Status"
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    disabled={true}
                    value={data.ihi_number_status}
                  />
                </Box>
                <Box width="50%">
                  <InputField
                    id="ihiRecordStatus"
                    label="Record Status"
                    type="text"
                    placeholder="-"
                    labelPadding="7px 5px 7px 0"
                    margin="0.75rem 0.25rem 0.75rem 0"
                    disabled={true}
                    value={data.ihi_record_status}
                  />
                </Box>
              </Box>
              <Typography variant="h2" mt={1.5} className={classes.heading}>
                Pharmacy Preference
              </Typography>
              <Box width="100%" my={7}>
                <RadioGroup
                  row
                  aria-label="home delivery"
                  name="homeDelivery"
                  value={deliveryOption}
                  onChange={async (e) => {
                    setDelivery(e.target.value);
                    if (e.target.value === '0') {
                      setData({ ...data, HomeDelivery: true });
                    } else {
                      setData({ ...data, HomeDelivery: false });
                    }
                    setChanged(true);
                  }}
                >
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio color="primary" size="small" disabled={!data.isActive || dischargeProgressStatus} />
                    }
                    label="Home Delivery"
                  />
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio color="primary" size="small" disabled={!data.isActive || dischargeProgressStatus} />
                    }
                    label="Trusted Pharmacy"
                  />
                </RadioGroup>
                {deliveryOption === '2' && (
                  <SearchableSelect
                    id="trusted-pharmacy"
                    label="Trusted Pharmacy"
                    options={pharmacyList}
                    labelPadding="7px"
                    customGrid={[2, 10]}
                    InputClasses="custom-adress-field"
                    value={data.trustedPharmacy}
                    lastField
                    lessPadding
                    onChange={handleDataInput('trustedPharmacy')}
                    error={!data.trustedPharmacy && !isLoading}
                    touched
                    helperText={!data.trustedPharmacy && !isLoading ? 'A pharmacy is required' : ''}
                  />
                )}
                <PotentialTokenIssuesInfo issues={contents.propertiesNeedingAttentionBeforeOrdering} />
              </Box>
            </TabPanel>
            <TabPanel value={TabValue} index={1}>
              <ConsultationTab
                onCreditCardHasBeenCharged={handleCreditCardHasBeenCharged}
                onInvoiceHasBeenCreated={handleInvoiceHasBeenCreated}
                data={{
                  consultations: contents.consultations,
                  patientNotes: contents.patientNotes
                }}
                fields={data}
                classes={{
                  heading: classes.heading,
                  link: classes.link,
                  tableLink: classes.tableLink,
                  tableLinkOrange: classes.tableLinkOrange,
                  subHeading: classes.subHeading,
                  checkbox: classes.checkbox,
                  checkboxIconSecondary: classes.checkboxIconSecondary,
                  floatRight: classes.floatRight,
                  alternaleafButton: classes.alternaleafButton,
                  light_color_button: classes.light_color_button
                }}
                doctor={contents.doctor}
                doctorsList={doctorsList}
                handleChange={handleDataInput}
                patientId={patientId}
                onSave={onSave}
                patient={contents.patient}
                showConsultation={showConsultation}
                setShowConsultation={setShowConsultation}
                consultationSubStatus={consultationSubStatus}
                setConsultationSubStatus={setConsultationSubStatus}
                saveConsultation={saveConsultation}
                handleDocNotes={handleDocNotes}
                isHomeDelivery={data.HomeDelivery}
                doctorsDropdownList={doctorDropdownList}
                refresh={getPatientData}
                dischargeProgressStatus={dischargeProgressStatus}
                isLoadingConsultations={isLoading}
              />
            </TabPanel>
            <TabPanel value={TabValue} index={2}>
              <Box component="p" mt={1} mb={2} className={classes.heading}>
                Quality Of Life Assessment
              </Box>
              {isAdminOnly && (
                <>
                  {contents.is_patient_qol_completed ? (
                    <Chip color="success" label={`QOL Completed ${contents.patient_qol_completed_date}`} />
                  ) : (
                    <Chip color="warning" label="QOL Not Completed" />
                  )}
                </>
              )}
              {!isAdminOnly && <QOL data={qolData} />}
            </TabPanel>

            <TabPanel value={TabValue} index={3}>
              <PrescriptionsTab
                data={contents.prescriptions}
                patientActive={data.isActive}
                dischargeProgressStatus={dischargeProgressStatus}
                doctor={contents.doctor}
                patient={contents.patient}
                isHomeDelivery={data.HomeDelivery}
                isVersion2Prescription={contents.isVersion2Prescription}
                classes={{
                  heading: classes.heading,
                  link: classes.link,
                  ellipsis: classes.ellipsis,
                  button: classes.button,
                  floatRight: classes.floatRight,
                  tableLink: classes.tableLink,
                  tableLinkOrange: classes.tableLinkOrange,
                  linkDanger: classes.linkDanger
                }}
                patientEmail={data.email}
                patientId={patientId}
                refresh={getPatientData}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value={TabValue} index={4}>
              <DocumentsTab
                patientId={patientId}
                refreshDocumentsData={refreshDocumentsData}
                disabled={!data.isActive || dischargeProgressStatus}
                classes={{
                  heading: classes.heading,
                  link: classes.link
                }}
              />
            </TabPanel>
          </Box>
        </Grid>
        {/* Tab Panel column  */}
      </Grid>
      <AcknowledgementModal
        open={acknowledgementModalOpen}
        doctorName={contents?.isDoctorTransferred?.doctor_name}
        medicationData={medicationData}
        handleClose={handleAcknowledgementModalClose}
      />
      <IhiVerifyAndUpdateModals
        modalDataVerifyIhiNumber={modalDataVerifyIhiNumber}
        modalDataSelectMultipleFields={modalDataSelectMultipleFields}
        modalDataChooseIhiNumber={modalDataChooseIhiNumber}
        modalDataUnableToVerifyIhi={modalDataUnableToVerifyIhi}
        setPatientIhiNeedsUpdate={setPatientIhiNeedsUpdate}
        handleUpdateIhiDetails={handleUpdateIhiDetails}
        handleCloseModalVerifyIhiNumber={handleCloseModalVerifyIhiNumber}
        handleCloseModalSelectMultipleFields={handleCloseModalSelectMultipleFields}
        handleCloseModalConfirmIhiNumber={handleCloseModalConfirmIhiNumber}
        handleCloseModalUnableToVerify={handleCloseModalUnableToVerify}
        patientPMSData={data}
        getPatientData={getPatientData}
      />
      <StyledSnackbar
        open={snackbarProps?.open}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbarProps?.severity} variant="filled">
          <Typography fontSize={16}>{snackbarProps?.message}</Typography>
        </Alert>
      </StyledSnackbar>
    </>
  );
};

export default Patient;
