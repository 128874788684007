import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ConfirmConsultation() {
  const navigate = useNavigate();
  const queryStr = window.location.search;

  useEffect(() => {
    navigate(`/card-detail${queryStr}`);
  }, [navigate, queryStr]);

  return null;
}

export default ConfirmConsultation;
