import { patientIhiClient } from '@/api/patients/patientIhiClient';
import type {
  UpdatePatientIhiBodyDto,
  UpdatePatientIhiErrorResponseDto,
  UpdatePatientIhiSuccessResponseDto
} from '@montugroup/pms-api-contracts';

export const usePostPatientIhi = () => {
  const { mutateAsync, ...rest } = patientIhiClient().postPatientIhi.useMutation();

  const postPatientIhi = async (payload: UpdatePatientIhiBodyDto): Promise<UpdatePatientIhiSuccessResponseDto> => {
    try {
      const response = await mutateAsync({
        body: payload
      });

      return response.body as UpdatePatientIhiSuccessResponseDto;
    } catch (error) {
      const defaultErrorMessage = 'Failed to update patient IHI. Please try again later.';
      if (typeof error === 'object' && error !== null && 'status' in error && 'body' in error) {
        if ((error.status as number) < 500) {
          const errorMessage = (error.body as UpdatePatientIhiErrorResponseDto)?.error?.message ?? defaultErrorMessage;
          throw new Error(errorMessage);
        }
      }
      throw new Error(defaultErrorMessage);
    }
  };

  return { postPatientIhi, ...rest };
};
