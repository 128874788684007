import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers';

import styles from './styles';

const useStyles = makeStyles(styles);

const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-input': {
    padding: 10,
    fontSize: 14
  }
});

/**
 * @deprecated due to the non-scalable composition of the component
 */
const GoToDateField = (props) => {
  const classes = useStyles();

  return (
    <Box
      component={Grid}
      width="100%"
      display={props.display ? props.display : 'flex'}
      m={isNaN(props.margin) ? 1 : props.margin}
      p={isNaN(props.padding) ? 0 : props.padding}
    >
      <Grid xs={props.customGrid ? props.customGrid[1] : 8} item={true}>
        <FormControl className={classes.TextField}>
          <Box p={props.selectPadding !== undefined ? props.selectPadding : 0} data-testid="adsasd">
            <StyledDatePicker
              id={props.id}
              inputVariant={props.variant ? props.variant : 'outlined'}
              value={props.value || null}
              onChange={(e) => props.onChange(e)}
              className={`${classes.TextField} ${props.InputClasses ? props.InputClasses : ''}`}
              disabled={props.disabled}
              format="DD-MMM-YYYY"
              emptyLabel=""
              style={{
                width: '100%'
              }}
              size={'small'}
              clearable
            />
          </Box>
        </FormControl>
      </Grid>
    </Box>
  );
};

export default GoToDateField;
