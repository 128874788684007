import { useQuery } from 'urql';

import { graphql } from '../generated';

export const GET_PAYMENT_STATUS_QUERY = graphql(`
  query getPaymentStatus($getPaymentStatusInput: PaymentStatusInput!) {
    getPaymentStatus(getPaymentStatusInput: $getPaymentStatusInput) {
      preAuthTransactionExists
      debitTransactionExists
      paymentStatus
    }
  }
`);

interface GetPaymentStatusQueryVariables {
  email: string;
  merchantTransactionId: string;
}

export function useGetPaymentStatus(variables: GetPaymentStatusQueryVariables) {
  const [{ data, fetching, stale, error, extensions, operation }, executeQuery] = useQuery({
    query: GET_PAYMENT_STATUS_QUERY,
    requestPolicy: 'network-only',
    variables: {
      getPaymentStatusInput: variables
    }
  });

  return {
    getPaymentStatusData: data,
    getPaymentStatusFetching: fetching,
    getPaymentStatusStale: stale,
    getPaymentStatusError: error,
    getPaymentStatusExtensions: extensions,
    getPaymentStatusOperation: operation,
    executeGetPaymentStatusQuery: executeQuery
  };
}
