import { requestHeaders } from '@/api';
import { QueryKey } from '@/data/query';
import { API_URL } from '@/data/service/dataService';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export interface MedicalConditionItem {
  id: number;
  condition_name: string;
}

const fetchMedicalConditions = async (url: string): Promise<MedicalConditionItem[] | null> => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: 'GET',
      headers: requestHeaders()
    });
    if (!response.ok) {
      throw new Error('There has been an error getting the list of medical conditions');
    }
    return await response.json();
  } catch (err) {
    console.error('Fetch error:', err);
    return null;
  }
};

export const useGetMedicalConditions = (): UseQueryResult<MedicalConditionItem[] | null> => {
  return useQuery({
    queryKey: [QueryKey.GetMedicalConditions],
    queryFn: () => fetchMedicalConditions('dass/medicalConditions')
  });
};
