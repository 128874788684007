import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, styled } from '@mui/material';

import { weekdayNames } from '@/components/calcom/shared/utils/weekday';
import type { Dayjs } from '@/utils/dayjs';
import dayjs from '@/utils/dayjs';

import DatePickerDays from './DatePickerDays';

/**
 * Date Picker component
 *
 * @remarks
 * A Datepicker used to pick date overrides
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DatePicker'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/calendars/DatePicker.tsx
 *
 * @param - See DatePickerProps for details of props
 *
 * @returns JSXElement
 *
 */

export type DatePickerProps = {
  /** which day of the week to render the calendar. Usually Sunday (=0) or Monday (=1) - default: Sunday */
  weekStart?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  /** Fires whenever a selected date is changed. */
  onChange: (date: Dayjs | null) => void;
  /** Fires when the month is changed. */
  onMonthChange?: (date: Dayjs) => void;
  /** which date or dates are currently selected (not tracked from here) */
  selected?: Dayjs | Dayjs[] | null;
  /** defaults to current date. */
  minDate?: Date;
  /** Furthest date selectable in the future, default = UNLIMITED */
  maxDate?: Date;
  /** locale, any IETF language tag, e.g. "hu-HU" - defaults to Browser settings */
  locale: string;
  /** Defaults to [], which dates are not bookable. Array of valid dates like: ["2022-04-23", "2022-04-24"] */
  excludedDates?: string[];
  /** defaults to all, which dates are bookable (inverse of excludedDates) */
  includedDates?: string[];
  /** allows adding classes to the container */
  className?: string;
  /** Shows a small loading spinner next to the month name */
  isLoading?: boolean;
  /** used to query the multiple selected dates */
  eventSlug?: string;
};

const StyledDatePickerContainer = styled(Box)({
  width: '450px'
});

const StyledMonthControls = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 15
});

const DatePicker = ({
  weekStart = 0,
  locale,
  selected,
  onMonthChange,
  ...passThroughProps
}: DatePickerProps & Partial<React.ComponentProps<typeof DatePickerDays>>) => {
  const browsingDate = passThroughProps.browsingDate || dayjs().startOf('month');

  const changeMonth = (newMonth: number) => {
    if (onMonthChange) {
      onMonthChange(browsingDate.add(newMonth, 'month'));
    }
  };
  const month = browsingDate
    ? new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(browsingDate.year(), browsingDate.month()))
    : null;

  return (
    <StyledDatePickerContainer>
      <StyledMonthControls>
        <span>
          {browsingDate && (
            <>
              <strong>{month}</strong> <span>{browsingDate.format('YYYY')}</span>
            </>
          )}
        </span>
        <Box>
          <Box display={'flex'}>
            <Button
              onClick={() => changeMonth(-1)}
              disabled={!browsingDate.isAfter(dayjs())}
              data-testid="decrementMonth"
            >
              <KeyboardArrowLeftIcon />
            </Button>

            <Button onClick={() => changeMonth(+1)} data-testid="incrementMonth">
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
        </Box>
      </StyledMonthControls>
      <Box sx={{ gridTemplateColumns: 'repeat(7,minmax(0,1fr))', display: 'grid' }} textAlign={'center'} mb={3}>
        {weekdayNames(locale, weekStart, 'short').map((weekDay) => (
          <span key={weekDay}>{weekDay}</span>
        ))}
      </Box>
      <Box sx={{ gridTemplateColumns: 'repeat(7,minmax(0,1fr))', display: 'grid', gap: '0.25rem' }}>
        <DatePickerDays
          weekStart={weekStart}
          selected={selected}
          {...passThroughProps}
          browsingDate={browsingDate}
          month={month}
          nextMonthButton={() => changeMonth(+1)}
        />
      </Box>
    </StyledDatePickerContainer>
  );
};

export default DatePicker;
