import * as braze from '@braze/web-sdk';
import md5 from 'crypto-js/md5';

import settings from '../data/constants';
import type { PaymentData } from '../hooks/rest/types';

braze.initialize(settings.brazeApiKey, {
  baseUrl: settings.brazeBaseUrl
});

export function brazeChangeUser(email: string) {
  try {
    const identifier = md5(email.toLowerCase().trim()).toString();
    braze.changeUser(identifier);
  } catch (e) {
    console.error('Braze change user failed', e);
  }
}

export function brazeTrackEvent(event: string, data?: object) {
  try {
    braze.logCustomEvent(event, data);
  } catch (e) {
    console.error('Braze event failed to send', e);
  }
}

export function brazeUpdateUserAttributesFromPaymentPage({ firstName, lastName, fullName, email }: PaymentData) {
  const user = braze.getUser();
  if (!user) {
    return;
  }

  user.setEmail(email);
  user.setFirstName(firstName);
  user.setLastName(lastName);

  // We won't have firstName and lastName after switching over from Calendly
  user.setCustomUserAttribute('fullName', fullName);
}
