import { Button } from '@montugroup/design-system';
import { Alert, AlertTitle, Box, Modal, styled } from '@mui/material';

export const StyledModal = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '100vh',
  overflowY: 'auto'
});

export const StyledBox = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(128),
  maxWidth: theme.spacing(160),
  padding: theme.spacing(8),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1)
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  padding: '0',
  border: 'none'
}));

export const StyledAlertTitle = styled(AlertTitle)(({ theme }) => ({
  margin: '0',
  padding: '0',
  fontSize: theme.spacing(7)
}));

export const StyledButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(4),
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold
}));

export const StyledConfirmButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[1]
}));
