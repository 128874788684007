export type User = {
  id: number;
  first_name: string;
  last_name?: string;
  email: string;
  phone?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  active: boolean;
  role_id: number;
  country_id: number;
  state_id: number;
};

export type UserResponse = {
  user?: User;
  token?: string;
};

export type UserRoles = Record<string, number>;

export enum UserRole {
  SuperAdmin = 1,
  Doctor = 2,
  Patient = 3,
  Nurse = 4,
  Admin = 5,
  // also called nurse manager
  Manager = 6,
  PtxSupportPartner = 8
}

export const StaffRoleIds: UserRole[] = [
  UserRole.SuperAdmin,
  UserRole.Doctor,
  UserRole.Nurse,
  UserRole.Admin,
  UserRole.Manager,
  UserRole.PtxSupportPartner
];

export type ValidLoginRoles = [
  UserRole.SuperAdmin,
  UserRole.Doctor,
  UserRole.Nurse,
  UserRole.Admin,
  UserRole.Manager,
  UserRole.PtxSupportPartner
];
