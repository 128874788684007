import axios from 'axios';

import settings from '@/data/constants';
import type { Prescription, SortablePaginatedRequest } from '@/types';
import { trimWhitespace } from '@/utils/trimWhitespace';

// TODO: remove once server is ready with returning this prop
const hasMoreResults = (currentRowCount = 0, returnedTotalRowCountWithFlag = 0, offset = 0) => {
  if (currentRowCount === 0 || returnedTotalRowCountWithFlag === 0) {
    return false;
  }
  const totalRowCount = currentRowCount + offset;
  // if there are more pages, "returnedTotalRowCountWithFlag" will have one more than the total.
  return returnedTotalRowCountWithFlag > totalRowCount;
};

export interface GetPrescriptionsQuery extends SortablePaginatedRequest {
  search?: string;
}

export interface GetPrescriptionResponse {
  prescriptions: Prescription[];
  count: number;
  hasMore: boolean;
}

export const getPrescriptions = async (params?: GetPrescriptionsQuery) => {
  const url = `${settings.url}/prescription`;
  const trimmedKeyword = params?.search ? trimWhitespace(params?.search) : undefined;

  const response = await axios.get<GetPrescriptionResponse>(url, {
    params: {
      ...params,
      search: trimmedKeyword
    }
  });
  const offset = params?.offset ? Number(params?.offset) : 0;
  const hasMore = hasMoreResults(response?.data?.prescriptions?.length, response?.data?.count, offset);

  return {
    ...response?.data,
    hasMore
  };
};
