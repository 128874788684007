import { List, ListItem, styled } from '@mui/material';

export const StyledList = styled(List)({
  listStyleType: 'disc',
  marginBottom: '1.5rem',
  padding: '0'
});

export const StyledListItem = styled(ListItem)({
  display: 'list-item',
  listStylePosition: 'inside',
  padding: '0',
  fontWeight: '700'
});
