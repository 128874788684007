import { useEffect } from 'react';

import { FF_OOS_CONCESSION_REPLACEMENTS } from '@/constants/featureFlags';

import settings from '../../data/constants';
import { useFeatureFlags } from '../flags';

import type { CatalogProduct, FormulationItem, Supplier } from './useGetProductList';
import useRequest from './useRequest';

export type UseGetRescriptProductsResponse = {
  formulation: FormulationItem[];
  products: CatalogProduct[];
  suppliers: Supplier[];
};

export function useGetRescriptProducts() {
  const { flags } = useFeatureFlags();
  const ffOOSConcessionReplacements = flags[FF_OOS_CONCESSION_REPLACEMENTS];
  const path = ffOOSConcessionReplacements ? '/product/for-rescript' : '/product/catalog';

  const { loading, data, error, execute, setData } = useRequest<UseGetRescriptProductsResponse>({
    config: {
      url: `${settings.url}${path}`,
      method: 'GET'
    }
  });

  useEffect(() => {
    execute();
  }, [execute]);

  return {
    loading,
    data,
    error,
    doGet: execute,
    setData
  };
}

export default useGetRescriptProducts;
