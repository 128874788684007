import type { ReactNode } from 'react';

import { getUser } from '@/data/service/authService';
import type { UserRole } from '@/types';

export const useAccessControl = () => {
  const user = getUser();
  const userRole = user?.role_id ?? -1;

  const restrictAccess = (roles: UserRole[], content: ReactNode) => {
    if (roles.includes(userRole)) {
      return null;
    }

    return content;
  };

  const allowAccess = (roles: UserRole[], content: ReactNode) => {
    if (roles.includes(userRole)) {
      return content;
    }

    return null;
  };

  return { restrictAccess, allowAccess };
};
