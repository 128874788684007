import type { EventClickArg, EventContentArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { useState } from 'react';

import settings from '../../data/constants';

import { CalendarEventCard } from '@/components/calendar/CalendarEventCard';
import type { NurseConsultation } from '@/types';
import { TimelineSchedulerDrawer } from './TimelineScheduler/TimelineSchedulerDrawer';

type CalendarViewProps = {
  eventCallback?: (
    fetchInfo: EventSourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void
  ) => void;
  calendarSettings?: CalendarSettings;
  initialDate?: string;
};

interface CalendarSettings {
  slotDuration?: string;
  slotMinTime?: string;
  slotMaxTime?: string;
}

const fullCalendarSettings = (settingOverrides: CalendarSettings | undefined) => {
  return {
    schedulerLicenseKey: settings.fullCalendarKey,
    plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin, multiMonthPlugin],
    slotDuration: settingOverrides?.slotDuration || '00:10:00',
    allDaySlot: false,
    slotMinTime: settingOverrides?.slotMinTime || '08:00:00',
    slotMaxTime: settingOverrides?.slotMaxTime || '18:00:00',
    hiddenDays: [0],
    views: {
      week: {
        type: 'timeGrid',
        duration: { days: 7 },
        buttonText: 'Week',
        dayHeaderContent: (args: { date: moment.MomentInput }) => {
          return moment(args.date).format('dddd - D/MM');
        }
      },
      month: {
        type: 'dayGridMonth',
        duration: { months: 1 },
        buttonText: 'Month'
      },
      multiMonth: {
        type: 'multiMonth',
        duration: { months: 3 },
        buttonText: '3 Months'
      }
    },
    headerToolbar: {
      left: 'prev, next',
      center: 'title',
      right: 'week, month, multiMonth'
    }
  };
};

export default function CalendarView({ eventCallback, calendarSettings, initialDate }: CalendarViewProps) {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<NurseConsultation>();

  const formatEventContentCard = (isSmallVariant: boolean) =>
    function FormatEventContentCard(eventArgs: EventContentArg) {
      return <CalendarEventCard eventArgs={eventArgs} isSmallVariant={isSmallVariant} />;
    };

  const handleEventClick = (info: EventClickArg) => {
    setShowSideBar(true);
    setSelectedEvent(info.event._def.extendedProps.eventDetails as NurseConsultation);
  };

  const handleFormatEventContent = (eventContentObj: { view: { type: string } }) => {
    const isLargeCard = ['week', 'timeGridWeek'].includes(eventContentObj.view.type);
    return formatEventContentCard(!isLargeCard);
  };

  const handleToggleDrawer = (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
    if (
      e.type === 'keydown' &&
      ((e as React.KeyboardEvent).key === 'Tab' || (e as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setShowSideBar(open);
  };

  return (
    <>
      <FullCalendar
        {...fullCalendarSettings(calendarSettings)}
        events={eventCallback}
        eventClick={handleEventClick}
        initialDate={initialDate}
        eventContent={handleFormatEventContent}
      />
      <TimelineSchedulerDrawer
        selectedEvent={selectedEvent as NurseConsultation}
        isOpen={showSideBar}
        toggleDrawer={handleToggleDrawer}
      />
    </>
  );
}
