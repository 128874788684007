import { Box, Typography } from '@mui/material';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';

import settings from '@/data/constants';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import { Button, Snackbar } from '@/shared-ui';

import DateRangeComp from '../common/DateRangeComp';

export type ConsultationPageHeaderProps = {
  canExport?: boolean;
};

/**
 * format 'YYYY-MM-DD HH:mm:ss'
 */
type ISODateString = string;

type DownloadConsultationsExportFileRequest = {
  title: string;
  dateRange: {
    from_date: ISODateString;
    to_date: ISODateString;
  };
};

const downloadConsultationsExportFile = async (data: DownloadConsultationsExportFileRequest) => {
  const response = await axios.post<DownloadConsultationsExportFileRequest, AxiosResponse<{ message: string }>>(
    `${settings.url}/excel/export-consultations`,
    {
      ...data
    }
  );

  return response?.data;
};

export const ConsultationsPageHeader = ({ canExport }: ConsultationPageHeaderProps) => {
  const [open, setOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<Pick<ExtendedSnackbarProps, 'severity' | 'message' | 'open'>>({
    open: false,
    message: ''
  });

  const DownloadFile = async (from_date: ISODateString, to_date: ISODateString) => {
    const dateRange = {
      from_date: from_date,
      to_date: to_date
    };

    const title = `PMS-Consultations-${dayjs().format('MMM-YYYY')}`;

    try {
      const response = await downloadConsultationsExportFile({
        title,
        dateRange
      });
      setSnackbarProps({
        open: true,
        severity: 'success',
        message: response?.message
      });
    } catch (error) {
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Unfortunately we've encountered an error while exporting: "${(error as Error).message}"`
      });
    }
  };

  return (
    <Box component="header" padding={4}>
      <Box mb={1} display="flex" justifyContent="space-between">
        <Typography variant="h5">Consultations</Typography>
        {canExport && (
          <Box component="div" ml={2} display="flex">
            <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
              Export
            </Button>
          </Box>
        )}
      </Box>
      <Box component="div" display="flex" ml={2}>
        {open && (
          <div>
            <DateRangeComp open={open} setOpen={setOpen} DownloadFile={DownloadFile} />
          </div>
        )}
      </Box>
      {/* consider creating a context provider to have Snackbar used globally */}
      <Snackbar
        {...snackbarProps}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => {
          setSnackbarProps({ open: false, message: '' });
        }}
      />
    </Box>
  );
};
