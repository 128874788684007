import { normalizeHeaders, requestHeaders } from '@/api';
import settings from '@/data/constants';
import { patientIhiContract } from '@montugroup/pms-api-contracts';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

const patientIhiClient = () =>
  initQueryClient(patientIhiContract, {
    baseUrl: settings.url,
    baseHeaders: {},
    api: (args) => {
      args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
      // @ts-expect-error: TS2345: typing seems to be reference from an old version of ts-rest
      return tsRestFetchApi(args);
    }
  });

export { patientIhiClient };
