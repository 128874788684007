import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useState } from 'react';

import BasicModal from '@/components/common/BasicModal';
import { FLEX_CALENDAR_URL } from '@/components/NurseFlexCalendar/NurseFlexCalendar.constants';
import theme from '@/theme';

const StyledBasicModal = styled(BasicModal)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

const StyledResetModalContents = styled(Box)({
  backgroundColor: theme.palette.background.paper,
  width: '20rem',
  height: '10rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px 10px'
});

type NurseFlexCalendarResetModalProps = {
  handleResetCalendar: () => void;
};

const NurseFlexCalendarResetModal = ({ handleResetCalendar }: NurseFlexCalendarResetModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleReset = async () => {
    const response = await axios.delete(FLEX_CALENDAR_URL);

    if (response.status === 204) {
      setModalOpen(false);
      handleResetCalendar();
    }
  };

  return (
    <>
      <Button onClick={handleOpen} variant={'outlined'} sx={{ marginRight: 3 }}>
        Reset Calendar
      </Button>
      <StyledBasicModal open={modalOpen} handleClose={handleClose}>
        <StyledResetModalContents>
          <Typography fontSize={'1.2rem'} textAlign={'center'} mb={10}>
            Are you sure you want to reset the calendar?
          </Typography>
          <Box display={'flex'}>
            <Button onClick={handleReset} variant={'contained'} sx={{ marginRight: 3 }} color={'secondary'} fullWidth>
              Reset
            </Button>
            <Button onClick={handleClose} variant={'outlined'} color={'secondary'} fullWidth>
              Cancel
            </Button>
          </Box>
        </StyledResetModalContents>
      </StyledBasicModal>
    </>
  );
};
export default NurseFlexCalendarResetModal;
