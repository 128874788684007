import { Box, Chip, styled, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import * as React from 'react';

import type { HeadCell, SortOrder, TableRowData } from '@/components/rescripting/RescriptingPage.types';

interface EnhancedTableProps {
  headCells: readonly HeadCell[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableRowData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: SortOrder;
  orderBy: string;
  rowCount: number;
  customOptions?: {
    columnName: keyof TableRowData;
    customNumber?: number;
    customColor?: string;
    customBgColor?: string;
  }[];
  isSortingEnabled: boolean;
  canActionScriptCreation: boolean;
}

const StyledChip = styled(Chip)`
  padding: 0.2rem;
  border-radius: 1rem;
  font-weight: bold;
`;

export default function EnhancedTableHead({
  headCells,
  order,
  orderBy,
  onRequestSort,
  customOptions,
  isSortingEnabled = true,
  canActionScriptCreation
}: EnhancedTableProps) {
  const createSortHandler = (property: keyof TableRowData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {canActionScriptCreation && <TableCell padding="checkbox"></TableCell>}
        {headCells.map((headCell) => {
          const customOption = customOptions && customOptions.find((opt) => opt.columnName === headCell.id);

          return (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
              {isSortingEnabled ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span">{order === 'desc' ? ' (sorted descending)' : ' (sorted ascending)'}</Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <Box display="flex" gap="0.5rem" alignItems="center">
                  <span>{headCell.label}</span>
                  {customOption && (
                    <StyledChip
                      size="small"
                      label={customOption.customNumber}
                      sx={{
                        backgroundColor: customOption.customBgColor || 'inherit',
                        color: customOption.customColor || 'inherit'
                      }}
                    />
                  )}
                </Box>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
