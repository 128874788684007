import React from 'react';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import iziToast from 'izitoast';

import FormInput from '../components/common/FormInput';
import settings from '../data/constants';
import { isAuthenticated, login } from '../data/service/authService';

const LoginPage = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');
  const navigate = useNavigate();
  const isAuthorized = isAuthenticated();

  React.useEffect(() => {
    if (isAuthorized) {
      navigate('/calendar');
    }
  }, [navigate, isAuthorized]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const payload = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    };
    try {
      const resp = await fetch(`${settings.url}/pub/login/alt-rJ3DSMd9vCKSmYwCNEedS7n3`, payload);
      const response = await resp.text();
      const respData = response && JSON.parse(response);
      if (resp.ok) {
        login(respData.data);
        if (respData.data.user.role_id === 4) {
          window.location.href = '/nurse-calendar';
        } else window.location.href = '/calendar';
      } else {
        const error = (response && respData.message) || resp.statusText;
        if ([401, 403].indexOf(resp.status) !== -1) {
          iziToast.error({ title: 'Error', message: respData.message });
        }
        setErrMessage(error);
        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.reject(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid className="login-container" container alignItems="center" direction="column" justifyContent="center">
      <Paper elevation={2} style={{ padding: '2rem', width: '400px' }}>
        <Typography align="center" variant="h5" color="primary" style={{ fontWeight: 600, fontSize: 38 }}>
          PMS Login
        </Typography>
        <form className="form" onSubmit={handleLogin}>
          <FormInput
            labelText="Email"
            id="email"
            error={setErrMessage !== ''}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: email
            }}
            handleChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <FormInput
            labelText="Password"
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            error={setErrMessage !== ''}
            inputProps={{
              value: password
            }}
            handleChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography color="error" align="center" variant="body1">
            {errMessage !== '' && errMessage}
          </Typography>
          <Grid container justifyContent="space-between">
            <Button type="submit" variant="contained" color="secondary">
              Log in
            </Button>
            {/* <Button type="button" variant="contained" color="secondary">Register</Button> */}
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default LoginPage;
