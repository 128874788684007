import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Card, CardContent, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import type { FilteredProduct } from '@/api';
import { useFilteredProductsByDevice } from '@/hooks';

import ProductSelection from '../../../../shared-ui/ProductSelection/ProductSelection';

import type { DeviceSelectorProps } from './DeviceSelector.types';

/**
 * A component used to display a list of available device products for
 * the doctor. This component uses `react-query`, so wrap it in QueryClientProvider
 */
const DeviceSelector = ({
  id,
  ariaLabel,
  initialSelectedDevice,
  doctorId,
  onSelect,
  onDelete,
  onSelectProduct,
  onPreviewIconClick
}: DeviceSelectorProps) => {
  const [selectedProduct, setSelectedProduct] = useState<FilteredProduct>();

  const { data } = useFilteredProductsByDevice({
    doctorId
  });

  useEffect(() => {
    if (data && data?.products?.length > 0) {
      const deviceInData = initialSelectedDevice
        ? data.products.find((deviceProduct) => deviceProduct.id === initialSelectedDevice.product_id)
        : data.products?.[0];
      setSelectedProduct(deviceInData);
    }
  }, [data, initialSelectedDevice]);

  useEffect(() => {
    if (selectedProduct) {
      onSelect?.({
        product: selectedProduct,
        // select 1 by default
        quantity: 1
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const products = data?.products || [];

  const handleSelectProduct = (chosenProduct: FilteredProduct) => {
    setSelectedProduct(chosenProduct);
    onSelectProduct?.(chosenProduct);
  };

  return (
    <Card id={id} aria-label={ariaLabel} raised>
      <CardContent>
        <Stack direction="row">
          <Stack minWidth="90%" spacing={6}>
            {/* select device product */}
            <ProductSelection
              name="deviceProduct"
              value={selectedProduct}
              options={products}
              onChange={handleSelectProduct}
              label="Select a device"
              onPreviewIconClick={onPreviewIconClick}
            />
          </Stack>
          <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={2} width="100%">
            <IconButton aria-label="remove" onClick={onDelete} sx={{ padding: 0 }}>
              <DeleteOutlineOutlinedIcon fontSize="large" />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DeviceSelector;
