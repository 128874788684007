import axios from 'axios';

import settings from '@/data/constants';
import type { PatientSummary } from '@/types';

export enum GetPatientStatus {
  Active = 1,
  Inactive = 0
}

export type GetPatientsQuery = {
  patientStatus?: GetPatientStatus;
  limit?: string; // number string.
  offset?: string; // number string
  search?: string;
  /**
   * which field to sort the result by
   */
  order?: string;
  /**
   * direction of the sort.
   */
  dir?: 'ASC' | 'DESC';
};

export type GetPatientsResponse = {
  patients: PatientSummary[];
  count: number;
  hasMore: boolean;
};

// TODO: move it to a helper
const hasMoreResults = (currentRowCount = 0, returnedTotalRowCountWithFlag = 0, offset = 0) => {
  if (currentRowCount === 0 || returnedTotalRowCountWithFlag === 0) {
    return false;
  }
  const totalRowCount = currentRowCount + offset;
  // if there are more pages, "returnedTotalRowCountWithFlag" will have one more than the total.
  return returnedTotalRowCountWithFlag > totalRowCount;
};

export const getPatients = async (params?: GetPatientsQuery) => {
  const url = `${settings.url}/patient`;
  const response = await axios.get<GetPatientsResponse>(url, {
    params
  });

  const offset = params?.offset ? Number(params?.offset) : 0;
  const hasMore = hasMoreResults(response?.data?.patients?.length, response?.data?.count, offset);

  return {
    ...response?.data,
    hasMore
  };
};
