import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { PrescriptionPadStep } from '../PrescriptionPad/PrescriptionPad.types';

export interface ConsultationConfirmationButtonProps {
  prescriptionPadStep: PrescriptionPadStep | null;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  isConfirmationCheckListEnabled: boolean;
  disabledDueToUnactionedConfirmCheckList: boolean;
}

export const ConsultationConfirmationButton = ({
  prescriptionPadStep,
  onClick,
  disabled,
  isLoading,
  isConfirmationCheckListEnabled,
  disabledDueToUnactionedConfirmCheckList = false
}: ConsultationConfirmationButtonProps) => {
  const [buttonHovered, setButtonHovered] = useState(false);

  const buttonLabel = useMemo(() => {
    switch (prescriptionPadStep) {
      case PrescriptionPadStep.Create:
        return 'Review Script';
      case PrescriptionPadStep.Confirm:
        return 'Create Script';
      case null:
      default:
        return 'Complete Consultation';
    }
  }, [prescriptionPadStep]);

  const button = (
    <Box
      onMouseEnter={() =>
        disabledDueToUnactionedConfirmCheckList &&
        (prescriptionPadStep === PrescriptionPadStep.Confirm || prescriptionPadStep === null) &&
        setButtonHovered(true)
      }
      onMouseLeave={() => setButtonHovered(false)}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={onClick}
        disabled={disabled}
        sx={{ borderRadius: 1, textTransform: 'none' }}
      >
        {isLoading && <CircularProgress size={20} thickness={4} />}
        {buttonLabel}
      </Button>
    </Box>
  );

  return isConfirmationCheckListEnabled ? (
    <Tooltip
      open={disabled && buttonHovered}
      title="Please complete the required actions checklist"
      placement="top-start"
      disableFocusListener
      disableTouchListener
      arrow
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};
