import React from 'react';
import Box from '@mui/material/Box';

import { getToken } from '../data/service/authService';
import { API_URL } from '../data/service/dataService';

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const ViewImage = (props) => {
  const key = props.match.params.key;
  const [imageSrc, setImageSrc] = React.useState('');

  const loadImage = () => {
    fetch(`${API_URL}/patientNote/download/${key}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${getToken()}`
      })
    })
      .then((response) => response.blob())
      .then(async (blob) => {
        const base64Url = await blobToBase64(blob);
        setImageSrc(base64Url);
      });
  };

  React.useEffect(() => {
    loadImage();
  }, [key]);

  return (
    <Box width="100%" height="100%">
      {key ? (
        <Box p={1} height="90vh" display="flex" flexGrow={1} justifyContent="center">
          {imageSrc ? (
            <img src={imageSrc} alt={key} style={{ objectFit: 'cover', height: '-webkit-fill-available' }} />
          ) : (
            'Loading Image ... '
          )}
        </Box>
      ) : (
        <Box width="50%" height="30vh" mx="auto" textAlign="center">
          <Box component="h1" mt={3}>
            Invalid Document
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ViewImage;
