import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CircularProgress, IconButton, Modal, styled } from '@mui/material';

import { FF_USE_SHOPIFY_PRODUCT_IMAGES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useGetProductDetails } from '@/hooks/filteredProducts/useGetProductDetails';
import { ProductDescriptionCard } from '@/shared-ui';
import getProductImageUrl from '@/utils/getProductImageUrl';

export interface PrescriptionPadItemDetailsModalProps {
  productId: number;
  isModalOpen: boolean;
  isConcessionCardHolder?: boolean;
  onClose: () => void;
}

const StyledContainerBox = styled(Box)({
  width: '90%',
  maxWidth: '45rem',
  margin: '2.5% auto'
});

const StyledCard = styled(Card)({
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledCloseButton = styled(IconButton)({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem'
});

export const PrescriptionPadItemDetailsModal = ({
  productId,
  isModalOpen,
  isConcessionCardHolder,
  onClose
}: PrescriptionPadItemDetailsModalProps) => {
  const { data: productDetails } = useGetProductDetails({ productId, isConcession: isConcessionCardHolder });

  const { flags } = useFeatureFlags();
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES];

  return (
    <Modal open={isModalOpen} onClose={() => onClose()}>
      <StyledContainerBox>
        {productDetails ? (
          <ProductDescriptionCard
            showCloseIcon={true}
            onClose={() => onClose()}
            id={productDetails?.id || -1}
            formulation={productDetails?.ProductFormulation}
            name={productDetails?.name}
            image={getProductImageUrl(
              useShopifyProductImages,
              productDetails?.image_url,
              productDetails?.ProductImages
            )}
            price={productDetails?.priceDetails?.normal}
            discountPrice={productDetails?.priceDetails?.discounted}
            summary={productDetails?.description}
            spectrumId={productDetails?.spectrum_id}
            description={productDetails?.description}
            supplier={
              productDetails?.Suppliers && productDetails?.Suppliers?.length > 0
                ? productDetails?.Suppliers[0]?.supplier_name
                : '-'
            }
            strain={productDetails?.ProductStrain?.name}
            terpeneProfile={productDetails?.contents ? productDetails?.contents.replaceAll('\n', ', ') : '-'}
            category={productDetails?.ProductCategory?.name}
            cbdThcRatio={productDetails?.cbd_thc_ratio}
            schedule={productDetails?.ProductSchedule.name}
            conditions={productDetails?.conditions}
            howToUse={productDetails?.how_to_use}
            isConcessionProduct={productDetails?.is_concession}
            cbdStrengthForDisplay={productDetails?.cbd_strength_for_display}
            thcStrengthForDisplay={productDetails?.thc_strength_for_display}
          />
        ) : (
          <StyledCard>
            <CircularProgress />
            <StyledCloseButton onClick={() => onClose()}>
              <CloseIcon />
            </StyledCloseButton>
          </StyledCard>
        )}
      </StyledContainerBox>
    </Modal>
  );
};
