import settings from '../../data/constants';

import useRequest from './useRequest';

export type NurseRoundRobinItem = {
  id: number;
  calcom_nurse_id: number;
  NurseUser: {
    id: number;
    first_name: string;
    last_name: string;
  };
};

export type NurseRoundRobinResponse = {
  current: NurseRoundRobinItem[];
  removed: NurseRoundRobinItem[];
};

export function useGetRoundRobinNurses() {
  const { loading, data, error, execute } = useRequest<NurseRoundRobinResponse>({
    config: {
      url: `${settings.url}/calcom/nurse-roundrobin`,
      method: 'GET'
    }
  });

  return {
    loading,
    data,
    error,
    doGet: execute
  };
}

export default useGetRoundRobinNurses;
