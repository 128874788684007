export type TillCardError = {
  attribute: string;
  key: string;
  message: string;
};

const integrationKeyTillCardError: TillCardError = {
  attribute: 'integration_key',
  key: 'errors.configuration',
  message: 'Invalid Integration Key'
};

const systemErrorTillCardError: TillCardError = {
  attribute: 'integration_key',
  key: 'errors.system',
  message: 'System error occurred, please retry'
};

const cardNumberEmptyTillCardError: TillCardError = {
  attribute: 'number',
  key: 'errors.blank',
  message: 'Card number must not be empty'
};

const cardNumberInvalidTillCardError: TillCardError = {
  attribute: 'number',
  key: 'errors.invalid',
  message: 'Invalid card number'
};

const cvvEmptyTillCardError: TillCardError = {
  attribute: 'cvv',
  key: 'errors.blank',
  message: 'CVV code must not be empty'
};

const cvvInvalidTillCardError: TillCardError = {
  attribute: 'cvv',
  key: 'errors.invalid',
  message: 'Invalid CVV code'
};

const monthEmptyTillCardError: TillCardError = {
  attribute: 'month',
  key: 'errors.blank',
  message: 'Expiration month must not be empty'
};

const monthInvalidTillCardError: TillCardError = {
  attribute: 'month',
  key: 'errors.invalid',
  message: 'Invalid expiration month'
};

const yearEmptyTillCardError: TillCardError = {
  attribute: 'year',
  key: 'errors.blank',
  message: 'Expiration year must not be empty'
};

const yearInvalidTillCardError: TillCardError = {
  attribute: 'year',
  key: 'errors.invalid',
  message: 'Invalid expiration year'
};

const cardExpiredTillCardError: TillCardError = {
  attribute: 'year',
  key: 'errors.expired',
  message: 'Card expired'
};

const cardHolderEmptyTillCardError: TillCardError = {
  attribute: 'card_holder',
  key: 'errors.blank',
  message: 'Card holder must not be empty'
};

const firstNameEmptyTillCardError: TillCardError = {
  attribute: 'first_name',
  key: 'errors.blank',
  message: 'First name must not be empty'
};

const lastNameEmptyTillCardError: TillCardError = {
  attribute: 'last_name',
  key: 'errors.blank',
  message: 'Last name must not be empty'
};

// All types of errors can be found [here](https://gateway.tillpayments.com/documentation/gateway) and search for
// "payment.js Error codes" in the documentation.
export const possibleTillCardErrors = {
  integrationKey: integrationKeyTillCardError,
  systemError: systemErrorTillCardError,
  cardNumberEmpty: cardNumberEmptyTillCardError,
  cardNumberInvalid: cardNumberInvalidTillCardError,
  cvvEmpty: cvvEmptyTillCardError,
  cvvInvalid: cvvInvalidTillCardError,
  monthEmpty: monthEmptyTillCardError,
  monthInvalid: monthInvalidTillCardError,
  yearEmpty: yearEmptyTillCardError,
  yearInvalid: yearInvalidTillCardError,
  cardExpired: cardExpiredTillCardError,
  cardHolderEmpty: cardHolderEmptyTillCardError,
  firstNameEmpty: firstNameEmptyTillCardError,
  lastNameEmpty: lastNameEmptyTillCardError
};

export function isTillErrorMatch(expected: TillCardError, actual: TillCardError): boolean {
  return expected.key === actual.key && expected.attribute === actual.attribute;
}
