import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';

import { ExportType, postExcelExports } from '@/api/excelExports/postExcelExports';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import type { ISODateString } from '@/types';

export interface UseExportExcelWithSnackbarStateOptions {
  exportType: ExportType;
}

const getExportFileTitleByType = (exportType: ExportType) => {
  switch (exportType) {
    case ExportType.CONSULTATION:
      return `PMS-Consultations-${DateTime.now().toFormat('MMM-yyyy')}`;
    case ExportType.PATIENT:
      return `PMS-Patients-${DateTime.now().toFormat('MMM-yyyy')}`;
    case ExportType.PRESCRIPTION:
      return `PMS-prescriptions-${DateTime.now().toFormat('MMM-yyyy')}`;
    default:
      throw new Error('Unsupported export type');
  }
};

export const useExportExcelWithSnackbarState = ({ exportType }: UseExportExcelWithSnackbarStateOptions) => {
  const [snackbarProps, setSnackbarProps] = useState<Pick<ExtendedSnackbarProps, 'severity' | 'message' | 'open'>>({
    open: false,
    message: ''
  });

  const downloadFile = useCallback(
    async (from_date: ISODateString, to_date: ISODateString) => {
      const dateRange = {
        from_date,
        to_date
      };

      try {
        const title = getExportFileTitleByType(exportType);
        const response = await postExcelExports(exportType, {
          title,
          dateRange
        });
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: response?.message
        });
      } catch (error) {
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: `Unfortunately we've encountered an error while exporting: "${(error as Error).message}"`
        });
      }
    },
    [exportType]
  );

  return {
    downloadFile,
    snackbarProps,
    setSnackbarProps
  };
};
