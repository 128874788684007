import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';

import theme from '@/theme';

const SkeletonHeading = styled(Skeleton)({
  height: '2rem',
  width: 150,
  marginBottom: '0.5rem'
});

const SkeletonTextField = styled(Skeleton)({
  height: '3.5rem',
  marginBottom: '0.5rem'
});

const StyledEwaySkeletonContainer = styled(Box)({
  // In small viewport devices we show the summary in an accordion over the form.
  // This padding moves the content down so it can be seen below the accordion
  paddingTop: '100px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0
  }
});

export default function PaymentFormSkeleton({ enableEwayPayments }: { enableEwayPayments: boolean }) {
  return enableEwayPayments ? (
    <StyledEwaySkeletonContainer>
      <SkeletonHeading />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <SkeletonTextField variant="rectangular" />
        <Box display="flex" justifyContent="space-between">
          <SkeletonTextField variant="rectangular" sx={{ flexBasis: '50%', mr: 2 }} />
          <SkeletonTextField variant="rectangular" sx={{ flexBasis: '50%', ml: 2 }} />
        </Box>
        <SkeletonTextField variant="rectangular" />
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={8}>
          <SkeletonTextField variant="rectangular" />
          <SkeletonTextField variant="rectangular" />
        </Box>
      </Box>
    </StyledEwaySkeletonContainer>
  ) : (
    <>
      <SkeletonHeading />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" flexDirection="column" justifyContent="space-between" mb={8}>
          <SkeletonTextField variant="rectangular" />
          <SkeletonTextField variant="rectangular" />
        </Box>
        <SkeletonHeading />
        <SkeletonTextField variant="rectangular" />

        <Box display="flex" justifyContent="space-between">
          <SkeletonTextField variant="rectangular" sx={{ flexBasis: '45%' }} />
          <SkeletonTextField variant="rectangular" sx={{ flexBasis: '45%' }} />
        </Box>

        <SkeletonTextField variant="rectangular" />
      </Box>
    </>
  );
}
