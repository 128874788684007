import UploadIcon from '@mui/icons-material/CloudUpload'; // TODO: Use FileUpload in Mui5
import ImageIcon from '@mui/icons-material/Image';
import {
  Alert,
  Avatar,
  Box,
  Card,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Fragment, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const ZoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: theme.spacing(3, 2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

const ZoneAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const Icon = styled(UploadIcon)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const MainTextContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const ClickMeText = styled(Link)({
  cursor: 'pointer'
});

const FileTypeTextContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1)
}));

const PreviewAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.light, 0.1)
}));

export type ConcessionCardFile = File & {
  identifier?: string;
};

export type ConcessionCardUploadProps = {
  loading?: boolean;
  files?: ConcessionCardFile[];
  onDropAccepted: (files: File[]) => Promise<void> | void;
};

export const ConcessionCardUpload = ({ loading, files, onDropAccepted }: ConcessionCardUploadProps) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      await onDropAccepted(acceptedFiles);
    },
    [onDropAccepted]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    disabled: loading,
    onDrop,
    maxFiles: 1,
    maxSize: 26214400, // 25MB
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'image/heic': ['.heic']
    }
  });

  const renderPreviews = () => {
    if (!files || files.length === 0) {
      return undefined;
    }
    return (
      <Box mb={1}>
        <Card variant="outlined">
          <List dense>
            {files.map((file, index, arr) => (
              <Fragment key={file.name}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <PreviewAvatar color="primary" variant="rounded">
                      <ImageIcon />
                    </PreviewAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    secondary={file.identifier ? 'Uploaded' : loading ? 'Uploading' : 'Ready to upload'}
                  />
                </ListItem>
                {index + 1 !== arr.length && <Divider component="li" />}
              </Fragment>
            ))}
          </List>
        </Card>
      </Box>
    );
  };

  const renderRejections = () => {
    if (!fileRejections || fileRejections.length === 0) {
      return undefined;
    }
    return (
      <ZoneAlert severity="warning">
        <List dense disablePadding>
          {fileRejections.map(({ file, errors }) => (
            <ListItem key={file.name}>
              <ListItemText primary={file.name} secondary={errors.map((e) => e.message).join(',')} />
            </ListItem>
          ))}
        </List>
      </ZoneAlert>
    );
  };

  return (
    <Box>
      {renderRejections()}
      {renderPreviews()}
      <ZoneContainer component="div" {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon />
        <MainTextContainer>
          {loading ? (
            <Typography variant="body1">Uploading...</Typography>
          ) : isDragActive ? (
            <Typography variant="body1">Drop the files here...</Typography>
          ) : (
            <Typography variant="body1">
              <ClickMeText underline="always">Click to upload image of concession card</ClickMeText> or drag and drop
            </Typography>
          )}
        </MainTextContainer>
        <FileTypeTextContainer>
          <Typography variant="body2" color="textSecondary">
            PNG, JPG, PDF, or HEIC
          </Typography>
        </FileTypeTextContainer>
      </ZoneContainer>
    </Box>
  );
};

export default ConcessionCardUpload;
