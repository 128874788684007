import type { GetPaymentStatusQuery } from '../graphql/generated/graphql';

export type ConsultPaymentMethod = 'INVOICE' | 'CREDIT_CARD';

export type ConsultPaymentState = 'NOT_PAID' | 'IN_PROGRESS' | 'PAID';

export type ConsultPaymentStatusData = GetPaymentStatusQuery['getPaymentStatus'];

function derivePaymentMethod(paymentDetails?: ConsultPaymentStatusData): ConsultPaymentMethod {
  if (paymentDetails?.preAuthTransactionExists) {
    return 'CREDIT_CARD';
  }
  return 'INVOICE';
}

function deriveCreditCardPaymentState(
  paymentDetails?: ConsultPaymentStatusData,
  paymentQueued?: boolean
): ConsultPaymentState {
  if (paymentDetails?.debitTransactionExists) {
    return 'PAID';
  }
  if (paymentQueued) {
    return 'IN_PROGRESS';
  }
  return 'NOT_PAID';
}

function deriveInvoicePaymentState(paidDate?: string, invoiceCode?: string): ConsultPaymentState {
  if (paidDate) {
    return 'PAID';
  }
  if (invoiceCode) {
    return 'IN_PROGRESS';
  }
  return 'NOT_PAID';
}

function derivePaymentState(
  paymentMethod: ConsultPaymentMethod,
  paymentDetails?: ConsultPaymentStatusData,
  paymentQueued?: boolean,
  paidDate?: string, // e.g. 2021-05-19T00:00:00.000Z,
  invoiceCode?: string
): ConsultPaymentState {
  if (paymentMethod === 'CREDIT_CARD') {
    return deriveCreditCardPaymentState(paymentDetails, paymentQueued);
  }
  return deriveInvoicePaymentState(paidDate, invoiceCode);
}

// Mark everything as optional for now since this hook is consumed in a js page that optionally displays a specific
// consult.
export type UseConsultProps = Partial<{
  paymentDetails: ConsultPaymentStatusData;
  paymentQueued: boolean;
  paidDate: string;
  invoiceCode: string;
}>;

// A hook for managing a singular consultations state. This hook will help us separate the "showConsultation"
// component block from of the ConsultationTab.
export function useConsult({ paymentDetails, paymentQueued, paidDate, invoiceCode }: UseConsultProps) {
  const paymentMethod = derivePaymentMethod(paymentDetails);
  const paymentState = derivePaymentState(paymentMethod, paymentDetails, paymentQueued, paidDate, invoiceCode);

  return {
    paymentMethod,
    paymentState
  };
}

export default useConsult;
