import settings from '@/data/constants';
import { consultationNotesHistoryContract } from '@montugroup/pms-api-contracts';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';
import { normalizeHeaders, requestHeaders } from '../api.utils';

export const consultationNotesClient = () => {
  return initQueryClient(consultationNotesHistoryContract, {
    /**
     * standard settings.url will not work here.
     * Results in error: Cannot GET /api/v1/api/pms/v1/consultations/{contractId}/notes
     *
     * note the double up of api url
     */
    baseUrl: settings.baseXApiUrl,
    baseHeaders: {},
    api: (args) => {
      args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
      // @ts-expect-error: TS2345: typing seems to be reference from an old version of ts-rest
      return tsRestFetchApi(args);
    }
  });
};
