import type { Theme } from '@emotion/react';
import CircleIcon from '@mui/icons-material/Circle';
import type { SxProps } from '@mui/material';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Link,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  chipClasses,
  styled
} from '@mui/material';
import type { ChangeEvent, MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import type { HeadCell, SortOrder, TableRowData } from '@/components/rescripting/RescriptingPage.types';
import { getComparator, getRowCount } from '@/components/rescripting/RescriptingPage.utils';
import { FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import theme from '@/theme';

import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import ProductSelect from './ProductSelect';

const hyperLinkStyle: SxProps<Theme> = {
  textDecoration: 'none',
  cursor: 'pointer',
  fontSize: '12px',
  color: theme.palette.secondary.main,
  background: 'none !important',
  border: 'none',
  ':hover': {
    textDecoration: 'underline'
  }
};

const PriorityChip = styled(Chip)`
  background-color: #c400001a;
  .${chipClasses.icon} {
    color: #c40000;
  }
  .${chipClasses.iconSmall} {
    font-size: 0.75rem;
  }
`;

const priorityRequestHeadCells: readonly HeadCell[] = [
  {
    id: 'daysGapReq',
    label: 'Request'
  }
];

type Props = {
  tableData: TableRowData[];
  productListData: CatalogProduct[];
  handleApproval: (selected: string[]) => void;
  handleProductSelected: (uniqueKey: string, selectedProduct: CatalogProduct | undefined) => void;
  showGenerativeCount?: boolean;
  mainHeadCells: readonly HeadCell[];
  canActionScriptCreation?: boolean;
};

export default function RescriptingTable({
  tableData,
  productListData,
  handleApproval,
  handleProductSelected,
  showGenerativeCount = false,
  mainHeadCells,
  canActionScriptCreation = true
}: Props) {
  const { flags } = useFeatureFlags();
  const ffEnableProductIssueEnquiries = flags[FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES];

  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableRowData | string>(
    ffEnableProductIssueEnquiries ? '' : 'patientName'
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const dataRows: TableRowData[] = tableData;

  // TODO: This memo isn't doing anything because the parent component is not memoizing `data`
  const visibleRows = useMemo(
    () =>
      dataRows
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, dataRows, page, rowsPerPage]
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataRows.length) : 0;
  const patientRescriptRequestCount = getRowCount(tableData, 'patientRescriptReqDate');

  const handleRequestSort = (_event: MouseEvent<unknown>, property: string) => {
    if (!ffEnableProductIssueEnquiries) {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = dataRows.map((n) => n.uniqueKey);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClickTableRow = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      // Temporarily limit selection of scripts to less than 10.
      // This fix can be removed once PDF generation process in BE improved.
      if (selected.length >= 10) {
        newSelected = selected;
      } else {
        newSelected = newSelected.concat(selected, name);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleApproveSelected = () => {
    handleApproval(selected);
  };

  // Temporarily show notice if more than 10 scripts selected
  const tempAlert =
    selected.length === 10 ? (
      <Alert sx={{ mb: 1 }} severity="info">
        Maximum number of scripts selected
      </Alert>
    ) : null;

  const headCells = ffEnableProductIssueEnquiries ? [...priorityRequestHeadCells, ...mainHeadCells] : mainHeadCells;

  return (
    <Box sx={{ width: '100%' }}>
      {tempAlert}
      <Paper sx={{ width: '100%', mb: 2 }}>
        {canActionScriptCreation && (
          <EnhancedTableToolbar numSelected={selected.length} onClick={handleApproveSelected} />
        )}
        <TableContainer>
          <Table sx={{ minWidth: 750, ['& th']: theme.spacing(1.5, 1) }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataRows.length}
              customOptions={[
                {
                  columnName: 'daysGapReq',
                  customNumber: patientRescriptRequestCount,
                  customColor: '#ffffff',
                  customBgColor: '#b3261e'
                }
              ]}
              isSortingEnabled={!ffEnableProductIssueEnquiries}
              canActionScriptCreation={canActionScriptCreation}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.uniqueKey);
                const labelId = `enhanced-table-checkbox-${index}`;

                const hasAlternative = Boolean(
                  row.selectedAlternativeProductId &&
                    row.selectedAlternativeProductId !== '' &&
                    (productListData || []).find((product) => product.id === row.selectedAlternativeProductId)
                );
                return (
                  <TableRow
                    hover
                    onClick={() => {
                      if (hasAlternative) {
                        handleClickTableRow(row.uniqueKey);
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.uniqueKey}_${index}`}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {canActionScriptCreation && (
                      <TableCell padding="checkbox">
                        <Tooltip
                          title={
                            !hasAlternative
                              ? 'Please provide an alternative medication first'
                              : 'Select this medication for approval'
                          }
                          placement="right"
                        >
                          <span>
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                              disabled={!hasAlternative}
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                    )}
                    {ffEnableProductIssueEnquiries && (
                      <TableCell>
                        {row.patientRescriptReqDate && (
                          <>
                            <PriorityChip icon={<CircleIcon />} label="Priority" size="small" />
                            <Typography sx={{ marginTop: '0.2rem' }} variant="body2">
                              {row.daysGapReq}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left">{row.orderCode}</TableCell>
                    <TableCell align="left">{row.patientName}</TableCell>
                    <TableCell align="left">{row.patientDob}</TableCell>
                    <TableCell align="left">
                      <Link href={`/patients/${row.patientId}`} target="_blank" sx={hyperLinkStyle}>
                        {row.patientCode}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.ossProductName}</TableCell>
                    <TableCell align="left">{row.scriptCreationDate}</TableCell>
                    <TableCell align="left" style={{ width: 100 }}>
                      {row.allProductsOnScript?.map((itm: string) => (
                        <ListItem key={itm} style={{ fontSize: '12px' }}>
                          • {itm.toString()}
                        </ListItem>
                      ))}
                    </TableCell>
                    {showGenerativeCount && <TableCell align="left">{row.generativeCount}</TableCell>}
                    <TableCell align="center">{row.totalCount}</TableCell>
                    {canActionScriptCreation && (
                      <TableCell align="left">
                        <ProductSelect
                          value={row.selectedAlternativeProductId ? Number(row.selectedAlternativeProductId) : ''}
                          options={productListData}
                          uniqueKey={row.uniqueKey}
                          onSelect={(uniqueKey, selectedProduct) => {
                            handleProductSelected(uniqueKey, selectedProduct);
                          }}
                        />
                      </TableCell>
                    )}
                    {!canActionScriptCreation && (
                      <TableCell align="left" style={{ width: 100 }}>
                        {row.doctorName}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={dataRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
