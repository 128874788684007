import type { EventContentArg } from '@fullcalendar/core';
import type { EventImpl } from '@fullcalendar/core/internal';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import CircleIcon from '@mui/icons-material/Circle';
import type { AlertColor, Theme } from '@mui/material';
import { Alert, Box, CircularProgress, darken, lighten, Snackbar, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';

import NurseFlexCalendarDrawer from '@/components/NurseFlexCalendar/components/NurseFlexCalendarDrawer';
import NurseFlexCalendarResetModal from '@/components/NurseFlexCalendar/components/NurseFlexCalendarResetModal';
import type { PaletteKey } from '@/components/NurseFlexCalendar/NurseFlexCalendar.constants';
import {
  CALENDAR_SLOT_MAX_TIME,
  CALENDAR_SLOT_MIN_TIME
} from '@/components/NurseFlexCalendar/NurseFlexCalendar.constants';
import { calulateScrollTimePosition } from '@/components/NurseFlexCalendar/NurseFlexCalendar.utils';
import settings from '@/data/constants';
import { getAuthData } from '@/data/service/authService';
import { useAccessControl } from '@/hooks';

import type { Nurse } from '@/types';
import { UserRole } from '@/types';

import { formatNurseList } from '@/components/calendar/TimelineScheduler/TimelineScheduler.utils';
import { AutocompleteMultiSelect } from '@montugroup/design-system';
import useNurseFlexCalendar from './hooks/useNurseFlexCalendar';

export type NurseFlexCalendarProps = {
  nurses: Nurse[];
  nurseListLoading: boolean;
};

interface SnackBarMessage {
  type: string;
  message: string;
}

const StyledEventCard = styled(Box, {
  shouldForwardProp: (prop: string) => !['cardColourTheme'].includes(prop as string)
})<{ cardColourTheme: PaletteKey }>(({ cardColourTheme, theme }) => ({
  padding: 5,
  marginLeft: 4,
  marginRight: 2,
  borderRadius: 5,
  color: darken(theme.palette[cardColourTheme].dark, 0.5),
  backgroundColor: lighten(theme.palette[cardColourTheme].light, 0.9),
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

const StyledConsultType = styled(Typography, {
  shouldForwardProp: (prop: string) => !['cardColourTheme'].includes(prop as string)
})<{ cardColourTheme: PaletteKey }>(({ cardColourTheme, theme }) => ({
  color: theme.palette[cardColourTheme].main
}));

const StyledCircleIcon = styled(CircleIcon, {
  shouldForwardProp: (prop) => !['cardColourTheme'].includes(prop as string)
})<{ cardColourTheme: PaletteKey }>(({ cardColourTheme, theme }) => ({
  color: theme.palette[cardColourTheme].dark,
  zoom: '0.6'
}));

const StyledCalendarButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '30px',
  marginBottom: '-35px' // Please forgive me
});

const NurseFlexCalendar = ({ nurses, nurseListLoading }: NurseFlexCalendarProps) => {
  const { allowAccess } = useAccessControl();

  const { isSuperAdmin, isManager, isAdminOnly } = getAuthData();
  const isAllowedToMoveEvents = isSuperAdmin || isManager || isAdminOnly;
  const calendarRef = useRef(null);
  const scrollTimeRef = useRef(null);
  const nurseListSelectOptions = useMemo(() => {
    return formatNurseList(nurses ?? []);
  }, [nurses]);

  const {
    handleSaveCalendar,
    handleFlexNurseSelected,
    handleLeaveNurseSelected,
    handleEventDrop,
    handleResetCalendar,
    handleEventClicked,
    handleToggleDrawer,
    handleSnackbarClose,
    isEventAssignedToFloatingNurse,
    isEventExistingWithFloatingNurse,
    isLoadingCalendarData,
    isRefetchingCalendarData,
    refetchAllNurseConsults,
    openEventDrawer,
    showSnackbar,
    selectedEvent,
    leaveNurses,
    flexNurses,
    events
  } = useNurseFlexCalendar({ nurseListSelectOptions, nurseListLoading, calendarRef });

  const getCardColourTheme = (event: EventImpl) => {
    const resources = event.getResources();
    const nurseResourceId = resources.map((resource: { id: string }) => resource.id)[0];
    const isAssignedToFloatingNurse = isEventAssignedToFloatingNurse(nurseResourceId || '');
    const isExistingFloatingNurseEvent = isEventExistingWithFloatingNurse(
      event,
      nurseResourceId,
      isAssignedToFloatingNurse
    );

    return isAssignedToFloatingNurse ? (isExistingFloatingNurseEvent ? 'info' : 'success') : 'error';
  };

  const formatEventContentCard = (arg: EventContentArg) => {
    const event = arg.event;
    const eventDetails = event.extendedProps;

    return (
      <StyledEventCard
        display={'flex'}
        flexDirection={'column'}
        cardColourTheme={getCardColourTheme(event)}
        data-dd-action-name="Consultation"
      >
        <Box display={'flex'} alignItems={'center'}>
          <StyledCircleIcon fontSize={'small'} cardColourTheme={getCardColourTheme(event)} />
          <StyledConsultType ml={2} fontSize={'0.8rem'} cardColourTheme={getCardColourTheme(event)}>
            {eventDetails.consult_type}
          </StyledConsultType>
        </Box>
        <Typography textOverflow={'ellipsis'} fontWeight={'500'} fontSize={'0.8rem'}>
          {eventDetails.patient_name}
        </Typography>
      </StyledEventCard>
    );
  };

  const calendarButtons = allowAccess(
    [UserRole.SuperAdmin, UserRole.Manager, UserRole.Admin],
    <StyledCalendarButtons>
      <NurseFlexCalendarResetModal handleResetCalendar={handleResetCalendar} />
      <Button onClick={handleSaveCalendar} variant={'contained'} color={'secondary'} sx={{ marginRight: 5 }}>
        Save Calendar
      </Button>
    </StyledCalendarButtons>
  ) as ReactNode;

  const resourceGroups = [
    {
      id: 'unplanned-leave-nurses',
      title: 'Unplanned Leave Nurses',
      eventAllow: (): boolean => {
        return false;
      },
      children: leaveNurses?.map((nurse) => {
        return {
          id: nurse?.id,
          title: nurse?.title,
          eventColor: 'transparent'
        };
      })
    },
    {
      id: 'floating-nurses',
      title: 'Floating Nurses',
      eventAllow: (): boolean => {
        return false;
      },
      children: flexNurses?.map((nurse) => {
        return {
          id: nurse?.id,
          title: nurse?.title,
          eventColor: 'transparent'
        };
      })
    }
  ];

  const fullCalendarSettings = {
    ref: calendarRef,
    schedulerLicenseKey: settings.fullCalendarKey,
    plugins: [interactionPlugin, resourceTimelinePlugin],
    initialView: 'resourceTimelineDay',
    scrollTime: scrollTimeRef.current || `${CALENDAR_SLOT_MIN_TIME}:00`,
    aspectRatio: 1.5,
    headerToolbar: {
      left: undefined,
      center: 'title',
      right: undefined
    },
    views: {
      resourceTimelineDay: {
        slotDuration: '00:20'
      }
    },
    slotMinWidth: 100,
    editable: isAllowedToMoveEvents,
    eventOverlap: false,
    eventDurationEditable: false,
    slotMinTime: `${CALENDAR_SLOT_MIN_TIME}:00`,
    slotMaxTime: `${CALENDAR_SLOT_MAX_TIME}:00`,
    resources: resourceGroups,
    events: events,
    scrollTimeReset: false,
    viewDidMount: () => {
      // This is used to reset the scroll position even after the calendar component
      // has re-rendered. Fixes a bug where the calendar scrolls to the start time after
      // an interaction event (due to parent state updating and causing a re-render).
      calulateScrollTimePosition(scrollTimeRef);
    },
    eventContent: formatEventContentCard,
    eventClick: handleEventClicked,
    eventDrop: handleEventDrop
  } as any;

  // This is ugly. But it's used to give background colours to the 'Floating nurses' calendar rows. A cleaner way
  // may exist with the FullCalendar API (https://fullcalendar.io/), but I couldn't find anything obvious.
  const rowNthOfTypeBackground = (theme: Theme) => {
    const leaveNursesLength = leaveNurses.length + 1;
    const flexNursesLength = flexNurses.length + 1;

    const timelineRowsLeftSelector = '.fc-resource-timeline .fc-datagrid-body';
    const timelineRowsRightSelector = '.fc-resource-timeline .fc-timeline-body .fc-scrollgrid-sync-table';

    return `
      ${timelineRowsLeftSelector} tr:nth-of-type(-n+${flexNursesLength}),
      ${timelineRowsRightSelector} tr:nth-of-type(-n+${flexNursesLength}):nth-of-type(-n+${
        leaveNursesLength + flexNursesLength
      }) {
         background: ${theme.palette.grey[100]};
      },
    `;
  };

  const StyledFullCalendarContainer = styled(Box)`
    .fc {
      height: 70vh;
    }

    .fc-datagrid-expander {
      display: none;
    }

    ${({ theme }) => rowNthOfTypeBackground(theme)}
  `;

  return (
    <>
      <Box>
        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box paddingY={5} mr={3} flexBasis={'50%'} flexGrow={0}>
            {!nurseListLoading && (
              <AutocompleteMultiSelect
                disabled={!isAllowedToMoveEvents}
                options={nurseListSelectOptions}
                value={flexNurses}
                itemSelected={handleFlexNurseSelected}
                labelText="Floating nurses"
                placeholderText="Nurse name"
              />
            )}
          </Box>
          <Box paddingY={5} ml={3} flexBasis={'50%'} flexGrow={0}>
            {!nurseListLoading && (
              <AutocompleteMultiSelect
                disabled={!isAllowedToMoveEvents}
                options={nurseListSelectOptions}
                value={leaveNurses}
                itemSelected={handleLeaveNurseSelected}
                labelText="Unplanned leave nurses"
                placeholderText="Nurse name"
              />
            )}
          </Box>
        </Box>
        {calendarButtons}
      </Box>
      <StyledFullCalendarContainer>
        {isLoadingCalendarData || isRefetchingCalendarData ? (
          <Box display="flex" justifyContent="center" mt={40}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <>
            <FullCalendar {...fullCalendarSettings} />
            <NurseFlexCalendarDrawer
              selectedEvent={selectedEvent}
              toggleDrawer={handleToggleDrawer}
              open={openEventDrawer}
              nurses={nurses}
              refetchAllNurseConsults={refetchAllNurseConsults}
            />
          </>
        )}
      </StyledFullCalendarContainer>
      <Snackbar
        open={!!showSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSnackbarClose}
        autoHideDuration={3000}
      >
        <Alert variant="filled" severity={(showSnackbar as SnackBarMessage)?.type as AlertColor}>
          {(showSnackbar as SnackBarMessage).message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default NurseFlexCalendar;
