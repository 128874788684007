import type { EventClickArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import type { ResourceSourceInput } from '@fullcalendar/resource';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import settings from '@/data/constants';
import type { ReactElement } from 'react';

export type CalendarEvent = {
  resourceId: string;
  title: string;
  start: string;
  end: string;
};

export type TimelineCalendarProps = {
  resources: ResourceSourceInput;
  resourceHeader: string;
  eventCallback?: (
    fetchInfo: EventSourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void
  ) => void;
  handleEventClicked?: (event: EventClickArg) => void;
  eventContent?: ReactElement;
  slotDuration?: string;
};

export default function TimelineCalendar({
  resources,
  eventCallback,
  resourceHeader,
  handleEventClicked,
  eventContent,
  slotDuration = '00:20'
}: TimelineCalendarProps) {
  const fullCalendarSettings = {
    schedulerLicenseKey: settings.fullCalendarKey,
    plugins: [resourceTimelinePlugin],
    slotDuration: slotDuration,
    slotMinTime: '07:00:00',
    slotMaxTime: '21:00:00',
    slotMinWidth: 100,
    initialView: 'resourceTimelineDay',
    aspectRatio: 1.5,
    views: {
      resourceTimelineDay: {
        buttonText: 'Day',
        slotDuration: slotDuration
      }
    },
    headerToolbar: {
      left: 'today prev,next',
      center: 'title',
      right: ''
    },
    editable: false,
    resourceAreaHeaderContent: resourceHeader,
    resources: resources,
    events: eventCallback,
    eventClick: handleEventClicked,
    ...(eventContent && { eventContent })
  };

  return <FullCalendar {...fullCalendarSettings} />;
}
