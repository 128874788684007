import { useMutation } from '@tanstack/react-query';

import { requestHeaders } from '@/api';
import { feedbackPayloadConstructor } from '@/components/PatientPageV2/components/PrescriptionPad';
import type { FeedbackPayload } from '@/components/PatientPageV2/components/PrescriptionPad/PrescriptionPad.types';
import type { Filter } from '@/shared-ui/ProductFilter/ProductFilter';
import { Logger } from '@/utils/logger';

import settings from '../../data/constants';

const logger = new Logger('usePostProductSurvey.ts');

type MutationProps = {
  doctorId: number;
  patientId: number;
  feedback: FeedbackPayload[];
};

export type UsePostProductSurveyResponse = {
  id: number;
  question_asked: number;
  answer_selected: number;
  doctor_id: number;
  patient_id: number;
  product_chosen: number;
  filters: Filter;
  modified_date: string;
  created_date: string;
};

export const usePostProductSurvey = () => {
  return useMutation({
    mutationFn: ({ doctorId, patientId, feedback }: MutationProps) =>
      fetch(`${settings.url}/product-survey/response?doctorId=${doctorId}&patientId=${patientId}`, {
        method: 'POST',
        headers: requestHeaders(),
        body: JSON.stringify(feedbackPayloadConstructor(feedback))
      }),
    onError: (err) => {
      logger.error(err);
    }
  });
};
