import { Alert, Button, Card, Stack, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import * as React from 'react';

type TutorialModalProps = {
  open: boolean;
  alertContent?: React.ReactNode;
  children: React.ReactNode;
  handleClose: () => void;
  handleClickPrimaryButton: () => void;
  handleClickSecondaryButton: () => void;
  primaryButtonText: string;
  secondaryButtonText: string;
};

const StyledCard = styled(Card)({
  width: '90%',
  maxWidth: '50rem',
  margin: '2.5% auto',
  padding: '2rem'
});

const StyledButton = styled(Button)({
  minWidth: 'unset',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  marginRight: '1rem'
});

export default function TutorialModal({
  open,
  alertContent,
  children,
  primaryButtonText,
  secondaryButtonText,
  handleClose,
  handleClickPrimaryButton,
  handleClickSecondaryButton
}: TutorialModalProps) {
  return (
    <Modal open={open} onClose={handleClose}>
      <StyledCard>
        {alertContent && <Alert severity="warning">{alertContent}</Alert>}

        {children}

        <Stack flexDirection="row" justifyContent="flex-end" marginTop="2rem" gap="5">
          <StyledButton variant="contained" color="warning" onClick={handleClickPrimaryButton}>
            {primaryButtonText}
          </StyledButton>
          <StyledButton size="small" variant="outlined" color="warning" onClick={handleClickSecondaryButton}>
            {secondaryButtonText}
          </StyledButton>
        </Stack>
      </StyledCard>
    </Modal>
  );
}
