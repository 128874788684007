const drawerWidth = 650;

/** @deprecated we're moving towards using a design system  */
const AppointmentStyles = (theme) => ({
  columnHeading: {
    'padding-right': '18px'
  },
  grid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      cursor: 'pointer'
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.4rem',
    justifyContent: 'space-between'
  },
  content: {
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  link: {
    color: '#000000'
  },
  button: {
    borderRadius: 20
  },
  drawerHeaders: {
    fontWeight: 600
  },
  Buttonlink: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    color: theme.palette.secondary.main,
    background: 'none !important',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  linkDanger: {
    color: `${theme.palette.orange.light} !important`
  },
  floatRight: {
    float: 'right'
  },
  heading: {
    'white-space': 'nowrap'
  },
  light_color_button: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '20px',
    padding: '10px 20px 4px 20px !important',
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: '#0F455D',
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: `${theme.palette.primary.light}`
    }
  }
});

export default AppointmentStyles;
