import { styled, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React from 'react';

import { CreatePrescriptionTutorialModal } from '@/components/PatientPageV2/components/CreatePrescriptionTutorialModal/CreatePrescriptionTutorialModal';
import { useShowInventoryInsightsTutorial } from '@/components/PatientPageV2/hooks/useShowInventoryInsightsTutorial';
import { DoctorCalendarTable } from '@/components/tables/DoctorCalendarTable';
import { getAuthData } from '@/data/service/authService';
import { makeGET } from '@/data/service/dataService';

import AppointmentStyles from '../assets/js/appointments';
import GoToDateField from '../components/fields/GoToDateField';

const useStyles = makeStyles((theme) => AppointmentStyles(theme));
const DoctorCalendar = () => {
  const classes = useStyles();
  const [doctorsList, setDoctorsList] = React.useState([]);
  const [selectedDoc, setSelectedDoc] = React.useState('all');
  const [date, setDate] = React.useState(moment());

  const { openTutorialModal, handleWatchLaterAndDismissModal, handleWatchNow } = useShowInventoryInsightsTutorial();

  const { isAdmin, isNurse, isManager } = getAuthData();

  const defaultSelectedDoctor = { label: 'All Doctors', id: 'all' };

  const getDoctors = async () => {
    const doctors = await makeGET('doctor?raw=true', 'getDoctors-Calendar');
    if (doctors) {
      const list = doctors.map((doctor) => {
        return {
          label: `${doctor.DoctorUser.first_name} ${doctor.DoctorUser.last_name} - ${doctor.doctor_code}`,
          id: doctor.id,
          email: doctor.DoctorUser.email,
          calcom_initial_consult_event: doctor.calcom_initial_consult_event,
          calcom_follow_up_consult_event: doctor.calcom_follow_up_consult_event
        };
      });
      list.unshift(defaultSelectedDoctor);
      setDoctorsList(list);
    }
  };

  React.useEffect(() => {
    getDoctors();
  }, []);

  const handleDateChange = (e) => {
    setDate(moment(e));
  };

  const handleSelectDoctorChange = (event, value) => {
    setSelectedDoc(value || defaultSelectedDoctor);
  };

  const StyledButton = styled(Button)({
    lineHeight: '25px'
  });

  return (
    <>
      <Box component="div">
        <Grid container py={4} px={5} width="100%">
          <Box display="flex" alignItems={'center'} width="100%" justifyContent={'space-between'} flexWrap="wrap">
            <Typography variant="h5" ml={1} color="textPrimary" className={classes.heading}>
              Doctor Calendar
            </Typography>
            <Box display="flex">
              <Box ml={3} mr={3}>
                <StyledButton
                  color="primary"
                  variant="contained"
                  p={1}
                  onClick={() => {
                    setDate(moment());
                  }}
                >
                  Today
                </StyledButton>
              </Box>
              <Box width={250} mr={2}>
                <GoToDateField
                  label="Date"
                  customGrid={[1, 12]}
                  margin={0}
                  value={moment(new Date(date))}
                  onChange={handleDateChange}
                />
              </Box>
              {(isNurse || isAdmin || isManager) && (
                <Box className="inputfield-container">
                  <Autocomplete
                    sx={{
                      width: '250px',
                      minWidth: '250px'
                    }}
                    defaultValue={defaultSelectedDoctor}
                    options={doctorsList}
                    filterSelectedOptions
                    onChange={handleSelectDoctorChange}
                    renderInput={(params) => {
                      const labelValue = doctorsList.length === 0 ? 'Loading doctors...' : 'Select a doctor';
                      return <TextField {...params} label={labelValue} placeholder="Select a doctor" size="small" />;
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box component="div" width="100%">
            <DoctorCalendarTable
              getQueryParams={{ selectedDoctor: selectedDoc.id, dateIsoString: date.toISOString() }}
            />
          </Box>
        </Grid>
      </Box>
      <CreatePrescriptionTutorialModal
        open={openTutorialModal}
        handleClose={handleWatchLaterAndDismissModal}
        handleClickPrimaryButton={handleWatchNow}
        handleClickSecondaryButton={handleWatchLaterAndDismissModal}
      />
    </>
  );
};

export default DoctorCalendar;
