import { prescribeContract, prescriptionContract } from '@montugroup/prescription-contracts';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

import { normalizeHeaders, requestHeaders } from '@/api';
import settings from '@/data/constants';

type ContractType = typeof prescriptionContract | typeof prescribeContract;

const buildPrescribeProductClient = <T extends ContractType>(contract: T) => {
  return initQueryClient(contract, {
    baseUrl: settings.url,
    api: (args) => {
      args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
      // @ts-expect-error: TS2345: typing seems to be reference from an old version of ts-rest
      return tsRestFetchApi(args);
    },
    baseHeaders: {}
  });
};

export const prescribedProductClient = buildPrescribeProductClient(prescriptionContract);
export const prescribeClient = buildPrescribeProductClient(prescribeContract);
