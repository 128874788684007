import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { QueryKey } from '@/data/query';
import type { DoctorRoundRobinItem } from '@/hooks/rest/types';

import settings from '../../data/constants';

export type DoctorRoundRobinResponse = {
  current: DoctorRoundRobinItem[];
  removed: DoctorRoundRobinItem[];
} | null;
const getRoundRobinDoctorsOverflow = async () => {
  const url = `${settings.url}/calcom/doctor-overflow-roundrobin`;

  try {
    const response = await axios.get<DoctorRoundRobinResponse>(url);
    if (response.status !== 200) {
      throw new Error(`Request failed with status code ${response.status}`);
    }
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch round robin doctors: ${(error as { message: string }).message}`);
  }
};

export const useGetRoundRobinDoctorsOverflow = () => {
  const { data, isError, refetch } = useQuery({
    queryKey: [QueryKey.RoundRobinDoctorsOverflow],
    queryFn: async () => getRoundRobinDoctorsOverflow()
  });

  return {
    data,
    isError,
    refetch
  };
};
