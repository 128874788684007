import settings from '../../data/constants';

import useRequest from './useRequest';

export type RescriptProduct = {
  productId: number;
  repeats: number;
  quantity: number;
  interval: number;
  dosage: string;
  name: string;
  isConcession: boolean;
  otherProduct: string;
  originalProductId?: number;
  isPatientRescriptReq?: boolean;
};

export type Rescript = {
  originalPrescriptionId: number;
  newPrescription: {
    orderDate: string;
    patientId: number | undefined;
    doctorId: number | undefined;
    products: RescriptProduct[];
  };
};

export type PutRescriptProps = {
  rescriptType?: 'GENERATIVE' | 'OUT_OF_STOCK';
  prescriptions: Rescript[];
};

export function usePutPrescriptBatchReplace() {
  const { loading, data, error, execute } = useRequest({
    config: {
      url: `${settings.url}/prescription/batch-replace`,
      method: 'PUT'
    }
  });

  const doPut = async (body: PutRescriptProps) => {
    return execute({ data: body });
  };

  return {
    loading,
    data,
    error,
    doPut
  };
}

export default usePutPrescriptBatchReplace;
