import { AlertModal } from '@/shared-ui';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DEVICE_SUITABILITY_MODAL_PROPS } from './DeviceSuitabilityModal.config';

type DeviceSuitabilityModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeviceSuitabilityModal = ({ isOpen, onClose, onConfirm }: DeviceSuitabilityModalProps) => {
  return (
    <AlertModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} {...DEVICE_SUITABILITY_MODAL_PROPS}>
      <Typography paragraph>
        There have been recent changes implemented by the Therapeutic Goods Administration (TGA) which outline the types
        of medical cannabis vaping devices we are able to supply to our patients.
      </Typography>

      <Typography paragraph>
        At present, the only approved medical cannabis vaping device is the Storz & Bickel Mighty Medic, which is used
        for dried herb medications only.
      </Typography>

      <Typography paragraph>
        You have selected medications which have been impacted by these changes. If the patient already has a suitable
        device for use with these medications, you can continue.
      </Typography>

      <Typography paragraph fontWeight="bold">
        Suitable Cannabis vaping devices:
      </Typography>

      <Typography paragraph>
        <Box component="b">Herb, Dried</Box> - Storz & Bickel - Mighty Medic
      </Typography>

      <Typography paragraph>
        <Box component="b">Inhalation</Box> - 510 batteries for use with medicinal cannabis cartridges.
      </Typography>

      <Typography paragraph fontWeight="bold">
        Does the patient have access to one of these devices for use with the medications you are prescribing?
      </Typography>
    </AlertModal>
  );
};

export { DeviceSuitabilityModal };
