import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

export type PageHeaderProps = BoxProps;

/**
 * Page Header used in PMS. Renders as a `<header>` element by default
 */
export const PageHeader = forwardRef<HTMLElement, BoxProps>(function PageHeader(props, ref) {
  return <Box component={'header'} padding={4} {...props} ref={ref} />;
});
