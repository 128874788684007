import isValidPhoneNumber from '@/utils/isValidPhoneNumber';
import { z } from 'zod';

export const schema = z.object({
  phone: z
    .string()
    .trim()
    .refine((val) => {
      return isValidPhoneNumber(val);
    }, 'Invalid phone number')
    .optional(),
  email: z.string().email().optional(),
  fullName: z.string().optional(),
  clinicName: z.string().optional()
});
