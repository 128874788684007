import AddIcon from '@mui/icons-material/Add';
import { Box, styled, Typography } from '@mui/material';
import { useFieldArray } from 'react-hook-form';

import dayjs from '@/utils/dayjs';

import { StyledButton } from '../shared/components/styled-components';
import type {
  AvailabilityFormValues,
  DateOverrideRange,
  TimeRange
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // WorkingHours
} from '../shared/types/calcom-schedule-types';

import DateOverrideInputDialog from './components/DateOverrideInputDialog';
import DateOverrideList from './components/DateOverrideList';

/**
 * DateOverride component
 *
 * @remarks
 * This component is part of the "UpdateScheduleForm" and allows the user to update availability overrides for specific dates
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DateOverride'
 * See - https://github.com/calcom/cal.com/blob/main/apps/web/pages/availability/[schedule].tsx
 *
 * @returns JSXElement
 *
 */

const StyledAddOverrideButton = styled(StyledButton)({
  marginTop: 20,
  marginBottom: 30
});

const DateOverride = () =>
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // { workingHours }: { workingHours: WorkingHours[] }
  {
    const { remove, append, replace, fields } = useFieldArray<AvailabilityFormValues, 'dateOverrides'>({
      name: 'dateOverrides'
    });

    const formatRangesDateFormat = (formFields: DateOverrideRange[]) => {
      const updatedFields = formFields.map((field: DateOverrideRange) => ({
        id: field.id,
        ranges: field.ranges.map((range: TimeRange) => ({
          start: dayjs(range.start).toDate(),
          end: dayjs(range.end).toDate()
        }))
      }));

      return updatedFields;
    };

    const fieldsWithFormattedRange = formatRangesDateFormat(fields);

    const excludedDates = fields.map((field) => dayjs(field.ranges[0].start).utc().format('YYYY-MM-DD'));

    return (
      <Box data-testid="date-overrides">
        <Typography variant="h6" mt={3} textAlign={'center'}>
          Add overrides for specific dates
        </Typography>
        <Typography textAlign={'center'}>Add dates when your availability changes from your daily hours</Typography>
        <Box>
          <DateOverrideInputDialog
            // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
            // workingHours={workingHours}
            excludedDates={excludedDates}
            onChange={(ranges) => ranges.forEach((range) => append({ ranges: [range] }))}
            trigger={
              <StyledAddOverrideButton variant="outlined" startIcon={<AddIcon />}>
                <span>Add an override</span>
              </StyledAddOverrideButton>
            }
          />
          <DateOverrideList
            excludedDates={excludedDates}
            remove={remove}
            replace={replace}
            items={fieldsWithFormattedRange}
            // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
            // workingHours={workingHours}
          />
        </Box>
      </Box>
    );
  };

export default DateOverride;
