import { initialState } from '../initialState';

import { AuthReducer } from './authReducer';
import { IdleReducer } from './idleReducer';

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action) => {
  return {
    authState: AuthReducer(state.authState, action),
    idleState: IdleReducer(state.idleState, action)
  };
};

export { initialState, reducer };
