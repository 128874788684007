// TODO: B2C-4154 migrate to MGS schema

import axios from 'axios';

import settings from '@/data/constants';

import type { ConsultationModel } from './Consultation.types';

/**
 * Query parameters for the consultation endpoint. This is copied
 * from PMS service with added comments
 * All types are `string`, as it's query params
 */
export type GetConsultationsQuery = {
  date?: string; // ISODate string
  endDate?: string; // ISODate string
  doc?: string; // doesn't seem to be used in `pms-service`
  /**
   * when `true`, also return nurse consultations.
   * defaults to `false`
   */
  nurse?: string; // boolean string.
  limit?: string; // number string.
  offset?: string; // number string
  search?: string;
  /**
   * which field to sort the result by
   */
  order?: string;
  /**
   * direction of the sort.
   */
  dir?: 'ASC' | 'DESC';
  hasPaymentId?: string; // boolean string
  byCreatedDate?: string; // boolean string
};

export type GetConsultationsResponse = { consultations: ConsultationModel[]; count: number };

export const getConsultations = async (params?: GetConsultationsQuery) => {
  const url = `${settings.url}/consultation`;
  const response = await axios.get<GetConsultationsResponse>(url, {
    params
  });

  return response?.data;
};
