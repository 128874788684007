import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

import settings from '../../data/constants';

export type CancelConsultationBodyResponse = boolean;
export type CancelConsultationBody = { cancellationReason: string };

type UsePostCancelConsultationProps = {
  consultationId: number | string;
};

export function usePostCancelConsultation({ consultationId }: UsePostCancelConsultationProps) {
  const { isPending, data, error, mutate } = useMutation({
    mutationFn: async (body: CancelConsultationBody) => {
      const cancelConsultationResponse = await axios.post<
        CancelConsultationBody,
        AxiosResponse<CancelConsultationBodyResponse>
      >(`${settings.url}/consultation/${consultationId}/cancel`, body);

      return cancelConsultationResponse.data;
    }
  });

  return {
    loading: isPending,
    data,
    error,
    mutate
  };
}

export default usePostCancelConsultation;
