import { Box, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BasicModal from '@/components/common/BasicModal';

import AcknowledgementModalTable from './AcknowledgementModalTable';

export type MedicationData = [
  string, // Script created
  string, // Product name
  number, // Quantity
  number, // Interval
  number // Repeats
];

type AcknowledgementModalProps = {
  open: boolean;
  doctorName: string;
  medicationData: MedicationData[];
  handleClose: () => void;
};

const StyledBasicModal = styled(BasicModal)({
  outline: 'none',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  '& iframe': {
    border: 0,
    width: '100%',
    height: '100%'
  }
});

/**
 * AcknowledgementModal component
 *
 * @remarks
 * Renders a modal with a warning message (for newly assigned doctors) and a table of all the ongoing prescriptions which the patient has.
 *
 * @typedef {object} Props
 * @property {boolean} open - A boolean indicating whether the modal is open or closed.
 * @property {string} doctorName - The full name of the doctor.
 * @property {Array<MedicationData>} medicationData - An array of the medications prescribed to the patient.
 * @property {Function} handleClose - A function to handle the closing of the modal.
 *
 * @param {Props} props - Contains 'open', 'doctorName', 'medicationData', and 'handleClose'.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */

export default function AcknowledgementModal(props: AcknowledgementModalProps) {
  const { open, doctorName, medicationData, handleClose } = props;

  return (
    <StyledBasicModal open={open} handleClose={handleClose}>
      <Paper>
        <Box p={10} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Typography align="center" mb={10}>
            This patient was previously prescribed under Dr. {doctorName}. Please re-prescribe as appropriate, the
            patients older prescriptions will be revoked and no longer accessible after this consultation. You can also
            find this information in the Patient notes section.
          </Typography>
          <AcknowledgementModalTable medicationData={medicationData} />
          <Box mt={10} textAlign={'center'}>
            <Button variant="contained" color="secondary" onClick={props.handleClose}>
              I Understand
            </Button>
          </Box>
        </Box>
      </Paper>
    </StyledBasicModal>
  );
}
