import type { PaginationModel } from '@montugroup/design-system';
import { PaginationVariant, SearchTextField, Table } from '@montugroup/design-system';
import Flag from '@mui/icons-material/Flag';
import InfoIcon from '@mui/icons-material/Info';
import type { SxProps, Theme } from '@mui/material';
import { Box, Link, Tooltip, Typography, styled } from '@mui/material';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import type { ConsultationModel } from '@/api/consultations/Consultation.types';
import type { GetConsultationsQuery } from '@/api/consultations/getConsultations';
import { renderHeader } from '@/components/tables/components/Table.utils';
import { getAuthData } from '@/data/service/authService';
import { useGetConsultations } from '@/hooks/consult/useGetConsultations';
import dayjs from '@/utils/dayjs';

import { useTableManualPagination } from './hooks/useTableManualPagination';

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

const columnHelper = createColumnHelper<ConsultationModel>();

const getConsultationSortField = (propName: string) => {
  switch (propName) {
    case 'patient_name':
      return 'Patient.PatientUser.first_name';
    case 'patient_email':
      return 'Patient.PatientUser.email';
    case 'doctor_name':
      return 'Doctor.DoctorUser.first_name,Nurse.NurseUser.first_name';
    case 'status':
      return 'ConsultationStatus.name';
    default:
      return propName;
  }
};

export const ConsultationsTable = () => {
  const { isDoc } = getAuthData();
  const { paginationModel, setPaginationModel, shouldResetPageIndex, setShouldResetPageIndex } =
    useTableManualPagination({
      page: 0,
      pageSize: 50
    });

  const [searchFilterText, setSearchFilterText] = useState<string>();

  const [sortingState, setSortingState] = useState<SortingState>([]);

  const sortQuery: Pick<GetConsultationsQuery, 'order' | 'dir'> = useMemo(() => {
    return sortingState.length > 0
      ? {
          order: getConsultationSortField(sortingState[0].id),
          dir: sortingState[0].desc ? 'DESC' : 'ASC'
        }
      : {};
  }, [sortingState]);

  const { data, isLoading } = useGetConsultations({
    nurse: 'true',
    limit: `${paginationModel.pageSize}`,
    offset: `${paginationModel.page * paginationModel.pageSize}`,
    search: searchFilterText,
    ...sortQuery
  });

  const columns = useMemo((): ColumnDef<ConsultationModel>[] => {
    return [
      columnHelper.accessor('isPatientTransferred', {
        id: 'View',
        header: () => renderHeader('View'),
        cell: ({ getValue: getIsPatientTransferred, row }) => {
          if (isDoc && getIsPatientTransferred()) {
            return (
              <Tooltip title="This is a transferred patient">
                <InfoIcon />
              </Tooltip>
            );
          }
          return (
            <Link href={`/patients/${row.original.patient_id}?show=1`} target="_blank">
              View
            </Link>
          );
        },
        meta: {
          sx: {
            width: '40px'
          }
        },
        enableSorting: false
      }) as ColumnDef<ConsultationModel>,
      columnHelper.accessor(
        (row) => {
          return row?.start_time ? new Date(row.start_time) : null;
        },
        {
          id: 'start_time',
          header: () => renderHeader('Consultation Date/Time'),
          cell: ({ getValue, row }) => {
            const endTimeString = row.original?.end_time;
            const errorStyles: SxProps<Theme> =
              !getValue() || !endTimeString
                ? {
                    color: 'error.main'
                  }
                : {};
            const startTime = getValue() ? dayjs(getValue()).format('DD-MMM-YYYY, h:mm a') : 'Invalid start date';
            const endTime = endTimeString ? dayjs(endTimeString).format('h:mm a') : 'Invalid end time';

            return <Typography sx={errorStyles}>{`${startTime} - ${endTime} (${row.original?.duration})`}</Typography>;
          },
          meta: {
            sx: {
              width: '240px'
            }
          }
        }
      ) as ColumnDef<ConsultationModel>,
      {
        id: 'consultation_code',
        accessorKey: 'consultation_code',
        meta: {
          sx: {
            width: '80px'
          }
        },
        header: () => renderHeader('ID')
      },
      {
        id: 'consult_type',
        accessorKey: 'consult_type',
        meta: {
          sx: {
            width: '130px'
          }
        },
        header: () => renderHeader('Consultation Type')
      },
      columnHelper.accessor('patient_name', {
        id: 'patient_name',
        meta: {
          sx: {
            width: '110px'
          }
        },
        header: () => renderHeader('Patient'),
        cell: ({ getValue, row }) => {
          return (
            <>
              {getValue()}
              {row.original.has_red_flag && (
                <Tooltip title={row.original.red_flag_comment ?? ''}>
                  <Flag fontSize="small" color="error" />
                </Tooltip>
              )}
            </>
          );
        }
      }) as ColumnDef<ConsultationModel>,
      columnHelper.accessor('patient_email', {
        id: 'patient_email',
        header: () => renderHeader('Email'),
        meta: {
          sx: {
            width: '180px'
          }
        },
        cell: ({ getValue }) => (
          <Box
            sx={{
              wordWrap: 'break-word'
            }}
          >
            {getValue()}
          </Box>
        )
      }) as ColumnDef<ConsultationModel>,
      columnHelper.accessor(
        (row) => {
          return row.doctor_id ? row.doctor_name : row.nurse_name;
        },
        {
          id: 'doctor_name',
          header: () => renderHeader('Practitioner'),
          meta: {
            sx: {
              width: '130px'
            }
          }
        }
      ) as ColumnDef<ConsultationModel>,
      {
        id: 'status',
        accessorKey: 'status',
        header: () => renderHeader('Status'),
        meta: {
          sx: {
            width: '100px'
          }
        }
      },
      columnHelper.accessor(
        (row) => {
          if (row.payment_status === 'PAID' && row.paid_date) {
            return new Date(row.paid_date);
          }
          return row.payment_status;
        },
        {
          id: 'payment_status',
          header: () => renderHeader('Payment Status'),
          cell: ({ getValue }) => {
            if (getValue() instanceof Date) {
              return dayjs(getValue()).format('DD-MMM-YYYY');
            }
            return getValue();
          },
          meta: {
            sx: {
              width: '100px'
            }
          }
        }
      ) as ColumnDef<ConsultationModel>,

      columnHelper.accessor('referral', {
        id: 'referral_code',
        header: () => renderHeader('Referral Code'),
        cell: ({ getValue }) => {
          if (!getValue()) {
            return '';
          }
          const { content, source, campaign, term } = getValue() || {};
          const referralText = `${content || ''}, ${campaign || ''}, ${term || ''}, ${source || ''}`;
          return (
            <Tooltip title={referralText}>
              <Typography>{referralText}</Typography>
            </Tooltip>
          );
        },
        meta: {
          sx: {
            width: '100px'
          }
        },
        enableSorting: false
      }) as ColumnDef<ConsultationModel>
    ];
  }, [isDoc]);

  const onPaginationModelChange = (changedPaginationModel: PaginationModel) => {
    setPaginationModel(changedPaginationModel);
    setShouldResetPageIndex(false);
  };

  const onSortingChange = (changedSortingState: SortingState) => {
    setSortingState(changedSortingState);
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTextOrEmptyString = event.target.value === '' ? undefined : event.target.value;
    setSearchFilterText(searchTextOrEmptyString);
    setShouldResetPageIndex(true);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <SearchTextField
          handleChange={handleSearchOnChange}
          handleClear={handleClearSearchFilter}
          labelText="Search"
          enableClear={true}
        />
      </Box>
      <StyledTable
        columns={columns}
        compact={true}
        data={data?.consultations || []}
        isLoading={isLoading}
        skeletonCount={paginationModel.pageSize}
        manualPagination={true}
        onPaginationModelChange={onPaginationModelChange}
        manualSorting={true}
        onSortingChange={onSortingChange}
        pageSize={paginationModel.pageSize}
        total={data?.count ? data.count : undefined}
        showPagination
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        isTableLayoutFixed={true}
        shouldResetPageIndex={shouldResetPageIndex}
      />
    </Box>
  );
};
