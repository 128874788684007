import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';

const PREFIX = 'PaginationComponent';

const classes = {
  ul: `${PREFIX}-ul`,
  btn: `${PREFIX}-btn`
};

const StyledBox = styled(Box)({
  [`& .${classes.ul}`]: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    border: '1px solid #d2d6dc',
    borderRadius: '6px',
    marginBottom: '15px'
  },
  [`& .${classes.btn}`]: {
    borderTop: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
    padding: '5px 14px',
    borderRight: '1px solid #d2d6dc',
    textTransform: 'capitalize'
  }
});

const PaginationComponent = ({ tablePerPage, totalCount, paginate, currentPage }) => {
  const pageNumber = [];
  for (let i = 0; i < Math.ceil(totalCount / tablePerPage); i++) {
    pageNumber.push(i);
  }
  const { items } = usePagination({
    count: pageNumber.length,
    selected: currentPage,
    onChange: (e, p) => paginate(e, p)
  });

  return (
    <StyledBox style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
      <nav>
        {items?.length > 2 && (
          <ul className={classes.ul}>
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;

              if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = (
                  <button className={classes.btn} style={{ background: 'white' }}>
                    ...
                  </button>
                );
              } else if (type === 'page') {
                children = (
                  <button
                    type="button"
                    style={{
                      fontWeight: selected ? 'bold' : undefined,
                      background: selected ? '#f7f7f7' : 'white'
                    }}
                    className={classes.btn}
                    {...item}
                  >
                    {page}
                  </button>
                );
              } else {
                children = (
                  <button type="button" className={classes.btn} {...item}>
                    {type}
                  </button>
                );
              }

              return <li key={index}>{children}</li>;
            })}
          </ul>
        )}
      </nav>
    </StyledBox>
  );
};

export default PaginationComponent;
