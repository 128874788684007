import type { ProductAttributeRange } from '@/api/filteredProducts/filteredProducts.types';
import { range } from '@/utils/array';

import {
  PRODUCT_ATTRIBUTE_RANGE_DEFAULT_MAXIMUM,
  PRODUCT_ATTRIBUTE_RANGE_DEFAULT_MINIMUM
} from './ProductDetailsCard.constants';

/**
 * Creates a product range based on product attribute range.
 * IF minimum or maximum is `null`, it'll attempt to use the `default`, otherwise set to default 0 or default 10
 */
export const productAttributeRangeToNumbersList = (attributeRange?: ProductAttributeRange) => {
  const optionMin = attributeRange?.minimum ?? attributeRange?.default ?? PRODUCT_ATTRIBUTE_RANGE_DEFAULT_MINIMUM;
  const optionMax = attributeRange?.maximum ?? attributeRange?.default ?? PRODUCT_ATTRIBUTE_RANGE_DEFAULT_MAXIMUM;

  return range(Math.min(optionMin, optionMax), optionMax);
};
