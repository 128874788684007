const ProductSideFilter = (props) => {
  const genId = (elementId) => `${props.id}-${elementId}`;

  return (
    <div>
      <label className="filter-heading">{props.title}</label>
      <ul className="filter-menu">
        {props.data.map((ele) => (
          <li key={ele.id} className="mt-1 mx-1  d-flex">
            <input
              onChange={props.handleChange(ele.id)}
              type="checkbox"
              className="checkbox mt-1"
              style={{ width: '14px', height: '14px' }}
              id={genId(ele.id)}
              checked={props.checked(ele)}
            />

            <label className="font-primary" htmlFor={genId(ele.id)}>
              {ele.supplier_name ? ele.supplier_name : ele.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductSideFilter;
