import { Alert } from '@mui/material';

export type CancelAlertProps = {
  medicationName: string;
};

export const CancelAlert = ({ medicationName }: CancelAlertProps) => {
  if (!medicationName) {
    return null;
  }

  return (
    <Alert aria-label={`${medicationName} will be cancelled`} severity="warning">
      <>
        <strong>{medicationName} will be cancelled</strong>{' '}
        <span>and updated to another medication based on the previously selected prescriptions</span>
      </>
    </Alert>
  );
};
