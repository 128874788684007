import type {
  MedicationPrescriptionStatus,
  PrescribedMedicationDossierItemSchema,
  PrescribedProductMetadataSchema,
  ReissueLinkAttributesSchema
} from '@montugroup/prescription-contracts';
import { Alert, CircularProgress, Link, Popover, Stack } from '@mui/material';
import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import { useFeatureFlags, useGetPrescribedProducts } from '@/hooks';
import { PrescribedMedicationsTable } from '@/shared-ui';
import PrescribedMedicationDossierTable from '@/shared-ui/PrescribedMedicationDossierTable/PrescribedMedicationDossierTable';
import { useAppStore } from '@/state-management';

import useAdditionalMedication from '@/hooks/useAdditionalMedication/useAdditionalMedication';
import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '../../../../constants/featureFlags';
import { sortPrescriptionItems } from '../../../../hooks/sortedPrescribedProducts/useSortedPrescribedProducts';
import PrescribedMedicationsTabsTable from '../PrescribedMedicationsTabsTable/PrescribedMedicationsTabsTable';
import type { CancelMedicationItemType } from '../PrescriptionPad/PrescriptionPad.types';

export interface PrescribedProductsWrapperProps {
  patientId: number;
  onClickReissue: (
    attributes: ReissueLinkAttributesSchema,
    medication: PrescribedProductMetadataSchema,
    prescriptionStatus: MedicationPrescriptionStatus
  ) => void;
  onClickCancel: (cancelItem: CancelMedicationItemType) => void;
}

export const PrescribedMedicationHistory = ({
  patientId,
  onClickReissue,
  onClickCancel
}: PrescribedProductsWrapperProps) => {
  const {
    prescriptionPad: { medicationPadItems },
    prescriptionHistory: { itemsToAction }
  } = useAppStore.use.prescriptionAssistant();
  const { flags } = useFeatureFlags();
  const { data: prescribedProductsData, isLoading: isPrescribedProductsLoading } = useGetPrescribedProducts({
    patientId: `${patientId}`
  });
  const existingProductIds = medicationPadItems.map((item) => Number(item.medicationPadItem?.productId));
  const [dossierAnchorEl, setDossierAnchorEl] = useState<null | HTMLElement>(null);
  const [dossierData, setDossierData] = useState<null | PrescribedMedicationDossierItemSchema[]>(null);
  const isDossierOpen = Boolean(dossierAnchorEl);

  const renderLoading = () => (
    <Stack direction="row" alignItems="center" justifyContent="center" height={300}>
      <CircularProgress color="inherit" />
    </Stack>
  );

  const handleViewScriptClick = (dossier: PrescribedMedicationDossierItemSchema[], event: MouseEvent<HTMLElement>) => {
    const sortedDossier = sortPrescriptionItems(dossier);
    setDossierData(sortedDossier);
    setDossierAnchorEl(dossierAnchorEl ? null : event.currentTarget);
  };

  const handleCloseDossier = () => {
    setDossierData(null);
    setDossierAnchorEl(null);
  };

  const handleAdditionalMedicationClick = useAdditionalMedication();

  const shouldDisableAddMedicationButton = useMemo(() => {
    const hasIncompleteMedicationItems = medicationPadItems.some((item) => !item.medicationPadItem);
    const hasItemsToAction = Boolean(itemsToAction?.length);
    return hasIncompleteMedicationItems || hasItemsToAction;
  }, [medicationPadItems, itemsToAction]);

  const renderPrescriptionsTabsTable = useMemo(() => {
    return (
      <PrescribedMedicationsTabsTable
        products={prescribedProductsData?.body?.prescribedMedications || []}
        headings={prescribedProductsData?.body?.headings || []}
        onReissueClick={onClickReissue}
        onClickCancel={onClickCancel}
        onViewDossierClick={handleViewScriptClick}
        prescribedProductIds={existingProductIds || []}
        onAdditionalMedicationClick={handleAdditionalMedicationClick}
        isAddAdditionalMedicationDisabled={shouldDisableAddMedicationButton}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescribedProductsData, existingProductIds]);

  const renderPrescriptionsTable = useMemo(() => {
    return (
      <PrescribedMedicationsTable
        products={prescribedProductsData?.body?.prescribedMedications || []}
        headings={prescribedProductsData?.body?.headings || []}
        onReissueClick={onClickReissue}
        onClickCancel={onClickCancel}
        onViewDossierClick={handleViewScriptClick}
        prescribedProductIds={existingProductIds || []}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescribedProductsData, existingProductIds]);

  const renderTable = () => {
    if (!prescribedProductsData) {
      return null;
    }

    return flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION]
      ? renderPrescriptionsTabsTable
      : renderPrescriptionsTable;
  };

  return isPrescribedProductsLoading ? (
    renderLoading()
  ) : (
    <>
      {renderTable()}
      {dossierData && (
        <Popover
          sx={{ width: '40rem' }}
          open={isDossierOpen}
          anchorEl={dossierAnchorEl}
          onClose={handleCloseDossier}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <PrescribedMedicationDossierTable
            headings={prescribedProductsData?.body?.dossierHeadings || []}
            products={dossierData}
          />
          <Alert severity="info" sx={{ borderRadius: 0 }}>
            <b>To understand </b>how this table was calculated click on the{' '}
            <Link
              href="https://app.getguru.com/card/iByRzd4T/Prescription-assistant-guide"
              target="_blank"
              rel="noopener"
              sx={{ textDecoration: 'underline !important' }}
            >
              link
            </Link>
          </Alert>
        </Popover>
      )}
    </>
  );
};
