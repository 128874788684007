import { Alert, Box } from '@mui/material';
import { IhiStatus } from '../IhiVerifyAndUpdateModals/IhiVerifyAndUpdateModals.utils';

type IhiNumberStatusBannerProps = {
  ihiStatus: IhiStatus;
};

export const IhiNumberStatusBanner = ({ ihiStatus }: IhiNumberStatusBannerProps) => {
  const getBannerMessage = (ihiStatus: IhiStatus) => {
    if (ihiStatus === IhiStatus.NEW_PATIENT) {
      return (
        <Alert aria-label="IHI number status banner" severity="warning">
          <strong>New Patient: </strong>
          <span>IHI number will be verified when you save the profile.</span>
        </Alert>
      );
    }

    if (ihiStatus === IhiStatus.VERIFIED) {
      return (
        <Alert aria-label="IHI number status banner" severity="success">
          <strong>IHI Verified: </strong>
          <span>{`This patient’s IHI Number has been successfully verified and it's active.`}</span>
        </Alert>
      );
    }

    return (
      <Alert aria-label="IHI number status banner" severity="error">
        <strong>IHI Warning: </strong>
        <span>This patient’s IHI Number is either unverified or inactive and requires verification.</span>
      </Alert>
    );
  };

  return <Box my={5}>{getBannerMessage(ihiStatus)}</Box>;
};
