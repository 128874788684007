import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const PREFIX = 'DateRangeComp';

const classes = {
  button: `${PREFIX}-button`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.button}`]: {
    '&.MuiButton-contained.MuiButton-containedSecondary': {
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black'
      }
    }
  }
}));

const DateRangeComp = (props) => {
  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [open, setOpen] = useState(true);
  // get the target element to toggle
  const refOne = useRef(null);
  const refTwo = useRef(null);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
      props.setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = async (e) => {
    // if clicked on exportAll will close calendar and downloads file
    if (refTwo.current && refTwo.current.contains(e.target)) {
      setOpen(false);
      props.setOpen(false);
      props.DownloadFile(
        moment(range[0].startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        moment(range[0].endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      );

      // if clicked outside other than exportAll and Calendar button will close the dropdown
    } else if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
      props.setOpen(false);
    }
  };

  useEffect(() => {
    // event listeners
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
    return () => {
      document.removeEventListener('keydown', hideOnEscape, true);
      document.removeEventListener('click', hideOnClickOutside, true);
    };
  }, [range]);

  return (
    <Root>
      {open && (
        <div className="calendarWrap">
          <div ref={refOne}>
            <>
              <DateRange
                className="myDatePicker"
                onChange={(item) => setRange([item.selection])}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={range}
                months={1}
                maxDate={
                  moment(range[0].startDate).add(6, 'month').toDate() > new Date()
                    ? new Date()
                    : moment(range[0].startDate).add(6, 'month').toDate()
                }
                direction="horizontal"
              />
            </>
          </div>
          <div ref={refTwo}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              fullWidth
              onClick={() => setOpen(true)}
            >
              Export
            </Button>
          </div>
        </div>
      )}
    </Root>
  );
};

export default DateRangeComp;
