import type { LDClient } from 'launchdarkly-js-client-sdk';
import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';

import type { User } from '@/types';
import { getParamDataFromLocalStorage } from '@/utils/calendar';
import { Logger } from '@/utils/logger';

const logger = new Logger('identifyUserForLaunchDarkly');

export const makeUserIdentityContext = (user: User | undefined): LDSingleKindContext => {
  // Use pre-screening email to identify users for testing
  const data = getParamDataFromLocalStorage();

  // We lose the ability to do percentage rollouts and experiments on un-authenticated pages (login,
  // forgot password etc) with this approach, however this saves us from automated tests increasing our MAU.
  // https://docs.launchdarkly.com/home/contexts/anonymous-contexts#using-a-shared-key-between-anonymous-contexts
  if (!user) {
    return {
      kind: 'user',
      anonymous: true,
      key: 'shared-anonymous',
      email: data?.email
    };
  }

  return {
    kind: 'user',
    key: `${user.id}`,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    role_id: user.role_id
  };
};

const identifyUserForLaunchDarkly = (ldClient: LDClient, user: User | undefined) => {
  const userContext = makeUserIdentityContext(user);

  ldClient.waitUntilReady().then(() => {
    ldClient.identify(userContext).then(() => {
      logger.info('LD client identify success', { ...userContext });
    });
  });
};

export default identifyUserForLaunchDarkly;
