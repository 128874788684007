import type { ConcessionCardType } from '../../components/concession';
import settings from '../../data/constants';

import type { ConcessionCardReponse } from './types';
import useRequest from './useRequest';

type UsePostPatientConcessionCardPayload = {
  cardType: ConcessionCardType;
  // Required if not ATSI type...
  cardNumber?: string;
  expiryYear?: number;
  expiryMonth?: number;
  expiryDay?: number;
  documentIdentifier?: string;
};

export type UsePostPatientConcessionCardResponse = ConcessionCardReponse;

type UsePostPatientConcessionCardProps = {
  patientId: number | string;
};

export function usePostPatientConcessionCard({ patientId }: UsePostPatientConcessionCardProps) {
  const { loading, data, error, execute } = useRequest<UsePostPatientConcessionCardResponse>({
    config: {
      url: `${settings.url}/patient/${patientId}/concession-card`,
      method: 'POST'
    }
  });

  const doPost = async (body: UsePostPatientConcessionCardPayload) => {
    return execute({ data: body });
  };

  return {
    loading,
    data,
    error,
    doPost
  };
}

export default usePostPatientConcessionCard;
