import { Box, Chip, Stack, styled, Typography } from '@mui/material';
import { useMemo } from 'react';

// import { CSSProperties } from '@mui/styles';
import { isOverrideDecoration, isRankDecoration, isStockDecoration } from '@/api';
import { formatPrice } from '@/utils/formatter';

import Image from '../Image/Image';
import StatusChip from '../StatusChip/StatusChip';
import TierLabel from '../TierLabel/TierLabel';

import type { ProductCardProps, ProductStockStatusProps } from './ProductCard.types';
import { ProductCardVariant } from './ProductCard.types';

interface ProductCardStyleProps {
  cardVariant: ProductCardVariant;
}
interface PriceStyleProps extends ProductCardStyleProps {
  isDiscountVariant?: boolean;
}

const boldedText = {
  fontWeight: 600
};

const productNameFontSize = '1.3rem';
const productDescFontSize = '1.1rem';

const ProductStockStatusContainer = styled(Box)(({ theme }) => ({
  gridArea: 'status',
  padding: theme.spacing(1, 0),
  height: '24px',
  boxSizing: 'content-box'
}));

/**
 * Internal component used to define whether to show `StatusChip` or `TierLabel` based on
 * the type of decoration
 */
const ProductStockStatus = ({ decoration }: ProductStockStatusProps) => {
  if (!decoration) {
    return null;
  }
  if (isStockDecoration(decoration)) {
    return (
      <StatusChip
        role="status"
        label="Out of stock"
        color="error"
        isTransparent={false}
        size="small"
        data-dd-privacy="allow"
      />
    );
  }
  if (isOverrideDecoration(decoration)) {
    return (
      <StatusChip
        role="status"
        label={decoration.overrideLabel}
        isTransparent={false}
        size="small"
        data-dd-privacy="allow"
      />
    );
  }
  // default is a rank decoration
  return <TierLabel role="status" label={decoration.label} tier={decoration.tier} data-dd-privacy="allow" />;
};

const Supplier = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'cardVariant'
})<ProductCardStyleProps>(({ theme, cardVariant }) => ({
  ...boldedText,
  margin: 0,
  fontSize: cardVariant === ProductCardVariant.Large ? productNameFontSize : theme.typography.body1.fontSize,
  gridArea: 'supplier'
}));

const ProductName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'cardVariant'
})<ProductCardStyleProps>(({ theme, cardVariant }) => ({
  fontSize: cardVariant === ProductCardVariant.Large ? productNameFontSize : theme.typography.body1.fontSize,
  margin: 0,
  gridArea: 'name'
}));

const ProductDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'cardVariant'
})<ProductCardStyleProps>(({ cardVariant }) => ({
  gridArea: 'desc',
  ...(cardVariant === ProductCardVariant.Large
    ? {
        fontSize: productDescFontSize
      }
    : {})
}));

const ProductPriceContainer = styled(Box)({
  gridArea: 'price'
});

const ProductTagChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  marginRight: '2rem',
  ...boldedText,
  position: 'absolute',
  top: 0,
  right: 0
}));

// TODO - apply theme and branding colours per design once theming strategy
const Price = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'cardVariant' && prop !== 'isDiscountVariant'
})<PriceStyleProps>(({ cardVariant, isDiscountVariant }) => ({
  display: 'inline-block',
  ...(isDiscountVariant
    ? {
        textDecoration: 'line-through',
        marginRight: '0.8rem'
      }
    : {
        ...boldedText
      }),
  ...(cardVariant === ProductCardVariant.Large
    ? {
        fontSize: '1.75rem'
      }
    : {})
}));

const ProductStockMessage = styled(Typography)({
  gridArea: 'message'
});

/**
 * A card for displaying product information
 *
 * @param decoration for displaying product status
 * @param description detailed description of the product
 * @param price price of the product
 * @param productImage thumbnail image of the product
 * @param productName name of the product
 * @param supplierName name of the supplier
 * @param variant pre-defined layout for the card
 * @param isConcessionProduct if product is a concession product
 *
 * @returns JSX Element
 *
 */
export default function ProductCard({
  decoration,
  description,
  price,
  productImage,
  productName,
  supplierName,
  variant = ProductCardVariant.Default,
  discountPrice,
  isConcessionProduct,
  thcStrengthForDisplay,
  cbdStrengthForDisplay
}: ProductCardProps) {
  const formattedPrice = price && formatPrice(price);
  const formattedDiscount = discountPrice && formatPrice(discountPrice);
  const showDiscountPrice = (price && discountPrice && discountPrice < price) || false;

  const imgSize = useMemo(() => {
    const defaultSize = {
      width: 60,
      height: 60
    };

    if (variant === ProductCardVariant.Large) {
      return {
        width: defaultSize.width * 3,
        height: defaultSize.height * 3
      };
    }

    return defaultSize;
  }, [variant]);

  return (
    <Stack direction="row">
      <Stack>
        {productImage && (
          <Image
            src={productImage}
            altSrc="/images/product_image_unavailable.png"
            width={imgSize.width}
            height={imgSize.height}
            alt={`${supplierName} ${productName}`}
            data-dd-privacy="allow"
          />
        )}
        {isConcessionProduct && variant === ProductCardVariant.Large && (
          <ProductTagChip label="Concession" size="small" />
        )}
      </Stack>
      <Stack flex="1" justifyContent="space-between">
        {decoration && variant === ProductCardVariant.Default && (
          <ProductStockStatusContainer>
            <ProductStockStatus decoration={decoration} />
          </ProductStockStatusContainer>
        )}
        <Stack>
          <Supplier variant="h4" cardVariant={variant} data-dd-privacy="allow">
            {supplierName}
          </Supplier>
          <ProductName variant="h3" cardVariant={variant} data-dd-privacy="allow">
            {productName}
          </ProductName>
        </Stack>
        <ProductDescription cardVariant={variant} data-dd-privacy="allow">
          {description}
        </ProductDescription>
        {cbdStrengthForDisplay && thcStrengthForDisplay && (
          <Typography>
            THC {thcStrengthForDisplay} : CBD {cbdStrengthForDisplay}
          </Typography>
        )}
        <ProductStockMessage variant="body2" color="error">
          {!isRankDecoration(decoration) && decoration?.message}
        </ProductStockMessage>
        {variant === ProductCardVariant.Large && (
          <ProductPriceContainer>
            {price && (
              <Price cardVariant={variant} isDiscountVariant={showDiscountPrice}>
                {formattedPrice}
              </Price>
            )}

            {formattedDiscount && price && discountPrice < price && (
              <Price cardVariant={variant}>{formattedDiscount}</Price>
            )}
          </ProductPriceContainer>
        )}
      </Stack>
      {variant !== ProductCardVariant.Large && (
        <Stack>
          <ProductPriceContainer>
            {price && (
              <Price cardVariant={variant} isDiscountVariant={showDiscountPrice}>
                {formattedPrice}
              </Price>
            )}

            {formattedDiscount && price && discountPrice < price && (
              <Price cardVariant={variant}>{formattedDiscount}</Price>
            )}
          </ProductPriceContainer>
        </Stack>
      )}
    </Stack>
  );
}
