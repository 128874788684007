import { datadogRum } from '@datadog/browser-rum';
import type { AsyncProviderConfig, LDEvaluationDetail } from 'launchdarkly-react-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import type { PropsWithChildren } from 'react';

import settings from '@/data/constants';
import type { User } from '@/types';
import { Logger } from '@/utils/logger';

import { makeUserIdentityContext } from './identifyUserForLaunchDarkly';

const logger = new Logger('LaunchDarkly');

interface ILaunchDarkly {
  init: (user: User) => Promise<void>;
  Provider: ({ children }: PropsWithChildren<unknown>) => JSX.Element | null;
}

export const LaunchDarkly: ILaunchDarkly = {
  init: async (user: User | undefined) => {
    const LDConfig: AsyncProviderConfig = {
      clientSideID: settings?.launchDarkly?.clientSideID ?? '',
      options: {
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, detail: LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value);
            }
          }
        ]
      },
      reactOptions: {
        useCamelCaseFlagKeys: false
      },
      context: makeUserIdentityContext(user)
    };

    try {
      const LDProvider = await asyncWithLDProvider(LDConfig);
      LaunchDarkly.Provider = function Provider({ children }) {
        return <LDProvider>{children}</LDProvider>;
      };
    } catch (e) {
      logger.error(e);
    }
  },
  // eslint-disable-next-line react/jsx-no-useless-fragment
  Provider: ({ children }) => <>{children}</>
};
