import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import PortraitIcon from '@mui/icons-material/Portrait';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { darken, Drawer, lighten } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import CalendarPopupButton from '@/components/calcom/CalendarPopupButton';
import BasicModal from '@/components/common/BasicModal';
import type { SelectedEvent } from '@/components/NurseFlexCalendar/NurseFlexCalendar.types';
import settings from '@/data/constants';
import theme from '@/theme';
import type { Nurse } from '@/types';
import { buildCancellationLink, buildRescheduleLink } from '@/utils/calendar';
import dayjs from '@/utils/dayjs';

export type NurseFlexCalendarDrawerProps = {
  selectedEvent: SelectedEvent | undefined;
  nurses: Nurse[];
  open: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  refetchAllNurseConsults: () => void;
};

const StyledDrawerContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  padding: 20
});

const StyledExistingCard = styled(Box)(({ theme: muiTheme }) => ({
  backgroundColor: lighten(muiTheme.palette.info.light, 0.9),
  color: darken(muiTheme.palette.info.main, 0.5),
  marginBottom: 30,
  padding: 15
}));

const StyledReassignedCard = styled(Box)(({ theme: muiTheme }) => ({
  backgroundColor: lighten(muiTheme.palette.success.light, 0.9),
  color: darken(muiTheme.palette.success.main, 0.5),
  marginBottom: 30,
  padding: 15
}));

const StyledUnassignedCard = styled(Box)(({ theme: muiTheme }) => ({
  backgroundColor: lighten(muiTheme.palette.error.light, 0.9),
  color: darken(muiTheme.palette.error.main, 0.5),
  marginBottom: 30,
  padding: 15
}));

const StyledCalendarPopupButton = styled(CalendarPopupButton)({
  marginBottom: 10,
  textTransform: 'capitalize',
  fontSize: '1rem'
});

const StyledBasicModal = styled(BasicModal)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '90%',
  '& iframe': {
    border: 0,
    width: '100%',
    height: '100%'
  }
});

const StyledCancelButton = styled(Button)({
  textTransform: 'capitalize',
  fontSize: '1rem'
});

const StyledCoviuLink = styled(Typography)({
  textDecoration: 'underline'
});

const StyledConsultType = styled(Typography)(({ theme: muiTheme }) => ({
  display: 'inline-block',
  backgroundColor: muiTheme.palette.grey[300],
  padding: '2px 13px',
  borderRadius: '15px',
  fontWeight: '600',
  marginBottom: 5
}));

export default function NurseFlexCalendarDrawer({
  nurses,
  selectedEvent,
  open,
  toggleDrawer,
  refetchAllNurseConsults
}: NurseFlexCalendarDrawerProps) {
  const [modalOpen, setModalOpen] = useState(false);

  if (!selectedEvent) {
    return null;
  }

  const consultationDetails = selectedEvent.extendedProps;
  const nurseCoviuLink = nurses.find((nurse) => consultationDetails.nurse_id === nurse.id)?.coviu_url;
  const eventDate = dayjs.utc(consultationDetails.start_time).local().format('MMMM Do, YYYY - h:mmA');
  const birthdate = dayjs.utc(consultationDetails.patient_dob).local();
  const patientsAge = dayjs().diff(birthdate, 'year');

  const prefillCalCom = {
    name: consultationDetails.patient_name,
    email: consultationDetails.patient_email,
    phoneNumber: `${consultationDetails.patient_phone || settings.localisation.phoneCountryCodes.australia}`
  };

  const allowedStatuses = ['Pending', 'Scheduled'];
  const isOpenStatus = allowedStatuses.includes(consultationDetails.status);
  const rescheduleLink = buildRescheduleLink(consultationDetails.calcom_consultation_uuid);
  const showRescheduleButton = isOpenStatus && rescheduleLink;
  const cancelBookingUrl = buildCancellationLink(consultationDetails?.calcom_consultation_uuid);
  const showCancelButton = isOpenStatus && !!cancelBookingUrl;

  const isReassignedNurseEvent = selectedEvent.isAssignedToFloatingNurse;
  const isExistingNurseEvent = selectedEvent.isExistingFloatingNurseEvent;

  const existingCard = (
    <StyledExistingCard>
      <Box display={'flex'} alignItems={'center'}>
        <Box mr={3}>
          <InfoIcon />
        </Box>
        <Typography fontSize={'1.2rem'} fontWeight={'600'}>
          Existing
        </Typography>
      </Box>
      <Typography>
        This is a pre-existing appointment with this floating nurse. This appointment requires no re-assigning.
      </Typography>
    </StyledExistingCard>
  );

  const resassignedCard = (
    <StyledReassignedCard>
      <Box display={'flex'} alignItems={'center'}>
        <Box mr={3}>
          <CheckCircleOutlineIcon />
        </Box>
        <Typography fontSize={'1.2rem'} fontWeight={'600'}>
          Reassigned
        </Typography>
      </Box>
      <Typography>This appointment was reassigned to a floating nurse</Typography>
    </StyledReassignedCard>
  );

  const unassignedCard = (
    <StyledUnassignedCard>
      <Box display={'flex'} alignItems={'center'}>
        <Box mr={3}>
          <WarningAmberIcon />
        </Box>
        <Typography fontSize={'1.2rem'} fontWeight={'600'}>
          Unassigned
        </Typography>
      </Box>
      <Typography>This appointment requires assignment to a floating nurse</Typography>
    </StyledUnassignedCard>
  );

  const viewLink = (
    <Button
      component={'a'}
      href={`/patients/${consultationDetails.patient_id}?show=1`}
      variant="contained"
      color="secondary"
      sx={{ marginBottom: 3, textTransform: 'capitalize', fontSize: '1rem' }}
      target={'_blank'}
    >
      View
    </Button>
  );

  const getCardType = () => {
    if (isReassignedNurseEvent) {
      if (isExistingNurseEvent) {
        return existingCard;
      }
      return resassignedCard;
    }
    return unassignedCard;
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);

    refetchAllNurseConsults();
  };

  const handleRescheduleSuccessful = () => {
    refetchAllNurseConsults();
  };

  return (
    <Drawer open={open} onClose={toggleDrawer(false)} anchor="right" data-testid="calendar-drawer">
      <StyledDrawerContentContainer>
        {getCardType()}
        {nurseCoviuLink && (
          <Link to={nurseCoviuLink} target={'_blank'}>
            <Box display={'flex'} mb={3}>
              <Box mr={2}>
                <PortraitIcon color="secondary" />
              </Box>
              <StyledCoviuLink color="secondary">Launch Coviu</StyledCoviuLink>
            </Box>
          </Link>
        )}
        <Typography fontSize={'1.2rem'} fontWeight={'bold'} mb={3}>
          {consultationDetails.consultation_code}
        </Typography>
        <Box display={'flex'} mb={10}>
          <CalendarTodayIcon />
          <Typography ml={3} fontSize={'1rem'}>
            {eventDate} ({consultationDetails.timezone})
          </Typography>
        </Box>
        <Box mb={10}>
          <StyledConsultType>{consultationDetails.consultation_type}</StyledConsultType>
          <Typography fontWeight={500} fontSize={'1.6rem'}>
            {consultationDetails.patient_name}
          </Typography>
          <Typography color={theme.palette.grey['600']}>{consultationDetails.patient_code}</Typography>
          <Typography color={theme.palette.grey['600']}>
            DoB: {birthdate.isValid() ? birthdate.format('DD-MM-YYYY') : 'unknown'}
          </Typography>
          <Typography color={theme.palette.grey['600']}>
            Age: {birthdate.isValid() ? patientsAge : 'unknown'}
          </Typography>
        </Box>
        <Box mb={5}>
          <Typography color={theme.palette.grey['600']} mb={2}>
            Phone
          </Typography>
          <Typography fontSize={'1.2rem'} borderBottom={`1px solid ${theme.palette.grey[500]}`} pb={2}>
            {consultationDetails.patient_phone}
          </Typography>
        </Box>
        <Box mb={15}>
          <Typography color={theme.palette.grey['600']} mb={2}>
            Email
          </Typography>
          <Typography fontSize={'1.2rem'} borderBottom={`1px solid ${theme.palette.grey[500]}`} pb={2}>
            {consultationDetails.patient_email}
          </Typography>
        </Box>
        {viewLink}
        {showRescheduleButton && (
          <StyledCalendarPopupButton
            variant="outlined"
            link={rescheduleLink}
            formData={prefillCalCom}
            onRescheduleBookingSuccessful={handleRescheduleSuccessful}
            isButton
          >
            Reschedule
          </StyledCalendarPopupButton>
        )}
        {showCancelButton && (
          <StyledCancelButton onClick={handleOpen} color={'secondary'}>
            Cancel
          </StyledCancelButton>
        )}
        {!isExistingNurseEvent && (
          <Box mt={5}>
            <Alert severity="info">
              <Typography>
                <strong>Before you reschedule or cancel this consultation:</strong> If you have moved any consultations
                or changed nurses, then please ensure you have saved those updates by saving the calendar beforehand.
              </Typography>
            </Alert>
          </Box>
        )}
      </StyledDrawerContentContainer>
      <StyledBasicModal open={modalOpen} handleClose={handleClose}>
        <iframe loading="lazy" src={cancelBookingUrl}></iframe>
      </StyledBasicModal>
    </Drawer>
  );
}
