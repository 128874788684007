import { FeedbackRadioGroup } from '@/shared-ui';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEvent } from 'react';

const StyledFeedbackContainer = styled(FeedbackRadioGroup)({
  '.MuiFormControlLabel-root': {
    padding: '5px'
  }
});

type ModalIhiQuestionsProps = {
  handleFeedbackOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const ModalIhiQuestions = (props: ModalIhiQuestionsProps) => {
  const { handleFeedbackOnChange } = props;

  return (
    <>
      <Box marginBottom={5}>
        <Typography variant={'h6'} fontWeight={600}>
          Why do you want to save the Unverified IHI number?
        </Typography>
        <Typography>We will use this data for future updates</Typography>
      </Box>
      <StyledFeedbackContainer
        onChange={handleFeedbackOnChange}
        options={[
          {
            label:
              "I have confirmed the patient's details through other documentation, and I am confident the IHI number is accurate",
            value: 'I have confirmed'
          },
          {
            label:
              'The patient verbally confirms this is their IHI number, I will request supporting documentation to formally verify it',
            value: 'The patient confirms'
          }
        ]}
      />
    </>
  );
};
