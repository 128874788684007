import type { NurseConsultation } from '@/types';

import settings from '../../data/constants';

import useRequest from './useRequest';

export function useGetNurseConsultations() {
  const { loading, data, error, execute } = useRequest<NurseConsultation[]>({
    config: {
      url: `${settings.url}/nurse/consultations`,
      method: 'GET'
    }
  });

  return {
    loading,
    data,
    error,
    doGet: execute
  };
}

export default useGetNurseConsultations;
