import { addMinutes, endOfDay, format, getUnixTime, isAfter, isBefore, startOfDay, toDate } from 'date-fns';
import { toDate as toUTCDate, zonedTimeToUtc } from 'date-fns-tz';
import { useCallback, useMemo, useState } from 'react';

import type { IOption } from '@/components/calcom/shared/types/calcom-schedule-types';
import dayjs from '@/utils/dayjs';

/**
 * Creates an array of times on a 10 minute interval from
 * 00:00:00 (Start of day) to
 * 23:50:00 (End of day with enough time for 10 min booking)
 */
/** Begin Time Increments For Select */
const INCREMENT = 10;
const useAvailabilitySelectOptions = () => {
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);

  const selectOptions = useMemo(() => {
    const startOfUTCDay = startOfDay(new Date().setUTCHours(0, 0, 0, 0));
    const endOfUTCDay = endOfDay(new Date().setUTCHours(0, 0, 0, 0));
    const options: IOption[] = [];

    for (let time = startOfUTCDay; isBefore(time, endOfUTCDay); time = addMinutes(time, INCREMENT)) {
      options.push({
        value: getUnixTime(zonedTimeToUtc(toDate(time), 'UTC')) * 1000,
        label: format(time, 'h:mmaaa')
      });
    }

    // allow 23:59
    options.push({
      value: getUnixTime(zonedTimeToUtc(toDate(endOfUTCDay), 'UTC')) * 1000,
      label: format(endOfUTCDay, 'h:mmaaa')
    });

    return options;
  }, []);

  const filter = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ offset, limit, current }: any) => {
      if (current) {
        const currentOption = selectOptions.find((option) => option.value === dayjs(current).toDate().valueOf());
        if (currentOption) {
          setFilteredOptions([currentOption]);
        }
      } else {
        setFilteredOptions(
          selectOptions.filter((option) => {
            const time = toUTCDate(option.value);
            return (!limit || isBefore(time, toUTCDate(limit))) && (!offset || isAfter(time, toUTCDate(offset)));
          })
        );
      }
    },
    [selectOptions]
  );

  return { options: filteredOptions, filter };
};

export default useAvailabilitySelectOptions;
