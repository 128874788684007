import { requestHeaders } from '@/api';
import settings from '@/data/constants';

export const getNurses = async () => {
  const getNursesUrl = `${settings.url}/nurse`;

  const nursesResponse = await fetch(getNursesUrl, {
    method: 'GET',
    headers: requestHeaders()
  });

  return nursesResponse.json();
};
