import ScheduleIcon from '@mui/icons-material/Schedule';
import { Chip, Tooltip, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface TimerProps {
  onTimeout: () => void;
  durationInSeconds: number; // Duration in seconds
  refreshTimer: boolean;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
  padding: '17px 5px'
}));

export function Timer({ onTimeout, durationInSeconds, refreshTimer }: TimerProps) {
  // Function to get the start time from local storage or set it if not present
  const getStartTime = () => {
    const storedStartTime = localStorage.getItem('timerStartTime');
    if (storedStartTime && !refreshTimer) {
      return parseInt(storedStartTime, 10);
    }
    const newStartTime = Date.now();
    localStorage.setItem('timerStartTime', newStartTime.toString());
    return newStartTime;
  };

  // Initialize startTime using local storage
  const [startTime] = useState(getStartTime());

  // Calculate the remaining time in seconds
  const calculateTimeRemaining = useCallback(() => {
    const now = Date.now();
    const endTime = startTime + durationInSeconds * 1000;
    return Math.max(0, endTime - now) / 1000;
  }, [durationInSeconds, startTime]);

  const [time, setTime] = useState(calculateTimeRemaining());

  useEffect(() => {
    // Calculate the delay until the next second
    const now = Date.now();
    const delayUntilNextSecond = 1000 - (now % 1000);

    // Set a timeout for the delay until the next second
    const timeoutId = setTimeout(() => {
      setTime(calculateTimeRemaining());

      // Set an interval for every subsequent second
      const intervalId = setInterval(() => {
        const newTime = calculateTimeRemaining();
        setTime(newTime);

        if (newTime <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem('timerStartTime');
          if (onTimeout) {
            onTimeout();
          }
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }, delayUntilNextSecond);

    return () => clearTimeout(timeoutId);
  }, [calculateTimeRemaining, onTimeout, startTime]);

  // Format time for display
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  return (
    <Tooltip title="Time remaining to confirm booking">
      <StyledChip
        sx={{ mb: 2, cursor: 'pointer' }}
        label={`${minutes}:${seconds.toString().padStart(2, '0')}`}
        variant="outlined"
        icon={<ScheduleIcon color="inherit" />}
      />
    </Tooltip>
  );
}
