import isValidPhoneNumber from '@/utils/isValidPhoneNumber';
import { z } from 'zod';

export const schema = z.object({
  phone: z
    .string()
    .trim()
    .refine((val) => {
      return isValidPhoneNumber(val);
    }, 'Invalid phone number')
    .optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  relationship: z.string().optional()
});
