import type { OverrideDecoration, ProductCardDecoration, RankDecoration, StockDecoration } from '@/api';

export enum ProductCardVariant {
  Default = 'default',
  Large = 'large'
}

/**
 * Type guard to determine whether this is a `RankDecoration` with tier and label
 */
export function isRankDecoration(decoration: ProductCardDecoration): decoration is RankDecoration {
  return (decoration as RankDecoration)?.tier !== undefined && typeof (decoration as RankDecoration).label === 'string';
}

/**
 * Type guard to determine whether this is a `StockDecoration` with the `isOutOfStock` prop.
 * Note that this specifically checks for `undefined` not falsy values
 */
export function isStockDecoration(decoration: ProductCardDecoration): decoration is StockDecoration {
  return (
    (decoration as StockDecoration)?.isOutOfStock !== undefined &&
    typeof (decoration as StockDecoration)?.message === 'string'
  );
}

/**
 * Type guard to determine whether this is a `OverrideDecoration`.
 */
export function isOverrideDecoration(decoration: ProductCardDecoration): decoration is OverrideDecoration {
  return (
    (decoration as OverrideDecoration)?.overrideLabel !== undefined &&
    typeof (decoration as OverrideDecoration)?.message === 'string'
  );
}

export type ProductCardProps = {
  variant?: ProductCardVariant;
  id: number;
  supplierName: string;
  productName: string;
  productImage?: string;
  description: string;
  decoration: ProductCardDecoration;
  price?: number;
  discountPrice?: number;
  isConcessionProduct?: boolean;
  thcStrengthForDisplay?: string;
  cbdStrengthForDisplay?: string;
};

export interface ProductStockStatusProps {
  decoration: ProductCardDecoration;
}
