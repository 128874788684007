import moment from 'moment/moment';

import type { AlternativeProduct, Prescription } from '@/hooks/rest/useGetPrescriptionsWithOOSProducts';
import type { CatalogProduct, UseGetProductListResponse } from '@/hooks/rest/useGetProductList';
import type { RescriptProduct } from '@/hooks/rest/usePutPrescriptBatchReplace';

import type { HeadCell, SortOrder, TableRowData, TableRowPrescription } from './RescriptingPage.types';

type ScriptProduct = {
  id: number;
  active: boolean;
  name: string;
  prescription_product_code: string | null;
  quantity: number;
  repeats: number;
  interval: number;
  dosage: string;
  is_concession: boolean;
  other_product: string;
  is_generative_scripting: boolean;
  alternativeProducts: { id: number; name: string }[];
  patient_rescript_request_date?: string | null;
};

type ApprovedProductMap = Map<number, CatalogProduct | undefined>;
type ApprovedScriptPerApprovedProductMap = Map<number, ApprovedProductMap>;
export type ModifiedPrescriptionMap = Map<string, CatalogProduct>;

export function makeUniqueRowKey(scriptId: number, productId: number): string {
  return `${scriptId}_${productId}`;
}

export function getPartsFromUniqueRowKey(uniqueKey: string): { scriptId: number; productId: number } {
  const parts = uniqueKey.split('_');
  return {
    scriptId: parseInt(parts[0]),
    productId: parseInt(parts[1])
  };
}

export function getDefaultAlternativeProduct(product?: ScriptProduct) {
  return product?.alternativeProducts[0];
}

// Create a new Map that contains all the approved scripts as well as information of swapped out products
export function makeApprovedPrescriptionChangeset(
  approvedPrescriptionKeys: string[],
  modifiedPrescriptions: ModifiedPrescriptionMap
): ApprovedScriptPerApprovedProductMap {
  const result = new Map();
  approvedPrescriptionKeys.forEach((prescriptionKey: string) => {
    const { scriptId, productId } = getPartsFromUniqueRowKey(prescriptionKey);
    if (!result.has(scriptId)) {
      result.set(scriptId, new Map());
    }
    const productMap = result.get(scriptId) as ApprovedProductMap;
    const modifiedProduct = modifiedPrescriptions.get(prescriptionKey);
    productMap.set(productId, modifiedProduct);
  });
  return result;
}

/**
 * This function ensures that the medication list has no OOS,
 * generative, or other excluded products.
 * However, it does not account for the concession status of the product.
 * This will need to be changed to ensure concession medications
 * can not be added to a non-concession patient.
 */
export function formatProductList({ products }: UseGetProductListResponse): CatalogProduct[] {
  const result: CatalogProduct[] = products
    // Remove product that fit criteria
    .filter(
      (product: CatalogProduct) =>
        product.active &&
        !product.is_out_of_stock &&
        !product.reasoning_toggle &&
        !product.is_supply_chain_risk &&
        !product.is_generative_scripting &&
        !product.is_excluded_from_new_prescriptions
    )
    .sort((a: CatalogProduct, b: CatalogProduct) => a.name.localeCompare(b.name));
  return result;
}

// Transform a ScriptProduct object into a RescriptProduct
export function scriptProductToRescriptProduct(product: ScriptProduct): RescriptProduct {
  return {
    productId: product.id,
    repeats: product.repeats,
    quantity: product.quantity,
    interval: product.interval,
    dosage: product.dosage,
    name: product.name,
    isConcession: product.is_concession,
    otherProduct: product.other_product,
    isPatientRescriptReq: !!product.patient_rescript_request_date
  };
}

export function createHeaderCells(cells: { id: keyof TableRowData; tableHeading: string }[]): readonly HeadCell[] {
  return cells.map((cell) => ({
    id: cell.id,
    label: cell.tableHeading
  }));
}

function getMomentGapString(date: string): string {
  const todayMoment = moment();
  const createdMoment = moment(date);

  const daysGap = todayMoment.diff(createdMoment, 'days');

  if (daysGap === 0) {
    return 'Today';
  }

  if (daysGap > 6) {
    const weeksGap = Math.floor(daysGap / 7);
    return `${weeksGap} ${weeksGap === 1 ? 'week' : 'weeks'} old`;
  }
  return `${daysGap} ${daysGap === 1 ? 'day' : 'days'} old`;
}

export function createTableRow(
  prescription: Prescription | TableRowPrescription,
  product: ScriptProduct,
  modifiedScripts: ModifiedPrescriptionMap,
  includeGenerativeCountLogic = false
): TableRowData | undefined {
  const { Patient, id, order_code: orderCode, patient_id: patientId, order_date: orderDate, Products } = prescription;
  const productsNames = Products.map((item) => item.name);

  if (includeGenerativeCountLogic) {
    const generativeProductsCount = Products.reduce((n, item) => (item.is_generative_scripting ? n + 1 : n), 0);
    if (generativeProductsCount > 1) {
      return;
    }
  }

  const uniqueRowKey = makeUniqueRowKey(id, product.id);
  const modifiedAlternative = modifiedScripts.get(uniqueRowKey);
  const defaultAlternative = getDefaultAlternativeProduct(product);
  const selectedAlternativeId =
    (modifiedAlternative && modifiedAlternative.id) || (defaultAlternative && defaultAlternative.id) || 0;

  const patientRescriptRequestDate = product.patient_rescript_request_date ?? null;
  const daysGapReq = getMomentGapString(product.patient_rescript_request_date ?? '');

  return {
    uniqueKey: uniqueRowKey,
    scriptId: id,
    orderCode: orderCode,
    patientId: patientId,
    patientName: `${Patient?.PatientUser?.first_name} ${Patient?.PatientUser?.last_name}`,
    patientDob: moment(Patient?.dob ?? '').format('DD/MM/YYYY') ?? '---',
    patientCode: Patient?.patient_code,
    ossProductName: product.name,
    scriptCreationDate: moment(orderDate ?? '').format('DD/MM/YYYY'),
    allProductsOnScript: productsNames,
    totalCount: prescription.Products.length,
    selectedAlternativeProductId: selectedAlternativeId,
    patientRescriptReqDate: patientRescriptRequestDate,
    daysGapReq,
    doctorName: `${prescription.Doctor?.DoctorUser?.first_name || ''} ${prescription.Doctor?.DoctorUser?.last_name || ''}`
  };
}

export function formatDataForTable(
  prescriptions: Prescription[] | TableRowPrescription[],
  modifiedScripts: ModifiedPrescriptionMap
): TableRowData[] {
  const tableArray: TableRowData[] = [];

  prescriptions.forEach((prescription: Prescription | TableRowPrescription) => {
    const tableRows = prescription.Products.map((product) => createTableRow(prescription, product, modifiedScripts));
    const validRows = tableRows.filter((row): row is TableRowData => row !== undefined);
    tableArray.push(...validRows);
  });

  return tableArray;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(
  order: SortOrder,
  orderBy: keyof TableRowData | string
): (a: TableRowData, b: TableRowData) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy as keyof TableRowData)
    : (a, b) => -descendingComparator(a, b, orderBy as keyof TableRowData);
}

export function getRowCount(data: TableRowData[], propertyName: keyof TableRowData) {
  return data.reduce((n, item) => (item[propertyName] ? n + 1 : n), 0);
}

// Determine and create the approved product for a new g-script
export function makeNewPrescriptionProduct(
  originalRescriptProduct: RescriptProduct,
  modifiedScriptProduct: CatalogProduct | undefined,
  defaultAlternative: AlternativeProduct | undefined,
  isOutOfStockScript = false
): RescriptProduct {
  if (modifiedScriptProduct) {
    // Doctor manually selected different alternate
    return {
      productId: modifiedScriptProduct.id,
      // Having hardcoded options are required for OOS as these are temp scripts
      repeats: isOutOfStockScript ? 0 : originalRescriptProduct?.repeats,
      quantity: isOutOfStockScript ? 2 : originalRescriptProduct?.quantity,
      interval: modifiedScriptProduct.interval,
      dosage: modifiedScriptProduct.dosage || '',
      name: modifiedScriptProduct.name,
      isConcession: modifiedScriptProduct.is_concession,
      otherProduct: '',
      originalProductId: originalRescriptProduct.productId,
      ...(originalRescriptProduct.isPatientRescriptReq && {
        isPatientRescriptReq: originalRescriptProduct.isPatientRescriptReq
      })
    };
  }
  // Doctor approved default alternative suggestion
  if (!defaultAlternative) {
    throw new Error('Approved script has no default alternative');
  }

  return {
    productId: defaultAlternative.id,
    name: defaultAlternative.name,
    // Having hardcoded options are required for OOS as these are temp scripts
    repeats: isOutOfStockScript ? 0 : originalRescriptProduct?.repeats,
    quantity: isOutOfStockScript ? 2 : originalRescriptProduct?.quantity,
    interval: originalRescriptProduct?.interval,
    dosage: originalRescriptProduct?.dosage,
    isConcession: false,
    otherProduct: '',
    originalProductId: originalRescriptProduct.productId,
    ...(originalRescriptProduct.isPatientRescriptReq && {
      isPatientRescriptReq: originalRescriptProduct.isPatientRescriptReq
    })
  };
}

export function sortTableData(tableData: TableRowData[]) {
  return tableData.sort((itemA, itemB) => {
    const isPatientRescriptReqA = !!itemA.patientRescriptReqDate;
    const isPatientRescriptReqB = !!itemB.patientRescriptReqDate;

    if (isPatientRescriptReqA && !isPatientRescriptReqB) {
      return -1;
    }
    if (!isPatientRescriptReqA && isPatientRescriptReqB) {
      return 1;
    }

    if (isPatientRescriptReqA && isPatientRescriptReqB) {
      const dateA = moment(itemA.patientRescriptReqDate);
      const dateB = moment(itemB.patientRescriptReqDate);

      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
    }

    const patientNameA = itemA.patientName || '';
    const patientNameB = itemB.patientName || '';
    return patientNameA.localeCompare(patientNameB);
  });
}
