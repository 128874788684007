export type PaymentError = boolean | string | undefined;
export type ErrorReason = {
  [key: string]: string;
};

// This enum is very similar to the ErrorCause enum (which comes from '@montugroup/payments-contracts')
// But, Eway sends back response error strings WITHOUT the dash, whilst our Enrol contract uses
// strings WITH a dash.
// TODO - Look at updating the Enrol contract in MGS so that the same enums can be used. Also make them uppercase since that is common convention for enums
export enum EwayChargeErrorCause {
  CREDIT_CARD = 'CREDITCARD',
  PAYMENT_GATEWAY = 'PAYMENTGATEWAY',
  INVALID_DISCOUNT = 'INVALIDDISCOUNT'
}

export enum GenericErrorCause {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INVALID_DETAILS = 'INVALID_DETAILS'
}
