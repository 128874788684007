import type { ConsultationFields } from '@/components/PatientPageV2/ConsultationTab.types';
import { LUXON_FORMAT_DAY_MONTH_YEAR, ZONE_UTC } from '@/utils/luxon';
import { DateTime } from 'luxon';
import type { PatientVerifyIhiResponsePatientData } from './hooks/usePatientIhi';

export const getIHINumberFromUrl = (ihiNumberUrl?: string | null) => {
  return ihiNumberUrl ? ihiNumberUrl.split('/').pop() : null;
};

export const formatPatientDobIhiModals = (patientDobISO?: string) => {
  return patientDobISO ? DateTime.fromISO(patientDobISO, { zone: ZONE_UTC }).toFormat(LUXON_FORMAT_DAY_MONTH_YEAR) : '';
};

const compareValuePMSVersusIHI = (
  patientsCurrentValue: string | number | null,
  patientsIHIValue?: string | number | null
) => {
  if (!patientsIHIValue) {
    return false;
  }
  return patientsCurrentValue !== patientsIHIValue;
};

export type PmsIhiComparisonResult = {
  firstName: boolean;
  lastName: boolean;
  medicareNumber: boolean;
  medicareRefNumber: boolean;
  ihiNumber: boolean;
  dob: boolean;
  gender: boolean;
};

export const compareValuesPMSVersusIHI = ({
  patientInPMS,
  patientInIHI
}: {
  patientInPMS: ConsultationFields;
  patientInIHI: PatientVerifyIhiResponsePatientData;
}): PmsIhiComparisonResult => ({
  firstName: compareValuePMSVersusIHI(patientInPMS?.FirstName?.toLowerCase(), patientInIHI?.firstName?.toLowerCase()),
  lastName: compareValuePMSVersusIHI(patientInPMS?.LastName?.toLowerCase(), patientInIHI?.lastName?.toLowerCase()),
  medicareNumber: compareValuePMSVersusIHI(patientInPMS?.medicareNumber, patientInIHI?.medicareNumber),
  medicareRefNumber: compareValuePMSVersusIHI(
    patientInPMS?.medicare_ref_number,
    patientInIHI?.medicareRefNumber ? parseInt(patientInIHI?.medicareRefNumber) : null
  ),
  ihiNumber: compareValuePMSVersusIHI(patientInPMS?.ihi_number, getIHINumberFromUrl(patientInIHI?.ihiNumber) as string),
  dob: compareValuePMSVersusIHI(
    formatPatientDobIhiModals(patientInPMS?.DoB),
    formatPatientDobIhiModals(patientInIHI?.doB)
  ),
  gender: compareValuePMSVersusIHI(patientInPMS.gender?.toLowerCase() ?? null, patientInIHI?.sex?.toLowerCase() ?? null)
});

export enum IhiStatus {
  VERIFIED,
  UNVERIFIED,
  NEW_PATIENT
}

export const getIhiStatus = (ihiNumber: string | null, numberStatus: string | null, recordStatus: string | null) => {
  if (!ihiNumber) {
    return IhiStatus.NEW_PATIENT;
  }

  if (numberStatus?.toLowerCase() !== 'active' || recordStatus?.toLowerCase() !== 'verified') {
    return IhiStatus.UNVERIFIED;
  }

  return IhiStatus.VERIFIED;
};
