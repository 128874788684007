import { Chip, Stack, styled } from '@mui/material';

import type { TagProps } from './Tag.types';

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.grey[900]
}));

const StyledImage = styled('img')({
  height: '1.2rem',
  marginLeft: '0.5rem'
});

/**
 * Typically used for displaying tags
 *
 * @param label - text to display on the tag
 * @param imagePath - image path for the icon
 * @param color - the color of the component
 *
 * @returns JSXElement
 *
 */
export default function Tag({ label, imagePath, color }: TagProps) {
  return (
    <StyledChip
      color={color}
      label={
        <Stack direction="row">
          {label}
          {imagePath && <StyledImage src={imagePath} />}
        </Stack>
      }
      variant="outlined"
    />
  );
}
