import CloseIcon from '@mui/icons-material/Close';
import type { DialogProps } from '@mui/material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { ConcessionCardFormProps } from '..';
import { ConcessionCardForm } from '..';
import type useConcessionCardForm from '../../../hooks/concession/useConcessionCardForm';

const ContentText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium as number,
  marginBottom: theme.spacing(2.5)
}));

const Content = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(5)
}));

const FormContainer = styled(Box)({
  maxWidth: '552px',
  width: '100%'
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

const ActionableDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

type ConcessionSubmit = ConcessionCardFormProps['onSubmit'];

export type ConcessionDialogProps = {
  cardFormProps?: Parameters<typeof useConcessionCardForm>[0];
  open: DialogProps['open'];
  alert?: React.ReactNode;
  onClose: DialogProps['onClose'];
  onSubmitConcession: ConcessionSubmit;
};

export const ConcessionDialog = ({
  open,
  onClose,
  onSubmitConcession,
  alert,
  cardFormProps
}: ConcessionDialogProps) => {
  const handleSubmitCard: ConcessionCardFormProps['onSubmit'] = async (data) => {
    return onSubmitConcession(data);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <ActionableDialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" component="h2">
            Add concession card status
          </Typography>
        </Box>
        <CloseButton onClick={(e) => onClose?.(e, 'backdropClick')}>
          <CloseIcon />
        </CloseButton>
      </ActionableDialogTitle>
      {alert}
      <Content>
        <ContentText variant="body1">
          Please select from the dropdown if this patient is eligible for any of the following concessions.
        </ContentText>
        <FormContainer>
          <ConcessionCardForm onSubmit={handleSubmitCard} cardFormProps={cardFormProps} />
        </FormContainer>
      </Content>
    </Dialog>
  );
};

export default ConcessionDialog;
