import { Box, styled } from '@mui/material';
import type { ArrayPath, Control, FieldValues } from 'react-hook-form';

import type { FieldPathByValue, TimeRange } from '../../shared/types/calcom-schedule-types';
import { daysOfWeek } from '../../shared/utils/days-of-week';

import CopyDayButton from './CopyDayButton';
import ScheduleDay from './ScheduleDay';

/**
 * Schedule component
 *
 * @remarks
 * Used within the Availability Scheduler to allow users set availability
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'Schedule'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param name - String to identify the form
 * @param control - React-hook-form control. This object contains methods for registering components into React Hook Form
 * @param weekStart - By default = 0. Used to find the index of the current day. See 'weekdayIndex'
 *
 * @returns JSXElement
 *
 */

const StyledScheduleContainer = styled(Box)({
  paddingBottom: 100
});

const Schedule = <TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, TimeRange[][]>>({
  name,
  control,
  weekStart = 0
}: {
  name: TPath;
  control: Control<TFieldValues>;
  weekStart?: number;
}) => {
  return (
    <StyledScheduleContainer data-testid="availability-scheduler">
      {/* Iterate through each day */}
      {daysOfWeek.map((weekday, num) => {
        const weekdayIndex = (num + weekStart) % 7;
        const dayRangeName = `${name}.${weekdayIndex}` as ArrayPath<TFieldValues>;

        return (
          <ScheduleDay
            name={dayRangeName}
            key={weekday}
            weekday={weekday}
            control={control}
            CopyDayButton={<CopyDayButton weekStart={weekStart} getValuesFromDayRange={dayRangeName} />}
          />
        );
      })}
    </StyledScheduleContainer>
  );
};

export default Schedule;
