import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useState } from 'react';

export const EditModeContext = createContext<{ isEditMode: boolean; setEditMode: Dispatch<SetStateAction<boolean>> }>({
  isEditMode: false,
  setEditMode: () => {}
});

function EditModeContextProvider({ children }: { children: ReactNode }) {
  const [isEditMode, setEditMode] = useState(false);
  return <EditModeContext.Provider value={{ isEditMode, setEditMode }}>{children}</EditModeContext.Provider>;
}
export default EditModeContextProvider;

export const withEditModeContextProvider =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (props: P) => (
    <EditModeContextProvider>
      <WrappedComponent {...(props as P)} />
    </EditModeContextProvider>
  );
