import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { QueryKey } from '@/data/query';
import type { Prescription } from '@/types';

import settings from '../../data/constants';

type UseGetPrescriptionsProps = {
  limit: number;
  status: string;
};

export type UseGetPrescriptionsResponse = {
  count: number;
  prescriptions: Prescription[];
};

const fetchPrescriptions = async ({ limit, status }: UseGetPrescriptionsProps) => {
  const response = await axios.get<UseGetPrescriptionsResponse>(
    `${settings.url}/prescription/generativeScripting?limit=${limit}&status=${status}&filter_generative_scripts=true`,
    {
      method: 'GET'
    }
  );

  return response.data;
};

export const useGetPrescriptionsWithGenerativeScripting = (payload: UseGetPrescriptionsProps) => {
  const queryKey = [QueryKey.GetGenerativeScriptingPrescriptions, payload];
  const { isLoading, data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => fetchPrescriptions(payload)
  });

  return {
    loading: isLoading,
    data,
    error,
    doGet: refetch
  };
};

export default useGetPrescriptionsWithGenerativeScripting;
