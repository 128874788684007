import type { CalendarEvent } from '@/components/calendar/TimelineCalendar';
import type { SelectOption } from '@/components/calendar/TimelineCalendar.types';
import type { Doctor, DoctorConsultation, Nurse, NurseConsultation } from '@/types';

export const formatNurseList = (nurses: Nurse[]): SelectOption[] => {
  const nursesOptions = nurses.map((nurse) => ({
    id: nurse.id.toString(),
    title: `${nurse.NurseUser.first_name} ${nurse.NurseUser.last_name} - ${nurse.nurse_code}`
  }));
  return nursesOptions;
};

export const formatDoctorList = (doctors: Doctor[]): SelectOption[] => {
  const nursesOptions = doctors.map((doctor) => ({
    id: doctor.doctor_id.toString(),
    title: `${doctor.doctor_name} - ${doctor.id}`
  }));
  return nursesOptions;
};

export const formatNurseConsultationsAsCalendarEvents = (nurseConsultations: NurseConsultation[]): CalendarEvent[] => {
  const events = nurseConsultations
    ?.map((consultation) => {
      return {
        resourceId: consultation.nurse_id,
        title: `${consultation.patient_name} - ${consultation.consultation_type}`,
        start: consultation.start_time,
        end: consultation.end_time,
        eventDetails: consultation,
        backgroundColor: 'white',
        borderColor: 'white'
      };
    })
    .filter(Boolean);

  return events;
};

export const formatDoctorConsultationsAsCalendarEvents = (
  doctorConsultations: DoctorConsultation[]
): CalendarEvent[] => {
  const events = doctorConsultations
    ?.map((consultation) => {
      return {
        resourceId: consultation.doctor_id.toString(),
        title: `${consultation.patient_name} - ${consultation.consult_type}`,
        start: consultation.start_time,
        end: consultation.end_time,
        eventDetails: consultation,
        backgroundColor: 'white',
        borderColor: 'white'
      };
    })
    .filter(Boolean);

  return events;
};
