import { RankTier } from '@montugroup/pms-api-contracts';
import { Typography, useTheme } from '@mui/material';

export interface TierLabelProps {
  label: string;
  tier?: RankTier;
  role?: React.AriaRole;
}

const tierToPalette = (tier: RankTier) => {
  switch (tier) {
    case RankTier.Second:
      return 'error';
    case RankTier.First:
    default:
      return 'success';
  }
};

/**
 * Typically used for displaying tags. colour coded label based on a set of pre-defined tiers
 *
 * @param label - text to display on the tag
 * @param tier - tier level i.e #1, #2...
 * @param role - aria-role for the component
 *
 * @returns JSXElement
 *
 */
const TierLabel = ({ label, tier = RankTier.First, role }: TierLabelProps) => {
  const theme = useTheme();
  const colorCode = theme.palette[tierToPalette(tier)].main;
  return (
    <Typography role={role} color={colorCode} variant="subtitle2">
      {label}
    </Typography>
  );
};

export default TierLabel;
