import type { MutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

// Define the type of options passed to useAsyncMutation
type AsyncMutationOptions<TData = unknown, TError = unknown, TVariables = void> = MutationOptions<
  TData,
  TError,
  TVariables
>;

export const useAsyncMutation = <TData = unknown, TError = unknown, TVariables = void>(
  options: AsyncMutationOptions<TData, TError, TVariables>
) => {
  const mutationResults = useMutation<TData, TError, TVariables>(options);

  return {
    ...mutationResults,
    mutateAsync: async (...params: Parameters<typeof mutationResults.mutateAsync>) => {
      if (!mutationResults.isPending) {
        return mutationResults.mutateAsync(...params);
      }

      // Return null if the mutation is pending
      return null;
    }
  };
};
