import previewNotificationFormClient from '@/api/document/previewNotificationFormClient';
import { QueryKey } from '@/data/query';

const usePostPreviewNotificationForm = () => {
  const queryKey = [QueryKey.PostPreviewNotificationForm];

  return previewNotificationFormClient.previewNotificationForm.useMutation({
    mutationKey: queryKey
  });
};

export default usePostPreviewNotificationForm;
