import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { QueryKey } from '@/data/query';
import settings from '../../data/constants';

type UseGetPrescriptionsWithOOSProductsProps = {
  limit: number;
};

type Patient = {
  PatientUser: {
    first_name: string | null;
    last_name: string | null;
  };
  dob: string | null;
  patient_code: string | null;
};

type Doctor = {
  DoctorUser: {
    first_name: string | null;
    last_name: string | null;
  };
};

export type AlternativeProduct = {
  id: number;
  name: string;
};

export type ScriptProduct = {
  id: number;
  active: boolean;
  name: string;
  prescription_product_code: string | null;
  quantity: number;
  repeats: number;
  interval: number;
  dosage: string;
  is_concession: boolean;
  other_product: string;
  is_generative_scripting: boolean;
  patient_rescript_request_date: string | null;
  alternativeProducts: AlternativeProduct[];
};

export type Prescription = {
  Patient: Patient;
  Products: ScriptProduct[];
  created_by: number;
  doctor_id: number;
  id: number;
  order_code: string | null;
  order_date: string | null;
  patient_id: number;
  Doctor: Doctor;
};

export type UseGetPrescriptionsResponse = {
  count: number;
  prescriptions: Prescription[];
};

const fetchPrescriptionWithOOSProducts = async ({ limit }: UseGetPrescriptionsWithOOSProductsProps) => {
  const response = await axios.get<UseGetPrescriptionsResponse>(
    `${settings.url}/prescription/getPrescriptionsListWithOOSProducts?limit=${limit}&status=active`,
    {
      method: 'GET'
    }
  );

  return response.data;
};

// Returns a list of prescriptions that have products that are out of stock.
export function useGetPrescriptionsWithOOSProducts(payload: UseGetPrescriptionsWithOOSProductsProps) {
  const queryKey = [QueryKey.GetOOSPrescriptions, payload];
  const { isLoading, data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => fetchPrescriptionWithOOSProducts(payload)
  });

  return {
    loading: isLoading,
    data,
    error,
    doGet: refetch
  };
}

export default useGetPrescriptionsWithOOSProducts;
