import iziToast from 'izitoast';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { makeGET, makePOST } from '@/data/service/dataService';

// import { mockScheduleResponse2 } from '../../AvailabilityScheduler/mocks/mockScheduleResponse';
import type {
  AvailabilitySlot,
  CalcomUser,
  DateOverrideRange,
  ScheduleResponse,
  TimeRange
} from '../../shared/types/calcom-schedule-types';

type UseUpdateScheduleProps = {
  user: CalcomUser;
  handleUpdateSuccess: () => void;
};

type ScheduleFormValues = {
  schedule: AvailabilitySlot[][] | undefined;
};

type ScheduleSubmit = {
  dateOverrides?: DateOverrideRange[];
  schedule: AvailabilitySlot[][] | undefined;
};

type ScheduleSubmitPayload = {
  scheduleId: number | undefined;
  availability: AvailabilitySlot[][] | undefined;
  dateOverrides: TimeRange[] | undefined;
};

export const useUpdateSchedule = (props: UseUpdateScheduleProps) => {
  const { user, handleUpdateSuccess } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleResponse, setScheduleResponse] = useState<ScheduleResponse>();

  const form = useForm<ScheduleFormValues>({
    values:
      scheduleResponse &&
      ({
        ...scheduleResponse,
        schedule: scheduleResponse?.availability || []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
  });

  const handleGetSchedules = async (calcomUserId: number) => {
    try {
      const response = await makeGET(`calcom/calcom-schedule/${calcomUserId}`);
      setIsLoading(true);

      if (response) {
        setScheduleResponse(response);
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
      iziToast.error({
        title: 'An error occured getting the existing schedule',
        position: 'topRight'
      });
    }
  };

  const handleScheduleSubmit = async ({ ...values }: ScheduleSubmit) => {
    const allDateOverridesCombined = values?.dateOverrides?.flatMap((override) => override.ranges);

    const updatedPayload: ScheduleSubmitPayload = {
      scheduleId: scheduleResponse?.scheduleId,
      availability: values.schedule,
      dateOverrides: allDateOverridesCombined
    };

    try {
      setIsLoading(true);
      // Using 'any' type because makePOST function is a legacy js file at this point in time.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = (await makePOST('calcom/calcom-schedule', updatedPayload as any)) as any;

      if (response) {
        iziToast.success({
          title: `This ${user.userType}'s schedule has been updated`,
          position: 'topRight'
        });

        // Resync with the server
        setScheduleResponse(response.data);
        setIsLoading(false);
        if (handleUpdateSuccess) {
          handleUpdateSuccess();
        }
      }
    } catch {
      setIsLoading(false);
      iziToast.error({
        title: 'An error occured updating this users existing schedule',
        position: 'topRight'
      });
    }
  };

  useEffect(() => {
    handleGetSchedules(user.calcom_id);
  }, [user.calcom_id]);

  return {
    form,
    scheduleResponse,
    handleScheduleSubmit,
    isLoading
  };
};
