import { CircularProgress, Modal } from '@mui/material';

import { ProgressContainer, ProgressMessage } from './FullScreenLoader.styles';

export type FullScreenLoaderProps = {
  /**
   * Message displayed in the loader
   */
  loadingMessage?: string;
  /**
   * Not displayed, but used in screen readers.
   */
  title?: string;
  open: boolean;
  onClose: () => void;
};

/**
 * A loader that masks the entire page (prevents user from interacting with other part of the app),
 * and displaying loading messages
 */
const FullScreenLoader = ({
  open,
  onClose,
  title = 'loading screen',
  loadingMessage = 'Loading...'
}: FullScreenLoaderProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ProgressContainer aria-label={title} aria-busy={open} aria-live="polite">
        <CircularProgress />
        <ProgressMessage variant="body2">{loadingMessage}</ProgressMessage>
      </ProgressContainer>
    </Modal>
  );
};

export default FullScreenLoader;
