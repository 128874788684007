import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import type { GroupBase, Props } from 'react-select';
import ReactSelect from 'react-select';

import dayjs from '@/utils/dayjs';

import useSelectOptions from '../../AvailabilityScheduler/hooks/useAvailabilitySelectOptions';
import type { IOption } from '../types/calcom-schedule-types';

/**
 * LazySelect component
 *
 * @remarks
 * A select box (using ReactSelect) which displays an array of times and allows a time to be chosen
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'LazySelect'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param value - The time value chosen
 * @param min - The minimum time which is allowed to be rendered in the options
 * @param max - The maximum time which is allowed to be rendered in the options
 *
 * @returns JSXElement
 *
 */

const StyledLazySelect = styled(Box)({
  width: '85px'
});

const LazySelect = ({
  value,
  min,
  max,
  ...props
}: Omit<Props<IOption, false, GroupBase<IOption>>, 'value'> & {
  value: any;
  min?: any;
  max?: any;
}) => {
  // Lazy-loaded options, otherwise adding a field has a noticeable redraw delay.
  const { options, filter } = useSelectOptions();

  useEffect(() => {
    filter({ current: value });
  }, [filter, value]);

  return (
    <StyledLazySelect>
      <ReactSelect
        options={options}
        onMenuOpen={() => {
          if (min) {
            filter({ offset: min });
          }
          if (max) {
            filter({ limit: max });
          }
        }}
        value={options.find((option) => {
          return option.value === dayjs(value).toDate().valueOf();
        })}
        onMenuClose={() => filter({ current: value })}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        {...props}
      />
    </StyledLazySelect>
  );
};

export default LazySelect;
