import React from 'react';
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

import styles from '../../assets/js/inputStyle';

const useStyles = makeStyles(styles);

export default function FormInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    white,
    inputRootCustomClasses,
    handleChange,
    type,
    endAdornment,
    helperText
  } = props;

  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + ' '} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: classes.underline
        }}
        id={id}
        endAdornment={endAdornment}
        onChange={handleChange}
        {...inputProps}
        type={type}
      />
      <FormHelperText className={classes.labelRootError} id={id + '-helper'}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

FormInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool
};
