import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Divider, Grid, IconButton, Stack, styled, Typography } from '@mui/material';

import ProductCard from '../ProductCard/ProductCard';
import { ProductCardVariant } from '../ProductCard/ProductCard.types';
import Tag from '../Tag/Tag';

import type { ProductDescriptionCardProps } from './ProductDescriptionCard.types';
import { ProductSpectrum } from './ProductDescriptionCard.types';

const StyledCard = styled(Card)({
  maxHeight: '90vh',
  height: '100%',
  position: 'relative',
  overflowY: 'auto'
});

const StyledCloseButton = styled(IconButton)({
  position: 'absolute',
  top: '0',
  right: '0.5rem'
});

/**
 * Typically used for displaying detailed information about a
 * product or medication and mostly embed inside a modal
 *
 * @param id - unique for the composite component
 * @param formulation - type of formulation
 * @param name - name of the product
 * @param image - thumbnail image of the product
 * @param price - price of the product or medication
 * @param summary - short description of the product
 * @param spectrumId - an id if its Full/Broad spectrum
 * @param size - the amount of medication i.e 30mL
 * @param description - detailed description of the product
 * @param supplier - name of the supplier
 * @param strain - type of strain
 * @param category - type of category
 * @param terpeneProfile - type of profile
 * @param cbdThcRatio - ratio between CBD and THC
 * @param schedule - schedule category
 * @param conditions - conditons that this medication is used for
 * @param howToUse - instruction on how to use the medication
 * @param onClose - callback fired when close icon is click
 * @param showCloseIcon - toggle to display the close icon
 * @param isConcessionProduct - if it is a concession product
 *
 * @returns JSXElement
 *
 */
export default function ProductDescriptionCard({
  id,
  formulation,
  name,
  image,
  price,
  discountPrice,
  summary,
  spectrumId,
  size,
  description,
  supplier,
  strain,
  category,
  terpeneProfile,
  cbdThcRatio,
  schedule,
  conditions,
  howToUse,
  isConcessionProduct,
  onClose,
  showCloseIcon = false,
  cbdStrengthForDisplay,
  thcStrengthForDisplay
}: ProductDescriptionCardProps) {
  return (
    <StyledCard>
      <CardContent>
        <Stack spacing={2} px={4}>
          <ProductCard
            id={id}
            variant={ProductCardVariant.Large}
            supplierName={supplier ?? ''}
            productName={name ?? ''}
            productImage={image}
            description={summary ?? ''}
            decoration={null}
            price={price}
            discountPrice={discountPrice}
            isConcessionProduct={isConcessionProduct}
            thcStrengthForDisplay={thcStrengthForDisplay}
            cbdStrengthForDisplay={cbdStrengthForDisplay}
          />

          {/* close icon if using component in modal  */}
          {showCloseIcon && (
            <StyledCloseButton onClick={() => onClose && onClose()}>
              <CloseIcon />
            </StyledCloseButton>
          )}

          {/* product description chips   */}
          <Stack direction="row" spacing={2} pb={2}>
            <Stack width="35%">&nbsp;</Stack>
            <Stack>
              <Stack direction="row" spacing={1}>
                {formulation && <Tag label={formulation.name} imagePath={formulation.image_url} />}
                {size && <Tag label={size} />}
                {spectrumId !== undefined && (
                  <Tag label={`${spectrumId === 1 ? ProductSpectrum.Broad : ProductSpectrum.Full} Spectrum`} />
                )}
              </Stack>
            </Stack>
          </Stack>

          {/* product description */}
          {description && <Typography variant="body2">{description}</Typography>}

          <Typography variant="h6" component="h6">
            Details
          </Typography>
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={6} p={0}>
              {/* brand */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Brand:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{supplier || '-'}</Typography>
                </Grid>
              </Grid>

              {/* strain */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Strain:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{strain || '-'}</Typography>
                </Grid>
              </Grid>

              {/* category */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Category:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{category ? `Category ${category}` : '-'}</Typography>
                </Grid>
              </Grid>

              {/* Terpene profile */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Terpene profile:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{terpeneProfile || '-'}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              {/* CBD THC Ratio */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">CBD-THC Ratio:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{cbdThcRatio || '-'}</Typography>
                </Grid>
              </Grid>

              {/* schedule */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Schedule:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{schedule || '-'}</Typography>
                </Grid>
              </Grid>

              {/* conditions */}
              <Grid container py={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Conditions used:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{conditions || '-'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* how to use */}
          <>
            <Typography variant="h6" component="h6">
              How to use
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              {howToUse || '-'}
            </Typography>
          </>
        </Stack>
      </CardContent>
    </StyledCard>
  );
}
