import { getDoctorCalendarConsultations } from '@/api/consultations/getDoctorCalendarConsultations';
import { CalendarEventCard } from '@/components/calendar/CalendarEventCard';
import TimelineCalendar from '@/components/calendar/TimelineCalendar';
import type { SelectOption } from '@/components/calendar/TimelineCalendar.types';
import {
  formatDoctorConsultationsAsCalendarEvents,
  formatDoctorList
} from '@/components/calendar/TimelineScheduler/TimelineScheduler.utils';
import { TimelineSchedulerDrawer } from '@/components/calendar/TimelineScheduler/TimelineSchedulerDrawer';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import type { Doctor, DoctorConsultation, NurseConsultation } from '@/types';
import type { EventClickArg, EventContentArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import { AutocompleteMultiSelect } from '@montugroup/design-system';
import { Snackbar, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';

export type TimelineSchedulerDoctorProps = {
  doctors: Doctor[] | undefined;
};

const StyledTimelineCalendarContainer = styled(Box)({
  '.fc': {
    height: '70vh'
  }
});

export const TimelineSchedulerDoctors = (props: TimelineSchedulerDoctorProps) => {
  const { doctors } = props;
  const [selectedDoctors, setSelectedDoctors] = useState<SelectOption[]>([]);
  const [snackbarProps, setSnackbarProps] = useState<Pick<ExtendedSnackbarProps, 'severity' | 'message' | 'open'>>({
    open: false,
    message: ''
  });
  const [selectedEvent, setSelectedEvent] = useState<NurseConsultation | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const SLOT_DURATION = '00:10';
  const selectListDoctors = formatDoctorList(doctors ?? []);
  const formatEventContentCard = (eventArgs: EventContentArg) => <CalendarEventCard eventArgs={eventArgs} />;

  // This function will run everytime something changes in Timeline Calendar (date etc.)
  const handleCalendarEvents = useCallback(
    async (fetchInfo: EventSourceFuncArg, successCallback: (eventInputs: EventInput[]) => void) => {
      const selectedDoctorIds = selectedDoctors.map((doctor) => doctor.id);

      if (!selectedDoctors.length) {
        // FullCalendar requires successCallback to return at least an empty array else it will error
        successCallback([]);
      }

      const timelineDateStartToUTC = DateTime.fromISO(fetchInfo.startStr).toUTC().toString();

      /*
      TODO - The following 'getDoctorCalendarConsultations' gets ALL doctors consultations for a given date.
      This isn't ideal, it'd be better to pass in the doctor IDs for specific doctors, but it's a current limitation
      of the backend endpoint which only accepts a single doctor ID. When the endpoint is updated to accept multiple
      doctor ids, then update this code below.
       */
      const response = await getDoctorCalendarConsultations({
        selectedDoctor: 'all',
        dateIsoString: timelineDateStartToUTC
      });

      if (response.statusText !== 'OK') {
        setSnackbarProps({
          open: true,
          severity: 'error',
          message: 'An error has occurred getting the doctor consultations'
        });
        successCallback([]);
      }

      const doctorConsultations = response.data.consultations;
      // TODO - This can be updated when we get an endpoint to fetch consults from an array of specific doctors
      const consultsMatchingSelectedDoctors = doctorConsultations.filter((consult: DoctorConsultation) => {
        return selectedDoctorIds.includes(consult.doctor_id.toString());
      });

      const calendarEvents = formatDoctorConsultationsAsCalendarEvents(consultsMatchingSelectedDoctors);

      successCallback(calendarEvents ?? []);
    },
    [selectedDoctors]
  );

  const handleEventClicked = (eventClickInfo: EventClickArg) => {
    setSelectedEvent(eventClickInfo.event.toPlainObject().extendedProps.eventDetails);
    setIsDrawerOpen(true);
  };

  const handleDoctorSelected = (selectedDoctors: SelectOption[]): void => {
    setSelectedDoctors(selectedDoctors);
  };

  const handleToggleDrawer = (open: boolean) => (_event: React.KeyboardEvent | React.MouseEvent) => {
    setIsDrawerOpen(open);
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({ open: false, message: '' });
  };

  return (
    <>
      <Box paddingY={5}>
        <AutocompleteMultiSelect
          options={selectListDoctors}
          itemSelected={handleDoctorSelected}
          labelText={'Select doctors'}
          placeholderText={'Doctor name'}
        />
      </Box>
      <StyledTimelineCalendarContainer>
        <TimelineCalendar
          resources={selectedDoctors}
          eventCallback={handleCalendarEvents}
          resourceHeader="Doctors"
          handleEventClicked={handleEventClicked}
          eventContent={formatEventContentCard as unknown as ReactElement}
          slotDuration={SLOT_DURATION}
        />
      </StyledTimelineCalendarContainer>
      <TimelineSchedulerDrawer selectedEvent={selectedEvent} isOpen={isDrawerOpen} toggleDrawer={handleToggleDrawer} />
      <Snackbar
        {...snackbarProps}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
