import { Link } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import BasicModal from '@/components/common/BasicModal';

export type CalendarCancelPopupButtonProps = {
  cancelBookingUrl: string | null;
  onClose?: () => void;
  isButton?: boolean;
};

const StyledBasicModal = styled(BasicModal)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '90%',
  '& iframe': {
    border: 0,
    width: '100%',
    height: '100%'
  }
});

const CalendarCancelPopupButton = ({
  cancelBookingUrl,
  onClose,
  isButton,
  ...rest
}: CalendarCancelPopupButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const PopupButton = (
    <Button
      color={'secondary'}
      onClick={handleOpen}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      data-testid="cancel-popup-trigger"
      {...rest}
    >
      Cancel
    </Button>
  );

  const PopupLink = (
    <Link
      onClick={handleOpen}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      data-testid="cancel-popup-trigger"
      {...rest}
    >
      Cancel
    </Link>
  );

  const PopupTrigger = isButton ? PopupButton : PopupLink;

  return cancelBookingUrl ? (
    <>
      {PopupTrigger}
      <StyledBasicModal open={modalOpen} handleClose={handleClose}>
        <iframe title="" loading="lazy" src={cancelBookingUrl}></iframe>
      </StyledBasicModal>
    </>
  ) : null;
};

export default CalendarCancelPopupButton;
