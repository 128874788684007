import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { Alert } from '@mui/material';
import type { ReissueStatusType } from '../../PrescriptionPad';

export type ReissuerAlertProps = {
  reissueStatus?: ReissueStatusType;
};

/**
 * Alert with reissued product messages. This will not show if it's
 * NOT reissued.
 */
export const ReissueAlert = ({ reissueStatus }: ReissuerAlertProps) => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];

  if (!reissueStatus?.isReissued) {
    return null;
  }

  const alertMessage = (statusMsg: string) => (
    <Alert aria-label={`${statusMsg} medication`} severity="warning">
      <span>We apologise, but </span>
      <strong>{reissueStatus.productName}</strong>
      <span>
        {' '}
        is currenlty {statusMsg}. Based on your order preferences, we recommend the following alternative product that
        closely matches your needs.
      </span>
    </Alert>
  );

  if (reissueStatus.isOutOfStock) {
    return alertMessage('out of stock');
  }

  return !ffV4PrescriptionAssistantCancelPrescribedMedication ? (
    <Alert aria-label="Re-issued medication" severity="warning">
      <>
        <strong>{reissueStatus.productName}</strong>
        <span> has been reissued based on the selection of previous prescriptions</span>
      </>
    </Alert>
  ) : (
    <Alert aria-label="Re-issued medication" severity="info">
      <>
        <strong>
          {reissueStatus.supplierName} | {reissueStatus.productName} has been reissued
        </strong>
        <span> according to the prescription previously selected.</span>
      </>
    </Alert>
  );
};
