import type { PaginationModel } from '@montugroup/design-system';
import { PaginationVariant, SearchTextField, Table } from '@montugroup/design-system';
import Flag from '@mui/icons-material/Flag';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Link, MenuItem, TextField, Tooltip, styled } from '@mui/material';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';

import type { GetPatientsQuery } from '@/api/patients/getPatients';
import { GetPatientStatus } from '@/api/patients/getPatients';
import { getAuthData } from '@/data/service/authService';
import { useGetPatients } from '@/hooks/patients/useGetPatients';
import type { PatientSummary } from '@/types';

import { dateColumnAccessor, renderHeader } from './components/Table.utils';
import { useTableManualPagination } from './hooks/useTableManualPagination';

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

const columnHelper = createColumnHelper<PatientSummary>();

const getPatientsSortField = (propName: string) => {
  switch (propName) {
    case 'patient_name':
      return 'PatientUser.first_name';
    case 'patient_email':
      return 'PatientUser.email';
    case 'practitionerName':
      // this replicates existing logic, but it's probably revising to see if we should be able to sort by Nurse or not
      return 'Doctor.DoctorUser.first_name';
    default:
      return propName;
  }
};

export const PatientsTable = () => {
  const { isAdmin, isNurse, isManager, isDoc } = getAuthData();
  const shouldShowPractitionerName = isAdmin || isNurse || isManager;

  const { paginationModel, setPaginationModel, shouldResetPageIndex, setShouldResetPageIndex } =
    useTableManualPagination({
      page: 0,
      pageSize: 50
    });

  const [searchFilterText, setSearchFilterText] = useState<string>();

  const [sortingState, setSortingState] = useState<SortingState>([]);

  const sortQuery: Pick<GetPatientsQuery, 'order' | 'dir'> = useMemo(() => {
    return sortingState.length > 0
      ? {
          order: getPatientsSortField(sortingState[0].id),
          dir: sortingState[0].desc ? 'DESC' : 'ASC'
        }
      : {};
  }, [sortingState]);

  const [statusFilterValue, setStatusFilterValue] = useState<GetPatientStatus | 'All'>(
    isDoc ? GetPatientStatus.Active : 'All'
  );

  const { data, isLoading } = useGetPatients({
    limit: `${paginationModel.pageSize}`,
    offset: `${paginationModel.page * paginationModel.pageSize}`,
    search: searchFilterText,
    ...sortQuery,
    ...(typeof statusFilterValue !== 'string'
      ? {
          patientStatus: statusFilterValue
        }
      : {})
  });

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: 'view',
        header: () => renderHeader('View'),
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Link href={`/patients/${row.original.patient_id}`} target="_blank">
              View
            </Link>
          );
        },
        meta: {
          sx: {
            width: '40px'
          }
        }
      }),
      columnHelper.accessor('patient_code', {
        header: () => renderHeader('Patient ID'),
        meta: {
          sx: {
            width: '100px'
          }
        }
      }),
      columnHelper.accessor('patient_name', {
        header: () => renderHeader('Name'),
        cell: ({ getValue, row }) => {
          return (
            <>
              {getValue()}
              {row.original?.has_red_flag && (
                <Tooltip title={row.original?.red_flag_comment || ''}>
                  <Flag fontSize="small" color="error" />
                </Tooltip>
              )}
            </>
          );
        },
        meta: {
          sx: {
            width: '120px'
          }
        }
      }),
      columnHelper.accessor('patient_email', {
        header: () => renderHeader('Email'),
        cell: ({ getValue }) => {
          return <Box sx={{ wordWrap: 'break-word', width: '100%' }}>{getValue()}</Box>;
        },
        meta: {
          sx: {
            width: '180px'
          }
        }
      }),
      ...(shouldShowPractitionerName
        ? [
            columnHelper.accessor(
              (row) => {
                if (row.doctor_id) {
                  return row.doctor_name;
                }
                return row.nurse_name;
              },
              {
                // using camelCase, as this isn't a field name from the response
                id: 'practitionerName',
                header: () => renderHeader('Practitioner'),
                meta: {
                  sx: {
                    width: '100px'
                  }
                }
              }
            )
          ]
        : []),
      columnHelper.accessor('patient_phone', {
        header: () => renderHeader('Patient Phone'),
        enableSorting: false,
        meta: {
          sx: {
            width: '90px'
          }
        }
      }),
      columnHelper.accessor('ihi_status', {
        header: () => renderHeader('IHI status'),
        enableSorting: false,
        meta: {
          sx: {
            overflow: 'hidden',
            width: '80px'
          }
        }
      }),
      columnHelper.accessor('consultations', {
        header: () => renderHeader('Consultations'),
        enableSorting: false,
        meta: {
          sx: {
            width: '70px'
          }
        }
      }),
      columnHelper.accessor('prescriptions', {
        header: () => renderHeader('Prescriptions'),
        enableSorting: false,
        meta: {
          sx: {
            width: '70px'
          }
        }
      }),
      columnHelper.accessor(dateColumnAccessor<PatientSummary>('last_consultation'), {
        id: 'last_consultation',
        header: () => renderHeader('Last Consultation'),
        cell: ({ getValue }) => {
          const lastConsultation = getValue();
          if (!lastConsultation) {
            return '';
          }
          const date = DateTime.fromJSDate(lastConsultation);
          return date.toFormat('dd-MMM-yyyy');
        },
        enableSorting: false,
        meta: {
          sx: {
            width: '100px'
          }
        }
      }),
      columnHelper.accessor('patient_status', {
        header: () => renderHeader('Status'),
        enableSorting: false,
        meta: {
          sx: {
            width: '40px'
          }
        }
      })
    ];
  }, [shouldShowPractitionerName]);

  const onPaginationModelChange = (changedPaginationModel: PaginationModel) => {
    setPaginationModel(changedPaginationModel);
    setShouldResetPageIndex(false);
  };

  const onSortingChange = (changedSortingState: SortingState) => {
    setSortingState(changedSortingState);
  };

  const handleStatusFilterOnChange = (event: SelectChangeEvent<GetPatientStatus | string>) => {
    if (typeof event.target.value === 'string') {
      setStatusFilterValue('All');
      return;
    }
    setStatusFilterValue(event.target.value);
    setShouldResetPageIndex(true);
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTextOrClearIfEmptyString = event.target.value === '' ? undefined : event.target.value.trim();
    setSearchFilterText(searchTextOrClearIfEmptyString);
    setShouldResetPageIndex(true);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={4}>
        {!isDoc && (
          <TextField
            select
            label="Show"
            onChange={(e) => handleStatusFilterOnChange(e as SelectChangeEvent)}
            value={statusFilterValue}
            size="small"
            sx={{
              minWidth: '140px'
            }}
          >
            <MenuItem value="All">All patients</MenuItem>
            <MenuItem value={GetPatientStatus.Active}>Active patients</MenuItem>
            <MenuItem value={GetPatientStatus.Inactive}>Inactive patients</MenuItem>
          </TextField>
        )}
        <SearchTextField
          handleChange={handleSearchOnChange}
          handleClear={handleClearSearchFilter}
          labelText="Search"
          enableClear={true}
          placeholder="e.g. Search by name, email or phone (include spaces)"
          debounceTime={0}
        />
      </Box>
      <StyledTable
        columns={columns}
        compact={true}
        data={data?.patients || []}
        isLoading={isLoading}
        skeletonCount={paginationModel.pageSize}
        manualPagination={true}
        onPaginationModelChange={onPaginationModelChange}
        manualSorting={true}
        onSortingChange={onSortingChange}
        pageSize={paginationModel.pageSize}
        pageCount={-1}
        isTableLayoutFixed={true}
        showPagination={true}
        paginationVariant={PaginationVariant.UNKNOWN_PAGE_SIZE}
        hasNextPage={Boolean(data?.hasMore)}
        shouldResetPageIndex={shouldResetPageIndex}
      />
    </Box>
  );
};
