import { useMemo } from 'react';
import { useAppStore } from '..';

const FORMULATION_REQUIRES_SUITABLE_DEVICE = ['Herb, Dried', 'Inhalation'];

const useMedicationRequiresSuitableDevice = (): boolean => {
  const medicationPadItems = useAppStore.use.prescriptionAssistant().prescriptionPad.medicationPadItems;

  const requiresSuitableDevice = useMemo(() => {
    return medicationPadItems.some((medication) => {
      if (medication.filters) {
        const formulation = medication.filters.get('select-formulation');
        return formulation ? FORMULATION_REQUIRES_SUITABLE_DEVICE.includes(formulation) : false;
      }
      return false;
    });
  }, [medicationPadItems]);

  return requiresSuitableDevice;
};

export { useMedicationRequiresSuitableDevice };
