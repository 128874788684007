import React from 'react';
import { Grid } from '@mui/material';

const ErrorMsg = (props) => {
  return (
    <div>
      <Grid container direction="column">
        <h4 data-testid="errorMsg">{props.errorMessage}</h4>
      </Grid>
    </div>
  );
};

export default ErrorMsg;
