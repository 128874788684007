import { Box, Checkbox, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';

import { daysOfWeek } from '../../shared/utils/days-of-week';

/**
 * Copy Times component
 *
 * @remarks
 * Used within the Availability Scheduler to allow users to choose days to copy availability times to
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'CopyTimes'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param disabled - Boolean for if the day is disabled
 * @param onClick - On click, the times are copied
 * @param onCancel - On cancel, the form is closed
 * @param weekStart - By default = 0. Used to find the index of the current day. See 'weekdayIndex'
 *
 * @returns JSXElement
 *
 */

const StyledList = styled('ul')({
  listStyleType: 'none',
  padding: 0,
  '& li label': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const CopyTimes = ({
  disabled,
  onClick,
  onCancel,
  weekStart
}: {
  disabled: number;
  onClick: (selected: number[]) => void;
  onCancel: () => void;
  weekStart: number;
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const itteratablesByKeyRef = useRef<(HTMLInputElement | HTMLButtonElement)[]>([]);
  const handleKeyDown = (event: KeyboardEvent) => {
    const itteratables = itteratablesByKeyRef.current;
    const isActionRequired =
      event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter';
    if (!isActionRequired || !itteratables.length) {
      return;
    }
    event.preventDefault();
    const currentFocused = document.activeElement as HTMLInputElement | HTMLButtonElement;
    let currentIndex = itteratables.findIndex((checkbox) => checkbox === currentFocused);
    if (event.key === 'Enter') {
      if (currentIndex === -1) {
        return;
      }
      currentFocused.click();
      return;
    }
    if (currentIndex === -1) {
      itteratables[0].focus();
    } else {
      // Move focus based on the arrow key pressed
      if (event.key === 'ArrowUp') {
        currentIndex = (currentIndex - 1 + itteratables.length) % itteratables.length;
      } else if (event.key === 'ArrowDown' || event.key === 'Tab') {
        currentIndex = (currentIndex + 1) % itteratables.length;
      }
      itteratables[currentIndex].focus();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box>
      <Box px={2} pt={1}>
        <Typography textTransform="uppercase" mb={2}>
          Copy times to:
        </Typography>
        <StyledList>
          <li key="select all">
            <label>
              <span>Select all</span>
              <Checkbox
                value={'Select all'}
                checked={selected.length === 7}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelected([0, 1, 2, 3, 4, 5, 6]);
                  } else if (!e.target.checked) {
                    setSelected([]);
                  }
                }}
                ref={(ref) => {
                  if (ref) {
                    itteratablesByKeyRef.current.push(ref as HTMLInputElement);
                  }
                }}
              />
            </label>
          </li>
          {daysOfWeek.map((weekday, num) => {
            const weekdayIndex = (num + weekStart) % 7;
            return (
              <li key={weekday} data-testid="copytime-day">
                <label>
                  <span>{weekday}</span>
                  <Checkbox
                    value={weekdayIndex}
                    checked={selected.includes(weekdayIndex) || disabled === weekdayIndex}
                    disabled={disabled === weekdayIndex}
                    onChange={(e) => {
                      if (e.target.checked && !selected.includes(weekdayIndex)) {
                        setSelected(selected.concat([weekdayIndex]));
                      } else if (!e.target.checked && selected.includes(weekdayIndex)) {
                        setSelected(selected.filter((item) => item !== weekdayIndex));
                      }
                    }}
                    ref={(ref) => {
                      if (ref && disabled !== weekdayIndex) {
                        //we don't need to iterate over disabled elements
                        itteratablesByKeyRef.current.push(ref as HTMLInputElement);
                      }
                    }}
                  />
                </label>
              </li>
            );
          })}
        </StyledList>
      </Box>
      <hr />
      <Box px={2} py={2}>
        <Button
          sx={{ marginRight: 3 }}
          variant="contained"
          onClick={() => onCancel()}
          ref={(ref) => {
            if (ref) {
              itteratablesByKeyRef.current.push(ref as HTMLButtonElement);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onClick(selected)}
          ref={(ref) => {
            if (ref) {
              itteratablesByKeyRef.current.push(ref as HTMLButtonElement);
            }
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default CopyTimes;
