import axios from 'axios';

import settings from '@/data/constants';

import type { ConsultationStatusResponse } from './Consultation.types';

export const getConsultationStatus = async (): Promise<ConsultationStatusResponse[]> => {
  const url = `${settings.url}/dropdown/consultation-status`;

  const response = await axios.get<ConsultationStatusResponse[]>(url);

  return response?.data;
};
