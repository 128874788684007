import iziToast from 'izitoast';
import type { ReactElement, Ref } from 'react';
import { forwardRef } from 'react';
import type { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

/**
 * Form component
 *
 * @remarks
 * A wrapper provider for a React Hook Forms form
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'Form'
 * See - https://github.com/calcom/cal.com/blob/main/packages/ui/components/form/inputs/Form.tsx
 *
 * @param form - An object containing methods and values for a Reach Hook Form form
 * @param handleSubmit - A callback for when the form is submitted
 *
 * @returns JSXElement
 *
 */

type FormProps<T extends object> = { form: UseFormReturn<T>; handleSubmit: SubmitHandler<T> } & Omit<
  JSX.IntrinsicElements['form'],
  'onSubmit'
>;

const PlainForm = <T extends FieldValues>(props: FormProps<T>, ref: Ref<HTMLFormElement>) => {
  const { form, handleSubmit, ...passThrough } = props;

  return (
    <FormProvider {...form}>
      <form
        ref={ref}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          form
            .handleSubmit(handleSubmit)(event)
            .catch(() => {
              iziToast.error({
                title: 'An error occured updating this users existing schedule',
                position: 'topRight'
              });
            });
        }}
        {...passThrough}
      >
        {props.children}
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(PlainForm) as <T extends FieldValues>(
  p: FormProps<T> & { ref?: Ref<HTMLFormElement> }
) => ReactElement;
