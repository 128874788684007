import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Menu } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import CopyTimes from './CopyTimes';

/**
 * Copy Day Button component
 *
 * @remarks
 * A button which opens a small modal where the user can choose days to copy the availability times into
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'CopyButton'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param getValuesFromDayRange - A string which identifies the form field (day) to copy the times from
 * @param weekStart - By default = 0. Used to find the index of the current day. See 'weekdayIndex' in CopyTimes.tsx
 *
 * @returns JSXElement
 *
 */

const CopyDayButton = ({ getValuesFromDayRange, weekStart }: { getValuesFromDayRange: string; weekStart: number }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setValue, getValues } = useFormContext();

  const fieldArrayName = getValuesFromDayRange.substring(0, getValuesFromDayRange.lastIndexOf('.'));
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="text" onClick={handleClick}>
        <ContentCopyIcon />
      </Button>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <CopyTimes
          weekStart={weekStart}
          disabled={parseInt(getValuesFromDayRange.replace(fieldArrayName + '.', ''), 10)}
          onClick={(selected) => {
            selected.forEach((day) => setValue(`${fieldArrayName}.${day}`, getValues(getValuesFromDayRange)));
            setAnchorEl(null);
          }}
          onCancel={() => setAnchorEl(null)}
        />
      </Menu>
    </>
  );
};

export default CopyDayButton;
