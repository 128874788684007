import type { QueryObserverResult } from '@tanstack/react-query';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { GetDoctorsResponse } from '@/api/doctor/getDoctors';
import { getDoctors } from '@/api/doctor/getDoctors';
import { QueryKey } from '@/data/query';
import type { Doctor } from '@/types';

export interface RefetchDoctors {
  (): Promise<QueryObserverResult<GetDoctorsResponse, Error>>;
}

export const useGetDoctors = () => {
  const { data, isError, isLoading, refetch } = useQuery<Doctor[]>({
    queryKey: [QueryKey.GetDoctors],
    queryFn: () => getDoctors(),
    placeholderData: keepPreviousData
  });

  return {
    data,
    isError,
    isLoading,
    refetch: refetch as RefetchDoctors
  };
};
