import settings from '../../data/constants';

import useRequest from './useRequest';

export type CalcomOnbooardResponse = {
  calcomAccountSuccess: boolean;
  pmsUpdateSuccess: boolean;
  associationSuccess: boolean;
  scheduleSuccess: boolean;
  initialEventSuccess?: boolean;
  followupEventSuccess?: boolean;
};

type UsePostCalcomOnboardProps = {
  practitionerId: number;
  isNurse?: boolean;
};

export function usePostCalcomOnboardPractitioner() {
  const { loading, data, error, execute } = useRequest<CalcomOnbooardResponse>({
    config: {
      url: `${settings.url}/calcom/onboard-practitioner`,
      method: 'POST'
    }
  });

  const doPost = async (body: UsePostCalcomOnboardProps) => {
    return execute({ data: body });
  };

  return {
    loading,
    data,
    error,
    doPost
  };
}

export default usePostCalcomOnboardPractitioner;
