import { MenuItem, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import type { FilteredProduct } from '@/api';
import { Attribute } from '@/api';

import { productAttributeRangeToNumbersList } from '../../ProductDetailsCard/ProductDetailsCard.helpers';

export type PrescriptionItemAttributesEditorProps = {
  quantity: number;
  repeats: number;
  interval: number;
  dosage: string;
  selectedMedication?: FilteredProduct;
  onChange: (attribute: Attribute, value: string | number) => void;
};

/**
 * Dosage text state that will update when the dosage param updates.
 */
const useDosageText = (dosage: string) => {
  const [dosageText, setDosageText] = useState<string>(dosage || '');
  useEffect(() => {
    setDosageText(dosage);
  }, [dosage]);

  return {
    dosageText,
    setDosageText
  };
};

/**
 * Component to edit prescription item attributes:
 * @param quantity
 * @param interval
 * @param repeats
 * @param dosage (dosage onChange is tied to onBlur, as it's a text input)
 * @param selectedMedication - available options for each attribute is dependent on the selected medicaiton
 * @param onChange - invoked when each attribute changes. Except for `dosage`, where the change happens `onBlur`
 */
export const PrescriptionItemAttributesEditor = ({
  quantity,
  repeats,
  interval,
  dosage,
  selectedMedication,
  onChange
}: PrescriptionItemAttributesEditorProps) => {
  const { dosageText, setDosageText } = useDosageText(dosage);

  const handleDosageBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onChange(Attribute.Dosage, e.target.value);
  };

  const handleDosageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDosageText(e.currentTarget.value);
  };

  return (
    <>
      <Stack direction="row" spacing={4} justifyContent="center">
        {/* Quantity */}
        <TextField
          fullWidth
          size="small"
          label="Quantity"
          select
          value={quantity ?? 2}
          disabled={!selectedMedication}
          onChange={(e) => onChange(Attribute.Quantity, Number(e.target.value))}
          data-dd-privacy="allow"
        >
          {productAttributeRangeToNumbersList(selectedMedication?.attributes?.quantity).map((val) => (
            <MenuItem key={val} value={val} data-dd-privacy="allow">
              {val}
            </MenuItem>
          ))}
        </TextField>

        {/* Repeats */}

        <TextField
          fullWidth
          select
          value={repeats ?? 4}
          label="Repeats"
          disabled={!selectedMedication}
          onChange={(e) => onChange(Attribute.Repeats, Number(e.target.value))}
          size="small"
          data-dd-privacy="allow"
        >
          {productAttributeRangeToNumbersList(selectedMedication?.attributes?.repeats).map((val) => (
            <MenuItem key={val} value={val} data-dd-privacy="allow">
              {val}
            </MenuItem>
          ))}
        </TextField>

        {/* Interval */}
        <TextField
          fullWidth
          select
          value={interval ?? 14}
          label="Interval"
          disabled={!selectedMedication}
          onChange={(e) => onChange(Attribute.Interval, Number(e.target.value))}
          size="small"
          data-dd-privacy="allow"
        >
          {productAttributeRangeToNumbersList(selectedMedication?.attributes?.interval).map((val) => (
            <MenuItem key={val} value={val} data-dd-privacy="allow">
              {val}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      {/* Dosage */}
      <TextField
        disabled={!selectedMedication}
        label="Dosage"
        multiline
        fullWidth
        rows={3}
        inputProps={{ maxLength: 500 }}
        helperText={`${dosageText.length}/500`}
        value={dosageText}
        onChange={handleDosageChange}
        onBlur={handleDosageBlur}
        data-dd-privacy="allow"
      />
    </>
  );
};
