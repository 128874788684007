import { Box, styled, Typography } from '@mui/material';

export const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '300px',
  height: '200px',
  position: 'absolute',
  display: 'flex',
  margin: 'auto',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper
}));

export const ProgressMessage = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  marginLeft: theme.spacing(2),
  fontWeight: 500,
  textAlign: 'center'
}));
