import { consultationNotesClient } from '@/api/consultations/consultationNotesHistoryClient';
import { QueryKey } from '@/data/query';

export const useGetConsultationNotesHistory = (consultationId: number, ffViewConsultationNotes = false) => {
  const queryClient = consultationNotesClient();
  const queryKey = [QueryKey.ConsultationNotes, consultationId];

  const data = queryClient.getByConsultationId.useQuery(
    queryKey,
    { params: { consultationId } },
    {
      queryKey,
      enabled: ffViewConsultationNotes,
      refetchOnMount: 'always'
    }
  );

  return data;
};
