import { useCallback, useEffect, useState } from 'react';

export enum FeatureNameEnum {
  CollapsibleMenu = 'collapsible_menu'
}

export interface FeatureAlertState {
  shouldShowAlertForFeature: boolean | null;
  setShouldShowAlertForFeature: (shouldShowAlert: boolean) => void;
}

export interface UseAlertNewFeatureProps {
  userId: number;
  featureName: FeatureNameEnum;
}

const generateHasDismissedAlertKey = (userId: number, featureName: FeatureNameEnum) =>
  `hasDismissedAlert:${featureName}:UserId:${userId}`;

export const useAlertNewFeature = ({ userId, featureName }: UseAlertNewFeatureProps): FeatureAlertState => {
  const [hasDismissedAlert, setHasDismissedAlert] = useState<boolean | null>(null);

  useEffect(() => {
    if (!userId || !featureName) {
      return;
    }
    const hasDismissedAlertKey = generateHasDismissedAlertKey(userId, featureName);

    const localStorageData = localStorage.getItem(hasDismissedAlertKey) === 'true';
    setHasDismissedAlert(localStorageData);
  }, [userId, featureName]);

  const setShouldShowAlertForFeature = useCallback(
    (shouldShowAlert: boolean) => {
      if (!userId || !featureName) {
        return;
      }
      const hasDismissedAlertKey = generateHasDismissedAlertKey(userId, featureName);
      if (!shouldShowAlert) {
        localStorage.setItem(hasDismissedAlertKey, 'true');
      } else {
        localStorage.removeItem(hasDismissedAlertKey);
      }
    },
    [userId, featureName]
  );

  return {
    shouldShowAlertForFeature: hasDismissedAlert === null ? null : !hasDismissedAlert,
    setShouldShowAlertForFeature
  };
};
