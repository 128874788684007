import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import type { z } from 'zod';
import { EditModeContext, withEditModeContextProvider } from '../../EditModeContextProvider';
import EditableSections from '../../EditableSections';
import { schema } from './TreatingDoctor.schema';

type FormFields = z.infer<typeof schema>;

type TreatingDoctorProps = {
  editable?: boolean;
  defaultValues?: FormFields;
  onSubmit: (data: FormFields) => void;
};

function TreatingDoctor({ editable = true, defaultValues, onSubmit }: TreatingDoctorProps) {
  const { isEditMode } = useContext(EditModeContext);
  const formContext = useForm<FormFields>({
    values: defaultValues,
    resolver: zodResolver(schema)
  });

  return (
    <EditableSections title="Treating Doctor" editable={editable} formContext={formContext} actions={{ onSubmit }}>
      <FormContainer formContext={formContext}>
        <Grid container columnSpacing={10} rowSpacing={5} marginY={2} data-testid="treating-doctor-form">
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="fullName" label="Full Name" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="email" label="Email" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="clinicName" label="Clinic Name" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="phone" label="Phone" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
        </Grid>
      </FormContainer>
    </EditableSections>
  );
}

export default withEditModeContextProvider(TreatingDoctor);
