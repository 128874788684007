import type { FilteredProduct, RankDecoration } from '@/api';
import type { MedicationPadItemType, ReissueStatusType } from '@/components/PatientPageV2/components/PrescriptionPad';
import type { MedicationType } from '@/components/PatientPageV2/components/ProductDetailsCard/ProductDetailsCard.types';
import { transformFilteredProductToPrescriptionMetadata } from '@/components/PatientPageV2/components/ProductDetailsCard/ProductDetailsCard.utils';
import { DEFAULT_QUESTION_ID, REISSUED_ANSWER_ID } from '@/shared-ui/TierLabel/TierLabel.types';
import { RankTier } from '@montugroup/pms-api-contracts';

/**
 * Internal custom hook that will take in a reissued medication, merge with
 * existing prescribed medications
 *
 * @param availableMedications - all the medications available for the Doctor to choose from
 * @param reissuedMedication - reissued medication to be added to pad
 *
 * @returns new resissued medication considering oos/inactive scenarios
 */
export const createReissuedMedication = (
  availableMedications: FilteredProduct[],
  reissuedMedication: MedicationPadItemType
) => {
  if (!reissuedMedication?.medicationPadItem?.productId) {
    return;
  }

  // check if it's already an created medication
  const matchingMedication = availableMedications.find(
    (medication) => medication.id === reissuedMedication.medicationPadItem?.productId
  );
  const reissuedPrescribedMedication: MedicationType | undefined = matchingMedication && {
    ...(reissuedMedication.medicationPadItem || {}),
    ...transformFilteredProductToPrescriptionMetadata(matchingMedication),
    productName: matchingMedication.productName,
    supplierName: matchingMedication.supplierName
  };
  const isNotTopRecommendation =
    matchingMedication && (matchingMedication.decoration as RankDecoration).tier !== RankTier.First;
  const reissueStatus: ReissueStatusType | undefined = reissuedMedication
    ? {
        ...reissuedMedication.reissueStatus,
        productId: matchingMedication?.id || reissuedMedication.medicationPadItem?.productId,
        productName: matchingMedication?.productName || reissuedMedication.medicationPadItem?.productName || '',
        supplierName: matchingMedication?.supplierName || reissuedMedication.medicationPadItem?.supplierName || '',
        description: matchingMedication?.description || reissuedMedication.medicationPadItem?.description || ''
      }
    : undefined;
  const newMedication: MedicationPadItemType = {
    ...reissuedMedication,
    medicationPadItem: reissuedPrescribedMedication ?? reissuedMedication.medicationPadItem,
    ...(isNotTopRecommendation && {
      moreProductsLoaded: true
    }),
    ...(isNotTopRecommendation && {
      feedback: {
        questionAsked: DEFAULT_QUESTION_ID,
        answerSelected: REISSUED_ANSWER_ID
      }
    }),
    reissueStatus
  };

  return newMedication;
};
