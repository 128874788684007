import { ErrorCause } from '@montugroup/payments-contracts';
import { Alert, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, useTheme } from '@mui/material/styles';
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PaymentFailureModal from '@/components/paymentCard/components/PaymentFailureModal';
import TimeOutModal from '@/components/paymentCard/components/TimeoutModal';
import { Timer } from '@/components/paymentCard/components/Timer';
import { FF_CONSULTATION_BOOKING_PAYMENT_WINDOW } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { Country, Currency, PaymentType } from '@/hooks/graphql/generated/graphql';
import { useRegisterPayment } from '@/hooks/graphql/mutations';
import type { CalendarData } from '@/hooks/rest/types';
import usePostEwayInitialConsultCharge from '@/hooks/rest/usePostEwayInitialConsultCharge';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import useTrackingProviders from '@/hooks/useTrackingProviders';
import { brazeChangeUser, brazeTrackEvent } from '@/services/braze.service';
import type { ErrorReason, PaymentError } from '@/types/payments.types';
import { EwayChargeErrorCause, GenericErrorCause } from '@/types/payments.types';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';
import { Logger } from '@/utils/logger';

import settings from '../../../data/constants';
import useTillPaymentScript from '../../../hooks/useTillPaymentScript';
import Mixpanel from '../../../utils/mixpanelServices.js';

import type { Payload } from './PaymentForm';
import PaymentForm from './PaymentForm';
import type { TillCardError } from './TillCardErrors';
import { isTillErrorMatch, possibleTillCardErrors } from './TillCardErrors';

const logger = new Logger('CreditCardPayment.ts');

declare const PaymentJs: any;

type Props = {
  data: CalendarData;
};

type InitialisingContextType = {
  initialising: boolean;
  setInitialising: React.Dispatch<React.SetStateAction<boolean>>;
};

declare global {
  interface Window {
    Cerberus: any;
  }
}

const FormContainer = styled('div')({
  position: 'relative'
});

const LoaderBlock = styled('div')({
  backgroundColor: '#000',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: 1,
  opacity: '0.3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const StyledTimerContainer = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  position: 'absolute',
  right: 0,
  top: '-110px',
  [theme.breakpoints.up('sm')]: {
    top: '-150px'
  },
  '.MuiChip-root': {
    border: 'none'
  }
}));

let tillPayment: any;

export const InitialisingContext = createContext<InitialisingContextType>({
  initialising: false,
  setInitialising: () => {}
});

export default function CreditCardPayment({ data }: Props) {
  const { flags } = useFeatureFlags();
  const { ddTrackAction } = useTrackingProviders(settings);
  const enableEwayPayments = !!data.isEwayPayment;
  const paymentWindowTimeoutInSeconds = flags[FF_CONSULTATION_BOOKING_PAYMENT_WINDOW];
  const tillPaymentScriptStatus = useTillPaymentScript();
  const [isLoading, setIsLoading] = useState(false);
  const [initialising, setInitialising] = useState(true);
  const theme = useTheme();

  const [creditCardError, setCreditCardError] = useState<PaymentError>(undefined);
  const [paymentError, setPaymentError] = useState<PaymentError>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState(false);
  const { doPost: ewayInitialConsultCharge } = usePostEwayInitialConsultCharge();
  const { registerPaymentExecuteMutation } = useRegisterPayment();
  const navigate = useNavigate();
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const [reloadPaymentKey, setPaymentReloadKey] = useState(0);
  const reloadPaymentComponent = () => {
    setPaymentReloadKey((prevKey) => prevKey + 1);
  };

  const currentCalcomUUID = data?.inviteeUuid;

  const HostedInputStyle = {
    border: '0px',
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    background: `rgba(0, 0, 0, 0.06)`,
    height: '92%',
    fontWeight: 200,
    padding: '25px 12px 8px 12px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    lineHeight: '1.5rem'
  };

  // This ref is used to ensure GTM tracking is only fired once on load. It's been
  // hoisted to this level to avoid it resetting upon a re-render of subcomponents.
  const isPaymentTrackingTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (tillPaymentScriptStatus === 'ready' && !enableEwayPayments) {
      tillPayment = new PaymentJs();

      tillPayment.init(settings.tillSdkKey, 'cardNumber', 'cvv', (payment: any) => {
        let numberFocused = false;
        let cvvFocused = false;

        const focussedInputStyle = {
          ...HostedInputStyle,
          outline: 'none'
        };
        // Set initial style
        payment.setNumberStyle(HostedInputStyle);
        payment.setCvvStyle(HostedInputStyle);

        payment.setNumberPlaceholder('Card Number');
        payment.setCvvPlaceholder('CVV');
        // Focus events
        payment.numberOn('focus', () => {
          numberFocused = true;
          payment.setNumberStyle(focussedInputStyle);
        });
        payment.cvvOn('focus', () => {
          cvvFocused = true;
          payment.setCvvStyle(focussedInputStyle);
        });

        // Blur events
        payment.numberOn('blur', () => {
          numberFocused = false;
          payment.setNumberStyle(HostedInputStyle);
        });
        payment.cvvOn('blur', () => {
          cvvFocused = false;
          payment.setCvvStyle(HostedInputStyle);
        });

        // Hover events
        payment.numberOn('mouseover', () => {
          // Don't override style if element is already focused
          if (!numberFocused) {
            payment.setNumberStyle(focussedInputStyle);
          }
        });
        payment.numberOn('mouseout', () => {
          // Don't override style if element is already focused
          if (!numberFocused) {
            payment.setNumberStyle(HostedInputStyle);
          }
        });
        payment.cvvOn('mouseover', () => {
          // Don't override style if element is already focused
          if (!cvvFocused) {
            payment.setCvvStyle(focussedInputStyle);
          }
        });
        payment.cvvOn('mouseout', () => {
          // Don't override style if element is already focused
          if (!cvvFocused) {
            payment.setCvvStyle(HostedInputStyle);
          }
        });
        setInitialising(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tillPaymentScriptStatus]);

  const trackingData = JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}');

  const sendBrazeEvent = (eventName: string) => {
    try {
      if (!data?.email) {
        return;
      }
      brazeChangeUser(data.email);
      brazeTrackEvent(eventName, data ?? {});
    } catch (error) {
      console.error(`Error sending event to Braze.`, error);
    }
  };

  async function handleSubmit(payload: Payload) {
    if (enableEwayPayments) {
      setIsLoading(true);

      if (!data.email && !data.fullName && !data.phoneNumber && !data.inviteeUuid) {
        window.location.replace(settings.preConsultUrl);
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const namesArr = data.fullName!.split(' ');
      const firstName = namesArr[0];
      const lastName = namesArr[namesArr.length - 1];

      const customer = {
        firstName: firstName,
        lastName: lastName,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: data.email!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        phoneNumber: data.phoneNumber!
      };

      const chargeEway = async (enrolResp: any | undefined) => {
        try {
          const ewayChargeResp = await ewayInitialConsultCharge({
            payment: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              reference: data.inviteeUuid!,
              securedData: payload.secureFieldCode ?? '',
              accessCode: enrolResp?.body.data?.accessCode ?? undefined,
              isConcession: payload.isConcession || false,
              discountCode: payload.discountCode
            },
            customer
          });

          if (ewayChargeResp.status == 400 && ewayChargeResp.body.error.code == EwayChargeErrorCause.CREDIT_CARD) {
            setCreditCardError(ErrorCause.CreditCard);
            return;
          }

          if (ewayChargeResp.status == 400 && ewayChargeResp.body.error.code == EwayChargeErrorCause.PAYMENT_GATEWAY) {
            setPaymentError(ErrorCause.PaymentGateway);
            return;
          }

          // We technically shouldn't get this because we check the discount before sending
          if (ewayChargeResp.status == 400 && ewayChargeResp.body.error.code == EwayChargeErrorCause.INVALID_DISCOUNT) {
            setPaymentError(ErrorCause.InvalidDiscount);
            return;
          }

          if (ewayChargeResp.status == 200 && ewayChargeResp.body.data?.success) {
            sendBrazeEvent('consult-payment-success');
            navigate(`/card-detail?status=success`, { replace: true });
          } else {
            throw new Error('Error processing payment');
          }
          setIsLoading(false);
        } catch (e) {
          setPaymentError(GenericErrorCause.UNKNOWN_ERROR);
          logger.error('eWay: Initial Consult Charge', e);
        }
      };

      await chargeEway(undefined);
      return;
    }

    const tillData = {
      card_holder: payload.cardHolder,
      month: payload.expiry!.format('MM'),
      year: payload.expiry!.format('YY')
    };

    setIsLoading(true);

    try {
      tillPayment.tokenize(
        tillData, //additional data, MUST include card_holder (or first_name & last_name), month and year
        async (token: string, cardData: { full_name: string }) => {
          try {
            const result = await registerPaymentExecuteMutation({
              registerPaymentInput: {
                transactionToken: token,
                merchantTransactionId: data.inviteeUuid || '',
                merchantTransactionDescription: 'alternaleaf',
                email: data.email || '',
                nameOnCard: cardData.full_name,
                currency: Currency.Aud,
                country: Country.Au,
                paymentType: PaymentType.Card
              }
            });

            const registerPaymentResult = result.data?.registerPayment;
            if (registerPaymentResult?.success) {
              // Send Event to Mixpanel
              Mixpanel('Payment Submitted Successfully');
              sendBrazeEvent('consult-payment-success');

              if (registerPaymentResult?.redirectUrl) {
                window.location.replace(registerPaymentResult.redirectUrl);
              }
            } else {
              console.log('PAYMENT FAILURE');
              console.dir(registerPaymentResult);
              setPaymentError(true);
              setIsLoading(false);
              const errors: string[] = [];

              if (registerPaymentResult?.errors && registerPaymentResult?.errors.length > 0) {
                errors.push(...registerPaymentResult.errors.map((rawError) => rawError.errorMessage));
              } else {
                errors.push('Unknown register payment error');
              }
              Mixpanel('Payment Failed', { errorMessages: errors });
              sendBrazeEvent('consult-payment-failure');

              sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_ERROR, {
                id: GoogleAnalyticsEventId.PAYMENT_DETAILS_ERROR,
                status: 'payment_error',
                error_code: 'TILL_PAYMENT_FAILURE',
                error_message: 'Till payment failure. Tracking fired from Payment page',
                ...trackingData
              });
            }
          } catch (e) {
            console.error(e);
            setPaymentError(true);
            setIsLoading(false);
            const errors: string[] = [];
            if (typeof e === 'string') {
              errors.push(e);
            } else if (e instanceof Error) {
              errors.push(e.message);
            } else {
              errors.push('Caught unknown Exception');
            }
            Mixpanel('Payment Failed', { errorMessages: errors });
            sendBrazeEvent('consult-payment-failure');

            sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_ERROR, {
              id: GoogleAnalyticsEventId.PAYMENT_DETAILS_ERROR,
              status: 'payment_error',
              error_code: 'TILL_UNKNOWN_ERROR',
              error_message: 'Till unknown error. Tracking fired from Payment page',
              ...trackingData
            });
          }
        },
        // The following handles errors with Till Payment Gateway
        (errors: TillCardError[]) => {
          //error callback function
          setCreditCardError(true);
          setErrorMessages(
            errors.map((error: TillCardError) => {
              if (isTillErrorMatch(possibleTillCardErrors.cardNumberInvalid, error)) {
                return 'Invalid card details';
              }
              if (isTillErrorMatch(possibleTillCardErrors.cvvInvalid, error)) {
                return 'Invalid Security Code';
              }
              if (isTillErrorMatch(possibleTillCardErrors.cvvEmpty, error)) {
                return 'Security Code must not be empty';
              }
              return error.message;
            })
          );
          setIsLoading(false);
          return false;
        }
      );
    } catch {
      setPaymentError(true);
    }
  }

  const Notice = () => {
    return (
      <>
        {/* Errors with the credit card that should be resolved by the users */}
        {creditCardError && !paymentError && (
          <Alert variant="outlined" severity="error">
            There was an issue processing the card.{' '}
            {errorMessages.map((errorMessage, key) => (
              <li key={key}>{errorMessage}</li>
            ))}
            Please double check your card details and retry.
          </Alert>
        )}

        {/* Issues with our payment gateway */}
        {paymentError && (
          <Alert variant="outlined" severity="error">
            There was an issue processing the card and you have not been charged. Please refresh page and try again.
          </Alert>
        )}
      </>
    );
  };

  // When the payment page times out, we save the timed out invitee_uuid ID to local storage. So that, if the user returns to the payment page,
  // we can test if the stored ID is different than the current ID (in search params). If its different, then we allow the user to continue.
  // But if the IDs are the same, the user is returning to a timed-out booking and we show the 'booking timeout modal' to force the user back
  // through to the calendar booking form so they can create a new booking.
  const timeoutIdHasChanged = useCallback((): boolean => {
    const previousCalcomUUID = localStorage.getItem('invitee_uuid_timed_out');
    return previousCalcomUUID !== currentCalcomUUID;
  }, [currentCalcomUUID]);

  // On load, we save the current invitee_uuid into local storage. When the page is returned to,
  // we check whether the timer needs to be restarted or continue counting down the remaining time.
  const currentIdHasChanged = useCallback((): boolean => {
    const currentStoredCalcomUUID = localStorage.getItem('invitee_uuid_current');
    return currentStoredCalcomUUID !== currentCalcomUUID;
  }, [currentCalcomUUID]);

  useEffect(() => {
    if (enableEwayPayments) {
      if (currentCalcomUUID) {
        // On load, save the current calcom invitee_uuid for use with timer refresh logic
        localStorage.setItem('invitee_uuid_current', currentCalcomUUID);
      }

      // When page loads, determine if the timeout modal
      // needs to show for customers who revisit the page
      setIsTimeoutModalOpen(!timeoutIdHasChanged());
    }
  }, [enableEwayPayments, timeoutIdHasChanged, currentCalcomUUID]);

  const handleSessionTimeout = () => {
    setIsTimeoutModalOpen(true);

    // Store calcom invitee_uuid for use with timeout modal logic
    if (currentCalcomUUID) {
      localStorage.setItem('invitee_uuid_timed_out', currentCalcomUUID);
    }
  };

  const [isPaymentFailureModalOpen, setIsPaymentFailureModalOpen] = useState(false);
  const [paymentFailureModalMessage, setPaymentFailureModalMessage] = useState('');

  useEffect(() => {
    if (!enableEwayPayments) {
      return;
    }

    if (creditCardError) {
      const creditCardErrorReason: ErrorReason = {
        [ErrorCause.CreditCard]: 'There was an issue processing the card and you have not been charged',
        [GenericErrorCause.INVALID_DETAILS]: 'The credit card details you have entered are incomplete or invalid'
      };

      const creditCardErrorMessage = `${creditCardErrorReason[creditCardError as string]}. Please try again`;
      ddTrackAction('Payment Failed (Eway)', {
        payment_failure_message: creditCardErrorMessage,
        consultation_start_time: data.startTime,
        invitee_uuid: data.inviteeUuid
      });
      setPaymentFailureModalMessage(creditCardErrorMessage);
    }

    if (paymentError) {
      const paymentErrorReason: ErrorReason = {
        [ErrorCause.PaymentGateway]: 'there was a payment gateway error. Please try again',
        [ErrorCause.InvalidDiscount]:
          'there was an issue with the discount code. Please check the discount code and try again',
        [GenericErrorCause.UNKNOWN_ERROR]: 'something went wrong. Please check your payment details and try again'
      };

      const paymentErrorMessage = `We tried to charge your card but, ${paymentErrorReason[paymentError as string]}.`;
      ddTrackAction('Payment Failed (Eway)', {
        payment_failure_message: paymentErrorMessage,
        consultation_start_time: data.startTime,
        invitee_uuid: data.inviteeUuid
      });
      setPaymentFailureModalMessage(paymentErrorMessage);
    }

    setIsPaymentFailureModalOpen(!!creditCardError || !!paymentError);
  }, [
    creditCardError,
    paymentError,
    enableEwayPayments,
    setIsPaymentFailureModalOpen,
    ddTrackAction,
    data.startTime,
    data.inviteeUuid
  ]);

  return (
    <InitialisingContext.Provider value={{ initialising, setInitialising }}>
      <FormContainer>
        {enableEwayPayments && (
          <>
            <StyledTimerContainer>
              <Timer
                onTimeout={handleSessionTimeout}
                durationInSeconds={!currentCalcomUUID || !timeoutIdHasChanged() ? 0 : paymentWindowTimeoutInSeconds}
                refreshTimer={currentIdHasChanged()}
              />
            </StyledTimerContainer>
            {isTimeoutModalOpen && <TimeOutModal isModalOpen={isTimeoutModalOpen} data={data} />}
            {isPaymentFailureModalOpen && (
              <PaymentFailureModal
                trackingErrorCode={creditCardError || paymentError}
                isModalOpen={isPaymentFailureModalOpen}
                messageText={paymentFailureModalMessage}
                buttonText={creditCardError ? 'Try again' : 'Update payment details'}
                onClose={() => {
                  setIsLoading(true);
                  setPaymentError(false);
                  setCreditCardError(false);
                  reloadPaymentComponent();
                  setIsLoading(false);
                }}
              />
            )}
          </>
        )}
        {isLoading && (
          <LoaderBlock>
            <CircularProgress color="info" size="6rem" />
          </LoaderBlock>
        )}
        {!enableEwayPayments && <Notice />}

        <PaymentForm
          key={reloadPaymentKey}
          data={data}
          onSubmit={handleSubmit}
          setCreditCardError={setCreditCardError}
          setErrorMessages={setErrorMessages}
          isPaymentTrackingTriggered={isPaymentTrackingTriggered}
        />
      </FormContainer>
    </InitialisingContext.Provider>
  );
}
