import type { SvgIconProps } from '@mui/material';
import { Button, Tooltip, alpha, styled } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';

export interface MenuButtonProps {
  name: string;
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
  icon?: React.ReactElement<SvgIconProps>;
  children?: ReactNode;
  isSideBarOpen?: boolean;
  enableToolTip?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  textAlign: 'left',
  alignContent: 'left',
  padding: '0.5rem 1rem',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '1.25rem',
  fontWeight: '400',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'none',
  color: theme.palette.primary.main,
  minHeight: '48px',
  minWidth: 0,
  textTransform: 'none',
  '&:not(.selected):hover': {
    background: alpha(theme.palette.primary.main, 0.039)
  },
  '& > svg': {
    '&:nth-of-type(1)': {
      marginRight: theme.spacing(5)
    },
    '&:nth-of-type(2)': {
      marginLeft: 'auto',
      color: theme.palette.secondary.main
    }
  },
  '&.selected': {
    backgroundColor: alpha(theme.palette.primary.main, 0.561),
    fontWeight: '600',
    color: 'white'
  },
  '&.selected.activeChildren': {
    color: theme.palette.primary.main
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.561)
  },
  '&:not(.sideBarOpen)': {
    padding: '8px',
    width: '100%',
    '& > svg': {
      margin: '0px'
    }
  },
  '&.activeChildren': {
    fontWeight: 600,
    background: alpha(theme.palette.primary.main, 0.08)
  },
  '&.activeChildren:not(.isOpen)': {
    backgroundColor: alpha(theme.palette.primary.main, 0.561),
    color: 'white',
    '& > svg': {
      '&:nth-of-type(2)': {
        color: 'white'
      }
    }
  },
  '&.activeChildren:not(.sideBarOpen)': {
    borderRadius: '0px'
  },
  '& > a': {
    paddingLeft: '2.34em'
  }
}));

export const MenuButton = ({
  name,
  icon,
  isActive,
  children,
  isSideBarOpen,
  enableToolTip,
  onClick,
  className
}: MenuButtonProps) => {
  return (
    <Tooltip
      title={name}
      placement={'right'}
      disableFocusListener={isSideBarOpen || !enableToolTip}
      disableHoverListener={isSideBarOpen || !enableToolTip}
      disableTouchListener={isSideBarOpen || !enableToolTip}
    >
      <StyledButton
        onClick={onClick}
        variant={isActive ? 'contained' : 'text'}
        className={`${isActive ? 'selected' : ''} ${isSideBarOpen ? 'sideBarOpen' : ''} ${className}`}
        data-testid="nav-menubutton"
      >
        {icon}
        <span className="menuButtonText">{name}</span>
        {children}
      </StyledButton>
    </Tooltip>
  );
};
