import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);
/**
 * @deprecated due to the non-scalable composition of the component
 */
const CheckBoxField = (props) => {
  const classes = useStyles();
  const {
    label,
    id,
    checked,
    padding,
    display,
    disabled,
    margin,
    onChange,
    color,
    icon,
    checkedIcon,
    checkBoxSuffix,
    customGrid,
    textAlign,
    labelPadding,
    labelClasses,
    labelFontWeight,
    fontSize,
    checkboxWidth,
    checkboxHeight,
    InputClasses,
    checkboxClasses,
    ...rest
  } = props;
  return (
    <Box
      component={Grid}
      width="100%"
      display={display ? display : 'flex'}
      m={margin !== undefined ? margin : 1}
      p={padding !== undefined ? padding : 0}
      {...rest}
    >
      <Grid xs={customGrid ? customGrid[0] : 4} item={true}>
        <Box
          component="div"
          textAlign={textAlign ? textAlign : 'left'}
          p={labelPadding !== undefined ? labelPadding : '12px'}
          px={labelPadding !== undefined ? '' : '10px'}
          className={`${disabled && classes.disabledLabel} ${labelClasses ? labelClasses : ''}`}
          fontWeight={isNaN(labelFontWeight) ? 600 : labelFontWeight}
          fontSize={fontSize}
        >
          <label htmlFor={id}>{label}</label>
        </Box>
      </Grid>
      <Grid xs={customGrid ? customGrid[1] : 8} item={true}>
        <FormControl className={classes.TextField} variant="outlined">
          <Box display="flex">
            <Box width={checkboxWidth ? checkboxWidth : '40px'} height={checkboxHeight ? checkboxHeight : '40px'}>
              <Checkbox
                id={id}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                color={color ? color : 'default'}
                className={InputClasses ? InputClasses : ''}
                classes={checkboxClasses}
                icon={icon}
                checkedIcon={checkedIcon}
                inputProps={{
                  'data-testid': id
                }}
              />
            </Box>
            {checkBoxSuffix}
          </Box>
        </FormControl>
      </Grid>
    </Box>
  );
};

export const CheckBoxSuffixField = (props) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.TextField}>
      <TextField
        classes={{ root: `${classes.input} ${props.InputClasses ? props.InputClasses : ''}` }}
        variant="outlined"
        id={props.id}
        onChange={props.onChange}
        type={props.type}
        value={props.value}
        multiline={props.multiline}
        rows={props.rows}
        placeholder={props.placeholder}
        helperText={props.helperText}
        margin="none"
        disabled={props.disabled}
      />
    </FormControl>
  );
};

export default CheckBoxField;
