import { Box, Button, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useRef } from 'react';

import { ReactComponent as ErrorInformationIcon } from '@/assets/images/error-information.svg';
import BasicModal from '@/components/common/BasicModal';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import type { PaymentError } from '@/types/payments.types';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  messageText: string;
  buttonText: string;
  trackingErrorCode: PaymentError;
};

const StyledBasicModal = styled(BasicModal)(({ theme: muiTheme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: muiTheme.palette.background.paper,
  borderRadius: '20px',
  width: '330px',
  outline: 'none'
}));

const StyledButton = styled(Button)({
  textTransform: 'inherit',
  fontSize: '1rem',
  marginTop: 20,
  alignSelf: 'end'
});

export default function PaymentFailureModal({
  isModalOpen,
  onClose,
  messageText,
  buttonText,
  trackingErrorCode
}: Props) {
  const theme = useTheme();
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  const isFailureTrackingTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (!isFailureTrackingTriggered.current) {
      const trackingData = JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}');

      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_ERROR, {
        id: GoogleAnalyticsEventId.PAYMENT_DETAILS_ERROR,
        payment_gateway: 'eway',
        error_code: trackingErrorCode,
        error_message: messageText,
        status: 'payment_error',
        ...trackingData
      });

      isFailureTrackingTriggered.current = true;
    }
  }, [sendGoogleAnalyticsEvent, trackingErrorCode, messageText]);

  return (
    <StyledBasicModal open={isModalOpen} handleClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center" p={5} pt={7}>
        <ErrorInformationIcon />
        <Typography color={theme.palette.error.main} fontWeight={600} fontSize={'1.6rem'} mt={5}>
          Payment failed
        </Typography>
        <br />
        <Typography>{messageText}</Typography>
        <StyledButton color="primary" onClick={onClose}>
          {buttonText}
        </StyledButton>
      </Box>
    </StyledBasicModal>
  );
}
