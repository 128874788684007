import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import type { ArrayPath, Control, FieldValues } from 'react-hook-form';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import RemoveTimeButton from '../../AvailabilityScheduler/components/RemoveTimeButton';
import { getDateSlotRange } from '../../AvailabilityScheduler/utils/get-date-slot-range';

import TimeRangeField from './TimeRangeField';

/**
 * DayRanges component
 *
 * @remarks
 * Renders a range of time ranges for a certain date (or day of the week)
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DayRanges'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/Schedule.tsx
 *
 * @param name - A string for the unique field
 * @param disabled - A boolean for whether the range is switched off
 * @param control - React-hook-form control. This object contains methods for registering components into React Hook Form
 *
 * @returns JSXElement
 *
 */

export const DayRanges = <TFieldValues extends FieldValues>({
  name,
  disabled,
  control
}: {
  name: ArrayPath<TFieldValues>;
  control?: Control<TFieldValues>;
  disabled?: boolean;
}) => {
  const { getValues } = useFormContext();

  const {
    remove,
    fields = [],
    append,
    prepend
  } = useFieldArray<any>({
    control,
    name
  });

  return (
    <>
      {fields.map((field, index: number) => {
        return (
          <Box display="flex" key={field.id}>
            <Controller
              name={`${name}.${index}`}
              render={({ field: fieldItem }) => {
                return <TimeRangeField {...fieldItem} />;
              }}
            />
            {index === 0 && (
              <Button
                disabled={disabled}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const slotRange: any = getDateSlotRange(
                    getValues(`${name}.${fields.length - 1}`),
                    getValues(`${name}.0`)
                  );

                  if (slotRange?.append) {
                    append(slotRange.append);
                  }

                  if (slotRange?.prepend) {
                    prepend(slotRange.prepend);
                  }
                }}
              >
                <AddIcon />
              </Button>
            )}
            {index !== 0 && <RemoveTimeButton index={index} remove={remove} />}
          </Box>
        );
      })}
    </>
  );
};

export default DayRanges;
