import type { ConsultationFields } from '@/components/PatientPageV2/ConsultationTab.types';
import { ModalChooseIhiNumber } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalChooseIhiNumber/ModalChooseIhiNumber';
import { ModalSelectMultipleIhiFields } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalSelectMultipleIhiFields/ModalSelectMultipleIhiFields';
import { ModalUnableToVerifyIhi } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalUnableToVerifyIhi/ModalUnableToVerifyIhi';
import type {
  ModalParamsChooseIhiNumber,
  ModalParamsSelectMultipleFields,
  ModalParamsUnableToVerifyIhi,
  PatientVerifyIhiResponseData
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/hooks/usePatientIhi';

export type IHIModalsProps = {
  modalDataVerifyIhiNumber: PatientVerifyIhiResponseData;
  modalDataSelectMultipleFields: ModalParamsSelectMultipleFields;
  modalDataChooseIhiNumber: ModalParamsChooseIhiNumber;
  modalDataUnableToVerifyIhi: ModalParamsUnableToVerifyIhi;
  handleUpdateIhiDetails: () => void;
  handleCloseModalSelectMultipleFields: () => void;
  handleCloseModalConfirmIhiNumber: () => void;
  handleCloseModalUnableToVerify: () => void;
  patientPMSData: ConsultationFields;
};

/**
 * IhiVerifyAndUpdateModals
 *
 * This component handles the rendering of various modals related to IHI verification and updates.
 * It decides which modal to show based on the provided modal data props.
 *
 * @param {IHIModalsProps} props - The properties object.
 * @param {PatientVerifyIhiResponseData} props.modalDataVerifyIhiNumber - Data for verifying IHI number.
 * @param {ModalParamsSelectMultipleFields} props.modalDataSelectMultipleFields - Data for the ModalSelectMultipleIhiFields modal.
 * @param {ModalParamsChooseIhiNumber} props.modalDataChooseIhiNumber - Data for the ModalChooseIhiNumber modal.
 * @param {ModalParamsUnableToVerifyIhi} props.modalDataUnableToVerifyIhi - Data for the ModalUnableToVerifyIhi modal.
 * @param {() => void} props.handleUpdateIhiDetails - Handler for updating IHI details.
 * @param {() => void} props.handleCloseModalSelectMultipleFields - Handler for closing the ModalSelectMultipleIhiFields modal.
 * @param {() => void} props.handleCloseModalConfirmIhiNumber - Handler for closing the ModalChooseIhiNumber modal.
 * @param {() => void} props.handleCloseModalUnableToVerify - Handler for closing the ModalUnableToVerifyIhi modal.
 * @param {ConsultationFields} props.patientPMSData - The patient data from PMS.
 *
 * @returns {JSX.Element} The rendered component.
 */

export const IhiVerifyAndUpdateModals = (props: IHIModalsProps) => {
  const {
    modalDataSelectMultipleFields,
    modalDataChooseIhiNumber,
    modalDataUnableToVerifyIhi,
    handleUpdateIhiDetails,
    handleCloseModalSelectMultipleFields,
    handleCloseModalConfirmIhiNumber,
    handleCloseModalUnableToVerify,
    patientPMSData
  } = props;

  if (!patientPMSData) {
    return;
  }

  const showModalSelectMultipleIhiFields = Boolean(modalDataSelectMultipleFields?.patientVerifyIhiResponseData);
  const showModalChooseIhiNumber =
    Boolean(modalDataChooseIhiNumber?.newlyInputtedIhiNumber) &&
    Boolean(modalDataChooseIhiNumber?.patientVerifyIhiResponseData);
  const showModalDataUnableToVerifyIhi = Boolean(modalDataUnableToVerifyIhi?.newlyInputtedIhiNumber);

  return (
    <>
      {showModalSelectMultipleIhiFields && (
        <ModalSelectMultipleIhiFields
          patientVerifyIhiResponseData={modalDataSelectMultipleFields?.patientVerifyIhiResponseData}
          patientDetailsInPMS={patientPMSData}
          handleUpdateIhiDetails={handleUpdateIhiDetails}
          handleModalClose={handleCloseModalSelectMultipleFields}
        />
      )}
      {showModalChooseIhiNumber && (
        <ModalChooseIhiNumber
          unverifiedIhiNumber={modalDataChooseIhiNumber?.newlyInputtedIhiNumber}
          patientVerifyIhiResponseData={modalDataChooseIhiNumber?.patientVerifyIhiResponseData}
          patientDetailsInPMS={patientPMSData}
          handleUpdateIhiDetails={handleUpdateIhiDetails}
          handleModalClose={handleCloseModalConfirmIhiNumber}
        />
      )}
      {showModalDataUnableToVerifyIhi && (
        <ModalUnableToVerifyIhi
          unverifiedIhiNumber={modalDataUnableToVerifyIhi?.newlyInputtedIhiNumber}
          handleUpdateIhiDetails={handleUpdateIhiDetails}
          handleModalClose={handleCloseModalUnableToVerify}
        />
      )}
    </>
  );
};
