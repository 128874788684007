import { SearchTextField, Table } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import iziToast from 'izitoast';
import React, { useState } from 'react';

import CreateCalcomAccountButton from '@/components/calcom/CreateCalcomAccountButton';
import UpdateScheduleDrawer from '@/components/calcom/UpdateScheduleDrawer/UpdateScheduleDrawer';
import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { isAdminOnly, isManager, isSuperAdmin } from '@/data/service/authService';
import { useGetNurses } from '@/hooks/rest/useGetNurses';
import type { Nurse } from '@/types';
import { NurseTableColumnHeaders } from '@/types';
import dayjs from '@/utils/dayjs';

const StyledBox = styled(Box)({
  marginTop: 10,
  width: '100%'
});

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

/**
 * NursesTable component
 *
 * @remarks
 * Renders a table of Nurses for the Nurses page
 *
 * @returns JSXElement
 *
 */

export const NursesTable = () => {
  const [searchFilterText, setSearchFilterText] = useState('');
  const { createColumnDefinition } = useTableUtils();
  const { nurses, isLoading: isLoadingNurses, isError: isErrorNurses, refetch: refetchGetNurses } = useGetNurses();

  const resultsNotFoundMessage = searchFilterText.length
    ? 'No matching nurses found for that search'
    : 'No nurses found';

  if (isErrorNurses) {
    iziToast.error({
      title: 'Error!',
      message: 'There has been an error loading the Nurses',
      position: 'topRight'
    });
  }

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  const formatNurseName = (nurse: Nurse) => {
    return `${nurse.NurseUser.first_name} ${nurse.NurseUser.last_name}`;
  };

  const formattedJoinedDate = (nurse: Nurse) => {
    return dayjs(nurse.NurseUser.created_date).format('DD-MMM-YYYY');
  };

  const formatCalcomIDAndButton = (nurse: Nurse) => {
    if (nurse.calcom_nurse_id) {
      return <Box data-testid={`${nurse.NurseUser.id}_nurse_calcomId`}>{nurse.calcom_nurse_id}</Box>;
    }

    return (
      <CreateCalcomAccountButton
        isNurse
        practitioner={{
          id: nurse.NurseUser.id,
          name: `${nurse.NurseUser.first_name} ${nurse.NurseUser.last_name}`
        }}
        refetchTableData={refetchGetNurses}
      />
    );
  };

  const formatCalcomSchedule = (nurse: Nurse) => {
    if (!nurse.calcom_nurse_id) {
      return null;
    }

    const formattedUser = {
      userType: 'nurse',
      name: `${nurse.NurseUser.first_name} ${nurse.NurseUser.last_name}`,
      calcom_id: nurse.calcom_nurse_id
    };

    return <UpdateScheduleDrawer user={formattedUser} />;
  };

  const formattedNurses = nurses?.map((nurse: Nurse) => {
    return {
      nurse_code: nurse.nurse_code,
      nurse_name: formatNurseName(nurse),
      nurse_email: nurse.NurseUser.email,
      joined_date: formattedJoinedDate(nurse),
      phone: nurse.NurseUser.phone,
      state: nurse.NurseUser.State?.name,
      coviu_link: nurse.coviu_url,
      calcom_id: formatCalcomIDAndButton(nurse),
      calcom_schedule: formatCalcomSchedule(nurse)
    };
  });

  const columns = [
    createColumnDefinition('nurse_code', NurseTableColumnHeaders.NurseCode),
    createColumnDefinition('nurse_name', NurseTableColumnHeaders.Name),
    createColumnDefinition('nurse_email', NurseTableColumnHeaders.Email),
    createColumnDefinition('joined_date', NurseTableColumnHeaders.JoinedDate),
    createColumnDefinition('phone', NurseTableColumnHeaders.Phone),
    createColumnDefinition('state', NurseTableColumnHeaders.State),
    createColumnDefinition('coviu_link', NurseTableColumnHeaders.CoviuLink),
    createColumnDefinition('calcom_id', NurseTableColumnHeaders.CalcomId),
    createColumnDefinition('calcom_schedule', NurseTableColumnHeaders.CalcomSchedule)
  ];

  return (
    <StyledBox>
      <SearchTextField
        handleChange={handleSearchOnChange}
        handleClear={handleClearSearchFilter}
        labelText="Search"
        enableClear={true}
      />
      <StyledTable
        data={formattedNurses || []}
        columns={columns}
        isLoading={isLoadingNurses}
        pageSize={20}
        globalFilter={searchFilterText.trim()}
        noDataFoundMessage={resultsNotFoundMessage}
        columnVisibility={{
          email: !isAdminOnly(),
          state: !isAdminOnly(),
          joined_date: !isAdminOnly(),
          calcom_id: isSuperAdmin() || isManager(),
          calcom_schedule: isSuperAdmin() || isManager()
        }}
        showPagination
        pageSizeOptions={[5, 10, 20, 50]}
      />
    </StyledBox>
  );
};
