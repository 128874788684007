import { styled, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import { useMemo, useState } from 'react';

import type { TableRowData } from '@/components/rescripting/RescriptingPage.types';
import {
  createHeaderCells,
  formatDataForTable,
  getDefaultAlternativeProduct,
  makeApprovedPrescriptionChangeset,
  makeNewPrescriptionProduct,
  scriptProductToRescriptProduct,
  sortTableData
} from '@/components/rescripting/RescriptingPage.utils';
import { FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useRescriptAlternatives } from '@/hooks/rescripting/useRescriptAlternatives';
import { useGetPrescriptionsWithOOSProducts } from '@/hooks/rest/useGetPrescriptionsWithOOSProducts';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import type { Rescript } from '@/hooks/rest/usePutPrescriptBatchReplace';
import usePutPrescriptBatchReplace from '@/hooks/rest/usePutPrescriptBatchReplace';

import RescriptingTable from './components/RescriptingTable';
import { RescriptingTableFilter } from './components/RescriptingTableFilter';
import { useHandleRescriptingPremission } from './hooks/useHandleRescriptingPermissions';
import { RescriptingPageContent } from './RescriptingPage.styled';

const NoticeList = styled('ul')({
  margin: '0.5rem 2rem'
});

const SELECT_ALTERNATIVE_PRODUCT_LABEL_ID = 'selectedAlternativeProductId';
const DOCTOR_NAME_LABEL_ID = 'doctorName';

const outOfStockLabels: { id: keyof TableRowData; tableHeading: string }[] = [
  { id: 'orderCode', tableHeading: 'Order Code' },
  { id: 'patientName', tableHeading: 'Patient Name' },
  { id: 'patientDob', tableHeading: 'Patient DOB' },
  { id: 'patientCode', tableHeading: 'Patient Code' },
  { id: 'ossProductName', tableHeading: 'Out of Service Product' },
  { id: 'scriptCreationDate', tableHeading: 'Script Creation' },
  { id: 'allProductsOnScript', tableHeading: 'Script Products' },
  { id: 'totalCount', tableHeading: 'Total Count' },
  { id: SELECT_ALTERNATIVE_PRODUCT_LABEL_ID, tableHeading: 'Alternative Product' },
  { id: DOCTOR_NAME_LABEL_ID, tableHeading: 'Doctor' }
];

const outOfStockHeaderCells = createHeaderCells(outOfStockLabels);

export default function RescriptingOutOfStockPage() {
  const [modifiedScripts, setModifiedScripts] = useState<Map<string, CatalogProduct>>(new Map());
  const [selectedPatientCodes, setSelectedPatientCodes] = useState<string[]>([]);
  const [selectedOOSProducts, setSelectedOOSProducts] = useState<string[]>([]);
  const [selectedDoctorNames, setSelectedDoctorNames] = useState<string[]>([]);
  const { flags } = useFeatureFlags();
  const ffEnableProductIssueEnquiries = flags[FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES];

  const {
    doPut: putPrescriptionBatchReplace,
    loading: putPrescriptionLoading,
    error: putPrescriptionError,
    data: putPrescriptionData
  } = usePutPrescriptBatchReplace();

  // Fetch scripts, filter and format as needed
  const {
    data: prescriptionData,
    loading: prescriptionRequestLoading,
    error: prescriptionRequestError,
    doGet: doGetPrescriptions
  } = useGetPrescriptionsWithOOSProducts({
    limit: 10000
  });
  const outOfStockPrescriptions = prescriptionData?.prescriptions;
  let tableData = outOfStockPrescriptions ? formatDataForTable(outOfStockPrescriptions, modifiedScripts) : [];

  if (ffEnableProductIssueEnquiries) {
    tableData = tableData = sortTableData(tableData);
  }

  const patientCodeSearchOptions = new Set(tableData.map((s) => s.patientCode || ''));
  const oosProductOptions = Array.from(new Set(tableData.map((s) => s.ossProductName)));
  const doctorNameSearchOptions = new Set(tableData.map((s) => s.doctorName || ''));

  const handleSelectedOOSProducts = (currentlySelectedProducts: string[]) => {
    setSelectedOOSProducts(currentlySelectedProducts);
  };

  const filteredTableData =
    selectedPatientCodes.length > 0
      ? tableData.filter((script) => selectedPatientCodes.includes(script.patientCode || ''))
      : tableData;

  // Fetch product list that will be used within the dropdowns
  const { parsed: productList, loading: productRequestLoading, error: productRequestError } = useRescriptAlternatives();

  const filteredTableDataByOOSProduct =
    selectedOOSProducts.length > 0
      ? filteredTableData.filter(
          (script) => script.ossProductName !== null && selectedOOSProducts.includes(script.ossProductName)
        )
      : filteredTableData;

  const filteredTableDataByDoctors =
    selectedDoctorNames.length > 0
      ? filteredTableDataByOOSProduct.filter(
          (script) => script.doctorName !== null && selectedDoctorNames.includes(script.doctorName)
        )
      : filteredTableDataByOOSProduct;

  const approvalSuccess = Boolean(putPrescriptionData);
  const loading = prescriptionRequestLoading || productRequestLoading || putPrescriptionLoading;
  const { shouldShowErrorAlert, isAbleToActionScriptCreation } = useHandleRescriptingPremission({
    isGetPrescriptionError: Boolean(prescriptionRequestError),
    isPutPrescriptionError: Boolean(putPrescriptionError),
    isGetProductsError: Boolean(productRequestError)
  });

  const headerCells = useMemo(
    () =>
      isAbleToActionScriptCreation
        ? outOfStockHeaderCells.filter((cell) => cell.id !== DOCTOR_NAME_LABEL_ID)
        : outOfStockHeaderCells.filter((cell) => cell.id !== SELECT_ALTERNATIVE_PRODUCT_LABEL_ID),
    [isAbleToActionScriptCreation]
  );

  async function submitApprovedPrescriptions(prescriptions: Rescript[]) {
    const { data } = await putPrescriptionBatchReplace({ rescriptType: 'OUT_OF_STOCK', prescriptions });
    if (data) {
      // Reload table data
      // TODO: Use setData pattern with useGetPrescriptions for better efficiency
      doGetPrescriptions();
    }
  }

  // Main action for selected items (currently Approve Selected)
  const handleApproval = async (approvedPrescriptions: string[]) => {
    const originalScriptPerId = new Map(prescriptionData?.prescriptions.map((script) => [script.id, script]));
    const approvedScriptChangeset = makeApprovedPrescriptionChangeset(approvedPrescriptions, modifiedScripts);
    const resultArray: Rescript[] = [];

    approvedScriptChangeset.forEach((approvedProductChangeset, scriptId) => {
      const originalScript = originalScriptPerId.get(scriptId);
      const selectedOutOfStockScriptProducts =
        originalScript?.Products.filter((product) =>
          Array.from(approvedProductChangeset.keys()).includes(product.id)
        ) || [];
      const transformedSelectedOutOfStockScriptProducts =
        selectedOutOfStockScriptProducts.map(scriptProductToRescriptProduct);
      const newScriptProducts = [...transformedSelectedOutOfStockScriptProducts];

      approvedProductChangeset.forEach((modifiedScriptProduct, productId) => {
        const currentIndex = newScriptProducts.findIndex((rescriptProduct) => rescriptProduct.productId === productId);
        const currentRescriptProduct = newScriptProducts[currentIndex];
        const currentScriptProduct = selectedOutOfStockScriptProducts.find(
          (scriptProduct) => scriptProduct.id === productId
        );
        const defaultAlternativeProduct = getDefaultAlternativeProduct(currentScriptProduct);
        newScriptProducts[currentIndex] = makeNewPrescriptionProduct(
          currentRescriptProduct,
          modifiedScriptProduct,
          defaultAlternativeProduct,
          true
        );
      });
      resultArray.push({
        originalPrescriptionId: scriptId,
        newPrescription: {
          orderDate: moment().toISOString(),
          patientId: originalScript?.patient_id,
          // Use doctor id from original script.
          doctorId: originalScript?.doctor_id,
          products: newScriptProducts
        }
      });
    });

    await submitApprovedPrescriptions(resultArray);
  };

  // Handle product in product list being selected.
  const handleProductSelected = (uniqueKey: string, selectedProduct: CatalogProduct | undefined) => {
    // Store changes to script products
    if (selectedProduct) {
      setModifiedScripts((prevMap) => new Map(prevMap).set(uniqueKey, selectedProduct));
    }
  };

  return (
    <RescriptingPageContent>
      <Box pt={4} pb={8} paddingX={2}>
        <Typography variant="body1">
          Please review the following prescriptions, which contain an out of stock medicine. In most cases, a
          substitution suggestion has been provided, based on comparable pharmacological features.
          <br />
          You are able to change the suggested replacement. Approving the OOS prescription will trigger the following
          actions:
          <br />
          <br /> A new script will be created, which will have the following features
        </Typography>
        <Typography component="div">
          <NoticeList>
            <li>Quantity 2</li>
            <li>Zero Repeats</li>
            <li>30 day expiry</li>
          </NoticeList>
          This will not effect the original script in any way.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" fontSize="24px" mb="1" fontWeight={600}>
          Out of stock scripts
        </Typography>
        <Box display="flex" gap={3} paddingY={2}>
          <Box flex={1}>
            <RescriptingTableFilter
              label="Filter patient codes"
              placeholder="Start typing to search"
              options={Array.from(patientCodeSearchOptions)}
              onSelect={setSelectedPatientCodes}
            />
          </Box>
          <Box flex={1}>
            <RescriptingTableFilter
              label="Filter product names"
              placeholder="Start typing to search"
              options={oosProductOptions.filter((option): option is string => option !== null)}
              onSelect={handleSelectedOOSProducts}
            />
          </Box>
          {!isAbleToActionScriptCreation && (
            <Box flex={1}>
              <RescriptingTableFilter
                label="Doctors"
                placeholder="Start typing to search"
                options={Array.from(doctorNameSearchOptions)}
                onSelect={setSelectedDoctorNames}
              />
            </Box>
          )}
        </Box>
        {approvalSuccess && (
          <Alert sx={{ mb: 1 }} severity="success">
            Patient scripts successfully created
          </Alert>
        )}
        {shouldShowErrorAlert && (
          <Alert sx={{ mb: 1 }} severity="error">
            An error has occurred.
          </Alert>
        )}
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <RescriptingTable
            tableData={filteredTableDataByDoctors}
            productListData={productList}
            handleApproval={handleApproval}
            handleProductSelected={handleProductSelected}
            mainHeadCells={headerCells}
            canActionScriptCreation={isAbleToActionScriptCreation}
          />
        )}
      </Box>
    </RescriptingPageContent>
  );
}
