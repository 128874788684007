import { PARCEL_LOCKER_ERROR_MESSAGE, validateParcelLocker } from '@montu-web/utilities';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import useStyles from '../assets/js/patientsV2';
import '../assets/scss/onboardPatients.scss';
import DateField from '../components/fields/DateField';
import InputField from '../components/fields/InputField';
import SearchableSelect from '../components/fields/SearchableSelect';
import { makeGET, makePOST } from '../data/service/dataService';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.path[0] === 'dob') {
      return { message: 'Date of Birth is required' };
    }
  }

  return { message: ctx.defaultError };
};

const formSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  gender: z.string().min(1, 'Gender is required'),
  phone: z.string().min(1, 'Phone number is required'),
  email: z.string().min(1, 'Email is required ').email('Enter a valid email'),
  streetLine1: z
    .string()
    .min(1, 'Street Address is required')
    .refine((val) => validateParcelLocker(val), {
      message: PARCEL_LOCKER_ERROR_MESSAGE
    }),
  streetLine2: z.string().optional(),
  suburb: z.string().min(1, 'Suburb is required'),
  postcode: z.string().min(1, 'Post code is required'),
  stateId: z.string().min(1, 'State is required').or(z.number()),
  dob: z.object({ _isValid: z.boolean() }).refine((data) => data._isValid, 'Date of Birth is invalid')
});

const OnBoardPatient = () => {
  z.setErrorMap(customErrorMap);

  const navigate = useNavigate();
  const classes = useStyles() as any;
  const [getData, setGetData] = useState({
    states: []
  });

  interface SendBody {
    first_name: string;
    last_name: string;
    email: string;
    gender: string;
    phone: string;
    address: string;
    dob: DateTime;
    city: string;
    zip_code: string;
    state_id: number;
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      gender: '',
      phone: '',
      email: '',
      street: '',
      suburb: '',
      postcode: '',
      streetLine1: '',
      streetLine2: '',
      stateId: '',
      dob: ''
    },
    validate: (values) => {
      try {
        formSchema.parse(values);
      } catch (error) {
        if (error instanceof z.ZodError) {
          return error.flatten().fieldErrors;
        }
      }
      return {};
    },
    onSubmit: async (values) => {
      const sendBody: SendBody = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        gender: values.gender,
        phone: values.phone,
        address: `${values.streetLine1}$$$$${values.streetLine2}`,
        // @ts-expect-error until we can remove moment date from date picker
        dob: DateTime.fromJSDate(values.dob.toDate()).setZone('utc', {
          keepLocalTime: true
        }),
        city: values.suburb,
        zip_code: values.postcode,
        state_id: Number(values.stateId)
      };
      // @ts-expect-error until we can move this from makePOST to fetch
      await makePOST('patient/create', sendBody, 'onBoardPatient');
      navigate('/patients');
    }
  });

  const handleDropdownChange = (selectedOption: { label: string; value: number }) => {
    formik.setFieldValue('stateId', selectedOption.value);
  };

  const handleDatePickerChange = (value: Date) => {
    formik.setFieldValue('dob', value);
  };

  const fetchData = async () => {
    const states: Array<{ id: number; name: string }> | null = await makeGET('location/states');
    if (!states) {
      return;
    }
    setGetData({
      states
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const inputFieldRowFlexDirection = {
    xs: 'column',
    sm: 'row'
  };

  const inputFieldGroupWidth = {
    xs: '100%',
    md: '50%'
  };

  return (
    <Box px={4}>
      <Box mt={5} mb={1} textAlign="left">
        <Typography variant="h5" color="textPrimary">
          Onboard New Patient
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box maxWidth="1024px" className="less-padding">
          {/* Patient Section */}
          <Box mt={5} component="p" className={classes.heading}>
            Patient
          </Box>
          {/*  @ts-expect-error ignore flexDirection */}
          <Box mt={1} mb={2} display="flex" flexDirection={inputFieldRowFlexDirection}>
            <Grid container width={inputFieldGroupWidth} pr={2}>
              <InputField
                id="firstName"
                type="text"
                label="First Name *"
                labelPadding="7px 5px"
                className={classes.inputField}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />

              <DateField
                id="dob"
                labelPadding="7px 5px"
                label="DoB *"
                variant="outlined"
                value={formik.values.dob}
                onChange={handleDatePickerChange}
                touched={true}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
            </Grid>

            <Grid container width={inputFieldGroupWidth} pr={2}>
              <InputField
                id="lastName"
                label="Last Name *"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <InputField
                id="gender"
                label="Gender *"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              />
            </Grid>
          </Box>
          {/* Contact Details Section */}
          <Box mt={2} component="p" className={classes.heading}>
            Contact Details
          </Box>
          {/*  @ts-expect-error ignore flexDirection */}
          <Box mt={1} mb={2} display="flex" flexDirection={inputFieldRowFlexDirection}>
            <Grid container width={inputFieldGroupWidth} pr={2}>
              <InputField
                id="phone"
                label="Phone *"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>

            <Grid container width={inputFieldGroupWidth} pr={2}>
              <InputField
                id="email"
                label="Email *"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Box>
          {/* Address Section */}
          <Box component="p" className={classes.heading}>
            Address
          </Box>
          {/*  @ts-expect-error ignore flexDirection */}
          <Box mt={1} mb={2} display="flex" flexDirection={inputFieldRowFlexDirection}>
            <Grid container width={inputFieldGroupWidth} pr={2}>
              <InputField
                id="streetLine1"
                label="Street Address *"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.streetLine1}
                onChange={formik.handleChange}
                error={formik.touched.streetLine1 && Boolean(formik.errors.streetLine1)}
                helperText={formik.touched.streetLine1 && formik.errors.streetLine1}
              />

              <InputField
                id="streetLine2"
                label="Address Line 2"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.streetLine2}
                onChange={formik.handleChange}
              />

              <InputField
                id="suburb"
                label="Suburb *"
                type="text"
                labelPadding="7px 5px"
                value={formik.values.suburb}
                onChange={formik.handleChange}
                error={formik.touched.suburb && Boolean(formik.errors.suburb)}
                helperText={formik.touched.suburb && formik.errors.suburb}
              />
            </Grid>

            <Grid container width={inputFieldGroupWidth} pr={2}>
              {/* State selection */}
              <Box component={Grid} width="100%" display="flex" m={1} p={0}>
                <Grid xs={4} item={true}>
                  <Box component="div" textAlign={'left'} p={'7px 5px'} fontWeight={600} style={{ display: 'flex' }}>
                    <label htmlFor="stateId">State *</label>
                  </Box>
                </Grid>
                <Grid xs={8} item={true}>
                  <Box p={0} width="100%" display="flex">
                    <SearchableSelect
                      id="stateId"
                      label="State *"
                      options={getData.states}
                      labelPadding="7px 5px"
                      value={formik.values.stateId}
                      onChange={handleDropdownChange}
                      varient="outlined"
                      lessPadding
                      margin={0}
                      padding={0}
                      customGrid={[null, 12]}
                      error={formik.touched.stateId && Boolean(formik.errors.stateId)}
                      touched={true}
                      helperText={formik.touched.stateId && formik.errors.stateId}
                    />
                  </Box>
                </Grid>
              </Box>
              <InputField
                id="postcode"
                label="PostCode *"
                type="text"
                labelPadding="7px 5px"
                InputClasses="custom-address-field"
                margin="8px 8px 0px 8px"
                value={formik.values.postcode}
                onChange={formik.handleChange}
                error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                helperText={formik.touched.postcode && formik.errors.postcode}
              />
            </Grid>
          </Box>
          <Box textAlign="right" marginTop="30px" height="30px">
            <Button type="submit" variant="contained" color="secondary">
              Onboard Patient
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default OnBoardPatient;
