import type { TransferListItem } from '@montugroup/design-system';
import { findIntersection, removeItemsByIntersection } from '@montugroup/design-system';
import { useEffect, useState } from 'react';

const useTransferList = (leftSideArray: TransferListItem[], rightSideArray: TransferListItem[]) => {
  const [checkedItems, setCheckedItems] = useState<TransferListItem[]>([]);
  const [leftSideItems, setLeftSideItems] = useState<TransferListItem[]>([]);
  const [rightSideItems, setRightSideItems] = useState<TransferListItem[]>([]);

  useEffect(() => {
    if (!leftSideItems?.length && !rightSideItems?.length) {
      setLeftSideItems(leftSideArray);
      setRightSideItems(rightSideArray);
    }
  }, [leftSideArray, leftSideItems, rightSideArray, rightSideItems]);

  const handleMoveAllLeftOnClick = () => {
    setLeftSideItems(leftSideItems.concat(rightSideItems));
    setRightSideItems([]);
    setCheckedItems([]);
  };

  const handleMoveAllRightOnClick = () => {
    setLeftSideItems([]);
    setRightSideItems(rightSideItems.concat(leftSideItems));
    setCheckedItems([]);
  };

  const handleMoveCheckedLeftOnClick = () => {
    const rightChecked = findIntersection(checkedItems, rightSideItems);
    setLeftSideItems(leftSideItems.concat(rightChecked));
    setRightSideItems(removeItemsByIntersection(rightSideItems, rightChecked));
    setCheckedItems([]);
  };

  const handleMoveCheckedRightOnClick = () => {
    const leftChecked = findIntersection(checkedItems, leftSideItems);
    setLeftSideItems(removeItemsByIntersection(leftSideItems, leftChecked));
    setRightSideItems(rightSideItems.concat(leftChecked));
    setCheckedItems([]);
  };

  const handleItemOnClick = (item: TransferListItem) => {
    const currentIndex = checkedItems.indexOf(item);
    const updatedCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      updatedCheckedItems.push(item);
    } else {
      updatedCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(updatedCheckedItems);
  };

  return {
    checkedItems,
    leftSideItems,
    rightSideItems,
    handleMoveAllLeftOnClick,
    handleMoveAllRightOnClick,
    handleMoveCheckedLeftOnClick,
    handleMoveCheckedRightOnClick,
    handleItemOnClick
  };
};

export default useTransferList;
