import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import { TimelineSchedulerDoctors } from '@/components/calendar/TimelineScheduler/TimelineSchedulerDoctors';
import { useGetDoctors } from '@/hooks/rest/useGetDoctors';

export const DoctorScheduler = () => {
  const { data: doctors, isError: isErrorGetDoctors } = useGetDoctors();

  return (
    <Box padding={5}>
      <Typography variant={'h5'}>Doctor Scheduler</Typography>
      {!isErrorGetDoctors ? (
        <TimelineSchedulerDoctors doctors={doctors} />
      ) : (
        <Box m={10}>
          <Alert severity="error">{isErrorGetDoctors.toString()}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default DoctorScheduler;
