import { Stack } from '@mui/material';
import type { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  tabIndex: number;
  selectedTabIndex: number;
}

const SupportTabContent = ({ children, tabIndex, selectedTabIndex }: Props) => (
  <div hidden={tabIndex !== selectedTabIndex} key={selectedTabIndex}>
    {tabIndex === selectedTabIndex && (
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={6} sx={{ p: 4, pb: 12 }}>
        {children}
      </Stack>
    )}
  </div>
);

export default SupportTabContent;
