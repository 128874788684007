import type { ConcessionCardType } from '../../components/concession';
import settings from '../../data/constants';

import type { ConcessionCardReponse } from './types';
import useRequest from './useRequest';

type UsePutPatientConcessionCardPayload = {
  id?: string | number;
  cardType: ConcessionCardType;
  // Required if not ATSI type...
  cardNumber?: string;
  expiryYear?: number;
  expiryMonth?: number;
  expiryDay?: number;
  documentIdentifier?: string;
};

export type UsePutPatientConcessionCardResponse = ConcessionCardReponse;

type UsePutPatientConcessionCardProps = {
  patientId: number | string;
};

export function usePutPatientConcessionCard({ patientId }: UsePutPatientConcessionCardProps) {
  const { loading, data, error, execute } = useRequest<UsePutPatientConcessionCardResponse>({
    config: {
      url: `${settings.url}/patient/${patientId}/concession-card`,
      method: 'PUT'
    }
  });

  const doPut = async (body: UsePutPatientConcessionCardPayload) => {
    return execute({ data: body });
  };

  return {
    loading,
    data,
    error,
    doPut
  };
}

export default usePutPatientConcessionCard;
