import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

import tagImage from '../../../assets/images/product_tag_concession.png';

export type ConcessionProductCatalogueChipProps = Omit<BoxProps, 'component' | 'src' | 'alt'>;

export function ConcessionProductCatalogueChip({ ...rest }: ConcessionProductCatalogueChipProps) {
  return <Box component="img" src={tagImage} alt="Concession" {...rest} sx={{ width: 125 }} />;
}

export default ConcessionProductCatalogueChip;
