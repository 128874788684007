import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import type { SelectProps } from '@mui/material';
import { Chip, IconButton, Radio, Stack, TextField, styled } from '@mui/material';
import type { MouseEvent, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { isStockDecoration } from '@/api';

import Image from '../Image/Image';
import ProductCard from '../ProductCard/ProductCard';
import type { ProductCardProps } from '../ProductCard/ProductCard.types';

import { FullWidthStack, StyledMenuItem, StyledStack } from './ProductSelection.styles';

export type ProductSelectionProps<T extends ProductCardProps> = {
  className?: string;
  value?: T | null;
  options: T[];
  /**
   * Invoked every time the value changes
   * @param value
   * @returns
   */
  onChange?: (value: T) => void;
  /**
   * Invoked every time a value is selected. This is different from onChange, in that
   * this is only invoked if the user opens a list of selection and manually made a VALID selection
   *
   * This will fire before `onChange`
   */
  onSelect?: (value: T) => void;
  label?: string;
  bottomElement?: ReactNode[];
  onPreviewIconClick?: (id: T) => void;
} & Pick<SelectProps<T>, 'MenuProps' | 'name' | 'open' | 'onClose'>;

const ProductTagChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fontWeight: '600'
}));

/**
 * A type of MUI Select component that renders multiple product card as menu items
 *
 * @param value - selected value from the list
 * @param options - list of options to create the menu items
 * @param onChange - callback fired when a menu item is selected
 * @param label - label that needs to be displayed in the select box
 * @param onPreviewIconClick - callback fired when clicking the preview icon
 * @param bottomElement - element block for embedding custom elements
 * @param MenuProps - props applied to the Menu element
 * @param name - name attribute of the input
 *
 * @returns JSX Element
 *
 */

export default function ProductSelection<T extends ProductCardProps>({
  value,
  options,
  onChange,
  onSelect,
  label = 'Medication',
  onPreviewIconClick,
  bottomElement,
  MenuProps,
  open,
  name,
  onClose
}: ProductSelectionProps<T>) {
  // use FF to accommodate different behaviors between ProductDetailsCard and PrescriptionItemEditor
  const initialValue = value || options[0];
  const [selectedProduct, setSelectedProduct] = useState<T | null>(initialValue);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(value?.id || null);

  useEffect(() => {
    if (!value) {
      setSelectedProduct(null);
      setSelectedProductId(null);
      return;
    }

    const optionMatchingValue = options.find((productOption) => productOption.id === value.id) || null;
    if (value === selectedProduct) {
      return;
    }
    if (optionMatchingValue) {
      setSelectedProduct(() => optionMatchingValue);
      setSelectedProductId(() => optionMatchingValue.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  useEffect(() => {
    if (onChange && selectedProduct) {
      onChange(selectedProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const handleProductSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    // MUI will return option index instead of the option itself
    const { value: targetValue } = e.target;
    const optionProductId = Number(targetValue);
    const chosenOption = options.find((optionProduct) => optionProduct.id === optionProductId);
    if (chosenOption) {
      onSelect?.(chosenOption);
    }
    setSelectedProduct(() => chosenOption || null);
    setSelectedProductId(() => optionProductId);
  };

  const handleViewDescriptionIcon = (e: MouseEvent<HTMLButtonElement>, selectedOption: T) => {
    e.stopPropagation();
    if (onPreviewIconClick) {
      onPreviewIconClick(selectedOption);
    }
  };

  return (
    <TextField
      fullWidth
      select
      name={name}
      value={selectedProductId || ''}
      size="small"
      SelectProps={{
        open,
        renderValue: () => selectedProduct && <ProductCard {...selectedProduct} />,
        onClose,
        // TODO find a better way to make the radio feedback more accessible
        MenuProps
      }}
      onChange={handleProductSelection}
      disabled={!options.length}
      label={label}
      data-dd-privacy="allow"
    >
      {options.map((option) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { id, productImage, price: _, ...rest } = option;
        return (
          <StyledMenuItem
            key={id}
            value={id}
            disableRipple
            disableTouchRipple
            disabled={isStockDecoration(rest.decoration)}
          >
            <FullWidthStack direction="row">
              <Stack justifyContent="center" mr={4}>
                <Image src={productImage} altSrc="/images/product_image_unavailable.png" width={60} height={60} />
              </Stack>
              <StyledStack direction="row">
                <FullWidthStack>
                  <ProductCard id={id} {...rest} />
                </FullWidthStack>
                <Stack alignItems="end">
                  {rest.isConcessionProduct && <ProductTagChip label="Concession" size="small" />}
                  <Stack direction="row" alignItems="flex-end">
                    <IconButton aria-label="Preview" onClick={(e) => handleViewDescriptionIcon(e, option)}>
                      <VisibilityOutlined />
                    </IconButton>
                    <Radio checked={selectedProduct?.id === id} />
                  </Stack>
                </Stack>
              </StyledStack>
            </FullWidthStack>
          </StyledMenuItem>
        );
      })}
      {
        // TODO - refactor this, and consider using `MenuProps.PaperProps` to append child elements OUTSIDE of `ul`, as this isn't part of the select "options"
        (bottomElement || []).map((element, index) => (
          <Stack key={index} my={2} component="li" width="100%">
            {element}
          </Stack>
        ))
      }
    </TextField>
  );
}
