import { useCallback, useEffect, useState } from 'react';

import { getAuthData } from '@/data/service/authService';
import { useFeatureTutorialState } from '@/hooks/useFeatureTutorialState';

export const useShowInventoryInsightsTutorial = () => {
  const [openTutorialModal, setOpenTutorialModal] = useState<boolean>(false);
  const { user } = getAuthData();
  const { shouldShowTutorialForFeature, setShouldShowTutorialForFeature } = useFeatureTutorialState({
    userId: user?.id,
    roleId: user?.role_id
  });

  useEffect(() => {
    if (shouldShowTutorialForFeature === true) {
      // TODO - replace with actual modal, this is just an example
      setOpenTutorialModal(true);
    }
  }, [shouldShowTutorialForFeature, setShouldShowTutorialForFeature]);

  const handleWatchLaterAndDismissModal = useCallback(() => {
    // TODO: set to show again later when we sort out the state issue
    setShouldShowTutorialForFeature(false);
    setOpenTutorialModal(false);
  }, [setShouldShowTutorialForFeature, setOpenTutorialModal]);

  const handleWatchNow = useCallback(async () => {
    setShouldShowTutorialForFeature(false);
    setOpenTutorialModal(false);
  }, [setShouldShowTutorialForFeature, setOpenTutorialModal]);

  return {
    openTutorialModal,
    setOpenTutorialModal,
    /**
     * Dismiss the modal, but will show it again later
     */
    handleWatchLaterAndDismissModal,
    /**
     * Dismiss the modal, and set to NOT show again
     */
    handleWatchNow
  };
};
