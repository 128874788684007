import { consultationPaymentsContract } from '@montugroup/payments-contracts';
import { initQueryClient } from '@ts-rest/react-query';

import settings from '@/data/constants';

export type ErrorResponse = {
  message: string;
  code?: string | undefined;
};

export default function useConsultationPaymentContract() {
  return initQueryClient(consultationPaymentsContract, {
    baseUrl: settings.paymentRestURL,
    baseHeaders: {}
  });
}
