import { prescribedProductClient } from '@/api/prescribedProducts/prescribedProductClient';
import { QueryKey } from '@/data/query';
import { getAuthData } from '@/data/service/authService';
import type { PrescribedMedicationRequestParamsInputSchema } from '@montugroup/prescription-contracts';

export const useGetPrescribedProducts = (payload: PrescribedMedicationRequestParamsInputSchema) => {
  const { isDoc } = getAuthData();

  const queryKey = [QueryKey.PrescribedProducts, payload];
  const data = prescribedProductClient.getPrescribedMedications.useQuery(
    queryKey,
    {
      params: {
        ...payload
      }
    },
    {
      enabled: isDoc,
      queryKey
    }
  );

  return data;
};
