import type { DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import type { Moment } from 'moment';
import moment from 'moment';

import type { ConcessionCardType } from '..';
import { CONCESSION_CARD_TYPE } from '..';

export type ConcessionCardExpirationDatePickerProps<T> = Pick<
  DesktopDatePickerProps<T>,
  'onChange' | 'value' | 'onError' | 'disabled' | 'slotProps'
> & {
  cardType: ConcessionCardType | '';
};

export const ConcessionCardExpirationDatePicker = ({
  cardType,
  ...otherProps
}: ConcessionCardExpirationDatePickerProps<Date | Moment>) => {
  const commonDatePickerProps = {
    inputVariant: 'outlined',
    variant: 'inline',
    minDate: otherProps.value ? moment() : undefined,
    label: 'Expiration date',
    InputLabelProps: {
      shrink: true
    },
    autoOk: true
  };

  // Veteran concession cards expire at monthly intervals. The mui4 date picker component does not handle switching
  // the "format" prop well after it has been mounted, hence we return a different DatePicker instance.
  if (cardType === CONCESSION_CARD_TYPE.VETERAN) {
    return <DesktopDatePicker format={'MM/YY'} views={['month']} {...commonDatePickerProps} {...otherProps} />;
  }

  // Other concession cards have a specific date as expiry.
  return <DesktopDatePicker format={'DD/MM/YY'} views={['day']} {...commonDatePickerProps} {...otherProps} />;
};

export default ConcessionCardExpirationDatePicker;
