import { Box, Button, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as InformationIcon } from '@/assets/images/information.svg';
import BasicModal from '@/components/common/BasicModal';
import settings from '@/data/constants';
import type { CalendarData } from '@/hooks/rest/types';

type Props = {
  isModalOpen: boolean;
  data: CalendarData;
};

const StyledBasicModal = styled(BasicModal)(({ theme: muiTheme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: muiTheme.palette.background.paper,
  borderRadius: '20px',
  width: '330px',
  outline: 'none'
}));

const StyledButton = styled(Button)({
  textTransform: 'inherit',
  fontSize: '1rem',
  marginTop: 20,
  alignSelf: 'end'
});

const StyledIconWrapper = styled('div')(({ theme }) => ({
  path: {
    fill: theme.palette.orange.main
  }
}));

export default function TimeOutModal({ isModalOpen, data }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  searchParams.set('email', data.email || '');
  searchParams.set('name', data.fullName || '');
  searchParams.set('phoneNumber', data.phoneNumber || '');

  const handleGoBackButton = () => {
    localStorage.removeItem('invitee_uuid_timed_out');

    // Remove current calendar booking values since the booking will be deleted
    searchParams.delete('invitee_uuid');
    searchParams.delete('invitee_full_name');
    searchParams.delete('invitee_email');
    searchParams.delete('invitee_phone_number');
    setSearchParams(searchParams);

    const goBackButtonUrl = `${settings.preScreeningUrl}?cal=true&${searchParams.toString()}`;
    window.location.replace(goBackButtonUrl);
  };

  return (
    <StyledBasicModal
      open={isModalOpen}
      handleClose={() => {
        return null;
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" p={5} pt={7}>
        <StyledIconWrapper>
          <InformationIcon />
        </StyledIconWrapper>
        <Typography color={theme.palette.orange.main} fontWeight={600} fontSize={'1.6rem'} mt={5}>
          Your session has timed out
        </Typography>
        <br />
        <Typography>
          You&apos;ll need to return to the Booking Consultation screen to select your date and time.
        </Typography>
        <StyledButton color="primary" onClick={handleGoBackButton}>
          Go back
        </StyledButton>
      </Box>
    </StyledBasicModal>
  );
}
