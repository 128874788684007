import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { e2eTestsSkipToken } from '@/constants/env';
import settings from '@/data/constants';
import { getToken, logout } from '@/data/service/authService';

const isPmsURL = (config: AxiosRequestConfig) => axios.getUri(config).startsWith(settings.url.toLowerCase());

const bearerTokenInjector = (config: AxiosRequestConfig) => {
  if (isPmsURL(config)) {
    const token = getToken();
    if (token) {
      config.headers = { Authorization: `Bearer ${token}`, ...config.headers };
    }
  }

  return config;
};

const logoutOnInvalidToken = (error: unknown) => {
  if (axios.isAxiosError(error) && isPmsURL(error.config)) {
    const HTTP_UNAUTHORIZED = 401;
    const status = error.response?.status;
    const overrideJWTToken = e2eTestsSkipToken() === 'true';
    if (status === HTTP_UNAUTHORIZED && !overrideJWTToken) {
      logout();
    }
  }

  throw error;
};

axios.defaults.headers = {
  ...axios.defaults.headers,
  'Content-Type': 'application/json'
};

axios.interceptors.request.use(bearerTokenInjector);
axios.interceptors.response.use(undefined, logoutOnInvalidToken);
