import { PrescriptionPadStep } from '@/components/PatientPageV2/components/PrescriptionPad/PrescriptionPad.types';

import type { PrescriptionHistoryState, PrescriptionPadState, WAHealthState } from '../types';

const initialPrescriptionPadReviewActions = [
  {
    id: 1,
    label: 'Created detailed consult notes',
    checked: false
  },
  {
    id: 2,
    label: 'Checked Real Time Prescription Monitoring (RTPM) services',
    checked: false
  }
];

export const initialPrescriptionHistoryState: PrescriptionHistoryState = {
  itemsToAction: [],
  itemsToReissue: [],
  itemsToCancel: []
};

export const initialPrescriptionPadState: PrescriptionPadState = {
  medicationPadItems: [],
  devicePadItems: [],
  step: PrescriptionPadStep.Create,
  actions: initialPrescriptionPadReviewActions
};

export const initialWAHealthState: WAHealthState = {
  isWaPatient: false
};

export const initialStatePrescriptionAssistantSlice = {
  prescriptionHistory: initialPrescriptionHistoryState,
  prescriptionPad: initialPrescriptionPadState,
  waHealth: initialWAHealthState
};
