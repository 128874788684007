import settings from '../data/constants';

import useScript from './useScript';

export default function useEwaySdkScript() {
  return useScript(`${settings.ewaySdkUrl}`, {
    id: 'eway-sdk-script',
    attributes: [['data-init', 'false']],
    removeOnUnmount: false
  });
}
