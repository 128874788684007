import { useQuery } from '@tanstack/react-query';

import type { GetNurseCalendarConsultationsParams } from '@/api/consultations/getNurseCalendarConsultations';
import { getNurseCalendarConsultations } from '@/api/consultations/getNurseCalendarConsultations';
import { QueryKey } from '@/data/query';
import type { NurseConsultation } from '@/types';

export const useGetNurseCalendarConsultations = ({
  selectedNurseIds,
  dateIsoString
}: GetNurseCalendarConsultationsParams) => {
  const {
    data: nurseCalendarConsultations,
    isError,
    isLoading,
    refetch
  } = useQuery<NurseConsultation[]>({
    queryKey: [QueryKey.NurseConsultations, selectedNurseIds, dateIsoString],
    queryFn: async () => {
      const getNurseConsultationsResponse = await getNurseCalendarConsultations({
        selectedNurseIds,
        dateIsoString
      });

      if (getNurseConsultationsResponse?.statusText !== 'OK') {
        throw new Error('An error has occurred getting the Nurse consultations');
      }

      return getNurseConsultationsResponse.data;
    }
  });

  return {
    nurseCalendarConsultations,
    isError,
    isLoading,
    refetch
  };
};
