import settings from '@/data/constants';

import { requestHeaders } from '../api.utils';

import type { GetProductDetailsParams, GetProductDetailsResponse } from './getProductDetails.types';

export const getProductDetailsById = async ({
  productId,
  isConcession
}: GetProductDetailsParams): Promise<GetProductDetailsResponse> => {
  return (
    await fetch(`${settings.url}/product/offering/${productId}?isConcession=${isConcession || false}`, {
      method: 'GET',
      headers: requestHeaders()
    })
  ).json();
};
