import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import BasicModal from '@/components/common/BasicModal';
import usePostCancelConsultation from '@/hooks/rest/usePostCancelConsultation';

export type ConsultationDirectCancelPopupButtonProps = {
  consultationId: string | number;
  onClose?: () => void;
  isButton?: boolean;
};

const StyledCard = styled(Card)(() => ({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch'
}));

const StyledCardHeader = styled(CardHeader)(() => ({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  flexDirection: 'row',
  alignSelf: 'stretch'
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: '0px 24px 20px 24px'
}));

const StyledCardActions = styled(CardActions)(() => ({
  display: 'flex',
  padding: '8px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch'
}));

const StyledBasicModal = styled(BasicModal)(({ theme: muiTheme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: muiTheme.palette.background.paper,
  borderRadius: '4px',
  width: '490px',
  outline: 'none',
  boxShadow:
    '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)'
}));

const StyledButton = styled(Button)({
  textTransform: 'inherit',
  fontSize: '1rem',
  alignSelf: 'end'
});

const ConsultationDirectCancelPopupButton = ({
  consultationId,
  onClose,
  isButton,
  ...rest
}: ConsultationDirectCancelPopupButtonProps) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const {
    mutate: cancelConsultation,
    loading: isLoading,
    data: result,
    error: hasError
  } = usePostCancelConsultation({
    consultationId
  });
  const didError = (isLoading == false && hasError) || result === false;
  const didSucceed = isLoading == false && result === true && !didError;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const PopupButton = (
    <Button
      color={'secondary'}
      onClick={handleOpen}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      data-testid="cancel-direct-popup-trigger"
      {...rest}
    >
      Cancel
    </Button>
  );

  const PopupLink = (
    <Link
      onClick={handleOpen}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      data-testid="cancel-direct-popup-trigger"
      {...rest}
    >
      Cancel
    </Link>
  );

  const PopupTrigger = isButton ? PopupButton : PopupLink;

  const ErrorContent = (
    <StyledCard data-testid="consultation-direct-cancel-popup-error-content">
      <StyledCardHeader
        avatar={
          <ErrorOutlineIcon
            sx={{
              marginLeft: '8px',
              marginRight: '0px',
              color: theme.palette.error.main
            }}
          />
        }
        title={'Cancel Pending Consultation'}
        titleTypographyProps={{
          variant: 'h5',
          color: theme.palette.error.dark
        }}
      />
      <StyledCardContent>
        <Typography textAlign={'left'}>
          There was an error Cancelling this consultation. Please try again later.
        </Typography>
      </StyledCardContent>
      <StyledCardActions>
        <StyledButton color="secondary" onClick={handleClose} data-testid="close-button">
          Close
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  );

  const LoadingContent = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      height={206}
      data-testid="consultation-direct-cancel-popup-loading-content"
    >
      <CircularProgress color="inherit" />
    </Stack>
  );

  const SuccessContent = (
    <StyledCard data-testid="consultation-direct-cancel-popup-success-content">
      <StyledCardHeader
        avatar={
          <CheckCircleOutlineIcon
            sx={{
              marginLeft: '8px',
              marginRight: '0px',
              color: theme.palette.success.main
            }}
          />
        }
        title={'Cancellation Successful'}
        titleTypographyProps={{
          variant: 'h5',
          color: theme.palette.success.dark
        }}
      />
      <StyledCardContent>
        <Typography textAlign={'left'}>This consultation has been cancelled.</Typography>
      </StyledCardContent>
      <StyledCardActions>
        <StyledButton color="secondary" onClick={handleClose} data-testid="close-button">
          Close
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  );

  const SubmissionContent = (
    <StyledCard data-testid="consultation-direct-cancel-popup-submission-content">
      <StyledCardHeader
        avatar={
          <ErrorOutlineIcon
            sx={{
              marginLeft: '8px',
              marginRight: '0px',
              color: theme.palette.error.main
            }}
          />
        }
        title={'Cancel Pending Consultation'}
        titleTypographyProps={{
          variant: 'h5',
          color: theme.palette.error.dark
        }}
      />
      <StyledCardContent>
        <Typography textAlign={'left'}>This action will not send any communication to the patient</Typography>
      </StyledCardContent>
      <StyledCardActions>
        <StyledButton color="secondary" onClick={handleClose} data-testid="close-button">
          No
        </StyledButton>
        <StyledButton
          color="primary"
          onClick={() => cancelConsultation({ cancellationReason: '' })}
          data-testid="yes-button"
        >
          Yes
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  );

  return consultationId ? (
    <>
      {PopupTrigger}

      <StyledBasicModal open={modalOpen} handleClose={handleClose} data-testid="consultation-direct-cancel-popup-modal">
        {/* When loading show loader */}
        {isLoading && !didError && LoadingContent}

        {/* We're not loading and have an error */}
        {!isLoading && didError && ErrorContent}

        {/* We're not loading and have no result -- Ready to send Req*/}
        {!isLoading && !didSucceed && !didError && SubmissionContent}

        {/* We're not loading and we do have a result  */}
        {!isLoading && didSucceed && !didError && SuccessContent}
      </StyledBasicModal>
    </>
  ) : null;
};

export default ConsultationDirectCancelPopupButton;
