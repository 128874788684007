import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

type PaymentBookingConfirmedProps = {
  email?: string | null;
};

const SummaryHeading = styled(Typography)({
  fontWeight: 600,
  marginBottom: 16
}) as typeof Typography;

const StyledTaskAltOutlinedIcon = styled(TaskAltOutlinedIcon)({
  fontSize: 40,
  marginRight: 2
});

export const PaymentBookingConfirmed = (props: PaymentBookingConfirmedProps) => {
  const { email } = props;

  return (
    <>
      <SummaryHeading
        component="h2"
        fontWeight={600}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        fontSize={'1.6rem'}
        marginTop={5}
      >
        <StyledTaskAltOutlinedIcon color="success" />
        Booking confirmed
      </SummaryHeading>
      <Typography marginBottom={4}>
        Your appointment has been confirmed. A confirmation email has been sent to <strong>{email}</strong>
      </Typography>
      <Typography marginBottom={8}>
        Please provide your payment details. Your card will <strong>only</strong> be charged after your consultation if
        you qualify.
      </Typography>
    </>
  );
};
