export const ZONE_UTC = 'utc';
export const ZONE_LOCAL = 'local';
export const ZONE_AUS_BRISBANE = 'Australia/Brisbane';

export const LUXON_FORMAT_LONG_DATE_TIME = 'MMMM d, yyyy - h:mma';

export const LUXON_FORMAT_DAY_MONTH_YEAR = 'dd LLL yyyy';
export const LUXON_FORMAT_HOUR_MINUTE_AM_PM = 'h.mm a';

// TODO - Current Luxon does not have methods to format dates with ordinal suffixes
// i.e. '4th', '1st', '3rd' etc. This function does that until Luxon adds functionality
// See - https://github.com/moment/luxon/issues/118
export const luxonFormatWithOrdinalSuffix = (dayOfMonth: number): string => {
  const ordinalStrings = ['th', 'st', 'nd', 'rd'];
  const v = dayOfMonth % 100;
  const ordinal = ordinalStrings[(v - 20) % 10] || ordinalStrings[v] || ordinalStrings[0];
  return `${dayOfMonth}${ordinal}`;
};
