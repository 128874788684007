import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { FLEX_CALENDAR_URL } from '@/components/NurseFlexCalendar/NurseFlexCalendar.constants';
import type { FlexCalendarConsultationMapping } from '@/components/NurseFlexCalendar/NurseFlexCalendar.types';
import { QueryKey } from '@/data/query';
import { useAccessControl } from '@/hooks';
import { UserRole } from '@/types';

type GetNurseFlexCalendarResponse = {
  consultations: FlexCalendarConsultationMapping[];
  nurse_flex_ids: number[];
  nurse_leave_ids: number[];
};

const getNurseFlexCalendar = async () => {
  const response = await axios.get<GetNurseFlexCalendarResponse>(FLEX_CALENDAR_URL);

  return response?.data;
};

export const useGetNurseFlexCalendar = () => {
  const { allowAccess } = useAccessControl();
  const canUserEditCalendar = Boolean(allowAccess([UserRole.SuperAdmin, UserRole.Manager, UserRole.Admin], true));
  const { data, isError, isLoading, isRefetching } = useQuery({
    queryKey: [QueryKey.GetNurseFlexCalendar],
    queryFn: () => getNurseFlexCalendar(),
    refetchOnMount: 'always',
    refetchInterval: canUserEditCalendar ? false : 60000
  });

  return {
    data,
    isError,
    isLoading,
    isRefetching
  };
};
