import { z } from 'zod';

export type PatientGenderTypes = 'Male' | 'Female' | 'Indeterminate/Intersex/Unspecified' | null | undefined;

export const GenderEnum = z.enum(['Male', 'Female', 'Indeterminate/Intersex/Unspecified']);

const PmsDoctorSchema = z.object({
  id: z.number(),
  label: z.string()
});

export const schema = z.object({
  FirstName: z.string().nullable().optional(),
  LastName: z.string().nullable().optional(),
  DoB: z.any(),
  gender: GenderEnum.nullable().optional(),
  atsi_status: z.union([z.number(), z.string()]).nullable().optional(),
  consent: z.boolean().nullable().optional(),
  pmsDoctor: z.union([PmsDoctorSchema, z.null(), z.number()]).optional(),
  dvaNumber: z.string().nullable().optional()
});
