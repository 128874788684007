import { MenuItem, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/styles';

export const StyledMenuItem = styled(MenuItem)({
  paddingTop: 0,
  paddingBottom: 0,
  '&:hover': { backgroundColor: 'transparent' },
  '&.Mui-selected': { backgroundColor: 'transparent' },
  '&.Mui-selected:hover': { backgroundColor: 'transparent' }
});

export const FullWidthStack = styled(Stack)({
  width: '100%'
});

export const StyledStack = styled(FullWidthStack)({
  borderBottom: `1px solid ${grey[300]}`,
  padding: '1rem 0'
});
