import { Stack, TextField, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { blueGrey } from '@mui/material/colors';

type IhiModalFieldProps = {
  label: string;
  value: string | number | null | undefined;
  helperText?: string;
  highlightColour?: string | undefined;
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => !['highlightColour'].includes(prop as string)
})<{ highlightColour: string | undefined }>(({ highlightColour }) => ({
  '.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
    textFillColor: blueGrey[800]
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  '.MuiInputBase-root': {
    border:
      highlightColour === 'error'
        ? '1px solid red'
        : highlightColour === 'success'
          ? '1px solid green'
          : `1px solid ${blueGrey[100]}`,
    textFillColor: 'red'
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: highlightColour === 'error' ? 'red' : highlightColour === 'success' ? 'green' : 'inherit',
    padding: '0 5px',
    backgroundColor: 'white'
  }
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop: string) => !['highlightColour'].includes(prop as string)
})<{ highlightColour: string | undefined }>(({ highlightColour }) => ({
  color: highlightColour === 'error' ? 'red' : highlightColour === 'success' ? 'green' : 'inherit'
}));

/**
 * IhiModalField
 *
 * This component renders a disabled text field with a label, value, and optional helper text.
 * It highlights the field and label based on the provided highlight colour (e.g., error, success).
 *
 * @param {IhiModalFieldProps} props - The properties object.
 * @param {string} props.label - The label for the text field.
 * @param {string | number | null | undefined} props.value - The value to display in the text field.
 * @param {string} [props.helperText] - Optional helper text to display below the text field.
 * @param {string | undefined} [props.highlightColour] - Optional highlight colour for the field and label (e.g., error, success).
 *
 * @returns {JSX.Element} The rendered component.
 */

export const IhiModalField = (props: IhiModalFieldProps) => {
  const { label, value, helperText, highlightColour } = props;
  return (
    <Stack flex={1}>
      <StyledTextField
        label={label}
        value={value}
        InputLabelProps={{ shrink: true }}
        highlightColour={highlightColour}
        disabled
        fullWidth
      />
      {helperText && (
        <StyledTypography variant={'caption'} highlightColour={highlightColour}>
          {helperText}
        </StyledTypography>
      )}
    </Stack>
  );
};
