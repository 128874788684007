import { Table } from '@montugroup/design-system';
import { useMemo } from 'react';

import {
  FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS
} from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';

import { useSortedPrescribedProducts } from '../../hooks/sortedPrescribedProducts/useSortedPrescribedProducts';
import { formatTableColumns } from './PrescribedMedicationsTable.config';
import type { PrescribedMedicationsTableProps } from './PrescribedMedicationsTable.types';

/**
 * Used to display the prescriptions table
 *
 * @param headings - column definition as per https://mui.com/x/react-data-grid/column-definition/
 * @param products - row definition as per https://mui.com/x/react-data-grid/row-definition/
 * @param pageSize - no. of items per page
 * @param pageSizeOptions - page size intervals
 * @param onClick - handle reissue button click
 */
const PrescribedMedicationsTable = ({
  headings,
  products,
  onReissueClick,
  onViewDossierClick,
  onClickCancel,
  pageSize = 5,
  prescribedProductIds = []
}: PrescribedMedicationsTableProps) => {
  const { flags } = useFeatureFlags();

  const ffEnablePrescriptionAssistantViewScripts = flags[FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS];
  const ffEnableCancelMedications = flags[FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const cellOptions = {
    onReissueClick: onReissueClick,
    onViewDossierClick,
    onClickCancel,
    enabledPrescriptionAssistantViewScripts: ffEnablePrescriptionAssistantViewScripts,
    enabledCancelMedications: ffEnableCancelMedications,
    prescribedProductIds
  };
  const formattedColumns = useMemo(
    () => formatTableColumns(headings, cellOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onReissueClick]
  );

  // a hook that does the sorting of the products by date
  const sortedProducts = useSortedPrescribedProducts(products);

  if (!sortedProducts.length) {
    return null;
  }

  return (
    <Table
      columns={formattedColumns}
      data={sortedProducts}
      pageSize={pageSize}
      pageSizeOptions={[5, 10, 25]}
      showPagination
      hasRowBackgroundColor={false}
      compact
    />
  );
};

export default PrescribedMedicationsTable;
