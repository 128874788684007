import axios from 'axios';

import settings from '@/data/constants';

export type GetDoctorPatientAndConsultCountResponse = { consultationCount: number; patientCount: number };

export const getDoctorPatientAndConsultCount = async (doctorId: number) => {
  const url = `${settings.url}/doctor/patientAndConsultationCount?doctorId=${doctorId}`;
  const response = await axios.get<GetDoctorPatientAndConsultCountResponse>(url);

  return response?.data;
};
