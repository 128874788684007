import React from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import Alternaleaf from '../components/branding/Alternaleaf';
import ErrorMsg from '../components/DassAssessmentForm/ErrorMsg';
import QuestionAndAnswer from '../components/DassAssessmentForm/QuestionAndAnswer';
import { makeGET } from '../data/service/dataService';

import '../assets/scss/DassForm/dassAssessmentForm.scss';

const DassAssessmentForm = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { patient_id: patientId, uuid } = useParams();
  const [patientDetails, setPatientDetails] = React.useState({});

  React.useEffect(() => {
    async function fetchPatientDassValidity() {
      try {
        const resp = await makeGET(
          `dass/patientDassValidity?patientId=${patientId}&uuid=${uuid}`,
          'fetchPatientDassValidity-DassAssessmentForm'
        );
        if (resp) {
          if (resp.is_submitted) {
            setErrorMessage(
              'You have already submitted answers for your DASS Assessment. Please contact Montu Support for further queries.'
            );
          } else {
            setErrorMessage('');
            setPatientDetails(resp.Patient.PatientUser);
          }
        } else {
          setErrorMessage(
            'This link is not valid. Please request a new link from the nurse and check your latest email once it is sent. If the issue persists, contact Montu Tech Support.'
          );
        }
        setLoading(false);
      } catch (err) {
        setErrorMessage('Something went wrong please try again later!');
        setLoading(false);
      }
    }
    fetchPatientDassValidity();
  }, [patientId, uuid]);

  return (
    <Alternaleaf>
      {loading ? (
        <div className="loading">
          <CircularProgress data-testid="circular-loading" />
        </div>
      ) : (
        <div>
          {errorMessage ? (
            <ErrorMsg errorMessage={errorMessage} />
          ) : (
            <QuestionAndAnswer patientDetails={patientDetails} />
          )}
        </div>
      )}
    </Alternaleaf>
  );
};

export default DassAssessmentForm;
