import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import type { EnvSettings } from '@/data/constants';

import { version } from '../../package.json';
import { Logger } from './logger';

const logger = new Logger('datadog');

export default function init(settings: EnvSettings) {
  const { datadog } = settings;

  const configs = {
    version,
    clientToken: 'pub8d52240067cf165d6bf3ec683c65fc9a',
    site: 'us3.datadoghq.com',
    service: 'pms-frontend'
  };

  if (datadog) {
    if (!datadogRum.getInternalContext()) {
      logger.info('Initializing DataDog RUM');
      datadogRum.init({
        ...datadog,
        ...configs,
        applicationId: 'aa9a371c-7dba-4128-aa59-766c38fe51de',
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        enableExperimentalFeatures: ['feature_flags'],
        /**
         * Having a default of mask, every field will be masked by default
         * If you need to unmask a field, you can use the `data-dd-privacy` attribute
         * data-dd-privacy="allow" will unmask the field if needed for RUM, and isn't PII
         */
        defaultPrivacyLevel: 'mask'
      });
    }

    datadogRum.startSessionReplayRecording();

    if (!datadogLogs.getInitConfiguration()) {
      logger.info('Initializing DataDog Logs');
      datadogLogs.init({
        ...configs,
        forwardErrorsToLogs: true
      });
    }
  }
}

export function customAction(topic: string, payload = {}) {
  datadogRum.addAction(topic, payload);
}
