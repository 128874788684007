import type { AxiosResponse } from 'axios';
import axios from 'axios';

import settings from '@/data/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('postExcelExports');
/**
 * format 'YYYY-MM-DD HH:mm:ss'
 */
export type ISODateString = string;

export type DownloadExportFileRequest = {
  title: string;
  dateRange: {
    from_date: ISODateString;
    to_date: ISODateString;
  };
};

export enum ExportType {
  PATIENT = 'PATIENT',
  CONSULTATION = 'CONSULTATION',
  PRESCRIPTION = 'PRESCRIPTION'
}

const getEndpointFromExportType = (exportType: ExportType) => {
  switch (exportType) {
    case ExportType.PATIENT:
      return 'export-patients';
    case ExportType.CONSULTATION:
      return 'export-consultations';
    case ExportType.PRESCRIPTION:
      return 'export-prescriptions';
    default:
      throw new Error(`Unsupported ExportType: ${exportType}`);
  }
};

export const postExcelExports = async (exportType: ExportType, request: DownloadExportFileRequest) => {
  try {
    const exportUrl = `${settings.url}/excel/${getEndpointFromExportType(exportType)}`;
    const response = await axios.post<DownloadExportFileRequest, AxiosResponse<{ message: string }>>(exportUrl, {
      ...request
    });
    return response?.data;
  } catch (error) {
    logger.error('Error encountered while exporting', {
      exportType,
      error
    });
    throw error;
  }
};
