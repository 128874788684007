import type { Filter } from './ProductFilter';

/**
 * Recursive function to retrieve selected child filters
 * @param allFilters - all the filters data send from API
 * @param selectedFilters - selected filter names
 * @param savedFilters - saved filters for the recursive function
 * @returns - selected filters
 */
export const getSelectedChildFilters = (
  allFilters: Filter,
  selectedFilters: string[],
  savedFilters: Filter[] = []
): Filter[] => {
  const currentSelectedFilter = allFilters.options.find((item) => selectedFilters.includes(item.value))?.filter;

  if (!currentSelectedFilter) {
    return savedFilters;
  }

  savedFilters.push(currentSelectedFilter);

  if ('options' in currentSelectedFilter) {
    const childSelectedFilter = currentSelectedFilter.options.find((item) =>
      selectedFilters.includes(item.value)
    )?.filter;

    if (!childSelectedFilter) {
      return savedFilters;
    }

    savedFilters.push(childSelectedFilter);

    return getSelectedChildFilters(childSelectedFilter, selectedFilters, savedFilters);
  }

  return savedFilters;
};
