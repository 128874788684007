import type { RecommendationInfo } from '../PrescriptionItemEditor.types';

/**
 * Returns a list of feature flag controlled props
 * and their default values if it doesn't exist
 */
export const usePrescriptionItemFeatureFlags = () => {
  /**
   * This function formerly returned the `prescription_recommended_products_count` and
   *  `prescription_recommendation_info` LaunchDarkly feature flag values.
   * Static values are now returned, with a future plan to change to consts in callers.
   */
  const recommendedMedicationsCount = 3;
  const recommendationInfo: RecommendationInfo = {
    href: 'https://app.getguru.com/card/iByRzd4T/Prescription-assistant-guide',
    text: 'Product recommendations are based on your selections and availability criteria'
  };

  return {
    recommendedMedicationsCount,
    /**
     * Information about the recommendation system
     */
    recommendationInfo
  };
};
