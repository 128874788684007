import { Box, FormControlLabel, styled, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import type {
  TimeRange
  // TODO Working hours to be used in Phase 2
  // WorkingHours
} from '@/components/calcom/shared/types/calcom-schedule-types';
import type { Dayjs } from '@/utils/dayjs';
import dayjs from '@/utils/dayjs';

import { DayRanges } from '../../shared/components/DayRanges';
import { Form } from '../../shared/components/Form';
import { StyledButton } from '../../shared/components/styled-components';
import { yyyymmdd } from '../../shared/utils/date-utils';

import DatePicker from './DatePicker';

/**
 * DateOverrideForm component
 *
 * @remarks
 * This component is part of the "UpdateScheduleForm" and allows the user to update availability overrides for specific dates
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DateOverrideForm'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/schedules/components/DateOverrideInputDialog.tsx
 *
 * @param value - An array of TimeRanges with start and end times for the overrides
 * @param workingHours - The standard working hours for that user. Used for default values
 * @param excludedDates - Dates which the user is not working on
 * @param onClose - Callback for when the form is closed
 * @param onChange - Callback for when the form is updated
 *
 * @returns JSXElement
 *
 */

const StyledFormContents = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 50,
  display: 'flex'
}));

const StyledDateOverrideTimeRanges = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '235px',
  padding: 5,
  marginLeft: 50
});

const StyledUnavailableText = styled(Typography)({
  fontWeight: 'bold',
  margin: '25px 0'
});

const StyledFormActionButton = styled(StyledButton)({
  padding: 10
});

const DateOverrideForm = ({
  value,
  // TODO Working hours to be used in Phase 2
  // workingHours,
  excludedDates,
  onClose,
  onChange
}: {
  // TODO Working hours to be used in Phase 2
  // workingHours?: WorkingHours[];
  onChange: (newValue: TimeRange[]) => void;
  excludedDates: string[];
  value?: TimeRange[];
  onClose?: () => void;
}) => {
  const [browsingDate, setBrowsingDate] = useState<Dayjs>();
  const [datesUnavailable, setDatesUnavailable] = useState(
    value &&
      value[0].start.getUTCHours() === 0 &&
      value[0].start.getUTCMinutes() === 0 &&
      value[0].end.getUTCHours() === 0 &&
      value[0].end.getUTCMinutes() === 0
  );

  const [selectedDates, setSelectedDates] = useState<Dayjs[]>(value ? [dayjs.utc(value[0].start)] : []);

  const isAddingNewOverride = !value;
  const isOverridesforMultipleDays = selectedDates.length > 1;

  const handleAllDayUnavailable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDatesUnavailable(event.target.checked);
  };

  const onDateChange = (newDate: Dayjs) => {
    // If clicking on a selected date unselect it
    if (selectedDates.some((date) => yyyymmdd(date) === yyyymmdd(newDate))) {
      setSelectedDates(selectedDates.filter((date) => yyyymmdd(date) !== yyyymmdd(newDate)));
      return;
    }

    // If it's editing a new override we can allow multiple dates to be selected
    if (isAddingNewOverride) {
      setSelectedDates((prev) => [...prev, newDate]);
      return;
    }

    setSelectedDates([newDate]);
  };

  // TODO: For Phase 1 of Date Overrides, we're not going to use 'workingHours' to generate default times.
  // But we may add it in Phase 2 so that we can add more granular functionality to the DateOverrideForm.
  // I've left the original code in there for future reference for Phase 2 devs.
  const defaultRanges = [];
  // const defaultRanges = (workingHours || []).reduce((dayRanges: TimeRange[], workingHour) => {
  //   if (selectedDates[0] && workingHour.days.includes(selectedDates[0].day())) {
  //     dayRanges.push({
  //       start: dayjs.utc().startOf('day').add(workingHour.startTime, 'minute').toDate(),
  //       end: dayjs.utc().startOf('day').add(workingHour.endTime, 'minute').toDate()
  //     });
  //   }
  //   return dayRanges;
  // }, []);

  // DayRanges does not support empty state, add 9-5 as a default
  if (!defaultRanges.length) {
    defaultRanges.push({
      start: dayjs.utc().startOf('day').add(540, 'minute').toDate(),
      end: dayjs.utc().startOf('day').add(1020, 'minute').toDate()
    });
  }

  const form = useForm({
    values: {
      range:
        value && value[0].start.valueOf() !== value[0].end.valueOf()
          ? value.map((range) => ({
              start: new Date(
                dayjs.utc().hour(range.start.getUTCHours()).minute(range.start.getUTCMinutes()).second(0).format()
              ),
              end: new Date(
                dayjs.utc().hour(range.end.getUTCHours()).minute(range.end.getUTCMinutes()).second(0).format()
              )
            }))
          : defaultRanges
    }
  });

  return (
    <Form
      form={form}
      handleSubmit={(values) => {
        if (selectedDates.length === 0) {
          return;
        }

        const datesInRanges: TimeRange[] = [];

        if (!datesUnavailable) {
          selectedDates.map((date) => {
            values.range.map((item) => {
              datesInRanges.push({
                start: date.hour(item.start.getUTCHours()).minute(item.start.getUTCMinutes()).utc(true).toDate(),
                end: date.hour(item.end.getUTCHours()).minute(item.end.getUTCMinutes()).utc(true).toDate()
              });
            });
          });
        }

        onChange(
          datesUnavailable
            ? selectedDates.map((date) => {
                return {
                  start: date.utc(true).startOf('day').toDate(),
                  end: date.utc(true).startOf('day').toDate()
                };
              })
            : datesInRanges
        );

        onClose?.();
        setSelectedDates([]);
      }}
    >
      <StyledFormContents>
        <Box display="flex" flexDirection={'column'}>
          <Typography variant="h5" mb={3}>
            Select the dates to override
          </Typography>
          {isAddingNewOverride && <Typography mb={5}>You can select multiple dates for date overrides</Typography>}
          <DatePicker
            excludedDates={excludedDates}
            weekStart={0}
            selected={selectedDates}
            onChange={(day: Dayjs | null) => {
              if (day) {
                onDateChange(day);
              }
            }}
            onMonthChange={(newMonth: Dayjs) => {
              setBrowsingDate(newMonth);
            }}
            browsingDate={browsingDate}
            locale={'en'}
          />
        </Box>
        {selectedDates[0] && (
          <StyledDateOverrideTimeRanges>
            <Box>
              <Typography variant={'h6'} mb={5}>
                Which hours are you free?
              </Typography>
              <Box mb={5}>
                {datesUnavailable ? (
                  <StyledUnavailableText>Unavailable all day</StyledUnavailableText>
                ) : (
                  <DayRanges name="range" />
                )}
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={datesUnavailable}
                    onChange={(e) => handleAllDayUnavailable(e)}
                    data-testid="date-override-mark-unavailable"
                  />
                }
                label="Mark unavailable (All day)"
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <StyledFormActionButton
                variant="outlined"
                type="submit"
                disabled={selectedDates.length === 0}
                data-testid="add-override-submit-btn"
                sx={{ marginBottom: 5 }}
              >
                {isAddingNewOverride
                  ? isOverridesforMultipleDays
                    ? 'Add new overrides'
                    : 'Add new override'
                  : 'Update overrides'}
              </StyledFormActionButton>
              <StyledFormActionButton onClick={onClose} variant="outlined">
                Close
              </StyledFormActionButton>
            </Box>
          </StyledDateOverrideTimeRanges>
        )}
      </StyledFormContents>
    </Form>
  );
};

export default DateOverrideForm;
