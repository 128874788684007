import type { DeviceProduct } from '@/hooks/prescription/usePrescriptionForm';

import type { PrescriptionPadReviewAction } from '../PrescriptionPad';
import type { MedicationType } from '../ProductDetailsCard/ProductDetailsCard.types';

export type InformationBlockTextRow = {
  id: string;
  body: number | string;
  heading: string;
};

export type InformationBlockProps = {
  heading: string;
  rowsOfText: InformationBlockTextRow[];
};

export type PrescriberDetails = {
  first_name: string;
  last_name: string;
  clinic: string;
  address: string;
  phone: string;
};

export type PatientDetails = {
  first_name: string;
  last_name: string;
  address: string;
  phone: string;
  medicare_number: string;
  dob: string;
  allergies: string;
  hasValidConcessionCard?: boolean;
};

export type ConfirmPrescriptionCardProps = {
  prescriberNumber: string;
  prescriberDetails: PrescriberDetails;
  patientDetails: PatientDetails;
  createdPrescriptionDetails: MedicationType[];
  reviewActions: PrescriptionPadReviewAction[];
  createdVaporizer?: DeviceProduct | null;
  createdDevices?: (DeviceProduct | null)[];
  onReturn: () => void;
  onActionClick: (action: PrescriptionPadReviewAction) => void;
};

export enum PrescriberHeadings {
  MainHeading = 'Prescriber details',
  PrescriberNumber = 'Prescriber no',
  PrescriberName = 'Name',
  Address = 'Address',
  Phone = 'Phone',
  Clinic = 'Clinic'
}

export enum PatientHeadings {
  MainHeading = 'Patient details',
  Medicare = 'Medicare no',
  Name = 'Name',
  DOB = 'Date of Birth',
  Address = 'Address',
  Phone = 'Phone',
  Allergies = 'Allergies'
}

export enum PrescriptionHeadings {
  MainHeading = 'Medication details',
  Name = 'Medication',
  Quantity = 'Quantity',
  Repeats = 'Repeats',
  Interval = 'Interval (Days)',
  Dosage = 'Dosage',
  DevicesHeading = 'Device',
  DeviceName = 'Device Name'
}
