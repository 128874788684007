import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { GetPatientsQuery } from '@/api/patients/getPatients';
import { getPatients } from '@/api/patients/getPatients';
import { QueryKey } from '@/data/query';

export const useGetPatients = (params?: GetPatientsQuery) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [QueryKey.GetPatient, { ...params }],
    queryFn: () => getPatients(params),
    placeholderData: keepPreviousData
  });

  return {
    data,
    isError,
    isLoading
  };
};
