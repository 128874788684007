import { getToken } from '@/data/service/authService';

export const requestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getToken()}`
});

// Function to normalize header keys to lowercase
// http headers are case insensitive
export const normalizeHeaders = (headers: Record<string, string>) => {
  const normalizedHeaders: Record<string, string> = {};
  for (const key in headers) {
    if (headers.hasOwnProperty(key)) {
      normalizedHeaders[key.toLowerCase()] = headers[key];
    }
  }
  return normalizedHeaders;
};
