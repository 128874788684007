import styled from '@emotion/styled';
import { CheckBoxSharp, UploadFile } from '@mui/icons-material';
import { Button } from '@mui/material';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

interface Props {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
}

const SupportTaskUploadCsvButton = ({ file, setFile }: Props) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const csv = event?.target?.files?.[0];

    if (csv) {
      setFile(csv);
    }
  };

  return (
    <Button
      component="label"
      role="button"
      variant="contained"
      tabIndex={-1}
      startIcon={file ? <CheckBoxSharp /> : <UploadFile />}
    >
      Upload csv
      <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".csv" />
    </Button>
  );
};

export default SupportTaskUploadCsvButton;
