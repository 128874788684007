import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

export type PageMainContentProps = BoxProps;

/**
 * Container for a PMS Page content. Renders to a `<main>` element by default
 */
export const PageMainContent = forwardRef<HTMLElement, BoxProps>(function PageMainContent(props, ref) {
  return <Box component="main" paddingX={4} paddingY={5} {...props} ref={ref} />;
});
