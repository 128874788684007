import { useEffect } from 'react';

import settings from '../../data/constants';

import type { ConcessionCardReponse } from './types';
import useRequest from './useRequest';

export type UseGetPatientConcessionCardResponse = ConcessionCardReponse;

type UseGetPatientConcessionCardProps = {
  patientId: number | string;
};

export function useGetPatientConcessionCard({ patientId }: UseGetPatientConcessionCardProps) {
  const { loading, data, error, execute, setData } = useRequest<UseGetPatientConcessionCardResponse>({
    config: {
      url: `${settings.url}/patient/${patientId}/concession-card`,
      method: 'GET'
    }
  });

  useEffect(() => {
    if (patientId) {
      execute();
    }
  }, [patientId, execute]);

  return {
    loading,
    data,
    error,
    doGet: execute,
    setData
  };
}

export default useGetPatientConcessionCard;
