import { Button, Table } from '@montugroup/design-system';
import { Box, Link, TextField, Tooltip, debounce } from '@mui/material';
import { styled } from '@mui/styles';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';

// TODO: worth identifying what the actual type of this is from PMS service, and move to MGS.
export type PatientNoteData = {
  CreatedBy: {
    email: string;
    first_name: string;
    last_name: string;
  };
  id: number;
  active?: boolean;
  /**
   * ID of the user who created this
   */
  created_by: number;
  created_date: string; // ISODate string
  /**
   * ID of the user who modified this
   */
  modified_by: number;
  modified_date: string; // ISODate string
  heading: string;
  note?: string;
  patient_id: number;
};

export type PatientNotesProps = {
  notes: PatientNoteData[];
  isAddNoteDisabled?: boolean;
  onViewNote: (selectedNote: PatientNoteData) => void;
  onAddNewNote: () => void;
};

const TextFieldOverridingLegacyStyles = styled(TextField)({
  '& .MuiInputBase-root.MuiOutlinedInput-root  input.MuiInputBase-input': {
    padding: '8px'
  }
});

export const PatientNotes = ({ notes, onViewNote, onAddNewNote, isAddNoteDisabled }: PatientNotesProps) => {
  const [searchFilterText, setSearchFilterText] = useState('');
  // `any` is necessary here to deal with multiple column return types. This could be improved by future versions of react-table
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnDef<PatientNoteData, any>[] = useMemo(
    () => [
      {
        id: 'viewNote',
        cell: ({ row }) => (
          <Link
            onClick={() => onViewNote(row.original)}
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            View
          </Link>
        ),
        header: () => (
          <Tooltip title="View Note">
            <Box>View Note</Box>
          </Tooltip>
        ),
        enableSorting: false,
        meta: {
          sx: {
            width: '80px'
          }
        }
      },
      {
        accessorFn: ({ created_date }) => new Date(created_date),
        enableSorting: true,
        id: 'createdDate',
        header: () => (
          <Tooltip title="Created Date">
            <Box>Created Date</Box>
          </Tooltip>
        ),
        cell: ({ getValue }: CellContext<PatientNoteData, Date>) => format(getValue(), 'dd-MMM-yyyy')
      },
      {
        accessorKey: 'heading',
        id: 'noteHeader',
        enableSorting: true,
        header: () => (
          <Tooltip title="Note Header">
            <Box>Note Header</Box>
          </Tooltip>
        )
      },
      {
        id: 'createdBy',
        enableSorting: true,
        accessorFn: ({ CreatedBy }) => `${CreatedBy.first_name} ${CreatedBy.last_name}`,
        header: () => (
          <Tooltip title="Created By">
            <Box>Created By</Box>
          </Tooltip>
        )
      }
      /**
       * TO DO
       *
       * Backend is only returning an id for modified_by, no user object. Backend changes required to display this information.
       */
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={3} width="100%" paddingY={6} justifyContent="space-between">
      <TextFieldOverridingLegacyStyles
        sx={{ width: '50%' }}
        label="Search"
        variant="outlined"
        onChange={debounce(handleSearchOnChange, 1000)}
      />
      <Button onClick={() => onAddNewNote?.()} color="secondary" disabled={isAddNoteDisabled}>
        Add Note
      </Button>
      <Box
        component={Table}
        columns={columns}
        data={notes}
        hasRowBackgroundColor={false}
        showPagination={true}
        pageSize={5}
        pageSizeOptions={[5, 10, 25, 50]}
        globalFilter={searchFilterText}
        sx={{
          width: '100%'
        }}
      />
    </Box>
  );
};
