import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

type PaymentBreadcrumbProps = {
  stepNumber: number;
};

const StyledBreadCrumbsContainer = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    marginBottom: 8,
    marginTop: 4,
    justifyContent: 'space-between',
    flex: 1,
    position: 'relative',

    '&::after': {
      content: '""',
      borderTop: `1px solid ${grey[300]}`,
      position: 'absolute',
      top: '18px',
      width: '100%'
    }
  }
}));

const StyledStep = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  fontSize: '1rem',
  padding: '0px 8px',
  backgroundColor: theme.palette.background.default
}));

const StyledStepsContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 1
});

const StyledTypography = styled(Typography)({
  color: 'text.primary',
  display: 'flex',
  alignItems: 'center'
});

export const PaymentFlowBreadcrumb = (props: PaymentBreadcrumbProps) => {
  const { stepNumber } = props;
  const CheckCircle = ({ text }: { text: string }) => {
    return (
      <StyledTypography>
        <CheckCircleIcon color="success" sx={{ height: '2.4rem', width: '2.4rem', mr: 1.5 }} fontSize="inherit" />
        {text}
      </StyledTypography>
    );
  };

  const NumberCircle = ({
    text,
    number,
    color
  }: {
    text: string;
    number: number;
    color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  }) => {
    return (
      <StyledTypography>
        <Chip
          color={color}
          component="span"
          size="small"
          sx={{ height: '2rem', width: '2rem', mr: 1.5 }}
          label={number}
        />
        {text}
      </StyledTypography>
    );
  };

  return (
    <StyledBreadCrumbsContainer aria-label={'breadcrumb'} component={'nav'} data-testid={'payment-breadcrumb'}>
      <StyledStepsContainer>
        <StyledStep>
          <CheckCircle text="Pre-screen" />
        </StyledStep>
        <StyledStep>
          <CheckCircle text="Booking" />
        </StyledStep>
        <StyledStep marginTop={1}>
          {stepNumber === 3 ? (
            <NumberCircle text="Payment Details" number={3} color={'warning'} />
          ) : (
            <CheckCircle text="Payment Details" />
          )}
        </StyledStep>
        <StyledStep marginTop={1}>
          {stepNumber === 4 ? (
            <NumberCircle text="Pre-consult" number={4} color={'warning'} />
          ) : (
            <NumberCircle text="Pre-consult" number={4} color={'default'} />
          )}
        </StyledStep>
      </StyledStepsContainer>
    </StyledBreadCrumbsContainer>
  );
};
