import type { PrescribedMedicationSchema } from '@montugroup/prescription-contracts';
import { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

export interface UseFilteredPrescribedProductsResult {
  data: PrescribedMedicationSchema[];
}

type FilterType = 'all' | 'recent';

const TIME_FRAME_MONTHS = 6;
const FILTER_RECENT: FilterType = 'recent';

/**
 * Filters prescribed medications based on the given filter type.
 *
 * @param medications - Array of prescribed medications to filter.
 * @param filter - The filter type, either 'all' or 'recent'.
 * @returns An array of filtered prescribed medications.
 */
export const filterPrescribedMedications = (
  medications: PrescribedMedicationSchema[],
  filter: FilterType
): PrescribedMedicationSchema[] => {
  const now = DateTime.now();
  const thresholdDate = now.minus({ months: TIME_FRAME_MONTHS });

  return medications.filter((medication) => {
    const itemDate = DateTime.fromISO(medication.updatedDate);
    const hasNotExpired = itemDate.isValid && itemDate >= thresholdDate;

    if (filter === FILTER_RECENT) {
      return hasNotExpired && medication.status === MedicationPrescriptionStatus.Active;
    }
    return hasNotExpired;
  });
};

/**
 * Custom hook to filter prescribed products based on the provided filter type ('all' or 'recent').
 *
 * @param prescribedProductsData - The prescribed products data to filter.
 * @param filter - The filter type, either 'all' or 'recent'.
 * @returns An object containing the filtered prescribed medications.
 */
export const useFilteredPrescribedProducts = (
  prescribedProductsData: PrescribedMedicationSchema[],
  filter: FilterType
): UseFilteredPrescribedProductsResult => {
  const prescribedMedications = prescribedProductsData ?? [];

  const filteredMedications = useMemo(() => {
    return filterPrescribedMedications(prescribedMedications, filter);
  }, [prescribedMedications, filter]);

  return { data: filteredMedications };
};
