const listSorter = (list, key, ASC = true) => {
  return list.sort((a, b) => {
    if (a[key] < b[key]) {
      if (ASC) {
        return 1;
      }
      return -1;
    }
    if (a[key] > b[key]) {
      if (ASC) {
        return -1;
      }
      return 1;
    }
    return 0;
  });
};

const getConsultationType = (row) => {
  if (!row.consult_type) {
    return '-';
  }
  if (row.consult_type.includes('Initial')) {
    if (row.doctor_id) {
      return 'Initial - Doctor';
    }
    return 'Initial - Nurse';
  }
  if (row.doctor_id) {
    return 'Follow-Up Doctor';
  }
  return 'Follow-Up Nurse';
};

export { getConsultationType, listSorter };
