import { CircularProgress, styled, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import type { ScheduleResponse } from '../shared/types/calcom-schedule-types';
import { daysOfWeek } from '../shared/utils/days-of-week';

import Schedule from './components/Schedule';

/**
 * Availability Scheduler component
 *
 * @remarks
 * This component is part of the "UpdateScheduleForm" and allows the user to update a Calcom users availability
 *
 * @param scheduleResponse - The response from the API which includes availability and override data etc
 *
 * @returns JSXElement
 *
 */

type AvailabilitySchedulerProps = {
  scheduleResponse: ScheduleResponse | undefined;
};

const StyledCircularProgress = styled(CircularProgress)({
  margin: 0,
  position: 'absolute',
  top: 'calc(50% - 60px)',
  left: '50%',
  transform: 'translateY(-50%)'
});

const AvailabilityScheduler = ({ scheduleResponse }: AvailabilitySchedulerProps) => {
  const weekStart = daysOfWeek.indexOf('Sunday');
  const form = useFormContext();

  return scheduleResponse?.availability ? (
    <>
      <Typography variant="h6" marginBottom={4} marginTop={3} textAlign={'center'}>
        Update users availability
      </Typography>
      <Schedule control={form.control} name="schedule" weekStart={weekStart} />
    </>
  ) : (
    <StyledCircularProgress data-testid="loading" />
  );
};

export default AvailabilityScheduler;
