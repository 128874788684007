import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DoctorsTable } from '@/components/tables/DoctorsTable';
import exportExcel from '@/data/excelExport';
import { useAccessControl } from '@/hooks';
import type { DoctorTableFormattedRow } from '@/types';
import { UserRole } from '@/types';
import { luxonFormatWithOrdinalSuffix } from '@/utils/luxon';

interface ExcelDataRow {
  'Doctor ID': string;
  Name: string;
  Email: string;
  'Joined Date': string;
  Phone: string;
  AHPRA: string;
  'Primary Clinic': string;
  Patients: number;
}

const StyledButton = styled(Button)({
  '&.MuiButton-root': {
    marginRight: 30
  }
});

const Doctors = () => {
  const { allowAccess } = useAccessControl();

  const [exportData, setExportData] = useState<DoctorTableFormattedRow[]>([]);
  const navigate = useNavigate();

  const handleDownloadFile = async () => {
    const excelData: ExcelDataRow[] = [];

    exportData?.map((doctor: DoctorTableFormattedRow) => {
      excelData.push({
        'Doctor ID': doctor.doctor_id,
        Name: doctor.doctor_name,
        Email: doctor.doctor_email,
        'Joined Date': doctor.joined_date,
        Phone: doctor.doctor_phone,
        AHPRA: doctor.aphra_number,
        'Primary Clinic': doctor.primary_clinic ? doctor.primary_clinic : '-',
        Patients: doctor.patient_count
      });
    });

    const now = DateTime.now();
    const month = now.toFormat('MMM');
    const day = luxonFormatWithOrdinalSuffix(now.day);
    const year = now.toFormat('yyyy');
    const fileDate = `${month}-${day}-${year}`;

    const fileTitle = `PMS-Doctors-${fileDate}`;
    await exportExcel(fileTitle, excelData);
  };

  const handleOnBoardPatient = () => {
    navigate('/doctors/onboard/new');
  };

  const handleSetExportData = (formattedTableRows: DoctorTableFormattedRow[]) => {
    setExportData(formattedTableRows);
  };

  const headerButtons = (
    <Box display={'flex'} justifyContent={'end'}>
      <StyledButton variant="contained" color="secondary" onClick={() => navigate('/roundrobin-doctors')}>
        Manage Doctor Round Robins
      </StyledButton>

      <Button variant="contained" color="secondary" onClick={handleOnBoardPatient}>
        Onboard New Doctor
      </Button>
      <Button style={{ marginLeft: '30px' }} variant="contained" color="secondary" onClick={handleDownloadFile}>
        Export All
      </Button>
    </Box>
  );

  return (
    <Box component="div" p={4}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h5" color="textPrimary">
          Doctors
        </Typography>
        {allowAccess([UserRole.SuperAdmin, UserRole.Manager], headerButtons)}
      </Box>
      <DoctorsTable handleSetExportData={handleSetExportData} />
    </Box>
  );
};

export default Doctors;
