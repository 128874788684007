/**
 * This file contains utility functions for normalizing and hashing user data for GA tracking purposes,
 * following the plan outlined in the JIRA document "Event Match quality improvement plan":
 * https://montugroup.atlassian.net/wiki/spaces/DAM/pages/397738444/Event+Match+quality+improvement+plan
 */

import type { CalendarData } from '@/hooks/rest/types';
import CryptoJS from 'crypto-js';

export const normaliseString = (value: string): string => {
  return value.trim().toLowerCase();
};

/* Function to hash values for GA tracking */
export const formatStringToSha256Hash = (value: string) => {
  const sanitisedString = normaliseString(value);
  return CryptoJS.SHA256(sanitisedString).toString(CryptoJS.enc.Hex);
};

/*
  Function to validate and format phone number for Google format.
  - Check if user phone number is in E.164 format
  - Check if phone number has correct digits
*/
export const normaliseAndHashPhoneNumberForGoogle = (phoneNumber: string): string | false => {
  // Return false if the phone number DOES NOT start with a '+' symbol
  if (!phoneNumber.startsWith('+')) {
    return false;
  }

  // Return false if phone number DOES NOT follow E.164 format (11 to 15 digits after the '+')
  const e164Regex = /^\+\d{11,15}$/;
  if (!e164Regex.test(phoneNumber)) {
    return false;
  }

  // Return hashed phone number string
  return CryptoJS.SHA256(phoneNumber.trim()).toString(CryptoJS.enc.Hex);
};

/*
  Function to validate and format phone number for Meta format.
  - Remove any leading + symbol
  - Remove leading zeros
  - Ensure the number starts with 61 (Australia phone code)
*/
export const normaliseAndHashPhoneNumberForMeta = (phoneNumber: string): string | false => {
  // Remove any '+' symbols
  const formattedNumber = phoneNumber.trim().replace(/\+/g, '');

  // Check if the number starts with '61'
  if (!formattedNumber.startsWith('61')) {
    return false;
  }

  // Return hashed phone number string
  return CryptoJS.SHA256(formattedNumber).toString(CryptoJS.enc.Hex);
};

/* Function to SHA-256 hash values which come from the Calcom booking data */
export const formatBookingDataToSha256HashedValues = (bookingData: CalendarData) => {
  const { fullName, email, phoneNumber } = bookingData;
  const userName = fullName?.split(' ') || [];
  const phoneNumberGoogle = phoneNumber && normaliseAndHashPhoneNumberForGoogle(phoneNumber);
  const phoneNumberMeta = phoneNumber && normaliseAndHashPhoneNumberForMeta(phoneNumber);

  return {
    ...(userName[0] ? { sha_first_name: formatStringToSha256Hash(userName[0]) } : {}),
    ...(userName[1] ? { sha_last_name: formatStringToSha256Hash(userName[1]) } : {}),
    ...(email ? { sha_email: formatStringToSha256Hash(email) } : {}),
    ...(phoneNumberGoogle ? { sha_gads_phone_number: phoneNumberGoogle } : {}),
    ...(phoneNumberMeta ? { sha_fb_phone_number: phoneNumberMeta } : {})
  };
};
