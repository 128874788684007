export function formatPrice(
  price: number,
  locale = 'en-AU',
  options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }
) {
  return new Intl.NumberFormat(locale, options).format(price);
}
