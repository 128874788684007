import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import InputField from '../components/fields/InputField';
import ProductImageTile from '../components/ProductCatalog/ProductImageTile';
import ProductSideFilter from '../components/ProductCatalog/ProductSideFilter';
import ProductTopLayout from '../components/ProductCatalog/ProductTopLayout';
import { makeGET } from '../data/service/dataService';

const sortList = [{ label: 'Alphabetical A-Z', value: 1 }];

const ProductCatalog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = React.useState([]);
  const [sortType, setSortType] = React.useState(0);
  const [data, setData] = React.useState({
    formulations: [],
    brands: [],
    loading: true,
    ratings: []
  });

  const [filters, setFilters] = React.useState({
    formulation: [],
    brand: [],
    exclude_oos: false,
    search: '',
    ...(location.state?.filters ?? {})
  });

  React.useEffect(() => {
    const loadData = async () => {
      const resp = await makeGET('product/catalog', 'fetchProductCatalog-ProductCatalog');
      setData({
        formulations: resp.formulation.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
        brands: resp.suppliers
          .sort((a, b) => {
            const textA = a.supplier_name.toUpperCase();
            const textB = b.supplier_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .sort((a) => (a.supplier_name.toUpperCase() === 'CIRCLE' ? -1 : 1)),
        loading: false,
        ratings: resp.ratings
      });
      setProducts(resp.products.filter((x) => x !== null));
    };

    loadData();
  }, []);

  React.useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        filters
      }
    });
  }, [filters]);

  const updateFilter =
    (id, type = null) =>
    (e) => {
      const newFilters = { ...filters };
      if (type) {
        newFilters[type] = [...newFilters[type]];
        const filterChecked = newFilters[type].includes(id);
        if (e.target.checked && !filterChecked) {
          newFilters[type].push(id);
        }
        if (!e.target.checked && filterChecked) {
          newFilters[type] = newFilters[type].filter((x) => x !== id);
        }
      } else if (id === 'exclude-oos') {
        newFilters.exclude_oos = e.target.checked;
      } else if (id === 'search') {
        newFilters.search = e.target.value;
      }
      setFilters(newFilters);
    };

  const filteredProducts = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let filteredProducts = products;
    if (filters.formulation.length > 0) {
      // setting up formulation based filter
      filteredProducts = filteredProducts.filter((x) => filters.formulation.includes(x.formulation_id));
    }
    if (filters.brand.length > 0) {
      // setting up Brand based filter
      filteredProducts = filteredProducts.filter((x) =>
        filters.brand.some((r) => x.Suppliers.find((sx) => sx.id === r))
      );
    }
    if (filters.exclude_oos) {
      // Exclude Out of Stock Filter
      filteredProducts = filteredProducts.filter((x) => !x.is_out_of_stock && !x.reasoning_toggle);
    }
    if (filters.search !== '') {
      // search field filter
      filteredProducts = filteredProducts.filter((x) => {
        const searchFields = [(x.short_name || x.name).toLowerCase()]; // filtering product name with regards to search field
        if (x.Suppliers.length > 0) {
          // filtering supplier name with regards to search field
          searchFields.push(x.Suppliers.map((s) => s.supplier_name.toLowerCase()).join(''));
        }
        return searchFields.some((r) => r.includes(filters.search.toLowerCase()));
      });
    }
    return filteredProducts;
  }, [products, filters]);

  const sortedFilteredProducts = React.useMemo(() => {
    if (sortType === 1) {
      return [...filteredProducts].sort((a, b) => {
        const textA = (a.short_name || a.name).toUpperCase();
        const textB = (b.short_name || b.name).toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    return filteredProducts;
  }, [filteredProducts, sortType]);

  const clearFilters = React.useCallback(() => {
    setFilters({
      formulation: [],
      brand: [],
      exclude_oos: false,
      search: ''
    });
  }, []);

  return (
    <React.Fragment>
      <div className="product-catalog-wrapper">
        <ProductTopLayout sortList={sortList} handleChange={(e) => setSortType(e.value)} />
        <Box width="95%" display="flex">
          <Box width="15%" className=" margin-left-menu-check">
            <Box mt={1} mb={3}>
              <Button variant="outlined" sx={{ width: '100%' }} onClick={clearFilters}>
                Clear filters
              </Button>
            </Box>
            <label className="filter-heading">AVAILABILITY</label>
            <ul className="filter-menu">
              <li className="mt-1 mx-1 d-flex">
                <input
                  type="checkbox"
                  className="checkbox mt-1"
                  style={{ width: '14px', height: '14px' }}
                  id="exclude-oos"
                  onChange={updateFilter('exclude-oos')}
                  checked={filters.exclude_oos}
                />
                <label className="font-primary" htmlFor="exclude-oos">
                  In-Stock
                </label>
              </li>
            </ul>
            <ProductSideFilter
              id="formulation"
              title={'TYPE'}
              data={data.formulations}
              handleChange={(ele) => updateFilter(ele, 'formulation')}
              checked={(ele) => filters.formulation.includes(ele.id)}
            />
            <ProductSideFilter
              id="brand"
              title={'BRAND'}
              data={data.brands}
              handleChange={(ele) => updateFilter(ele, 'brand')}
              checked={(ele) => filters.brand.includes(ele.id)}
            />
          </Box>
          <Box width="85%">
            <Box width="100%">
              <InputField
                label=""
                type="text"
                placeholder="Search"
                customGrid={[12, 12]}
                display="block"
                value={filters.search}
                onChange={updateFilter('search')}
              />
            </Box>
            <Box width="100%" display="flex" flexWrap="wrap">
              {sortedFilteredProducts.map((ele) => (
                <Box key={ele.id} width="25%" height="auto">
                  <ProductImageTile product={ele} type={data.formulations.find((x) => x.id === ele.formulation_id)} />
                </Box>
              ))}
              {data.loading ? (
                <Box textAlign={'center'} className="w-100" width={'inherit'}>
                  <p>Loading...</p>
                </Box>
              ) : (
                <Box textAlign={'center'} className="w-100" width={'inherit'}>
                  {filteredProducts.length === 0 && <p>No Products Available.</p>}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
};
export default ProductCatalog;
