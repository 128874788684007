/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';

import settings from '../constants';

export const API_URL = settings.url;

/**
 * @deprecated - please create appropriate `fetch` functions or react-query hooks
 */
export const makeGET = async (url, from = '') => {
  try {
    const response = await axios.get(`${API_URL}/${url}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

/**
 * @deprecated - please create appropriate `fetch` functions or react-query hooks
 */
export const makePOST = async (url, body = null, from = '') => {
  let response = '';
  try {
    response = await axios.post(`${API_URL}/${url}`, body);
    return response;
  } catch (err) {
    return null;
  }
};

/**
 * @deprecated - please create appropriate `fetch` functions or react-query hooks
 */
export const makePUT = async (url, body, from = '') => {
  try {
    const response = await axios.put(`${API_URL}/${url}`, body);
    return response;
  } catch (err) {
    return null;
  }
};

/**
 * @deprecated - please create appropriate `fetch` functions or react-query hooks
 */
export const makeDelete = async (url, body, from = '') => {
  try {
    const response = await axios.delete(`${API_URL}/${url}`, body);
    return response;
  } catch (err) {
    return null;
  }
};
