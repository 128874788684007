import type { AlertColor, SnackbarProps, SxProps, Theme } from '@mui/material';
import { Snackbar as MuiSnackbar } from '@mui/material';

export type ExtendedSnackbarProps = SnackbarProps & {
  severity?: AlertColor;
};

const getSeverityColor = (severity?: AlertColor) => {
  switch (severity) {
    case 'success':
      // simulates the iziToast colors at the moment
      return 'rgba(166,239,184,.9)';
    case 'error':
      // simulates the iziToast colors at the moment
      return 'rgba(255,175,180,.9)';
    default:
      return `${severity || 'primary'}.light`;
  }
};
/**
 * Good replacement for `iziToast`. Same with the MUI Snackbar, with extend support for changing the snackbar colors like in `Alert`,
 * using the `severity` prop.
 *
 * The **color** for success and error currently uses the same color as `iziToast`. Worth changing our theme
 * to match design etc.
 */
export const Snackbar = ({ severity, sx, ...props }: ExtendedSnackbarProps) => {
  const paperSxBackgroundColor: SxProps<Theme> = severity
    ? {
        '& .MuiPaper-root': {
          color: 'grey.900',
          fontWeight: 600,
          backgroundColor: getSeverityColor(severity)
        }
      }
    : {};
  return (
    <MuiSnackbar
      {...props}
      sx={{
        ...paperSxBackgroundColor,
        ...sx
      }}
    />
  );
};
