import { ihiReviewLogClient } from '@/api/patients/ihiReviewLogClient';
import { QueryKey } from '@/data/query';
import type { FindIHIReviewLogsQueryApiDto } from '@montugroup/pms-api-contracts';

interface IhiPageParams {
  size: number;
  after?: string;
  before?: string;
}

export const useGetIhiReviewLog = (pageParams: IhiPageParams, filterParams: FindIHIReviewLogsQueryApiDto) => {
  const queryClient = ihiReviewLogClient();

  const {
    data: listAllData,
    isError: listAllIsError,
    isLoading: listAllIsLoading
  } = queryClient.listAll.useQuery([QueryKey.GetIhiReviewLogs, pageParams, filterParams], {
    // @ts-expect-error - Ignoring TS error for now since there seems to be an issue with Zod or TS-Rest inference of the `isResolved` type
    // It's expecting 'isResolved' to be either a string of 'true' or 'false', when in fact it SHOULD be allowed to be a boolean
    query: {
      page: pageParams,
      ...filterParams
    }
  });

  return {
    data: listAllData,
    isError: listAllIsError,
    isLoading: listAllIsLoading
  };
};
