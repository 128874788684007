import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, styled } from '@mui/material';

const StyledButton = styled(Button)({
  fontWeight: '600',
  textTransform: 'capitalize',
  fontSize: '1rem',
  display: 'flex',
  gap: '4px'
});

type State = 'loading' | 'validConcession' | 'expiredConcession' | 'noConcession';

export type ConcessionChipProps = {
  state: State;
  onClick: () => void;
};

export const ConcessionChip = ({ onClick, state }: ConcessionChipProps) => {
  switch (state) {
    case 'loading':
      return <Chip color="warning" label="Loading..." disabled />;
    case 'validConcession':
      return <Chip color="success" label="Concession holder" icon={<EditIcon />} onClick={onClick} />;
    case 'expiredConcession':
      return <Chip color="warning" label="Concession holder" icon={<EditIcon />} onClick={onClick} />;
    case 'noConcession':
      return (
        <StyledButton color="secondary" variant="text" onClick={() => onClick()}>
          <AddIcon /> <span>Add Concession Status</span>
        </StyledButton>
      );
  }
};

export default ConcessionChip;
