import type { Moment } from 'moment';
import moment from 'moment';

import type { ConcessionCardType } from './types';
import { CONCESSION_CARD_TYPE } from './types';

export function transformMomentToYearMonthDay(candidate: Moment) {
  const year = parseInt(candidate.format('YYYY'));
  const month = parseInt(candidate.format('MM')); // January = 1
  const day = parseInt(candidate.format('DD'));
  return { year, month, day };
}

export function parseMomentYearMonthDay(year: number, month: number, day: number) {
  // JS Date month is parsed as 0 -> 11, however BE tracks 1 -> 12
  return moment({ year, month: month - 1, day });
}

// Some card types expire at a month, others on a day.
export function formatExpirationForCardType(expiry: Moment, cardType: ConcessionCardType): string {
  if (cardType === CONCESSION_CARD_TYPE.VETERAN) {
    return expiry.format('YYYY-MM');
  }
  return expiry.format('YYYY-MM-DD');
}

// Format expiration consistently always as an ISO date string to make aggregation easier using a consistent data type
export function formatExpirationForAnalytics(expiry: Moment, cardType: ConcessionCardType): string {
  if (cardType === CONCESSION_CARD_TYPE.VETERAN) {
    return moment(expiry).endOf('month').format('YYYY-MM-DD');
  }
  return expiry.format('YYYY-MM-DD');
}
