import { FF_CONCESSION_DISCOUNT_RATE } from '@/constants/featureFlags';

import useConsultationPrice from '../consult/useConsultationPrice';
import { useFeatureFlags } from '../flags';

function calculateDiscountedPrice(price: string, discountRate: number) {
  return parseFloat(price) * ((100 - discountRate) / 100);
}

function formatDiscountValues(price: number, currency: string, locale: string) {
  const hasDecimal = !Number.isInteger(price);
  const minimumFractionDigits = hasDecimal ? 2 : 0;
  const formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits
  }).format(price);
  return [price.toFixed(minimumFractionDigits), formatted];
}

export function useConcessionConsultationPrice() {
  // Use the charge prices for deriving concession values instead of the display price since concession discounts are
  // separate to campaigns.
  const {
    consultationChargePrice: consultationPrice,
    followUpConsultationChargePrice: followUpConsultationPrice,
    currency,
    locale
  } = useConsultationPrice();
  const { flags } = useFeatureFlags();
  const ffDiscountRate = flags[FF_CONCESSION_DISCOUNT_RATE];
  const discountRate = parseFloat(ffDiscountRate) || 0;
  const discountedPrice = calculateDiscountedPrice(consultationPrice, discountRate);
  const [discountedConsultationPrice, formattedDiscountedConsultationPrice] = formatDiscountValues(
    discountedPrice,
    currency,
    locale
  );
  const followUpDiscountedPrice = calculateDiscountedPrice(followUpConsultationPrice, discountRate);
  const [discountedFollowUpConsultationPrice, formattedDiscountedFollowUpConsultationPrice] = formatDiscountValues(
    followUpDiscountedPrice,
    currency,
    locale
  );
  return {
    consultationPrice: discountedConsultationPrice,
    formattedConsultationPrice: formattedDiscountedConsultationPrice,
    followUpConsultationPrice: discountedFollowUpConsultationPrice,
    formattedFollowUpConsultationPrice: formattedDiscountedFollowUpConsultationPrice,
    currency,
    locale,
    discountRate
  };
}

export default useConcessionConsultationPrice;
