import { Drawer } from '@mui/material';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import type { CalcomUser } from '../shared/types/calcom-schedule-types';

import UpdateScheduleForm from './UpdateScheduleForm';

/**
 * Update Scheduler Drawer component
 *
 * @remarks
 * A 'drawer' component which renders a form that contains 'Availability Scheduler' and 'Date Overrides' form fields
 * The UpdateScheduleForm lets a user update Calcom availabilities
 *
 * @param user.userType - The type of Calcom account being updated (i.e. 'nurse', 'doctor' etc)
 * @param user.name - The Calcom users name
 * @param user.calcom_id - The Calcom users calcom ID
 *
 * @returns JSXElement
 *
 */

type UpdateScheduleDrawerProps = {
  user: CalcomUser;
};

const StyledLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none'
});

const StyledDrawer = styled(Drawer)({
  '> .MuiDrawer-paper': {
    width: '540px',
    padding: '40px 20px'
  }
});

const UpdateScheduleDrawer = ({ user }: UpdateScheduleDrawerProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleUpdateSuccess = () => setDrawerOpen(false);
  const handleOpen = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);

  return (
    <>
      <StyledLink onClick={handleOpen}>Update Schedule</StyledLink>
      <StyledDrawer
        anchor="right"
        open={drawerOpen}
        onClose={(_ev, reason) => reason === 'backdropClick' && handleClose()}
      >
        <UpdateScheduleForm user={user} handleUpdateSuccess={handleUpdateSuccess} />
      </StyledDrawer>
    </>
  );
};

export default UpdateScheduleDrawer;
