import { useCallback, useEffect, useState } from 'react';

import { getAuthData } from '@/data/service/authService';
import { FeatureNameEnum, useAlertNewFeature } from '@/hooks/useAlertNewFeature';

export const useShowCollapsibleMenuAlert = () => {
  const [openFeatureAlert, setOpenFeatureAlert] = useState<boolean>(false);
  const { user } = getAuthData();
  const { shouldShowAlertForFeature, setShouldShowAlertForFeature } =
    useAlertNewFeature({
      userId: user?.id,
      featureName: FeatureNameEnum.CollapsibleMenu
    }) || false;

  useEffect(() => {
    if (shouldShowAlertForFeature === true) {
      setOpenFeatureAlert(true);
    }
  }, [shouldShowAlertForFeature, setShouldShowAlertForFeature]);

  const handleDismissAlert = useCallback(async () => {
    setShouldShowAlertForFeature(false);
    setOpenFeatureAlert(false);
  }, [setShouldShowAlertForFeature, setOpenFeatureAlert]);

  return {
    openFeatureAlert,
    setOpenFeatureAlert,
    /**
     * Dismiss the alert, and set to NOT show again
     */
    handleDismissAlert
  };
};
