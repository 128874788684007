import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SvgIconProps } from '@mui/material';
import { alpha, List, Paper, Popper, styled } from '@mui/material';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { MenuButton } from './MenuButton';
import { classes } from './NavBarV2';

interface DropDownMenuProps {
  children: ReactNode;
  name: string;
  navLinks: React.RefObject<HTMLAnchorElement>[];
  sideBarOpen: boolean;
  icon?: React.ReactElement<SvgIconProps>;
}

const StyledDropDownMenuDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  borderRadius: '4px',
  cursor: 'pointer',
  [`& > div > ul .${classes.menuLink}`]: {
    paddingLeft: theme.spacing(14)
  },
  [`& ul li button`]: {
    paddingLeft: theme.spacing(14)
  }
}));

const StyledDropDownTextDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  color: theme.palette.primary.main,
  fontSize: '1.25rem',
  lineHeight: '1.63rem',
  letterSpacing: '0.01rem',
  textDecoration: 'none',
  borderRadius: '4px',
  alignItems: 'center',
  minHeight: '48px',
  boxSizing: 'border-box',
  textWrap: 'nowrap',
  padding: theme.spacing(2, 4),
  fontWeight: 600,
  background: alpha(theme.palette.primary.main, 0.08),
  '& > svg': {
    marginRight: theme.spacing(5)
  }
}));

const POPPER_ARROW_SIZE = 10;
const StyledDropDownMenuItemsPaper = styled(Paper)(({ theme }) => ({
  width: '286px',
  boxShadow: '-1px 2px 4px 3px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  position: 'relative',
  padding: '8px',
  '&.popper > ul > li': {
    padding: '0px',
    button: {
      paddingLeft: theme.spacing(14)
    }
  },
  '&:after': {
    width: 0,
    height: 0,
    borderRight: `${POPPER_ARROW_SIZE}px solid white`,
    borderTop: `${POPPER_ARROW_SIZE}px solid transparent`,
    borderBottom: `${POPPER_ARROW_SIZE}px solid transparent`,
    webkitFilter: 'drop-shadow(2px 0px 2px rgba(130,130,130,1))',
    filter: 'drop-shadow(2px 0px 2px rgba(130,130,130,1))',
    clipPath: 'inset(-100% 0% -100% -100%)',
    content: "''",
    display: 'inline-block',
    position: 'absolute',
    top: '20px',
    left: `-${POPPER_ARROW_SIZE}px`
  }
}));

export const DropDownMenu = ({ children, name, icon, navLinks, sideBarOpen }: DropDownMenuProps) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [isMouseOver, setMouseOver] = useState(false);
  const [hasActiveChild, setHasActiveChild] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!sideBarOpen && openMenu) {
      setOpenMenu(false);
    }

    const hasActiveChild = navLinks.some((link) => {
      return link.current?.classList.contains('active');
    });
    setHasActiveChild(hasActiveChild);

    if (sideBarOpen && !openMenu && hasActiveChild && isFirstLoad) {
      setOpenMenu(true);
      setIsFirstLoad(false);
      return;
    }
  }, [navLinks, openMenu, setOpenMenu, isFirstLoad, setIsFirstLoad, setHasActiveChild, sideBarOpen]);

  const onMouseEnterHandler = () => {
    if (!sideBarOpen) {
      setMouseOver(true);
    }
  };

  const onMouseLeaveHandler = () => {
    if (!sideBarOpen) {
      setMouseOver(false);
    }
  };

  return (
    <StyledDropDownMenuDiv
      data-testid="nav-dropdown-div"
      className={'dropDropMenuDiv'}
      ref={anchorRef}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <MenuButton
        onClick={() => sideBarOpen && setOpenMenu(!openMenu)}
        icon={icon}
        name={name}
        isSideBarOpen={sideBarOpen}
        isActive={hasActiveChild}
        className={`${hasActiveChild ? 'activeChildren' : ''} ${openMenu && sideBarOpen ? 'isOpen' : ''}`}
      >
        {sideBarOpen && !openMenu && <ExpandMoreIcon />}
        {sideBarOpen && openMenu && <ExpandLessIcon />}
      </MenuButton>

      <div data-testid="nav-dropdown-list-div" hidden={!openMenu || !sideBarOpen}>
        <List>{children}</List>
      </div>

      <Popper
        id={name}
        sx={{ zIndex: 1200, paddingLeft: '10px' }}
        placement="right-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-5, 0]
            }
          }
        ]}
        open={isMouseOver}
        anchorEl={anchorRef.current}
      >
        <StyledDropDownMenuItemsPaper data-testid="nav-dropdown-popper" className={`popper`}>
          <StyledDropDownTextDiv>
            {icon}
            {name}
          </StyledDropDownTextDiv>
          <List>{children}</List>
        </StyledDropDownMenuItemsPaper>
      </Popper>
    </StyledDropDownMenuDiv>
  );
};
