import { Box, styled } from '@mui/material';

/**
 * Invisible component for screen reader
 */
export const SROnly = styled(Box)({
  position: 'absolute',
  width: '1px',
  height: '1px',
  overflow: 'hidden'
});
