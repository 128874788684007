import { Button } from '@montugroup/design-system';
import { styled } from '@mui/material/styles';
import iziToast from 'izitoast';
import { useState } from 'react';

import BasicModal from '@/components/common/BasicModal';

import BookSpecificDoctorList from './BookSpecificDoctorList';

export interface Doctor {
  DoctorUser: {
    first_name: string;
    last_name: string;
    email: string;
  };
  calcom_initial_consult_event: string;
  calcom_follow_up_consult_event: string;
  id: number;
  doctor_code: string;
}

export type DoctorWithSlugs = {
  name: string;
  calcom_username: string;
  calcom_initial_consult_event: string;
  calcom_follow_up_consult_event: string;
  id: number;
  doctor_code: string;
};

export interface PrefillCalCom {
  name: string;
  email: string;
  phoneNumber: string;
}

type BookSpecificDoctorButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  disabledMessage?: {
    type: string;
    text: string;
  };
  doctors: Doctor[];
  assignedDoctor: Doctor;
  formData: PrefillCalCom;
  disableBookingTypeButton: boolean;
};

const StyledDisabledButtonContainer = styled('div')({
  cursor: 'not-allowed',

  'button.MuiButton-root': {
    border: 'none'
  }
});

const BookSpecificDoctorButton = (props: BookSpecificDoctorButtonProps) => {
  const {
    children,
    disabled,
    disabledMessage,
    doctors,
    assignedDoctor,
    formData,
    disableBookingTypeButton,
    ...restProps
  } = props;
  const StyledBasicModal = styled(BasicModal)(({ theme: muiTheme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '90%',
    [muiTheme.breakpoints.up('xl')]: {
      width: '60%'
    }
  }));

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOnClickDisabledButton = () => {
    if (disabled) {
      iziToast.warning({
        title: disabledMessage?.type,
        message: disabledMessage?.text,
        position: 'topRight'
      });
    }
  };

  const PopupButton = disabled ? (
    <StyledDisabledButtonContainer onClick={handleOnClickDisabledButton}>
      <Button color="secondary" disabled {...restProps}>
        {children}
      </Button>
    </StyledDisabledButtonContainer>
  ) : (
    <Button color="secondary" onClick={handleOpen} {...restProps}>
      {children}
    </Button>
  );

  return (
    <>
      {PopupButton}
      <StyledBasicModal open={modalOpen} handleClose={handleClose}>
        <BookSpecificDoctorList
          doctors={doctors}
          assignedDoctor={assignedDoctor}
          formData={formData}
          disableBookingTypeButton={disableBookingTypeButton}
          handleClose={handleClose}
        />
      </StyledBasicModal>
    </>
  );
};

export default BookSpecificDoctorButton;
