import type { MedicationPadItemType } from '@/components/PatientPageV2/components/PrescriptionPad/PrescriptionPad.types';
import { useAppStore } from '@/state-management';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useAdditionalMedication = (): (() => void) => {
  const { medicationPadItems } = useAppStore.use.prescriptionAssistant().prescriptionPad;
  const updateMedicationPadItems = useAppStore.use.updateMedicationPadItems();

  const handleAdditionalMedication = useCallback(() => {
    const medicationItem: MedicationPadItemType = { medicationPadItemId: uuidv4() };
    const updatedMedicationPadItems = [medicationItem, ...(medicationPadItems || [])];

    updateMedicationPadItems(updatedMedicationPadItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicationPadItems]);

  return handleAdditionalMedication;
};

export default useAdditionalMedication;
