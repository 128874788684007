import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';

export interface LimitedAvailabilityChipProps extends ChipProps {}

const LimitedAvailabilityChip = (props: LimitedAvailabilityChipProps) => {
  return (
    <Chip
      sx={{
        minWidth: '100px',
        maxWidth: '200px',
        backgroundColor: 'lightgrey',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '0',
        fontWeight: '600',
        fontSize: '12px'
      }}
      {...props}
    />
  );
};

export default LimitedAvailabilityChip;
