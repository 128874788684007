import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import type { DropdownOption } from '@/types/support-self-service.types';

interface Props {
  dropdown: DropdownOption;
  value: string;
  handleInputChange: (property: string, value: string) => void;
}

const SupportTaskDropdown = ({ dropdown, value, handleInputChange }: Props) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{dropdown.label}</InputLabel>
      <Select
        value={value}
        label={dropdown.label}
        onChange={(e) => handleInputChange(dropdown.property, e.target.value)}
      >
        {dropdown.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SupportTaskDropdown;
