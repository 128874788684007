/**
 * @deprecated inherit styles from theming config
 */
const styles = {
  TextField: {
    width: '100%'
  },
  input: {
    cursor: 'pointer',
    width: '100%'
  },
  disabledLabel: {
    opacity: 0.5
  },
  verticalCenterAlign: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  }
};

export default styles;
