import ReactSelect from 'react-select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ProductTopLayout = (props) => {
  return (
    <Box width="95%" display="flex" borderBottom="1px solid #e5e5e5" marginBottom={'30px'}>
      <Box width="70%" pr={2}>
        <Box ml={4} mt={5} mb={1}>
          <Typography variant="h5" color="textPrimary">
            Products
          </Typography>
        </Box>
      </Box>
      <Box width="30%" px={2}>
        <Box ml={4} mt={5} mb={1}>
          <ReactSelect options={props.sortList} onChange={props.handleChange} />
        </Box>
      </Box>
    </Box>
  );
};
export default ProductTopLayout;
