export const getCancelledOrDeletedStatus = (orderCode) => {
  return orderCode.startsWith('ALEPS') ? 'Cancelled' : 'Deleted';
};

export const showNotificationForProductSelection = (product) => {
  const {
    is_out_of_stock: isOutOfStock,
    reasoning_toggle: reasoningToggle,
    is_excluded_from_new_prescriptions: isExcludedFromNewPrescriptions,
    is_generative_scripting: isGenerativeScripting,
    is_supply_chain_risk: isSupplyChainRisk
  } = product;
  return (
    isOutOfStock || reasoningToggle || isExcludedFromNewPrescriptions || isGenerativeScripting || isSupplyChainRisk
  );
};

// Product with is_out_of_stock flag true can be selected by doctor based on stock return date.
// Hence, we don't disable the products with is_out_of_stock flag.
export const isProductUnAvailableForSelection = (product) => {
  const {
    reasoning_toggle: reasoningToggle,
    is_excluded_from_new_prescriptions: isExcludedFromNewPrescriptions,
    is_generative_scripting: isGenerativeScripting,
    is_supply_chain_risk: isSupplyChainRisk
  } = product;
  return reasoningToggle || isExcludedFromNewPrescriptions || isGenerativeScripting || isSupplyChainRisk;
};
