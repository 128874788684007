import axios from 'axios';

import settings from '@/data/constants';
import type { ISODateString } from '@/types';

export type GetNurseCalendarConsultationsParams = {
  selectedNurseIds: (string | number)[];
  dateIsoString: ISODateString;
};

export const getNurseCalendarConsultations = async ({
  selectedNurseIds,
  dateIsoString
}: GetNurseCalendarConsultationsParams) => {
  const nurseIdsParamArr = selectedNurseIds.map((nurseId) => {
    return `nurse=${nurseId}`;
  });
  const nurseIdsParamStrings = nurseIdsParamArr.join('&');
  const nurseIdsParams = nurseIdsParamStrings;

  const getNurseUrl = `${settings.url}/nurse/consultations?${nurseIdsParams}&active=true&date=${dateIsoString}`;

  const nurseConsultationsResponse = await axios(getNurseUrl, {
    method: 'GET'
  });

  return nurseConsultationsResponse;
};
