import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { fetchFilteredProductsByDoctorByDevice } from '@/api';
import type {
  FilteredProductsPayloadType,
  ProductByDeviceResponse
} from '@/api/filteredProducts/filteredProducts.types';
import { QueryKey } from '@/data/query';

const useFilteredProductsByDevice = (
  payload: FilteredProductsPayloadType,
  options?: Omit<UseQueryOptions<ProductByDeviceResponse>, 'queryKey' | 'queryFn' | 'select'>
) => {
  return useQuery({
    ...(options || {}),
    queryKey: [QueryKey.FilteredProductsByDevice, { ...payload }],
    queryFn: () => fetchFilteredProductsByDoctorByDevice(payload),
    select: (response: ProductByDeviceResponse) => response.data
  });
};

export default useFilteredProductsByDevice;
