import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getParamDataFromLocalStorage, getParamDataFromUrl, saveParamDataToLocalStorage } from '@/utils/calendar';

import { PaymentFlowBreadcrumb } from '@/components/paymentCard/components/PaymentFlowBreadcrumb';
import Alternaleaf from '../components/branding/Alternaleaf';
import PaymentCard, { PaymentCardState } from '../components/paymentCard/PaymentCard';
import { brazeChangeUser, brazeTrackEvent, brazeUpdateUserAttributesFromPaymentPage } from '../services/braze.service';

export default function CardDetail() {
  const [paymentCardState, setPaymentCardState] = useState<number | undefined>();
  let paramData = getParamDataFromUrl();

  // If there's a missing UUID, try pulling existing data from local storage
  if (!paramData.inviteeUuid) {
    const localVal = getParamDataFromLocalStorage();
    // Only mutate if an entry exists
    if (localVal) {
      paramData = localVal;
    }
  }

  // update the entry in local storage for mixpanel
  useEffect(() => {
    if (paramData.inviteeUuid) {
      saveParamDataToLocalStorage(paramData);
    }
  }, [paramData]);

  // track and send events to braze
  useEffect(() => {
    if (paramData.email) {
      brazeChangeUser(paramData.email);
      brazeTrackEvent('consult-payment-page-loaded', paramData);
      brazeUpdateUserAttributesFromPaymentPage(paramData);
    }
  }, [paramData]);

  const location = useLocation();
  const isSuccessPage = paymentCardState === PaymentCardState.Success;

  useEffect(() => {
    const isSuccess = window.location.href.includes('success');
    const isFailure = window.location.href.includes('failure');
    const fallBackState = isFailure ? PaymentCardState.Fail : PaymentCardState.Input;
    setPaymentCardState(isSuccess ? PaymentCardState.Success : fallBackState);
  }, [location]);

  return (
    <Alternaleaf>
      <Box>
        <Grid container spacing={3}>
          <Box width={'100%'}>
            <PaymentFlowBreadcrumb stepNumber={isSuccessPage ? 4 : 3} />
          </Box>
          <PaymentCard data={paramData} state={paymentCardState} />
        </Grid>
      </Box>
    </Alternaleaf>
  );
}
