import { Table } from '@montugroup/design-system';
import type { SxProps, Theme } from '@mui/material';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import type {
  DoubleBookingsPatientModel,
  DoubleBookingsPracitionerModel
} from '@/api/consultations/getConsultationDoubleBookings';
import { useGetConsultationDoubleBookings } from '@/hooks/consult/useGetConsultationDoubleBookings';

const practitionerColumnHelper = createColumnHelper<DoubleBookingsPracitionerModel>();
const patientColumnHelper = createColumnHelper<DoubleBookingsPatientModel>();

export const ConsultationDoubleBookingsTable = ({ tabValue }: { tabValue: number }) => {
  const { data, isLoading } = useGetConsultationDoubleBookings();

  const pracitionerColumns = [
    practitionerColumnHelper.accessor(
      ({ doctor_id, nurse_id }) => {
        return { doctor_id, nurse_id };
      },
      {
        id: 'View',
        header: () => (
          <Tooltip title={'View'}>
            <Box>{'View'}</Box>
          </Tooltip>
        ),
        cell: ({ getValue }) => {
          const isDoctor = getValue().doctor_id;
          return (
            // Currently no way to auto select doctor or date on consultation calendar pages.
            // Can revisit if we add url param support to calendar pages
            <Link href={isDoctor ? `/calendar` : '/nurse-calendar'} target="_blank">
              View
            </Link>
          );
        },
        meta: {
          sx: {
            width: '40px'
          }
        },
        enableSorting: false
      }
    ) as ColumnDef<DoubleBookingsPracitionerModel>,
    practitionerColumnHelper.accessor('start_time', {
      id: 'start_time',
      header: () => (
        <Tooltip title={'Consultation Date/Time'}>
          <Box>{'Consultation Date/Time'}</Box>
        </Tooltip>
      ),
      cell: ({ getValue }) => {
        const errorStyles: SxProps<Theme> = !getValue()
          ? {
              color: 'error.main'
            }
          : {};
        const startTime = getValue()
          ? DateTime.fromISO(getValue()).toFormat('dd-LLL-yyyy, H:mm a')
          : 'Invalid start date';
        return <Typography sx={errorStyles}>{`${startTime}`}</Typography>;
      },
      meta: {
        sx: {
          width: '180px'
        }
      }
    }) as ColumnDef<DoubleBookingsPracitionerModel>,
    practitionerColumnHelper.accessor(
      ({ doctor_id, nurse_id }) => {
        return { doctor_id, nurse_id };
      },
      {
        id: 'practitioner_type',
        meta: {
          sx: {
            width: '120px'
          }
        },
        header: () => (
          <Tooltip title={'Pracitioner Type'}>
            <Box>{'Pracitioner Type'}</Box>
          </Tooltip>
        ),
        cell: ({ getValue }) => {
          const isDoctor = getValue().doctor_id;
          const errorStyles: SxProps<Theme> = !getValue()
            ? {
                color: 'error.main'
              }
            : {};

          return <Typography sx={errorStyles}>{`${isDoctor ? 'Doctor' : 'Nurse'}`}</Typography>;
        }
      }
    ) as ColumnDef<DoubleBookingsPracitionerModel>,
    practitionerColumnHelper.accessor(
      ({ doctor_id, nurse_id }) => {
        return { doctor_id, nurse_id };
      },
      {
        id: 'name',
        header: () => (
          <Tooltip title={'Name'}>
            <Box>{'Name'}</Box>
          </Tooltip>
        ),
        cell: ({ row }) => {
          const firstName = row.original.Doctor?.DoctorUser?.first_name || row.original.Nurse?.NurseUser?.first_name;
          const lastName = row.original.Doctor?.DoctorUser?.last_name || row.original.Nurse?.NurseUser?.last_name;

          return <Typography>{`${firstName} ${lastName}`}</Typography>;
        }
      }
    ) as ColumnDef<DoubleBookingsPracitionerModel>
  ];

  const patientColumns = [
    patientColumnHelper.accessor('patient_id', {
      id: 'patient_id',
      header: () => (
        <Tooltip title={'View'}>
          <Box>{'View'}</Box>
        </Tooltip>
      ),
      cell: ({ getValue }) => {
        return (
          <Link href={`/patients/${getValue()}`} target="_blank">
            View
          </Link>
        );
      },
      meta: {
        sx: {
          width: '40px'
        }
      },
      enableSorting: false
    }) as ColumnDef<DoubleBookingsPatientModel>,
    patientColumnHelper.accessor('Patient', {
      id: 'patient_code',
      meta: {
        sx: {
          width: '120px'
        }
      },
      header: () => (
        <Tooltip title={'Patient Code'}>
          <Box>{'Patient Code'}</Box>
        </Tooltip>
      ),
      cell: ({ getValue }) => {
        return <Typography>{`${getValue().patient_code}`}</Typography>;
      }
    }) as ColumnDef<DoubleBookingsPatientModel>,
    patientColumnHelper.accessor('Patient', {
      id: 'name',
      meta: {
        sx: {
          width: '160px'
        }
      },
      header: () => (
        <Tooltip title={'Patient Name'}>
          <Box>{'Patient Name'}</Box>
        </Tooltip>
      ),
      cell: ({ getValue }) => {
        const firstName = getValue().PatientUser.first_name;
        const lastName = getValue().PatientUser.last_name;

        return <Typography>{`${firstName} ${lastName}`}</Typography>;
      }
    }) as ColumnDef<DoubleBookingsPatientModel>,
    {
      id: 'count',
      accessorKey: 'count',
      meta: {
        sx: {
          width: '140px'
        }
      },
      header: () => (
        <Tooltip title={'Active Consultations'}>
          <Box>{'Active Consultations'}</Box>
        </Tooltip>
      )
    },
    patientColumnHelper.accessor('nextConsultation', {
      id: 'nextConsultation',
      meta: {
        sx: {
          width: '200px'
        }
      },
      header: () => (
        <Tooltip title={'Next Consultation'}>
          <Box>{'Next Consultation'}</Box>
        </Tooltip>
      ),
      cell: ({ getValue }) => {
        const errorStyles: SxProps<Theme> = !getValue()
          ? {
              color: 'error.main'
            }
          : {};
        const value = getValue();
        const nextTime = value ? DateTime.fromISO(value).toFormat('dd-LLL-yyyy, h:mm a') : 'Invalid date/time';
        return <Typography sx={errorStyles}>{`${nextTime}`}</Typography>;
      }
    }) as ColumnDef<DoubleBookingsPracitionerModel>,
    patientColumnHelper.accessor('types', {
      id: 'types',
      header: () => (
        <Tooltip title={'Consult Types'}>
          <Box>{'Consult Types'}</Box>
        </Tooltip>
      ),
      cell: ({ getValue }) => {
        const errorStyles: SxProps<Theme> = !getValue()
          ? {
              color: 'error.main'
            }
          : {};
        const nextTime = getValue();
        return <Typography sx={errorStyles}>{`${nextTime?.join(', ')}`}</Typography>;
      }
    }) as ColumnDef<DoubleBookingsPracitionerModel>
  ];

  return (
    <Box mt={10}>
      {tabValue === 0 && (
        <Box>
          <Typography variant="h6" color="textPrimary">
            Patients with multiple active consultations
          </Typography>
          <Table
            columns={patientColumns}
            compact={true}
            data={data?.patients || []}
            isLoading={isLoading}
            total={data?.patients?.length || 0}
            showPagination
          />
        </Box>
      )}
      {tabValue === 1 && (
        <Box>
          <Typography variant="h6" color="textPrimary">
            Pracitioner double bookings
          </Typography>
          <Table
            columns={pracitionerColumns}
            compact={true}
            data={data?.pracitioners || []}
            isLoading={isLoading}
            total={data?.patients.length}
            showPagination
          />
        </Box>
      )}
    </Box>
  );
};
