import { Box, Modal, styled } from '@mui/material';

import { TutorialVideo } from './TutorialVideo';

type TutorialVideoModalProps = {
  open: boolean;
  handleClose: () => void;
};

const StyledBox = styled(Box)({
  width: '80%',
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(50%, -50%)',
  padding: '2rem'
});

export const TutorialVideoModal = ({ open, handleClose }: TutorialVideoModalProps) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox>
        <TutorialVideo />
      </StyledBox>
    </Modal>
  );
};
