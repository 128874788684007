import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import type { z } from 'zod';
import { EditModeContext, withEditModeContextProvider } from '../../EditModeContextProvider';
import EditableSections from '../../EditableSections';
import { schema } from './EmergencyContact.schema';

type FormFields = z.infer<typeof schema>;

type EmergencyContactProps = {
  editable?: boolean;
  defaultValues?: FormFields;
  onSubmit: (data: FormFields) => void;
};

function EmergencyContact({ editable = true, defaultValues, onSubmit }: EmergencyContactProps) {
  const { isEditMode } = useContext(EditModeContext);
  const formContext = useForm<FormFields>({
    values: defaultValues,
    resolver: zodResolver(schema)
  });

  return (
    <EditableSections title="Emergency Contact" editable={editable} formContext={formContext} actions={{ onSubmit }}>
      <FormContainer formContext={formContext}>
        <Grid container columnSpacing={10} rowSpacing={5} marginY={2} data-testid="emergency-contact-form">
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="firstName" label="First Name" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="lastName" label="Last Name" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement
              name="relationship"
              label="Relationship"
              disabled={!isEditMode}
              placeholder="-"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="phone" label="Phone" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
        </Grid>
      </FormContainer>
    </EditableSections>
  );
}

export default withEditModeContextProvider(EmergencyContact);
