import type { FieldArrayWithId } from 'react-hook-form';

import dayjs from '@/utils/dayjs';

import type { TimeRange } from '../../shared/types/calcom-schedule-types';

export const getDateSlotRange = (endField?: FieldArrayWithId, startField?: FieldArrayWithId) => {
  const timezoneStartRange = dayjs((startField as unknown as TimeRange).start).utc();
  const nextRangeStart = dayjs((endField as unknown as TimeRange).end).utc();
  const nextRangeEnd =
    nextRangeStart.hour() === 23
      ? dayjs(nextRangeStart).add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds')
      : dayjs(nextRangeStart).add(1, 'hour');

  const endOfDay = nextRangeStart.endOf('day');

  if (!nextRangeStart.isSame(endOfDay)) {
    return {
      append: {
        start: nextRangeStart.toDate(),
        end: nextRangeEnd.isAfter(endOfDay) ? endOfDay.toDate() : nextRangeEnd.toDate()
      }
    };
  }

  const previousRangeStart = dayjs((startField as unknown as TimeRange).start).subtract(1, 'hour');
  const startOfDay = timezoneStartRange.startOf('day');

  if (!timezoneStartRange.isSame(startOfDay)) {
    return {
      prepend: {
        start: previousRangeStart.isBefore(startOfDay) ? startOfDay.toDate() : previousRangeStart.toDate(),
        end: timezoneStartRange.toDate()
      }
    };
  }
};
