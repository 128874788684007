import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import ReactSelect from 'react-select';

import styles from './styles';

const useStyles = makeStyles(styles);

/**
 * @deprecated - please migrate to `shared-ui/Autocomplete`
 */
const SearchableSelect = (props) => {
  const classes = useStyles();

  let value = '';
  if (props.value) {
    if ((typeof props.value === 'number' || props.value === 'all') && props.options.length > 0) {
      const selected = props.options.find((x) => x.id === props.value);
      if (selected) {
        value = {
          label: selected.name,
          id: selected.id
        };
      }
    } else if (Array.isArray(props.value)) {
      value = props.value;
    }
  }

  const reactSelectProps = {
    onMenuOpen: props.onMenuOpen,
    onMenuClose: props.onMenuClose,
    menuIsOpen: props.menuIsOpen,
    noOptionsMessage: props.noOptionsMessage,
    inputValue: props.inputValue,
    isMulti: props.isMulti,
    closeMenuOnSelect: props.closeMenuOnSelect,
    onInputChange: props.onInputChange,
    onKeyDown: props.onKeyDown
  };

  return (
    <Box
      component={Grid}
      width="100%"
      display={props.display ? props.display : 'flex'}
      m={props.margin !== undefined ? props.margin : 1}
      p={isNaN(props.padding) ? 0 : props.padding}
    >
      <Grid xs={props.customGrid ? props.customGrid[1] : 8} item={true}>
        <FormControl className={classes.TextField}>
          <Box p={props.selectPadding !== undefined ? props.selectPadding : 0}>
            <ReactSelect
              id={`select-input-${props.id}`}
              aria-label={props?.label}
              value={value}
              onChange={props.onChange}
              {...(props.disabled && { menuIsOpen: false })}
              options={(props.options || []).map((itm) => ({ label: itm.name, value: itm.id }))}
              isSearchable
              isClearable={props.isClearable === true ? true : false}
              className={props.InputClasses ? props.InputClasses : ''}
              {...reactSelectProps}
              styles={{
                singleValue: (theme) => ({
                  ...theme,
                  color: '#0F455D'
                }),
                control: (theme, state) => ({
                  ...theme,
                  borderRadius: 8,
                  borderColor: !!props.error && props.touched ? 'red' : state.isFocused ? '#194EFF' : theme.borderColor,
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: state.isFocused ? '#194EFF' : 'grey'
                  },
                  minHeight: '30px',
                  backgroundColor: props.disabled ? '#efecee' : 'unset'
                }),
                menu: (theme) =>
                  props.lastField
                    ? {
                        ...theme,
                        top: 'unset',
                        bottom: '100%',
                        color: '#0F455D'
                      }
                    : theme
              }}
            />
            {!!props.error && props.touched && (
              <div style={{ color: 'red', marginTop: '.5rem' }}>{props.helperText}</div>
            )}
          </Box>
        </FormControl>
      </Grid>
    </Box>
  );
};

export default SearchableSelect;
