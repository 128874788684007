import makeStyles from '@mui/styles/makeStyles';

/** @deprecated we're moving towards using a design system */
const useStyles = makeStyles((theme) => ({
  TabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderColor: 'grey'
  },
  TabsHeader: {
    backgroundColor: 'white',
    color: 'black'
  },
  heading: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light,
    width: '100%',
    fontSize: 16,
    fontWeight: 600
  },
  Tab: {
    textTransform: 'none',
    border: '1px solid grey',
    fontSize: '16px',
    minWidth: '20%',
    color: theme.palette.primary.main
  },
  Tabs: {
    boxShadow: 'none'
  },
  tabSelected: {
    borderBottom: '1px solid black',
    backgroundColor: '#efecee',
    fontWeight: 600
  },
  checkboxIconSecondary: {
    color: theme.palette.primary.light
  },
  checkbox: {
    padding: '3px'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.secondary.main
  },
  tableLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.secondary.main
  },
  linkDanger: {
    color: `${theme.palette.orange.light} !important`
  },
  tableLinkOrange: {
    color: theme.palette.primary.main
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main
  },
  button: {
    borderRadius: 20
  },
  alternaleafButton: {
    backgroundColor: theme.palette.orange.light,
    borderRadius: 20,
    height: '100%',
    color: 'white',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  floatRight: {
    float: 'right'
  },
  subHeading: {
    color: 'black',
    fontSize: 14,
    fontWeight: 600
  },
  light_color_button: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '20px',
    padding: '10px 20px 4px 20px !important',
    marginLeft: '1rem !important',
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: '#0F455D',
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: theme.palette.primary.light
    }
  },
  selectOption: {
    color: '#0F455D'
  },
  labellineHeight: {
    lineHeight: '18px'
  },
  selectInput: {
    padding: '0px 5px !important'
  },
  accordionDetail: {
    padding: '0px 20px 20px 20px'
  },
  chips_container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  statusBullet: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '20px',
    padding: '5px 20px 4px 20px',
    marginLeft: '1rem',
    fontWeight: 600,
    color: '#0F455D',
    textAlign: 'center'
  },
  menuButton: {
    background: '#bdbbbb',
    marginLeft: '10px',
    color: 'white',
    width: '40px',
    height: '40px',
    '&:hover': {
      background: '#194EFF'
    }
  },
  menuBtnActive: {
    background: '#194EFF',
    marginLeft: '10px',
    color: 'white',
    width: '40px',
    height: '40px'
  },
  paragraph: {
    margin: '10px'
  }
}));

export default useStyles;
