import { alpha, css, styled, Tab, Tabs } from '@mui/material';

import type { TabStyleVariant } from './Tabs.types';

export type StyledTabProps = {
  styleVariant: TabStyleVariant;
};

export const StyledTabs = styled(Tabs)(
  ({ theme }) => css`
    border-radius: 30px;
    background-color: ${alpha(theme.palette.secondary.light, 0.3)};
    .MuiTabs-flexContainer {
      position: relative;
      z-index: 2;

      .Mui-selected {
        color: white;
        font-weight: 700;
      }
    }
    .MuiTabs-indicator {
      background-color: ${theme.palette.secondary.main};
      height: 100%;
      border-radius: 30px;
      z-index: 1;
    }
  `
);

export const StyledTab = styled(Tab)(css`
  text-transform: none;
  font-size: 16px;
`);
