import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, styled } from '@mui/material';
import React from 'react';

export type PaymentAccordionProps = {
  accordionHeading: React.ReactNode;
  accordionContent: React.ReactNode;
  handleAccordionOnChange: (expanded: boolean) => void;
  isExpanded: boolean;
};

const StyledAccordionContainer = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.pillCard.main,
  position: 'absolute',
  top: 0,
  zIndex: 1000,
  left: 0,
  padding: 0,
  marginLeft: '-12px',
  width: 'calc(100% + 36px)',

  '.MuiPaper-root.Mui-expanded': {
    marginBottom: 0
  },

  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.pillCard.main,
  boxShadow: 'none',

  '.MuiPaper-rounded': {
    borderRadius: 0,
    boxShadow: 'none'
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '0 15px',

  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0'
  }
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0
});

const StyledBackdrop = styled(Backdrop)({
  zIndex: 1
});

const PaymentAccordion = (props: PaymentAccordionProps) => {
  const { accordionHeading, accordionContent, handleAccordionOnChange, isExpanded } = props;

  return (
    <>
      <StyledAccordionContainer>
        <StyledAccordion onChange={(_, expanded) => handleAccordionOnChange(expanded)} expanded={isExpanded}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>{accordionHeading}</StyledAccordionSummary>
          <StyledAccordionDetails>{accordionContent}</StyledAccordionDetails>
        </StyledAccordion>
      </StyledAccordionContainer>
      <StyledBackdrop open={isExpanded} onClick={() => handleAccordionOnChange(false)} />
    </>
  );
};

export default PaymentAccordion;
