import { Alert, Box, LinearProgress, styled, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';

import type { TableRowData } from '@/components/rescripting/RescriptingPage.types';
import {
  createHeaderCells,
  formatDataForTable,
  getDefaultAlternativeProduct,
  makeApprovedPrescriptionChangeset,
  makeNewPrescriptionProduct,
  scriptProductToRescriptProduct,
  sortTableData
} from '@/components/rescripting/RescriptingPage.utils';
import {
  FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES,
  FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB
} from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useRescriptAlternatives } from '@/hooks/rescripting/useRescriptAlternatives';
import type { UseGetPrescriptionsResponse } from '@/hooks/rest/useGetPrescriptionsWithGenerativeScripting';
import { useGetPrescriptionsWithGenerativeScripting } from '@/hooks/rest/useGetPrescriptionsWithGenerativeScripting';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import type { Rescript } from '@/hooks/rest/usePutPrescriptBatchReplace';
import usePutPrescriptBatchReplace from '@/hooks/rest/usePutPrescriptBatchReplace';
import type { Prescription } from '@/types';

import RescriptingTable from './components/RescriptingTable';
import { RescriptingTableFilter } from './components/RescriptingTableFilter';
import { useHandleRescriptingPremission } from './hooks/useHandleRescriptingPermissions';
import { RescriptingPageContent } from './RescriptingPage.styled';

const NoticeList = styled('ul')({
  margin: '0.5rem 2rem'
});

const SELECT_ALTERNATIVE_PRODUCT_LABEL_ID = 'selectedAlternativeProductId';
const DOCTOR_NAME_LABEL_ID = 'doctorName';

const discontinuedLabels: { id: keyof TableRowData; tableHeading: string }[] = [
  { id: 'orderCode', tableHeading: 'Order Code' },
  { id: 'patientName', tableHeading: 'Patient Name' },
  { id: 'patientDob', tableHeading: 'Patient DOB' },
  { id: 'patientCode', tableHeading: 'Patient Code' },
  { id: 'ossProductName', tableHeading: 'Discontinued Medication' },
  { id: 'scriptCreationDate', tableHeading: 'Script Creation' },
  { id: 'allProductsOnScript', tableHeading: 'Script Medications' },
  { id: 'generativeCount', tableHeading: 'Generative Count' },
  { id: 'totalCount', tableHeading: 'Total Count' },
  { id: SELECT_ALTERNATIVE_PRODUCT_LABEL_ID, tableHeading: 'Alternative Medication' },
  { id: DOCTOR_NAME_LABEL_ID, tableHeading: 'Doctor' }
];

const discontinuedHeaderCells = createHeaderCells(discontinuedLabels);

/**
 * Filter out prescriptions that do not contain medications
 * that are not flagged with `is_generative_scripting`.
 */
const filterFlaggedPrescriptions = (data: UseGetPrescriptionsResponse): Prescription[] => {
  const prescriptions = data.prescriptions as Prescription[];
  const filtered: Prescription[] = [];

  prescriptions.forEach((pres) => {
    const containsGenerativeProduct = pres.Products.some((product) => product.is_generative_scripting);
    if (containsGenerativeProduct) {
      filtered.push(pres);
    }
  });

  return filtered;
};

export default function RescriptingDiscontinuedPage() {
  const [modifiedScripts, setModifiedScripts] = useState<Map<string, CatalogProduct>>(new Map());
  const [selectedPatientCodes, setSelectedPatientCodes] = useState<string[]>([]);
  const [selectedDoctorNames, setSelectedDoctorNames] = useState<string[]>([]);
  const [selectedProductNames, setSelectedProductNames] = useState<string[]>([]);
  const { flags } = useFeatureFlags();
  const ffEnableProductIssueEnquiries = flags[FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES];
  const disableDiscontinuedTab = flags[FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB];

  const {
    doPut: putPrescriptionBatchReplace,
    loading: putPrescriptionLoading,
    error: putPrescriptionError,
    data: putPrescriptionData
  } = usePutPrescriptBatchReplace();

  // Fetch scripts, filter and format as needed
  const {
    data: prescriptionData,
    loading: prescriptionRequestLoading,
    error: prescriptionRequestError,
    doGet: doGetPrescriptions
  } = useGetPrescriptionsWithGenerativeScripting({
    limit: 1000,
    status: 'ACTIVE'
  });

  const scriptData = prescriptionData ? filterFlaggedPrescriptions(prescriptionData) : [];
  let tableData = scriptData ? formatDataForTable(scriptData, modifiedScripts) : [];

  if (ffEnableProductIssueEnquiries) {
    tableData = sortTableData(tableData);
  }

  const doctorNameSearchOptions = new Set(tableData.map((s) => s.doctorName || ''));
  const patientCodeSearchOptions = new Set(tableData.map((s) => s.patientCode || ''));
  const oosProductNameSearchOptions = new Set(tableData.map((s) => s.ossProductName || ''));

  // Fetch product list that will be used within the dropdowns
  const { parsed: productList, loading: productRequestLoading, error: productRequestError } = useRescriptAlternatives();

  const approvalSuccess = Boolean(putPrescriptionData);
  const loading = prescriptionRequestLoading || productRequestLoading || putPrescriptionLoading;

  const { shouldShowErrorAlert, isAbleToActionScriptCreation } = useHandleRescriptingPremission({
    isGetPrescriptionError: Boolean(prescriptionRequestError),
    isPutPrescriptionError: Boolean(putPrescriptionError),
    isGetProductsError: Boolean(productRequestError)
  });

  const filteredTableData =
    selectedPatientCodes.length > 0
      ? tableData.filter((script) => selectedPatientCodes.includes(script.patientCode || ''))
      : tableData;

  const filteredTableDataByDoctors =
    selectedDoctorNames.length > 0
      ? filteredTableData.filter(
          (script) => script.doctorName !== null && selectedDoctorNames.includes(script.doctorName)
        )
      : filteredTableData;

  const filteredTableDataByMedications =
    selectedProductNames.length > 0
      ? filteredTableDataByDoctors.filter(
          (script) => script.ossProductName !== null && selectedProductNames.includes(script.ossProductName)
        )
      : filteredTableDataByDoctors;

  const headerCells = useMemo(
    () =>
      isAbleToActionScriptCreation
        ? discontinuedHeaderCells.filter((cell) => cell.id !== DOCTOR_NAME_LABEL_ID)
        : discontinuedHeaderCells.filter((cell) => cell.id !== SELECT_ALTERNATIVE_PRODUCT_LABEL_ID),
    [isAbleToActionScriptCreation]
  );

  async function submitApprovedPrescriptions(prescriptions: Rescript[]) {
    const { data } = await putPrescriptionBatchReplace({ prescriptions });
    if (data) {
      // Reload table data
      // TODO: Use setData pattern with useGetPrescriptions for better efficiency
      await doGetPrescriptions();
    }
  }

  // Main action for selected items (currently Approve Selected)
  const handleApproval = async (approvedPrescriptions: string[]) => {
    const originalScriptPerId = new Map(prescriptionData?.prescriptions.map((script) => [script.id, script]));
    const approvedScriptChangeset = makeApprovedPrescriptionChangeset(approvedPrescriptions, modifiedScripts);
    const resultArray: Rescript[] = [];

    approvedScriptChangeset.forEach((approvedProductChangeset, scriptId) => {
      const originalScript = originalScriptPerId.get(scriptId);
      const originalScriptProducts = originalScript?.Products || [];
      const originalRescriptProducts = originalScriptProducts.map(scriptProductToRescriptProduct);
      const newScriptProducts = [...originalRescriptProducts];

      approvedProductChangeset.forEach((modifiedScriptProduct, productId) => {
        const currentIndex = newScriptProducts.findIndex((rescriptProduct) => rescriptProduct.productId === productId);
        const currentRescriptProduct = newScriptProducts[currentIndex];
        const currentScriptProduct = originalScriptProducts.find((scriptProduct) => scriptProduct.id === productId);
        const defaultAlternativeProduct = getDefaultAlternativeProduct(currentScriptProduct);
        newScriptProducts[currentIndex] = makeNewPrescriptionProduct(
          currentRescriptProduct,
          modifiedScriptProduct,
          defaultAlternativeProduct
        );
      });
      resultArray.push({
        originalPrescriptionId: scriptId,
        newPrescription: {
          orderDate: moment().toISOString(),
          patientId: originalScript?.Patient.id,
          // Use logged in doctor's id.
          doctorId: originalScript?.Doctor.id,
          products: newScriptProducts
        }
      });
    });

    await submitApprovedPrescriptions(resultArray);
  };

  const handleProductSelected = (uniqueKey: string, selectedProduct: CatalogProduct | undefined) => {
    if (selectedProduct) {
      setModifiedScripts((prevMap) => new Map(prevMap).set(uniqueKey, selectedProduct));
    }
  };

  return (
    <RescriptingPageContent component="div">
      <Box pt={4} pb={8} paddingX={2}>
        {!isAbleToActionScriptCreation && !disableDiscontinuedTab && (
          <Alert severity="info">
            {' '}
            Doctors are not able to see the Discontinued scripts tab, they are not able to action Discontinued scripts.
            The functionality will be updated for use in the future.
          </Alert>
        )}
        <Typography variant="body1" marginTop={!isAbleToActionScriptCreation && !disableDiscontinuedTab ? 4 : 0}>
          Please review the following prescriptions, which contain an out of stock or discontinued product. In most
          cases, a substitution suggestion has been provided, based on comparable pharmacological features. <br />
          <br /> You are able to change the suggested replacement. Approving the replacement prescription will trigger
          the following actions:
        </Typography>
        <Typography component="div">
          <NoticeList>
            <li>The original prescription, inclusive of all products, will be cancelled.</li>
            <li>
              A new prescription will be generated, the replacement product superceding the out of stock or discontinued
              product, with repeats adjusted to account for supplies already used.
            </li>
            <li>
              Any non-out of stock products from the original prescription will be brought forward onto the replacement
              prescription, with repeats adjusted to account for supplies already used and ensuring all product dispense
              intervals remain aligned.
            </li>
          </NoticeList>
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" fontSize="24px" mb="1" fontWeight={600}>
          Discontinued scripts
        </Typography>
        {approvalSuccess && (
          <Alert sx={{ marginY: 1 }} severity="success">
            Patient scripts successfully created
          </Alert>
        )}
        {shouldShowErrorAlert && (
          <Alert sx={{ marginY: 1 }} severity="error">
            An error has occurred.
          </Alert>
        )}
        <Box display="flex" gap={3} paddingY={2}>
          <Box flex={1}>
            <RescriptingTableFilter
              label="Filter patient codes"
              placeholder="Start typing to search"
              options={Array.from(patientCodeSearchOptions)}
              onSelect={setSelectedPatientCodes}
            />
          </Box>
          <Box flex={1}>
            <RescriptingTableFilter
              label="Filter product names"
              placeholder="Start typing to search"
              options={Array.from(oosProductNameSearchOptions)}
              onSelect={setSelectedProductNames}
            />
          </Box>
          {!isAbleToActionScriptCreation && (
            <Box flex={1}>
              <RescriptingTableFilter
                label="Doctors"
                placeholder="Start typing to search"
                options={Array.from(doctorNameSearchOptions)}
                onSelect={setSelectedDoctorNames}
              />
            </Box>
          )}
        </Box>
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <RescriptingTable
            tableData={filteredTableDataByMedications}
            productListData={productList}
            handleApproval={handleApproval}
            handleProductSelected={handleProductSelected}
            mainHeadCells={headerCells}
            showGenerativeCount={true}
            canActionScriptCreation={isAbleToActionScriptCreation}
          />
        )}
      </Box>
    </RescriptingPageContent>
  );
}
