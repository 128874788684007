import type { Theme } from '@mui/material';
import { Button, styled } from '@mui/material';
import { useEffect } from 'react';

import type dayjs from '@/utils/dayjs';
import type { Dayjs } from '@/utils/dayjs';

import { daysInMonth, yyyymmdd } from '../../shared/utils/date-utils';
import { getAvailableDatesInMonth } from '../../shared/utils/get-available-dates-in-month';

import type { DatePickerProps } from './DatePicker';
import NoAvailabilityOverlay from './NoAvailabilityOverlay';

/**
 * DatePickerDays component
 *
 * @remarks
 * A component for the days in the DatePicker
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'Days'
 * See - https://github.com/calcom/cal.com/blob/main/packages/features/calendars/DatePicker.tsx
 *
 * @param date - The Date for a day
 * @param active - A boolean for whether the day is selected
 * @param disabled - A boolean for whether the day is disabled (i.e. already has a date or is excluded as a possible day)
 * @param - See DatePickerProps for details of props
 *
 * @returns JSXElement
 *
 */

const StyledButton = styled(Button)(
  ({ isActive, isDisabled, theme }: { isActive: boolean; isDisabled: boolean | undefined; theme: Theme }) => ({
    position: 'relative',
    padding: 10,
    borderRadius: 5,
    backgroundColor:
      isDisabled && isActive
        ? theme.palette.primary.dark
        : isDisabled
          ? theme.palette.grey[200]
          : isActive
            ? theme.palette.primary.dark
            : theme.palette.grey[400],
    minWidth: '100%',

    '&.MuiButton-root': {
      color: isDisabled ? theme.palette.grey[400] : isActive ? theme.palette.common.white : theme.palette.grey[900]
    },

    '&.MuiButton-root:hover': {
      backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.grey[800],
      color: theme.palette.common.white
    }
  })
);

const StyledTodayIndicator = styled('div')({
  width: 5,
  height: 5,
  display: 'block',
  position: 'absolute',
  background: 'black',
  borderRadius: 10,
  bottom: '10%',

  '> span': {
    display: 'none'
  }
});

export const DatePickerDay = ({
  date,
  active,
  disabled,
  ...props
}: JSX.IntrinsicElements['button'] & {
  active: boolean;
  date: Dayjs;
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <StyledButton isActive={active} isDisabled={disabled} data-testid="day" disabled={disabled} {...props}>
      {date.date()}
      {date.isToday() && (
        <StyledTodayIndicator>
          <span>today</span>
        </StyledTodayIndicator>
      )}
    </StyledButton>
  );
};

const DatePickerDays = ({
  minDate,
  excludedDates = [],
  browsingDate,
  weekStart,
  DayComponent = DatePickerDay,
  selected,
  month,
  nextMonthButton,
  ...props
}: Omit<DatePickerProps, 'locale' | 'className' | 'weekStart'> & {
  DayComponent?: React.FC<React.ComponentProps<typeof DatePickerDay>>;
  browsingDate: Dayjs;
  weekStart: number;
  month: string | null;
  nextMonthButton: () => void;
}) => {
  // Create placeholder elements for empty days in first week
  const weekdayOfFirst = browsingDate.date(1).day();

  const includedDates = getAvailableDatesInMonth({
    browsingDate: browsingDate.toDate(),
    minDate,
    includedDates: props.includedDates
  });

  const days: (Dayjs | null)[] = Array((weekdayOfFirst - weekStart + 7) % 7).fill(null);
  for (let day = 1, dayCount = daysInMonth(browsingDate); day <= dayCount; day++) {
    const date = browsingDate.set('date', day);
    days.push(date);
  }

  const isActive = (day: dayjs.Dayjs) => {
    // for selecting a range of dates
    if (Array.isArray(selected)) {
      return Array.isArray(selected) && selected?.some((e) => yyyymmdd(e) === yyyymmdd(day));
    }

    if (selected && yyyymmdd(selected) === yyyymmdd(day)) {
      return true;
    }

    return false;
  };

  const daysToRenderForTheMonth = days.map((day) => {
    if (!day) {
      return { day: null, disabled: true };
    }
    return {
      day: day,
      disabled: (includedDates && !includedDates.includes(yyyymmdd(day))) || excludedDates.includes(yyyymmdd(day))
    };
  });

  /**
   * Takes care of selecting a valid date in the month if the selected date is not available in the month
   */

  const useHandleInitialDateSelection = () => {
    // Let's not do something for now in case of multiple selected dates as behaviour is unclear and it's not needed at the moment
    if (selected instanceof Array) {
      return;
    }
    const firstAvailableDateOfTheMonth = daysToRenderForTheMonth.find((day) => !day.disabled)?.day;

    const isSelectedDateAvailable = selected
      ? daysToRenderForTheMonth.some(({ day, disabled }) => {
          if (day && yyyymmdd(day) === yyyymmdd(selected) && !disabled) {
            return true;
          }
        })
      : false;

    if (!isSelectedDateAvailable && firstAvailableDateOfTheMonth) {
      // If selected date not available in the month, select the first available date of the month
      props.onChange(firstAvailableDateOfTheMonth);
    }

    if (!firstAvailableDateOfTheMonth) {
      props.onChange(null);
    }
  };

  useEffect(useHandleInitialDateSelection);

  return (
    <>
      {daysToRenderForTheMonth.map(({ day, disabled }, idx) => (
        <div key={day === null ? `e-${idx}` : `day-${day.format()}`}>
          {day === null ? (
            <div key={`e-${idx}`} />
          ) : props.isLoading ? (
            <Button key={`e-${idx}`} disabled>
              -
            </Button>
          ) : (
            <DayComponent
              date={day}
              onClick={() => {
                props.onChange(day);
              }}
              disabled={disabled}
              active={isActive(day)}
            />
          )}
        </div>
      ))}

      {!props.isLoading && includedDates && includedDates?.length === 0 && (
        <NoAvailabilityOverlay month={month} nextMonthButton={nextMonthButton} />
      )}
    </>
  );
};

export default DatePickerDays;
